/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { produce } from "immer";
import { Fragment, useCallback, useState } from "react";
import {
  Alert,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { FlexColDiv, FlexDiv, FlexRowDiv } from "styles/container-elements";

import { Button } from "components/atoms/Button.atom";
import { FontSize, Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { useIsMediumAndDown } from "components/responsive";

import { fieldConfiguration } from "./const";
import { useCustomerSupportTranslations } from "shared/hooks/useCustomerSupportTranslations";

export const CustomerSupport = ({
  submitSupportTicket,
  resetTicketResponseData,
  ticketResponseData,
  sidebarMinimize,
  isCustomerSupportEnabled,
}) => {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const isMobileView = useIsMediumAndDown();

  if (!isCustomerSupportEnabled) {
    return null;
  }
  return (
    <Fragment>
      <FlexDiv
        css={{
          // Using px to match the other NavGroups
          padding: "10px 5px 10px 0",
          marginLeft: "0.5em",
          ":hover": {
            color: "white",
            cursor: "pointer",
            ".navicon": {
              color: Colors.background.AQUA,
            },
          },
        }}
        onClick={() => setDisplayModal(true)}
      >
        <FlexRowDiv css={{ alignItems: "center" }}>
          <FlexColDiv
            css={{
              // Using px to match the other NavGroups
              minWidth: 34,
              minHeight: 34,
              justifyContent: "center",
            }}
          >
            <Icon
              src={faQuestionCircle}
              className="navicon"
              size={FontSize.size20}
              color={Colors.background.DARK_TEAL}
            />
          </FlexColDiv>

          {!sidebarMinimize && !isMobileView ? (
            <Text bold>{t("support:Contact Us")}</Text>
          ) : null}
        </FlexRowDiv>
      </FlexDiv>
      <ContactUsModal
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        submitSupportTicket={submitSupportTicket}
        ticketResponseData={ticketResponseData}
        resetTicketResponseData={resetTicketResponseData}
      />
    </Fragment>
  );
};

CustomerSupport.propTypes = {
  isCustomerSupportEnabled: PropTypes.bool,
  sidebarMinimize: PropTypes.bool,
  submitSupportTicket: PropTypes.func.isRequired,
  ticketResponseData: PropTypes.object,
  resetTicketResponseData: PropTypes.func.isRequired,
};

const AlertMessage = ({ ticketResponseData, successMessage, errorMessage }) => {
  const { status, isLoadingError } = ticketResponseData;
  if (status === null && isLoadingError === false) {
    return null;
  }
  const isSuccess = isLoadingError === false && status === 201;

  return (
    <Alert variant={isSuccess ? "success" : "danger"}>
      {isSuccess ? successMessage : errorMessage}
    </Alert>
  );
};

AlertMessage.propTypes = {
  ticketResponseData: PropTypes.object,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

export const ContactUsModal = ({
  displayModal,
  setDisplayModal,
  submitSupportTicket,
  ticketResponseData,
  resetTicketResponseData,
}) => {
  const { t } = useTranslation("support");
  const { getContactUsFormFieldLabelTranslation } =
    useCustomerSupportTranslations();

  const isSuccess = ticketResponseData?.status === 201;

  const getInitialState = (config) => {
    let initialState = {};
    config.forEach((field) => {
      initialState[field.key] = field.default;
    });
    return initialState;
  };

  const [data, setData] = useState(getInitialState(fieldConfiguration));
  const [validated, setValidated] = useState(false);

  const updateData = useCallback((field, val) => {
    setData(
      produce((draft) => {
        draft[field] = val;
      }),
    );
  }, []);

  const submitHandler = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity()) {
      submitSupportTicket(data);
    }
    setValidated(true);
  };

  const resetForm = () => {
    setData(getInitialState(fieldConfiguration));
    setValidated(false);
  };

  const handleModalHide = () => {
    setDisplayModal(false);
    resetForm();
    resetTicketResponseData();
  };

  return (
    <Modal
      backdrop="static"
      show={displayModal}
      onHide={() => {
        handleModalHide();
      }}
    >
      <Modal.Header
        closeButton
        style={{
          padding: "0.5em",
          color: Colors.background.DARK_BLUE,
          backgroundColor: Colors.background.LIGHT_GRAY,
        }}
      >
        <Modal.Title style={{ fontWeight: "300" }}>
          {t("support:Contact Us")}
        </Modal.Title>
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={submitHandler}>
        <Modal.Body
          css={{
            overflow: "hidden",
          }}
        >
          {isSuccess ? null : (
            <Text>
              {t(
                "support:Our team is happy to help. Fill out the form and we'll be in touch as soon as possible.",
              )}
            </Text>
          )}
          <FlexDiv
            css={{
              marginTop: "1em",
              marginLeft: 0,
              width: "100%",
              flexDirection: "column",
            }}
          >
            <AlertMessage
              ticketResponseData={ticketResponseData}
              successMessage={t("support:New ticket has been created.")}
              errorMessage={t(
                "support:Ticket submission failed. Please try again later!",
              )}
            />

            {isSuccess ? null : (
              <FlexRowDiv css={{ flexWrap: "wrap", width: "100%" }}>
                {fieldConfiguration.map((field) => (
                  <FormGroup
                    key={field.key}
                    css={{
                      paddingRight: "1em",
                      width: field.width,
                      marginBottom: "1rem",
                    }}
                  >
                    <FormLabel>
                      {getContactUsFormFieldLabelTranslation(field.label)}
                      {field.required ? <sup>*</sup> : null}
                    </FormLabel>
                    <FormControl
                      as={field.type === "textarea" ? "textarea" : "input"}
                      type={field.type}
                      required={field.required}
                      value={data[field.key]}
                      onChange={(e) => {
                        updateData(field.key, e.target.value);
                      }}
                      rows={field.type === "textarea" ? 3 : 1}
                    />
                    <FormControl.Feedback type="invalid">
                      {t("support:Please enter valid data.")}
                    </FormControl.Feedback>
                  </FormGroup>
                ))}
              </FlexRowDiv>
            )}
          </FlexDiv>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          {isSuccess ? (
            <Button
              variant="outline-secondary"
              style={{
                marginRight: "0.5em",
              }}
              onClick={handleModalHide}
              data-qa="button-cancel-contact-us-modal"
            >
              {t("support:Close")}
            </Button>
          ) : (
            <Fragment>
              <Button
                variant="outline-secondary"
                style={{
                  marginRight: "0.5em",
                }}
                onClick={handleModalHide}
                data-qa="button-cancel-contact-us-modal"
              >
                {t("support:Cancel")}
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={ticketResponseData?.isLoading}
                data-qa="button-submit-contact-us-modal"
              >
                {ticketResponseData?.isLoading ? (
                  <Icon src={faSpinner} spin />
                ) : (
                  t("support:Submit")
                )}
              </Button>
            </Fragment>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ContactUsModal.propTypes = {
  displayModal: PropTypes.bool,
  setDisplayModal: PropTypes.func.isRequired,
  submitSupportTicket: PropTypes.func.isRequired,
  ticketResponseData: PropTypes.object,
  resetTicketResponseData: PropTypes.func,
};
