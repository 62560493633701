import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  FilterTypesEnum,
  ModeOptionsEnum,
  ScheduleDeliveryOptionsEnum,
} from "pages/administration/notification-management/notificationmanagement.const";

export const useNotificationManagementTranslations = () => {
  const { t } = useTranslation("notification-management");

  const translateFilterType = useCallback(
    (filterType: FilterTypesEnum) => {
      switch (filterType) {
        case FilterTypesEnum.ORIGIN:
          return t("notification-management:Origin");
        case FilterTypesEnum.DESTINATION:
          return t("notification-management:Destination");
        case FilterTypesEnum.SCHEDULED_DELIVERY:
          return t("notification-management:Scheduled Delivery");
        case FilterTypesEnum.PARTS_NUMBER:
          return t("notification-management:Part Number");
        case FilterTypesEnum.CARRIER:
          return t("notification-management:Carrier");
        case FilterTypesEnum.MILES_OUT:
          return t("notification-management:Miles Out");
        case FilterTypesEnum.MODE:
          return t("notification-management:Mode");
        default:
          return filterType;
      }
    },
    [t],
  );

  return { translateFilterType };
};

export const useTranslateModeOptionType = () => {
  const { t } = useTranslation("notification-management");

  return useCallback(
    (filterType: ModeOptionsEnum) => {
      switch (filterType) {
        case ModeOptionsEnum.LTL:
          return "LTL";
        case ModeOptionsEnum.OCEAN:
          return t("notification-management:Ocean");
        case ModeOptionsEnum.PARCEL:
          return t("notification-management:Parcel");
        case ModeOptionsEnum.RAIL:
          return t("notification-management:Rail");
        case ModeOptionsEnum.TRUCK:
          return t("notification-management:Truck");
        case ModeOptionsEnum.AIR:
          return t("notification-management:Air");
        case ModeOptionsEnum.INTERMODAL:
          return t("notification-management:Intermodal");
        case ModeOptionsEnum.MULTIMODAL:
          return t("notification-management:Multimodal");
        default:
          return filterType;
      }
    },
    [t],
  );
};

export const useTranslateScheduledDeliveryOptionType = () => {
  const { t } = useTranslation("notification-management");

  return useCallback(
    (filterType: ScheduleDeliveryOptionsEnum) => {
      switch (filterType) {
        case ScheduleDeliveryOptionsEnum.TODAY:
          return t("notification-management:Today");
        case ScheduleDeliveryOptionsEnum.TOMORROW:
          return t("notification-management:Tomorrow");
        case ScheduleDeliveryOptionsEnum.THIS_WEEK:
          return t("notification-management:This Week");
        case ScheduleDeliveryOptionsEnum.THIS_MONTH:
          return t("notification-management:This Month");
        default:
          return filterType;
      }
    },
    [t],
  );
};
