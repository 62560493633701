import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import {
  productTypesUrl,
  positionUpdateCitiesUrl,
} from "modules/domain-data/ConnectedCarDomainData";
import { buildLocationAsyncFilterOptionsState } from "modules/location/utils/buildLocationAsyncFilterOptionsState";

export const originOptionsState = buildLocationAsyncFilterOptionsState({
  topic: "originShipmentFilter",
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const destinationOptionsState = buildLocationAsyncFilterOptionsState({
  topic: "destinationShipmentFilter",
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "connectedCarCityFilter",
  getUrl: productTypesUrl,
  pageSize: 20,
  getResponseData: (data) => data?.productTypes ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const connectedCarCityOptionsState = buildAsyncFilterOptionsState({
  topic: "connectedCarCityFilter",
  getUrl: positionUpdateCitiesUrl,
  pageSize: 20,
  getResponseData: (data) => data?.positionUpdateCity ?? [],
  transformResult: (result) => ({ label: result, value: result }),
});
