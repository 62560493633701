/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";
import { Modal } from "react-bootstrap";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "components-old/modal-elems";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";

export const ShipmentDwellNotificationDeleteModal = ({
  show,
  hide,
  rowData,
  modifyDwellNotification,
  isModifyDwellNotificationLoading,
  fetchPrefillData,
  isPrefillDataLoading,
  location,
  recipients,
  searchEntities,
}) => {
  const { t } = useTranslation("shipment-dwell");

  const code = rowData?.loc_code;
  useEffect(() => {
    if (show) {
      fetchPrefillData(code);
    }
  }, [show, fetchPrefillData, code]);

  const locationName = location?.name;
  const locationCode = location?.code;
  const locationAddress = location?.address;

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <ModalHeader title={t("shipment-dwell:Delete Notification")} />
      <ModalBody>
        <div css={{ position: "relative", minHeight: "3em" }}>
          <Loader
            loaded={!isPrefillDataLoading}
            scale={0.75}
            top="50%"
            left="50%"
          >
            <Text block css={{ marginBottom: "1em" }}>
              {t(
                "shipment-dwell:Are you sure you want to delete all dwell notifications for this location?",
              )}
            </Text>
            {locationName ? (
              <Text block bold align="center" size={FontSize.size16}>
                {locationName}
                {locationCode ? ` (${locationCode})` : null}
                {locationAddress ? (
                  <Text block align="center" size={FontSize.size16}>
                    {locationAddress}
                  </Text>
                ) : null}
              </Text>
            ) : null}{" "}
          </Loader>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="light"
          onClick={() => {
            hide();
          }}
        >
          {t("shipment-dwell:Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            modifyDwellNotification(
              locationCode,
              recipients?.map(({ id } = {}) => ({ id, status: "deleted" })),
            ).then(() => {
              hide();

              // Force search page to re-search to see updated values
              searchEntities();
            });
          }}
          disabled={isModifyDwellNotificationLoading || isPrefillDataLoading}
        >
          {isModifyDwellNotificationLoading
            ? t("shipment-dwell:Deleting...")
            : t("shipment-dwell:Delete")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ShipmentDwellNotificationDeleteModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  rowData: PropTypes.object,
  modifyDwellNotification: PropTypes.func.isRequired,
  isModifyDwellNotificationLoading: PropTypes.bool,
  fetchPrefillData: PropTypes.func.isRequired,
  isPrefillDataLoading: PropTypes.bool,
  location: PropTypes.object,
  recipients: PropTypes.arrayOf(PropTypes.object),
  searchEntities: PropTypes.func.isRequired,
};
