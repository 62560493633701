/** @jsxImportSource @emotion/react */
import debouncePromise from "debounce-promise";
// import AsyncPaginate from "react-select-async-paginate";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { asyncSearchLocations } from "../../location-search/LocationSearchStateExtensions";
import apiUrl from "../../../api-url";
import Colors from "../../../styles/colors";
import LocationName from "../../location/components/LocationName";

const asyncSearchShipperLocations = debouncePromise(
  (query, loadedOptions, selectedShipper) => {
    if (query.length < 2) {
      return {
        options: [],
        hasMore: false,
      };
    }

    // Calculate page number by checking if the currently loaded results
    // are divisble by the pageSize
    let page = 0;
    const pageSize = 10;
    if (loadedOptions.length % pageSize === 0) {
      page = loadedOptions.length / pageSize;
    }

    return asyncSearchLocations(
      query,
      true,
      page,
      pageSize,
      (q) =>
        apiUrl(
          `/location/locations?verbose=false&all=true&everything=${q}&owner_id=${selectedShipper}`,
        ),
      "LocationDesignation",
    );
  },
  500,
);

const LocationOption = ({
  data,
  innerProps,
  selectProps,
  isDisabled,
  isFocused,
  isSelected,
}) => {
  const locationName = data.name;
  const locationCode = data.code;

  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: "0.7rem",

        padding: "10px",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        color: isDisabled
          ? "#aaa"
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
      }}
    >
      <LocationName
        name={locationName}
        code={locationCode}
        style={{ fontWeight: "normal !important" }}
      />
    </div>
  );
};

LocationOption.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export const LocationDesignationSelect = ({
  value,
  onChange,
  selectedShipper,
}) => {
  const { t } = useTranslation(["organizations"]);

  return (
    <AsyncPaginate
      name="async-select-locations-filter"
      isMulti={true}
      loadOptions={(query, loadedOptions) =>
        asyncSearchShipperLocations(query, loadedOptions, selectedShipper)
      }
      placeholder={t(`organizations:Search locations`)}
      styles={customStyles}
      value={value}
      onChange={onChange}
      components={{
        Option: LocationOption,
      }}
      cacheUniqs={[selectedShipper]} // to clear cached options
    />
  );
};

LocationDesignationSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  selectedShipper: PropTypes.number,
};

// todo incorporate this into SelectField to remove duplicate code
const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: "5px",
    borderWidth: "2px",
    borderColor: "#ced4da",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#DEEBFF",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#2684ff",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#2684ff",
    ":hover": {
      backgroundColor: "#B2D4FF",
    },
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};
