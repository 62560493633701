import { connect } from "react-redux";
import ReportsFilterModal from "./ReportsFilter.modal";
import ReportsState from "pages/reports/redux/ReportsState";
import ReportsSharedSearchBarState from "pages/reports/redux/ReportsSharedState";
import ReportsCoreSearchBarState from "pages/reports/redux/ReportsCoreState";
import ReportsMineSearchBarState from "pages/reports/redux/ReportsMineState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import ShipmentFilterSectionContainer from "pages/shipments/components/search/Shipments.SearchFilters.container";
import FinishedVehicleSearchBarState from "modules/fv-search/FinVehicleSearchBarState";
import FinishedVehicleFilterSectionContainer from "modules/fv-search/FinVehicleSearchFiltersContainer";
import finVehicleDomainData from "modules/domain-data/FinVehicleDomainData";
import {
  fetchLocationDetails,
  getLocations,
} from "modules/location/LocationsState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

const mapStateToProps = (state) => {
  return {
    solutionId: getSolutionId(state),
    shipmentSearchFilters:
      ShipmentsSearchBarState.selectors.getSearchFilters(state),
    ShipmentFilterSectionContainer: ShipmentFilterSectionContainer,
    finishedVehicleSearchFilters:
      FinishedVehicleSearchBarState.selectors.getSearchFilters(state),
    FinishedVehicleFilterSectionContainer:
      FinishedVehicleFilterSectionContainer,
    isSaving: ReportsState.selectors.getIsSaving(state),
    isUpdating: ReportsState.selectors.getIsEditing(state),
    locations: getLocations(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { searchEntities: searchSharedEntities } =
    ReportsSharedSearchBarState.actionCreators;
  const { searchEntities: searchCoreEntities } =
    ReportsCoreSearchBarState.actionCreators;
  const { searchEntities: searchMineEntities } =
    ReportsMineSearchBarState.actionCreators;

  return {
    setShipmentSearchFilter: (key, value) =>
      dispatch(
        ShipmentsSearchBarState.actionCreators.setSearchFilter(key, value),
      ),
    clearShipmentSearchFilters: () =>
      dispatch(ShipmentsSearchBarState.actionCreators.clearSearchFilters()),
    setFinishedVehicleSearchFilter: (key, value) =>
      dispatch(
        FinishedVehicleSearchBarState.actionCreators.setSearchFilter(
          key,
          value,
        ),
      ),
    clearFinishedVehicleSearchFilters: () =>
      dispatch(
        FinishedVehicleSearchBarState.actionCreators.clearSearchFilters(),
      ),
    fetchDomainData: (solutionId) =>
      dispatch(finVehicleDomainData.actionCreators.fetchDomainData(solutionId)),
    fetchReports: () => dispatch(ReportsState.actionCreators.fetchReports()),
    saveFilteredReport: (reportId, payload) =>
      dispatch(
        ReportsState.actionCreators.saveFilteredReport(reportId, payload),
      ),
    updateFilteredReport: (reportId, filterSetId, payload) =>
      dispatch(
        ReportsState.actionCreators.updateFilteredReport(
          reportId,
          filterSetId,
          payload,
        ),
      ),
    fetchLocationDetails: (locationId) =>
      dispatch(fetchLocationDetails(locationId)),
    searchCoreReports: (solutionId) => dispatch(searchCoreEntities(solutionId)),
    searchSharedReports: (solutionId) =>
      dispatch(searchSharedEntities(solutionId)),
    searchMineReports: (solutionId) => dispatch(searchMineEntities(solutionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilterModal);
