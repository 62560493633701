/** @jsxImportSource @emotion/react */
import { useMemo, useState, Fragment } from "react";
import _ from "lodash";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { get, isEmpty, sortBy, size } from "lodash";
import moment from "moment";
import { Table } from "react-bootstrap";

import { MdErrorOutline } from "react-icons/md";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { useTranslation, withTranslation } from "react-i18next";

import { DateTime, FontSize } from "components/atoms/DateTime.atom";
import { DateTimeRange } from "components/atoms/DateTimeRange.atom";
import { Text } from "components/atoms/Text.atom";
import { OrganizationType } from "shared/constants/organization.const";
import {
  ShipmentStatusActorType,
  ShipmentStatusCode,
  ShipmentStatusName,
  ShipmentStatusType,
} from "shared/constants/shipments.const";

import treeStartImage from "assets/tree-start.svg";
import treeMiddleImage from "assets/tree-middle.svg";
import treeEndImage from "assets/tree-end.svg";

import { useCarrierDelayedReasonCode } from "pages/shipments/utils/exception.utils";
import {
  useShipmentTranslation,
  useShipmentExceptionTranslation,
} from "shared/hooks/useShipmentTranslation";
import { getDayOfWeek } from "utils/translation-utils";
import Colors from "styles/colors";
import { getAssetId } from "./ShipmentUtils";
import DetailsTable from "components-old/DetailsTable";
import { NaPlaceholder } from "components/no-data-placeholders";
import {
  BoldText,
  BoldSpan,
  ItalicText,
  FlexDiv,
  FlexColDiv,
} from "styles/container-elements";

const BEHIND_SCHEDULE_EXCEPTION = {
  shipment_exception_type_id: 27,
  status_code: "behind_schedule",
};

const BEHIND_SCHEDULE_CLEARED = {
  status_code: "behind_schedule_clear",
};

const PointerOnHover = styled.span({
  ":hover": {
    cursor: "pointer",
  },
});

const ErrorIcon = (props) => <MdErrorOutline size={"1.5em"} />;

const treeImageStyle = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(0%, -50%)",
  pointerEvents: "none",
};

const _statusSeverity = {
  warning: { textColor: Colors.text.YELLOW, showIcon: true },
  critical: { textColor: Colors.highlight.RED, showIcon: true },
  none: { textColor: Colors.background.GRAY_BLUE, showIcon: false },
};

const getStatusSeverity = (severity) =>
  get(_statusSeverity, severity, _statusSeverity.none);

const TypeElem = ({ label }) => {
  return <div>{label}</div>;
};

TypeElem.propTypes = {
  label: PropTypes.string,
};

const ToggleElem = ({ show, toggleHandler, toggleVisible = true }) => {
  return (
    <div>
      {toggleVisible ? (
        <PointerOnHover
          style={{ marginLeft: ".35em" }}
          onClick={() => toggleHandler(!show)}
        >
          {show ? (
            <FaMinusCircle style={{ color: Colors.highlight.RED }} />
          ) : (
            <FaPlusCircle style={{ color: Colors.highlight.GREEN }} />
          )}
        </PointerOnHover>
      ) : null}
    </div>
  );
};

ToggleElem.propTypes = {
  show: PropTypes.bool,
  toggleHandler: PropTypes.func,
  toggleVisible: PropTypes.bool,
};

const HumanDetails = ({ row, t }) => {
  // the notes are in either the remarks or details
  // field.  remarks for a status, details for an exception
  const rowNotes = row.remarks ? row.remarks : row.details;

  return (
    <tr
      key={`status-details-human`}
      css={{ color: Colors.background.GRAY_BLUE }}
    >
      <td colSpan={5} css={{ backgroundColor: "white" }}>
        <Table responsive>
          <tbody>
            <tr>
              <td style={{ borderTop: "0", width: "5em" }}>
                {t("shipment-details:By")}
              </td>
              <td style={{ borderTop: "0" }}>
                {row.actor_email ? row.actor_email : "N/A"}
              </td>
            </tr>
            <tr>
              <td css={{ width: "5em" }}>{t("shipment-details:At")}</td>
              <td>
                <DateTime stack plain localize dateTime={row.created_at}>
                  <DateTime.Time bold />
                  <DateTime.Timezone bold />
                </DateTime>
              </td>
            </tr>
            <tr>
              <td css={{ width: "5em" }}>{t("shipment-details:Notes")}</td>
              <td>{rowNotes}</td>
            </tr>
          </tbody>
        </Table>
      </td>
    </tr>
  );
};

HumanDetails.propTypes = {
  t: PropTypes.func,
  row: PropTypes.object,
};

const AssetDetails = ({ row }) => {
  if (!row.grouped) {
    return null;
  }

  return row.grouped.map((g, i) => {
    return (
      <tr key={`asset-${i}`}>
        <td
          style={{
            width: 30,
            position: "relative",
            overflow: "hidden",
          }}
        >
          {i === row.grouped.length - 1 ? (
            <img
              key="oldLogo"
              src={treeEndImage}
              alt="Tree End"
              style={treeImageStyle}
            />
          ) : (
            <img
              key={`tree-middle-${i}`}
              src={treeMiddleImage}
              alt="Tree Middle"
              style={treeImageStyle}
            />
          )}
        </td>
        <td style={{ verticalAlign: "middle" }}>{g.status_name}</td>
        <td></td>
        <td
          css={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <DateTime stack plain localize dateTime={g.actual_created_at}>
            <DateTime.Time bold />
            <DateTime.Timezone bold />
          </DateTime>
        </td>
        <td
          css={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <DateTime stack plain localize dateTime={g.created_at}>
            <DateTime.Time bold />
            <DateTime.Timezone bold />
          </DateTime>
        </td>
      </tr>
    );
  });
};

AssetDetails.propTypes = {
  row: PropTypes.object,
};

const getCancelDetails = (row, t) => {
  return {
    sourceType:
      row.status_details && row.status_details.source_type_name
        ? row.status_details.source_type_name
        : "N/A",
    email: row.actor_email ? row.actor_email : "N/A",
    reportedAt: row.status_details?.reported_at ?? "",
    actorType:
      row.actor_type && row.actor_type === "human"
        ? t("shipment-details:Manual")
        : t("shipment-details:System Generated"),
    remarks: row.remarks ? row.remarks : "",
  };
};

const CancelDetails = ({ row, t }) => {
  const details = getCancelDetails(row, t);

  return (
    <tr
      key={`status-details-cancel`}
      css={{ color: Colors.background.GRAY_BLUE }}
    >
      <td colSpan={5} css={{ backgroundColor: "white" }}>
        <Table responsive>
          <tbody>
            <tr>
              <td style={{ borderTop: "0", width: "5em" }}>
                {t("shipment-details:Notes")}
              </td>
              <td style={{ borderTop: "0" }}>{details.sourceType}</td>
            </tr>
            <tr>
              <td css={{ width: "5em" }} />
              <td>
                {t("shipment-details:Reported at")}:{" "}
                <DateTime plain localize dateTime={details.reportedAt} />
              </td>
            </tr>
            <tr>
              <td css={{ width: "5em" }} />
              <td>{`${t("shipment-details:Created by")}: ${details.email}`}</td>
            </tr>
            {/* H1-2055 Remove Actor Type from Cancel Details */}
            {/* <tr>
          <td css={{ width: "5em" }} />
          <td>{`${t("shipment-details:Actor type")}: ${details.actorType}`}</td>
        </tr> */}
            <tr>
              <td css={{ width: "5em" }} />
              <td>{`${details.remarks}`}</td>
            </tr>
          </tbody>
        </Table>
      </td>
    </tr>
  );
};

CancelDetails.propTypes = {
  t: PropTypes.func,
  row: PropTypes.object,
};

const CarrierDelayedDetails = ({ row }) => {
  const { t } = useTranslation("shipment-details");

  let statusCode = row.status_code;
  let email = row?.actor_email;
  let carrierName = row?.status_details?.scac_name;
  let scac = row?.status_details?.scac ?? "N/A";
  let reasonCode = row?.status_reason_code;
  let carrierEta = row?.status_details?.frozenEta;
  let comments = row?.remarks;

  const { getFullTranslatedLabelForReasonCode } = useCarrierDelayedReasonCode();

  return (
    <tr css={{ color: Colors.background.GRAY_BLUE }}>
      <td colSpan={5} css={{ backgroundColor: "white" }}>
        <Table responsive>
          <tbody>
            <tr>
              <td style={{ borderTop: 0, width: "5em" }}>
                {t("shipment-details:Notes")}
              </td>
              <td style={{ borderTop: 0 }} colSpan={2}>
                <Text>
                  {t("shipment-details:Created by")}: {email}
                </Text>
              </td>
            </tr>
            {statusCode === ShipmentStatusCode.MANUAL_CARRIER_DELAY ||
            ((statusCode === ShipmentStatusCode.CLEAR_MANUAL_CARRIER_DELAY ||
              statusCode === ShipmentStatusCode.CLEAR_MANUAL_SHIPPER_DELAY) &&
              scac !== "N/A") ? (
              <Fragment>
                <tr>
                  <td />
                  <td>
                    <Text>
                      {t("shipment-details:Carrier Name")}: {carrierName}
                    </Text>
                  </td>
                </tr>
              </Fragment>
            ) : null}
            {statusCode === ShipmentStatusCode.MANUAL_SHIPPER_DELAY ||
            ((statusCode === ShipmentStatusCode.CLEAR_MANUAL_SHIPPER_DELAY ||
              statusCode === ShipmentStatusCode.CLEAR_MANUAL_CARRIER_DELAY) &&
              scac === "N/A") ? (
              <Fragment>
                <tr>
                  <td />
                  <td>
                    <Text>
                      {t("shipment-details:Shipper Name")}: {carrierName}
                    </Text>
                  </td>
                </tr>
              </Fragment>
            ) : null}
            <tr>
              <td />
              <td>
                <Text>
                  {t("shipment-details:SCAC")}: {scac}
                </Text>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <Text>
                  {t("shipment-details:Reason")}:{" "}
                  {getFullTranslatedLabelForReasonCode(reasonCode)}
                </Text>
              </td>
            </tr>
            {statusCode === ShipmentStatusCode.MANUAL_CARRIER_DELAY ? (
              <Fragment>
                <tr>
                  <td />
                  <td>
                    <Text>{t("shipment-details:Carrier ETA")}: </Text>
                    <DateTime plain localize dateTime={carrierEta} />
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <Text>
                      {t("shipment-details:Comments")}: {comments}
                    </Text>
                  </td>
                </tr>
              </Fragment>
            ) : null}
            {statusCode === ShipmentStatusCode.MANUAL_SHIPPER_DELAY ? (
              <Fragment>
                <tr>
                  <td />
                  <td>
                    <Text>{t("shipment-details:Shipper ETA")}: </Text>
                    <DateTime plain localize dateTime={carrierEta} />
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <Text>
                      {t("shipment-details:Comments")}: {comments}
                    </Text>
                  </td>
                </tr>
              </Fragment>
            ) : null}
          </tbody>
        </Table>
      </td>
    </tr>
  );
};

CarrierDelayedDetails.propTypes = {
  row: PropTypes.shape({
    status_code: PropTypes.string,
    actor_email: PropTypes.string,
    remarks: PropTypes.string,
    status_reason_code: PropTypes.string,
    status_details: PropTypes.shape({
      scac_name: PropTypes.string,
      scac: PropTypes.string,
      frozenEta: PropTypes.string,
    }),
  }),
};

const RpOrSSADetails = ({ row }) => {
  const remarkNotes = row.remarks;
  let remarks = remarkNotes.split("\n");
  let remarkRow = [];

  for (let i = 1; i < remarks.length + 1; i++) {
    let remark = remarks[i];
    if (i === 1) {
      remarkRow.push(
        <tr key={`remarks-${i}`}>
          <td style={{ borderTop: "0", width: "9em" }}>{remarks[0]}</td>
          <td style={{ borderTop: "0" }}>{remark}</td>
        </tr>,
      );
    } else {
      remarkRow.push(
        <tr key={`remarks-${i}`}>
          <td css={{ width: "9em" }} />
          <td>{remark}</td>
        </tr>,
      );
    }
  }

  return (
    <tr key={`status-details-rp`} css={{ color: Colors.background.GRAY_BLUE }}>
      <td colSpan={5} css={{ backgroundColor: "white" }}>
        <Table responsive>
          <tbody>{remarkRow}</tbody>
        </Table>
      </td>
    </tr>
  );
};

RpOrSSADetails.propTypes = {
  row: PropTypes.object,
};

const StatusNoteDetails = ({ row }) => {
  const noteParas = row.status_note
    .split("\n")
    .map((line, i) => <p key={`status-note-${i}`}>{line}</p>);

  return (
    <tr
      key={`status-details-note`}
      css={{ color: Colors.background.GRAY_BLUE }}
    >
      <td colSpan={5} css={{ backgroundColor: "white" }}>
        <div css={{ marginLeft: "0.5em" }}>{noteParas}</div>
      </td>
    </tr>
  );
};
StatusNoteDetails.propTypes = {
  row: PropTypes.object,
};

const getUpdatedStops = (statusDetail) => {
  if (!statusDetail || !statusDetail.updated_stops) {
    return [];
  }
  return getStops(statusDetail.updated_stops);
};

const getOriginalStops = (statusDetail) => {
  if (!statusDetail || !statusDetail.original_stops) {
    return [];
  }
  return getStops(statusDetail.original_stops);
};

const getStops = (stops) => {
  const sortedStops = sortBy(stops, "stop_sequence");
  return sortedStops.map((stop) => {
    return {
      locationCode: stop.creator_location_id,
      address: stop.address,
      city: stop.city,
      state: stop.state,
      country: stop.country,
      postalCode: stop.postal_code,
      earliest: stop.earliest_arrival_datetime,
      latest: stop.latest_arrival_datetime,
    };
  });
};

const getUpdateDetails = (row) => {
  return {
    email: row.actor_email ? row.actor_email : "N/A",
    originalStops: getOriginalStops(row.status_details),
    updatedStops: getUpdatedStops(row.status_details),
    scac: row.status_details?.scac_name,
  };
};

const StopUpdateWindow = ({ window }) => {
  return (
    <FlexDiv
      style={{
        fontSize: "smaller",
      }}
    >
      <FlexColDiv
        style={{
          justifyContent: "center",
          marginRight: ".25em",
          minWidth: "11em",
        }}
      >
        {window.locationCode && <span>{window.locationCode}</span>}
        {window.address && <span>{window.address}</span>}
        <span>
          {window.city && <span>{window.city + " "}</span>}
          {window.state && <span>{window.state + " "}</span>}
          {window.postalCode && <span>{window.postalCode + " "}</span>}
          {window.country && <span>{window.country}</span>}
        </span>
      </FlexColDiv>
      <DateTimeRange
        stack
        plain
        localize
        to={window.latest}
        from={window.earliest}
        size={FontSize.size12}
      >
        <DateTime>
          <DateTime.Time bold />
          <DateTime.Timezone bold />
        </DateTime>
      </DateTimeRange>
    </FlexDiv>
  );
};

StopUpdateWindow.propTypes = {
  window: PropTypes.object,
};

const ShipmentCreatedDetails = ({ row, t }) => {
  const { originalStops, email, scac } = getUpdateDetails(row);

  const detailsRows = originalStops.map((stop, i) => {
    return (
      <tr key={i}>
        <td />
        <td>
          <StopUpdateWindow window={stop} />
        </td>
        <td />
      </tr>
    );
  });

  return (
    <tr css={{ color: Colors.background.GRAY_BLUE }}>
      <td colSpan={5} css={{ backgroundColor: "white" }}>
        <Table responsive>
          <tbody>
            <tr>
              <td style={{ borderTop: 0, width: "5em" }}>
                {t("shipment-details:Notes")}
              </td>
              <td style={{ borderTop: 0 }} colSpan={2}>
                <div className="d-flex flex-wrap">
                  <Text className="me-3">
                    {t("shipment-details:Created by")}: {email}
                  </Text>
                  <Text>
                    {t("shipment-details:SCAC")}: {scac}
                  </Text>
                </div>
              </td>
            </tr>
            <tr>
              <td />
              <td>{t("shipment-details:Original Stop Details")}</td>
              <td />
            </tr>
            {detailsRows}
          </tbody>
        </Table>
      </td>
    </tr>
  );
};

ShipmentCreatedDetails.propTypes = {
  t: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

const ShipmentUpdatedDetails = ({ row, t }) => {
  const { originalStops, updatedStops, email } = getUpdateDetails(row);

  const updateCount = Math.max(size(originalStops), size(updatedStops));

  const detailsRow = [];

  for (let i = 0; i < updateCount; i++) {
    const originalStop = originalStops[i];
    const updatedStop = updatedStops[i];

    detailsRow.push(
      <tr key={i}>
        <td />
        <td>
          {originalStop ? <StopUpdateWindow window={originalStop} /> : null}
        </td>
        <td>
          {updatedStop ? <StopUpdateWindow window={updatedStop} /> : null}
        </td>
      </tr>,
    );
  }

  return (
    <tr
      key={`status-details-shipment-updated`}
      css={{ color: Colors.background.GRAY_BLUE }}
    >
      <td colSpan={5} css={{ backgroundColor: "white" }}>
        <Table responsive>
          <tbody>
            <tr>
              <td style={{ borderTop: "0", width: "5em" }}>
                {t("shipment-details:Notes")}
              </td>
              <td style={{ borderTop: "0" }} colSpan={2}>{`${t(
                "shipment-details:Updated by",
              )}: ${email}`}</td>
            </tr>
            <tr>
              <td />
              <td>{t("shipment-details:Current Stop Details")}</td>
              <td>{t("shipment-details:New Stop Details")}</td>
            </tr>
            {detailsRow}
          </tbody>
        </Table>
      </td>
    </tr>
  );
};

ShipmentUpdatedDetails.propTypes = {
  t: PropTypes.func,
  row: PropTypes.object,
};

// EXCEPTIONS

/*
  HT-100 Change to table to display a Reported Time & Received Time per client request
  for Exceptions: created_utc = Reported, n/a = Received
*/

const ExceptionRow = ({ exception }) => {
  const { t } = useTranslation("shipment-details");
  const { getTranslatedExceptionDescription } =
    useShipmentExceptionTranslation();

  //org type changes for SH-7205
  let label = "";
  if (exception?.actor_type === ShipmentStatusActorType.HUMAN) {
    if (exception?.org_type === OrganizationType.SHIPPER) {
      label = t("shipment-details:Shipper Manual Entry");
    } else if (exception?.org_type === OrganizationType.CARRIER) {
      label = t("shipment-details:Carrier Manual Entry");
    }
  }

  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <tr css={{ color: Colors.background.GRAY_BLUE }}>
        <td style={{ color: Colors.highlight.RED, verticalAlign: "middle" }}>
          <ErrorIcon />
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <BoldText style={{ color: Colors.highlight.RED }}>
            {getTranslatedExceptionDescription(exception.name)}
          </BoldText>
        </td>
        <td
          style={{
            width: "11em",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          {label ? (
            <TypeElem
              label={label}
              show={showDetails}
              toggleHandler={setShowDetails}
            />
          ) : null}
        </td>
        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
          <DateTime stack plain localize dateTime={exception.created_utc}>
            <DateTime.Time bold />
            <DateTime.Timezone bold />
          </DateTime>
        </td>
        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
          <NaPlaceholder />
        </td>
      </tr>
      {showDetails && exception.actor_type === "human" ? (
        <HumanDetails row={exception} t={t} />
      ) : null}
    </>
  );
};

ExceptionRow.propTypes = {
  exception: PropTypes.shape({
    name: PropTypes.string,
    actor_type: PropTypes.string,
    org_type: PropTypes.string,
    created_utc: PropTypes.string,
  }),
};

const getFilteredExceptions = (exceptions) => {
  return exceptions
    ? exceptions
        .filter((exception) => {
          return (
            // H1-641:  The 'Shipment is behind schedule' exception should be displayed as
            // many times as it's triggered, so we will skip drawing it here if it's the
            // current active exception and instead display it on the getStatusRows function.
            exception.shipment_exception_type_id !==
              BEHIND_SCHEDULE_EXCEPTION.shipment_exception_type_id &&
            // H1-4568: Don't display Carrier Delayed exception rows.
            // These will be in the status rows.
            exception.name !== "Carrier Delayed" &&
            // H1-4653: Handle Backorder exception in status rows.
            exception.name !== "Backorder" &&
            exception.name !== "Shipper Delayed"
          );
        })
        .map((exception) => {
          const exceptionTime = moment.utc(exception.created_utc).local();

          return {
            time: exceptionTime,
            exception: exception,
          };
        })
    : [];
};

// STATUSES

const DetailsElem = ({ row, t }) => {
  const statusName = row.status_name;
  const statusCode = row.status_code;

  // case - Asset Assigned
  if (
    statusName.includes("Asset Assigned") &&
    row.grouped &&
    row.grouped.length > 1
  ) {
    return <AssetDetails row={row} />;
  }

  // case - Estimated Delivery
  if (statusName.includes("Estimated Delivery")) {
    return <AssetDetails row={row} />;
  }

  // case - Shipment Cancel
  if (statusName.includes("Shipment Canceled")) {
    return <CancelDetails row={row} t={t} />;
  }

  if (
    statusCode === ShipmentStatusCode.MANUAL_CARRIER_DELAY ||
    statusCode === ShipmentStatusCode.CLEAR_MANUAL_CARRIER_DELAY ||
    statusCode === ShipmentStatusCode.MANUAL_SHIPPER_DELAY ||
    statusCode === ShipmentStatusCode.CLEAR_MANUAL_SHIPPER_DELAY
  ) {
    return <CarrierDelayedDetails row={row} />;
  }

  // case - Manually Entered
  if (row.actor_type === "human") {
    return <HumanDetails row={row} t={t} />;
  }

  // case - status code RP or status code SSA
  if (
    row.status_code &&
    (row.status_code === "RP" || row.status_code === "SSA")
  ) {
    return <RpOrSSADetails row={row} />;
  }

  // case - has status note
  const hasStatusNote = !!row.status_note;
  if (hasStatusNote) {
    return <StatusNoteDetails row={row} />;
  }

  // case - Shipment Created
  if (row.status_code === ShipmentStatusCode.SHIPMENT_CREATED) {
    return <ShipmentCreatedDetails row={row} t={t} />;
  }

  // case - Shipment Updated
  if (row.status_code === ShipmentStatusCode.SHIPMENT_UPDATED) {
    return <ShipmentUpdatedDetails row={row} t={t} />;
  }

  return null;
};

DetailsElem.propTypes = {
  t: PropTypes.func,
  row: PropTypes.object,
};

const StatusTypeElem = ({ status, t }) => {
  const statusName = status?.status_name?.toUpperCase() ?? "";
  const statusCode = status?.status_code ?? "";
  const statusType = status?.status_type ?? null;
  const actorType = status?.actor_type ?? "";
  const orgType = status?.org_type ?? "";
  const scac = status?.status_details?.scac;

  // case - Asset Assigned & Estimated Delivery
  if (
    statusName.includes(ShipmentStatusName.ASSET_ASSIGNED.toUpperCase()) ||
    statusName.includes(ShipmentStatusName.ESTIMATED_DELIVERY.toUpperCase())
  ) {
    if (orgType === OrganizationType.SHIPPER) {
      return <TypeElem label={t("shipment-details:Shipper Provided")} />;
    }

    if (orgType === OrganizationType.CARRIER) {
      return <TypeElem label={t("shipment-details:Carrier Provided")} />;
    }
  }

  // case - Shipment Cancel

  if (statusCode === ShipmentStatusCode.SHIPMENT_CANCELLED) {
    if (orgType === OrganizationType.SHIPPER) {
      return (
        <TypeElem label={t("shipment-details:Shipper Manual Cancellation")} />
      );
    }

    if (orgType === OrganizationType.CARRIER) {
      return (
        <TypeElem label={t("shipment-details:Carrier Manual Cancellation")} />
      );
    }
  }

  // case - Asset Unassigned

  if (statusCode === ShipmentStatusCode.ASSET_UNASSIGNED) {
    if (orgType === OrganizationType.SHIPPER) {
      return <TypeElem label={t("shipment-details:Shipper Manual Entry")} />;
    }

    if (orgType === OrganizationType.CARRIER) {
      return <TypeElem label={t("shipment-details:Carrier Manual Entry")} />;
    }
  }

  // case - Report arrival/Depature/Pick up/Delivered
  if (
    statusCode === ShipmentStatusCode.ARRIVED_AT_DROP_OFF ||
    statusCode === ShipmentStatusCode.ARRIVED ||
    statusCode === ShipmentStatusCode.DEPARTED_DROP_OFF ||
    statusCode === ShipmentStatusCode.DEPARTED_PICK_UP ||
    statusCode === ShipmentStatusCode.PICKED_UP ||
    statusCode === ShipmentStatusCode.DELIVERED
  ) {
    if (orgType === OrganizationType.SHIPPER) {
      let shipperLabel = t("shipment-details:Shipper Provided");

      if (actorType === ShipmentStatusActorType.HUMAN) {
        shipperLabel = t("shipment-details:Shipper Manual Entry");

        if (statusType === ShipmentStatusType.SYSTEM_GENERATED) {
          //status_type = 2 and actor_type = human and org_type = shipper
          shipperLabel = t(
            "shipment-details:System Generated from Shipper Manual Entry",
          );
        }
      } else if (
        actorType === ShipmentStatusActorType.SYSTEM &&
        statusType === ShipmentStatusType.SYSTEM_GENERATED
      ) {
        //status_type = 2 and actor_type = system and org_type = shipper
        shipperLabel = t(
          "shipment-details:System Generated from Shipper Provided",
        );
      }

      return <TypeElem label={shipperLabel} />;
    }

    if (orgType === OrganizationType.CARRIER) {
      let carrierLabel = t("shipment-details:Carrier Provided");

      if (actorType === ShipmentStatusActorType.HUMAN) {
        carrierLabel = t("shipment-details:Carrier Manual Entry");

        if (statusType === ShipmentStatusType.SYSTEM_GENERATED) {
          //status_type = 2 and actor_type = human and org_type = carrier
          carrierLabel = t(
            "shipment-details:System Generated from Carrier Manual Entry",
          );
        }
      } else if (
        actorType === ShipmentStatusActorType.SYSTEM &&
        statusType === ShipmentStatusType.SYSTEM_GENERATED
      ) {
        //status_type = 2 and actor_type = system and org_type = carrier
        carrierLabel = t(
          "shipment-details:System Generated from Carrier Provided",
        );
      }

      return <TypeElem label={carrierLabel} />;
    }
  }

  if (
    statusCode === ShipmentStatusCode.BACKORDER ||
    statusCode === ShipmentStatusCode.CLEAR_BACK_ORDER
  ) {
    if (orgType === OrganizationType.SHIPPER) {
      return <TypeElem label={t("shipment-details:Shipper Provided")} />;
    }
  }

  if (statusCode === ShipmentStatusCode.MANUAL_CARRIER_DELAY) {
    return <TypeElem label={t("shipment-details:Carrier Provided")} />;
  }

  if (statusCode === ShipmentStatusCode.MANUAL_SHIPPER_DELAY) {
    return <TypeElem label={t("shipment-details:Shipper Provided")} />;
  }

  if (statusCode === ShipmentStatusCode.CLEAR_MANUAL_CARRIER_DELAY) {
    let label;
    if (actorType === ShipmentStatusActorType.SYSTEM) {
      label = t("shipment-details:System Generated");
    } else if (actorType === ShipmentStatusActorType.HUMAN) {
      if (scac) {
        label = t("shipment-details:Carrier Provided");
      } else {
        label = t("shipment-details:Shipper Provided");
      }
    }

    return <TypeElem label={label} />;
  }

  if (statusCode === ShipmentStatusCode.CLEAR_MANUAL_SHIPPER_DELAY) {
    let label;
    if (actorType === ShipmentStatusActorType.SYSTEM) {
      label = t("shipment-details:System Generated");
    } else if (actorType === ShipmentStatusActorType.HUMAN) {
      if (scac) {
        label = t("shipment-details:Carrier Provided");
      } else {
        label = t("shipment-details:Shipper Provided");
      }
    }

    return <TypeElem label={label} />;
  }

  // case - Manually Entered
  if (actorType === ShipmentStatusActorType.HUMAN) {
    if (orgType === OrganizationType.SHIPPER) {
      return <TypeElem label={t("shipment-details:Shipper Manual Entry")} />;
    }

    if (orgType === OrganizationType.CARRIER) {
      return <TypeElem label={t("shipment-details:Carrier Manual Entry")} />;
    }

    //status_type = 2 and actor_type = human and org_type = other
    if (statusType === ShipmentStatusType.SYSTEM_GENERATED) {
      return (
        <TypeElem
          label={t("shipment-details:System Generated from Manual Entry")}
        />
      );
    }
  }

  // case - status code RP or has status note
  const hasStatusNote = !!status.status_note;
  const isRp = status.status_code && status.status_code === "RP";
  if (isRp || hasStatusNote) {
    return (
      <TypeElem
        label={
          status.status_type_name
            ? status.status_type_name
            : t("shipment-details:System Generated")
        }
      />
    );
  }

  // case - Shipment Created/Updated
  if (
    statusCode === ShipmentStatusCode.SHIPMENT_CREATED ||
    statusCode === ShipmentStatusCode.SHIPMENT_UPDATED
  ) {
    return <TypeElem label={t("shipment-details:Details")} />;
  }

  return (
    <ItalicText>
      {statusType === ShipmentStatusType.SENT_BY_CARRIER
        ? t("shipment-details:Carrier Provided")
        : statusType === ShipmentStatusType.SYSTEM_GENERATED
        ? t("shipment-details:System Generated")
        : status.status_type_name}
    </ItalicText>
  );
};

StatusTypeElem.propTypes = {
  status: PropTypes.object,
  t: PropTypes.func,
};

const StatusToggleElem = ({ status, showDetails, setShowDetails }) => {
  const statusName = status.status_name;
  const statusCode = status.status_code;

  // case - Asset Assigned & Estimated Delivery
  if (
    (statusName.includes("Asset Assigned") ||
      statusName.includes("Estimated Delivery")) &&
    status.grouped &&
    status.grouped.length > 1
  ) {
    return (
      <ToggleElem
        show={showDetails}
        toggleVisible={status.count && status.count > 1}
        toggleHandler={setShowDetails}
      />
    );
  }

  // case - Shipment Cancel
  if (statusName.includes("Shipment Canceled")) {
    return <ToggleElem show={showDetails} toggleHandler={setShowDetails} />;
  }

  // case - Manually Entered
  if (status.actor_type === "human") {
    return <ToggleElem show={showDetails} toggleHandler={setShowDetails} />;
  }

  // case - Carrier Delay
  if (
    statusCode === ShipmentStatusCode.MANUAL_CARRIER_DELAY ||
    statusCode === ShipmentStatusCode.CLEAR_MANUAL_CARRIER_DELAY ||
    statusCode === ShipmentStatusCode.MANUAL_SHIPPER_DELAY ||
    statusCode === ShipmentStatusCode.CLEAR_MANUAL_SHIPPER_DELAY
  ) {
    return <ToggleElem show={showDetails} toggleHandler={setShowDetails} />;
  }

  // case - status code RP or has status note and is not behind schedule with a trip plan remark
  const hasStatusNote = !!status.status_note;
  const isRp = status.status_code && status.status_code === "RP";
  let isBehindScheduleWithTripPlanRemark =
    statusName.includes("Shipment Behind Schedule") &&
    status.remarks &&
    status.remarks.startsWith("TRIP_PLAN_UPDATED");
  if (!isBehindScheduleWithTripPlanRemark && (isRp || hasStatusNote)) {
    return <ToggleElem show={showDetails} toggleHandler={setShowDetails} />;
  }

  //H1-6033: case - status code SSA or has status note
  const isSSA = status.status_code && status.status_code === "SSA";
  if (isSSA || hasStatusNote) {
    return <ToggleElem show={showDetails} toggleHandler={setShowDetails} />;
  }

  // case - Shipment Created/Updated
  if (
    statusCode === ShipmentStatusCode.SHIPMENT_CREATED ||
    statusCode === ShipmentStatusCode.SHIPMENT_UPDATED
  ) {
    return <ToggleElem show={showDetails} toggleHandler={setShowDetails} />;
  }

  const isBehindSchedule =
    status.status_code === BEHIND_SCHEDULE_EXCEPTION.status_code;

  if (isBehindSchedule) {
    return (
      <div style={{ color: Colors.highlight.RED }}>
        <ErrorIcon />
      </div>
    );
  }

  const { textColor, showIcon } = getStatusSeverity(status.severity);
  if (showIcon) {
    return (
      <div style={{ color: textColor }}>
        <ErrorIcon />
      </div>
    );
  }

  return null;
};

StatusToggleElem.propTypes = {
  status: PropTypes.object,
  showDetails: PropTypes.bool,
  setShowDetails: PropTypes.func,
};

const StatusNameElem = ({ status, organization, t }) => {
  const { getTranslatedStatusName } = useShipmentTranslation();
  const statusCode = status.status_code;

  let statusName = getTranslatedStatusName(status);

  let style;
  if (statusCode === BEHIND_SCHEDULE_EXCEPTION.status_code) {
    style = { color: Colors.highlight.RED };
  } else if (statusCode === ShipmentStatusCode.BACKORDER) {
    style = { color: Colors.exceptions.BACKORDER };
    statusName = t("shipment-details:Shipment has parts that are on backorder");
  } else if (statusCode === ShipmentStatusCode.CLEAR_BACK_ORDER) {
    style = { color: Colors.exceptions.BACKORDER };
    statusName = t(
      "shipment-details:Shipment no longer has parts that are on backorder",
    );
  } else {
    const { textColor } = getStatusSeverity(status.severity);
    style = { color: textColor };
  }

  return <BoldText style={style}>{statusName}</BoldText>;
};

StatusNameElem.propTypes = {
  status: PropTypes.object,
  organization: PropTypes.object,
  t: PropTypes.func,
};

const StatusNameSubElem = ({ status, organization }) => {
  const { t } = useTranslation("shipment-details");

  const isBehindSchedule =
    status.status_code === BEHIND_SCHEDULE_EXCEPTION.status_code;

  const isBehindScheduleClear =
    status.status_code === BEHIND_SCHEDULE_CLEARED.status_code;

  if (isBehindSchedule || isBehindScheduleClear) {
    if (status.remarks && status.remarks.startsWith("TRIP_PLAN_UPDATED")) {
      const remarks = status.remarks.split(";");
      const startDayOfWeek =
        remarks && remarks.length > 0 ? getDayOfWeek(remarks[1], t) : "Unknown";
      const endDayOfWeek =
        remarks && remarks.length > 1 ? getDayOfWeek(remarks[2], t) : "Unknown";

      const { textColor } = getStatusSeverity(status.severity);

      return (
        <div
          style={{
            color: isBehindSchedule ? Colors.highlight.RED : textColor,
            fontSize: "0.8em",
          }}
        >
          {t(
            "Trip Plan Updated from [[[missedDayOfWeek]]] to [[[followingDayOfWeek]]]",
            {
              missedDayOfWeek: startDayOfWeek,
              followingDayOfWeek: endDayOfWeek,
            },
          )}
        </div>
      );
    }
  }

  return null;
};

StatusNameSubElem.propTypes = {
  status: PropTypes.object,
  organization: PropTypes.object,
};

/*
  HT-100 Change to table to display a Reported Time & Received Time per client request
  for Statuses: actual_created_at = Reported, created_at = Received
*/

const StatusRow = ({ status, organization, t }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Fragment>
      <tr css={{ color: Colors.background.GRAY_BLUE }}>
        <td
          style={{
            width: 30,
            position: "relative",
            overflow: "hidden",
            verticalAlign: "middle",
          }}
        >
          <StatusToggleElem
            status={status}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
          {showDetails && status.grouped && status.grouped.length > 1 ? (
            <img
              key="tree-start"
              src={treeStartImage}
              alt="Tree Start"
              style={{ marginTop: 10, ...treeImageStyle }}
            />
          ) : null}
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <StatusNameElem status={status} organization={organization} t={t} />
          <StatusNameSubElem status={status} organization={organization} />
        </td>
        <td
          style={{
            width: "11em",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <StatusTypeElem status={status} t={t} />
        </td>
        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
          <StatusEventTimeElem status={status} />
        </td>
        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
          <StatusReceivedTimeElem status={status} />
        </td>
      </tr>
      {showDetails ? <DetailsElem row={status} t={t} /> : null}
    </Fragment>
  );
};

StatusRow.propTypes = {
  status: PropTypes.object,
  organization: PropTypes.object,
  t: PropTypes.func.isRequired,
};

const StatusEventTimeElem = ({ status }) => {
  if (status.count && status.count > 1 && status.grouped) {
    return (
      <FlexDiv css={{ justifyContent: "center" }}>
        <FlexColDiv>
          <DateTime
            stack
            plain
            localize
            dateTime={
              status.grouped[status.grouped.length - 1].actual_created_at
            }
          >
            <DateTime.Time bold />
            <DateTime.Timezone bold />
          </DateTime>
        </FlexColDiv>
        <FlexColDiv style={{ justifyContent: "center", margin: "0 5px" }}>
          <BoldSpan>-</BoldSpan>
        </FlexColDiv>
        <FlexColDiv>
          <DateTime
            stack
            plain
            localize
            dateTime={status.grouped[0].actual_created_at}
          >
            <DateTime.Time bold />
            <DateTime.Timezone bold />
          </DateTime>
        </FlexColDiv>
      </FlexDiv>
    );
  }

  return (
    <DateTime stack plain localize dateTime={status.actual_created_at}>
      <DateTime.Time bold />
      <DateTime.Timezone bold />
    </DateTime>
  );
};

StatusEventTimeElem.propTypes = {
  status: PropTypes.object,
};

const StatusReceivedTimeElem = ({ status }) => {
  if (status.count && status.count > 1 && status.grouped) {
    return (
      <FlexDiv css={{ justifyContent: "center" }}>
        <DateTime stack plain localize dateTime={status.grouped[0].created_at}>
          <DateTime.Time bold />
          <DateTime.Timezone bold />
        </DateTime>
      </FlexDiv>
    );
  }

  return (
    <DateTime stack plain localize dateTime={status.created_at}>
      <DateTime.Time bold />
      <DateTime.Timezone bold />
    </DateTime>
  );
};

StatusReceivedTimeElem.propTypes = {
  status: PropTypes.object,
};

const groupAssets = (arr, org) => {
  let result = [];
  let prevAssetAssignedIndex = null;
  let prevEstimatedDeliveryIndex = null;

  // Loop through all assets
  arr.forEach((asset, i) => {
    // For Asset Assigned assets
    if (
      asset.status_type_name === "Carrier Provided" &&
      asset.status_name.includes("Asset Assigned")
    ) {
      let assetId = getAssetId(org, asset);

      // If we already have an asset in the result array with the same assetId
      if (
        prevAssetAssignedIndex &&
        result[prevAssetAssignedIndex].assetId &&
        result[prevAssetAssignedIndex].assetId === assetId
      ) {
        // Update the count and push the asset to the grouped array
        // (to the front, so it's sorted from newest to oldest)
        result[prevAssetAssignedIndex].count += 1;
        result[prevAssetAssignedIndex].grouped.unshift(asset);
      } else {
        // Create a new object for storing the asset with a count of 1
        let update = Object.assign({}, asset, {
          assetId: assetId,
          count: 1,
          grouped: [asset],
        });

        // Push to result array
        result.push(update);

        // Update prevAssetAssignedIndex so we can keep assigning to the same
        // item in the array for other Asset Assigned's with the same assetId
        prevAssetAssignedIndex = result.length - 1;
      }
    }
    // For Estimated Delivery assets
    else if (asset.status_name.includes("Estimated Delivery")) {
      // If we already have an Estimated Delivery asset in the result array
      if (prevEstimatedDeliveryIndex) {
        // Update the count and push the asset to the grouped array
        // (to the front, so it's sorted from newest to oldest)
        result[prevEstimatedDeliveryIndex].count += 1;
        result[prevEstimatedDeliveryIndex].grouped.unshift(asset);
      } else {
        // Create a new object for storing the asset with a count of 1
        let update = Object.assign({}, asset, {
          count: 1,
          grouped: [asset],
        });

        // Push to result array
        result.push(update);

        // Update prevEstimatedDeliveryIndex so we can keep assigning to the same
        // item in the array for other Asset Assigned's with the same assetId
        prevEstimatedDeliveryIndex = result.length - 1;
      }
    } else {
      // Store the asset in the array as-is
      result.push(asset);

      // Asset Assigned assets with the same assetId generally come from the API in succession,
      // so we if get a non-Asset Assigned asset, then clear the index,
      // as any new Asset Assigned assets will have a different assetId
      prevAssetAssignedIndex = null;
    }
  });

  return result;
};

const getFilteredStatuses = (statuses, organization) => {
  /* HT-122 Group consecutive Asset Assigned AssetID */
  const assetGroupedStatuses = statuses
    ? groupAssets(statuses, organization)
    : [];

  return !isEmpty(assetGroupedStatuses)
    ? assetGroupedStatuses
        .filter((status) => {
          // Do not process any exceptions which appear in the status list.
          // They will be handled separately.
          let isNotException = status.is_exception === false;

          // But allow the following exceptions:
          // - Behind Schedule (H1-641)
          // - Carrier Delayed (H1-4568)
          // - Backorder (H1-4653)
          let allowedExceptionStatusCodes = [
            BEHIND_SCHEDULE_EXCEPTION.status_code,
            ShipmentStatusCode.MANUAL_CARRIER_DELAY,
            ShipmentStatusCode.MANUAL_SHIPPER_DELAY,
            ShipmentStatusCode.BACKORDER,
            ShipmentStatusCode.CLEAR_BACK_ORDER,
          ];
          let isAllowedException = allowedExceptionStatusCodes.includes(
            status.status_code,
          );

          // H1-561 - Filter out System Generated ETAs from table
          let isNotSystemGeneratedEta = !(
            status.status_name === "Estimated Delivery" &&
            status.status_type_name === "System Generated"
          );

          return (
            (isNotException || isAllowedException) && isNotSystemGeneratedEta
          );
        })
        .map((stat) => {
          const statusTime = moment.utc(stat.actual_created_at).local();
          return {
            time: statusTime,
            status: stat,
          };
        })
    : [];
};

const UpdatesTable = ({ exceptions, organization, statuses, t }) => {
  const filteredExceptions = useMemo(
    () => getFilteredExceptions(exceptions),
    [exceptions],
  );

  const filteredStatuses = useMemo(
    () => getFilteredStatuses(statuses, organization),
    [statuses, organization],
  );

  const sortedRows = sortBy(
    [...filteredStatuses, ...filteredExceptions],
    "time",
  )
    .reverse()
    .map((row, i) => {
      if (row.exception) {
        return (
          <ExceptionRow key={row.exception.id} exception={row.exception} />
        );
      }
      // ETA-1213: We now want to exclude any shipment statuses that start with "FV-" from the updates table across all modes.
      return !_.startsWith(row?.status?.status_code, "FV-") ? (
        <StatusRow
          key={`status-${i}`}
          status={row.status}
          organization={organization}
          t={t}
        />
      ) : null;
    });

  return (
    <DetailsTable
      headers={[
        "", // expand/collapse icon and tree lines/images
        { title: t("shipment-details:Status"), centered: false },
        { title: t("shipment-details:Type"), centered: true },
        { title: t("shipment-details:Event Time"), centered: true },
        { title: t("shipment-details:Received Time"), centered: true },
      ]}
      rows={sortedRows}
    />
  );
};

UpdatesTable.propTypes = {
  exceptions: PropTypes.array,
  organization: PropTypes.object,
  statuses: PropTypes.array,
  t: PropTypes.func,
};

export default withTranslation("shipment-details")(UpdatesTable);
