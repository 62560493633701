/** @jsxImportSource @emotion/react */
// Third party Libraries
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// UI Libraries
import { Text } from "components/atoms/Text.atom";

const DealerLocationCell = (props) => {
  const { dealerCountry, dealerDistrict, dealerRegion, dealerZone } =
    props.value;
  const { t } = useTranslation("driveaway-search");
  return (
    <div>
      <div>
        <Text bold css={{ marginRight: 3 }}>
          {t("driveaway-search:Region")}:
        </Text>
        <Text>{dealerRegion}</Text>
      </div>
      <div>
        <Text bold css={{ marginRight: 3 }}>
          {t("driveaway-search:Zone")}:
        </Text>
        <Text>{dealerZone}</Text>
      </div>
      <div>
        <Text bold css={{ marginRight: 3 }}>
          {t("driveaway-search:District")}:
        </Text>
        <Text>{dealerDistrict}</Text>
      </div>
      <div>
        <Text bold css={{ marginRight: 3 }}>
          {t("driveaway-search:Country")}:
        </Text>
        <Text>{dealerCountry}</Text>
      </div>
    </div>
  );
};

export { DealerLocationCell };

DealerLocationCell.propTypes = {
  value: PropTypes.shape({
    dealerCountry: PropTypes.string,
    dealerDistrict: PropTypes.string,
    dealerRegion: PropTypes.string,
    dealerZone: PropTypes.string,
  }),
};
