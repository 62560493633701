import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import DamageViewSearchBarState from "../../redux/DamageViewSearchBarState";
import {
  selectCurrentPositionTypes,
  selectStatusFilterOptions,
} from "./DamageView.SearchFilterSelectors";
import { SEARCH_FILTERS } from "./DamageView.searchOptions";

import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthorization(state),
  solutionId: getSolutionId(state),
  searchFilters: DamageViewSearchBarState.selectors.getSearchFilters(state),
  canUserSearch: DamageViewSearchBarState.selectors.getCanUserSearch(state),
  hasSearchCriteriaChanged:
    DamageViewSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  currentPositionTypes: selectCurrentPositionTypes(state),
  statusFilterOptions: selectStatusFilterOptions(state),
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = DamageViewSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: SEARCH_FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
