import _ from "lodash";
import axios from "axios";
import debouncePromise from "debounce-promise";
import {
  FilterTypesEnum,
  filterOptionUrlLookupTable,
  ScheduleDeliveryOptionsEnum,
  ModeOptionsEnum,
  NotificationEventTypes,
} from "../notificationmanagement.const";
import {
  useTranslateModeOptionType,
  useTranslateScheduledDeliveryOptionType,
} from "./useNotificationManagementTranslations";

export const useFilterOptionListLoadOptions = () => {
  const { getModeOptions } = useHardCodedModeOptions();
  const { getScheduledDeliveryOptions } =
    useHardCodedScheduledDeliveryOptions();

  const getFilterOptionListLoadOptions = debouncePromise(
    (
      filterType: FilterTypesEnum,
      query: string,
      loadedOptions: LoadedOptionsType[],
      eventType: NotificationEventTypes,
    ) => {
      if (filterType === FilterTypesEnum.SCHEDULED_DELIVERY) {
        return getScheduledDeliveryOptions(query);
      }
      if (filterType === FilterTypesEnum.MODE) {
        return getModeOptions(query, eventType);
      }
      const pageSize = 20;
      const requestUrl = filterOptionUrlLookupTable[filterType];

      const requestParams = getFilterOptionRequestParam(
        filterType,
        query,
        loadedOptions,
        pageSize,
      );

      return axios
        .get(requestUrl, { params: requestParams })
        .then((response) => {
          return {
            options: formatFilterOptionResponse(filterType, response),
            hasMore: response.data?.data?.length === pageSize,
          };
        })
        .catch((err) => {
          return {
            options: [],
            hasMore: false,
          };
        });

      function getFilterOptionRequestParam(
        filterType: FilterTypesEnum,
        query: string,
        allLoadedOptions: LoadedOptionsType[],
        pageSize: number,
        everythingRestrict: string = "code,name",
      ) {
        let pageNumber = 0;
        if (
          !_.isNil(allLoadedOptions.length) &&
          !_.isNaN(pageSize) &&
          pageSize > 0
        ) {
          pageNumber = Math.floor(allLoadedOptions.length / pageSize);
        }

        const requestParams: RequestParamsType = {
          verbose: false,
          pageNumber: pageNumber,
          pageSize: pageSize,
        };
        switch (filterType) {
          case FilterTypesEnum.ORIGIN:
            requestParams.everything = query;
            requestParams.everythingRestrict = everythingRestrict;
            break;
          case FilterTypesEnum.DESTINATION:
            requestParams.everything = query;
            requestParams.everythingRestrict = everythingRestrict;
            break;
          case FilterTypesEnum.PARTS_NUMBER:
            requestParams.query = query;
            break;
          default:
            break;
        }
        return requestParams;
      }

      function formatFilterOptionResponse(
        filterType: FilterTypesEnum,
        response: APIResponseType,
      ) {
        switch (filterType) {
          case FilterTypesEnum.ORIGIN:
            const originAPIResponse: LocationAPIResponseType =
              response.data as LocationAPIResponseType;
            return originAPIResponse.data?.map(
              (item: LocationAPIResponseDataType) => {
                return {
                  label: `${item.name} (${item.code})`,
                  value: item.code,
                };
              },
            );
          case FilterTypesEnum.DESTINATION:
            const destinationAPIResponse: LocationAPIResponseType =
              response.data as LocationAPIResponseType;
            return destinationAPIResponse.data?.map((item) => {
              return { label: `${item.name} (${item.code})`, value: item.code };
            });
          case FilterTypesEnum.PARTS_NUMBER:
            const partsNumberAPIResponse =
              response.data as PartsNumberAPIResponseType;
            return partsNumberAPIResponse.data?.map(
              (item: PartsNumberAPIResponseDataType) => {
                return { label: item.label, value: item.value };
              },
            );
          case FilterTypesEnum.CARRIER:
            const carrierAPIResponse =
              response.data as CarrierAPIResponseType[];
            return carrierAPIResponse.map((item) => {
              return { label: item.name, value: item.fv_id };
            });
          default:
            break;
        }
      }
    },
    500,
  );

  return { getFilterOptionListLoadOptions };
};

const useHardCodedScheduledDeliveryOptions = () => {
  const translateOptionType = useTranslateScheduledDeliveryOptionType();
  const hardCodedScheduledDeliveryOptions = [
    ScheduleDeliveryOptionsEnum.TODAY,
    ScheduleDeliveryOptionsEnum.TOMORROW,
    ScheduleDeliveryOptionsEnum.THIS_WEEK,
    ScheduleDeliveryOptionsEnum.THIS_MONTH,
  ];
  const getScheduledDeliveryOptions = async (query: string) => {
    const scheduledDeliveryOptions = hardCodedScheduledDeliveryOptions.map(
      (option: ScheduleDeliveryOptionsEnum) => {
        return {
          value: option,
          label: translateOptionType(option),
        };
      },
    );

    return {
      options: getUserQueriedFilterOptions(scheduledDeliveryOptions, query),
      hasMore: false,
    };
  };
  return { getScheduledDeliveryOptions };
};

const useHardCodedModeOptions = () => {
  const translateModeOptionType = useTranslateModeOptionType();
  let modes = [
    ModeOptionsEnum.LTL,
    ModeOptionsEnum.OCEAN,
    ModeOptionsEnum.PARCEL,
    ModeOptionsEnum.RAIL,
    ModeOptionsEnum.TRUCK,
    ModeOptionsEnum.AIR,
    ModeOptionsEnum.INTERMODAL,
  ];
  const getModeOptions = async (
    query: string,
    eventType?: NotificationEventTypes,
  ) => {
    if (eventType === NotificationEventTypes.CARRIER_DELAY) {
      modes = [
        ModeOptionsEnum.PARCEL,
        ModeOptionsEnum.RAIL,
        ModeOptionsEnum.TRUCK,
      ];
    }
    const modeOptions = modes.map((mode: ModeOptionsEnum) => {
      return {
        value: mode,
        label: translateModeOptionType(mode),
      };
    });

    return {
      options: getUserQueriedFilterOptions(modeOptions, query),
      hasMore: false,
    };
  };

  return { getModeOptions };
};

const getUserQueriedFilterOptions = (
  availableOptions: LoadedOptionsType[],
  userQuery: string,
) => {
  return Object.values(availableOptions).filter((option: LoadedOptionsType) =>
    option.label.toLowerCase().includes(userQuery.toLowerCase()),
  );
};

interface LoadedOptionsType {
  value: string;
  label: string;
}

interface RequestParamsType {
  verbose: boolean;
  pageNumber: number;
  pageSize: number;
  query?: string;
  everything?: string;
  everythingRestrict?: string;
}

interface APIResponseType {
  data:
    | LocationAPIResponseType
    | CarrierAPIResponseType[]
    | PartsNumberAPIResponseType;
}

interface APIResponseMetaType {
  currentPage: number;
  totalCount: number;
  totalPages: number;
}

interface LocationAPIResponseType {
  data: LocationAPIResponseDataType[];
  meta: APIResponseMetaType;
}

interface LocationAPIResponseDataType {
  code: string;
  name: string;
}

interface CarrierAPIResponseType {
  fv_id: string;
  id: number;
  name: string;
  scac: string;
}

interface PartsNumberAPIResponseDataType {
  id: string;
  label: string;
  value: string;
}

interface PartsNumberAPIResponseType {
  data: PartsNumberAPIResponseDataType[];
  meta: APIResponseMetaType;
}
