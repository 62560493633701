const REGEX_REMOVE_QUOTES_AROUND_CELL = /"([^"]+(?="))"/g;
const isEmptyString = (value) => {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
};

/*
 *
 * Parses a CSV file and returns an array of lines.
 *
 * Note: This function assumes that quotes are used in CSVs only to wrap an entire cell
 * or to escape special characters. It may not handle all possible CSV formats, especially
 * when quotes are used differently within cells.
 *
 * @param {csv-file} uploadFile The CSV file to parse.
 * @returns formatted CSV file
 */
export const getLinesFromCsv = (uploadFile) => {
  return new Promise((resolve, reject) => {
    // Read the contents of the file
    const reader = new FileReader();
    reader.readAsText(uploadFile);

    // When the file loads, create an array of lines
    reader.onload = (file) => {
      const csv = file.target.result.trim();
      const row = csv.split(/\r\n|\n/);

      if (row.length > 1) {
        const parsedLines = row.filter((line) => {
          // Remove commas from each line
          const trimmedLine = line.toString().replace(/,/g, "");
          if (!isEmptyString(trimmedLine)) {
            return line
              .toString()
              .replace(REGEX_REMOVE_QUOTES_AROUND_CELL, "$1");
          }
          return false;
        });

        resolve(parsedLines);
      }
      resolve([]);
    };

    reader.onerror = (e) => {
      reject(e);
    };
  });
};
