import { connect } from "react-redux";

import NotificationManagement from "./NotificationManagement.page";
import NotificationManagementState from "./redux/NotificationManagementState";
import NotificationManagementWizardState from "./redux/NotificationManagementWizardState";

function mapStateToProps(state) {
  const {
    getNotificationRuleList,
    getPageSize,
    getPageIdx,
    getTotalPages,
    getNotificationRuleListLoading,
  } = NotificationManagementState.selectors;
  const {
    getIsNextButtonDisabled,
    getPostNewNotificationRuleStatus,
    getAllUserInputData,
    getEditNotificationRuleApiStatus,
  } = NotificationManagementWizardState.selectors;
  return {
    notificationRuleList: getNotificationRuleList(state),
    notificationRuleListLoading: getNotificationRuleListLoading(state),
    pageIdx: getPageIdx(state),
    pageSize: getPageSize(state),
    pageCount: getTotalPages(state),
    isNextButtonDisabled: getIsNextButtonDisabled(state),
    postNewNotificationRuleStatus: getPostNewNotificationRuleStatus(state),
    allUserInputData: getAllUserInputData(state),
    editNotificationRuleApiStatus: getEditNotificationRuleApiStatus(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  const { fetchNotificationRuleList, setPagination, deleteNotification } =
    NotificationManagementState.actionCreators;
  const {
    setIsNextButtonDisabled,
    resetWizardState,
    postNewNotificationRule,
    fetchIndividualNotification,
  } = NotificationManagementWizardState.actionCreators;
  return {
    fetchNotificationRuleList: (pageIdx, pageSize) =>
      dispatch(fetchNotificationRuleList(pageIdx, pageSize)),
    setPagination: (pageIdx, pageSize) =>
      dispatch(setPagination(pageIdx, pageSize)),
    setIsNextButtonDisabled: (isNextButtonDisabled) => {
      dispatch(setIsNextButtonDisabled(isNextButtonDisabled));
    },
    deleteNotification: (notificationId) =>
      dispatch(deleteNotification(notificationId)),
    resetWizardState: () => {
      dispatch(resetWizardState());
    },
    postNewNotificationRule: (allUserInputData) => {
      dispatch(postNewNotificationRule(allUserInputData));
    },
    fetchIndividualNotification: (notificationId) => {
      dispatch(fetchIndividualNotification(notificationId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationManagement);
