import { connect } from "react-redux";
import {
  getFeatureData,
  getSolutionId,
  getActiveOrganization,
} from "modules/organizations/OrganizationsState";
import DamageViewDashboard from "./DamageView.Dashboard.page";
import DamageViewSearchBarState from "../redux/DamageViewSearchBarState";
import DamageViewDashboardState from "../redux/DamageViewDashboardState";
import DamageViewWatchedSubmissionsWidgetState from "../redux/DamageViewWatchedSubmissionState";
import DamageViewMySubmissionsWidgetState from "../redux/DamageViewMySubmissionsState";
import DamageViewExportSubmissionState from "../redux/DamageViewExportSubmissionState";
import DamageViewDamageFormState from "../redux/DamageViewDamageFormState";
import ProfileState from "modules/profile/ProfileState";
import DamageViewDomainDataState from "../components/search/DamageViewDomainData";

const mapStateToProps = (state) => {
  return {
    solutionId: getSolutionId(getFeatureData(state)),
    currentOrganization: getActiveOrganization(state),
    showFilters:
      DamageViewSearchBarState.selectors.getShowAdvancedSearch(state),
    submissionData:
      DamageViewDashboardState.selectors.getSubmissionCountsData(state),
    isSubmissionDataLoading:
      DamageViewDashboardState.selectors.getSubmissionLoading(state),
    isWatchSubmissionsLoading:
      DamageViewWatchedSubmissionsWidgetState.selectors.getIsLoading(state),
    watchSubmissionsData:
      DamageViewWatchedSubmissionsWidgetState.selectors.getSearchResults(state),
    watchSubmissonPageIndex:
      DamageViewWatchedSubmissionsWidgetState.selectors.getPage(state),
    watchSubmissionPageSize:
      DamageViewWatchedSubmissionsWidgetState.selectors.getPageSize(state),
    watchSubmissionPageCount:
      DamageViewWatchedSubmissionsWidgetState.selectors.getTotalPages(state),
    isMySubmissionsLoading:
      DamageViewMySubmissionsWidgetState.selectors.getIsLoading(state),
    mySubmissionsData:
      DamageViewMySubmissionsWidgetState.selectors.getSearchResults(state),
    mySubmissonPageIndex:
      DamageViewMySubmissionsWidgetState.selectors.getPage(state),
    mySubmissionPageSize:
      DamageViewMySubmissionsWidgetState.selectors.getPageSize(state),
    mySubmissionPageCount:
      DamageViewMySubmissionsWidgetState.selectors.getTotalPages(state),
    exportName: DamageViewExportSubmissionState.selectors.getExportName(state),
    exportIdentifier:
      DamageViewExportSubmissionState.selectors.getExportIdentifier(state),
    exportFailed:
      DamageViewExportSubmissionState.selectors.getExportFailed(state),
    isExporting:
      DamageViewExportSubmissionState.selectors.getIsExporting(state),
    fields: DamageViewDamageFormState.selectors.getFields(state),
    userPreferences: ProfileState.selectors.getUserPreferences(state),
    shipperOptions: DamageViewDamageFormState.selectors.getShippers(state),
    isFieldsLoading:
      DamageViewDamageFormState.selectors.getFieldsLoading(state),
    statusConfig: DamageViewDomainDataState.selectors.getStatus(state),
    mySubmissionsDefaultSortColumn:
      DamageViewMySubmissionsWidgetState.selectors.getDefaultSortColumn(state),
    mySubmissionsDefaultReverseSort:
      DamageViewMySubmissionsWidgetState.selectors.getDefaultReverseSort(state),
    mySubmissionsSortColumn:
      DamageViewMySubmissionsWidgetState.selectors.getSortColumn(state),
    mySubmissionsReverseSort:
      DamageViewMySubmissionsWidgetState.selectors.getReverseSort(state),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...stateProps, ...dispatchProps, ...ownProps };
  return props;
};

const {
  toggleShowFilters,
  setSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
} = DamageViewSearchBarState.actionCreators;

const { resetExport, exportSubmissonData, clearExportErrors } =
  DamageViewExportSubmissionState.actionCreators;

const { submitDamageForm, fetchFields, fetchShippers } =
  DamageViewDamageFormState.actionCreators;

const actionCreators = {
  fetchShippers,
  toggleShowFilters,
  setSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  resetExport,
  clearExportErrors,
  fetchFields,
  submitDamageForm: (formData, fields, shipper) => (dispatch) =>
    dispatch(submitDamageForm(formData, fields, shipper)),
  fetchSubmissionData: (solutionId) => (dispatch) =>
    dispatch(
      DamageViewDashboardState.actionCreators.fetchSubmissionCountsData(
        solutionId,
      ),
    ),
  fetchWatchedSubmissions: (solutionId) => (dispatch) =>
    dispatch(
      DamageViewWatchedSubmissionsWidgetState.actionCreators.searchEntities(
        solutionId,
      ),
    ),
  setWatchedSubmissonsPageIndex: (solutionId, page, pageSize) => (dispatch) =>
    dispatch(
      DamageViewWatchedSubmissionsWidgetState.actionCreators.setPagination(
        solutionId,
        page,
        pageSize,
      ),
    ),
  fetchMySubmissions: (solutionId) => (dispatch) =>
    dispatch(
      DamageViewMySubmissionsWidgetState.actionCreators.searchEntities(
        solutionId,
      ),
    ),
  setMySubmissonsPageIndex: (solutionId, page, pageSize) => (dispatch) =>
    dispatch(
      DamageViewMySubmissionsWidgetState.actionCreators.setPagination(
        solutionId,
        page,
        pageSize,
      ),
    ),
  exportSubmissonData: (submissionId, locale) => (dispatch) => {
    dispatch(exportSubmissonData(submissionId, locale));
  },
  pushDamageViewDetailsPage: (submissionId) => (dispatch) => {
    dispatch({
      type: "DAMAGE_VIEW_DETAILS",
      payload: { submission_id: submissionId },
    });
  },
  mySubmissionsSetSort: (sortColumn, reverse) => (dispatch) =>
    dispatch(
      DamageViewMySubmissionsWidgetState.actionCreators.setSort(
        null,
        sortColumn,
        reverse,
      ),
    ),
};

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(DamageViewDashboard);
