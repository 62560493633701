import { connect } from "react-redux";
import { PartViewSearch } from "./PartView.Search.page";

import PartViewSearchBarState from "../redux/PartViewSearchBarState";
import PartViewSavedSearchState from "../redux/PartViewSavedSearchState";

function mapStateToProps(state) {
  return {
    savedSearch: PartViewSearchBarState.selectors.getSelectedSavedSearch(state),
    partViewSearchResults:
      PartViewSearchBarState.selectors.getSearchResults(state),
    showFilters: PartViewSearchBarState.selectors.getShowAdvancedSearch(state),
    isLoading: PartViewSearchBarState.selectors.getIsLoading(state),
    sortColumn: PartViewSearchBarState.selectors.getSortColumn(state),
    reverseSort: PartViewSearchBarState.selectors.getReverseSort(state),
    page: PartViewSearchBarState.selectors.getPage(state),
    pageSize: PartViewSearchBarState.selectors.getPageSize(state),

    totalPages:
      PartViewSearchBarState.selectors.getTotalPageCountForSearch(state),
    totalEntities:
      PartViewSearchBarState.selectors.getTotalCountForSearch(state),
    totalEntitiesIsLoading:
      PartViewSearchBarState.selectors.getTotalCountForSearchIsLoading(state),

    exportIdentifier:
      PartViewSearchBarState.selectors.getExportIdentifier(state),
    exportName: PartViewSearchBarState.selectors.getExportName(state),
    isExporting: PartViewSearchBarState.selectors.getIsExporting(state),
    exportFailed: PartViewSearchBarState.selectors.getExportFailed(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSavedSearch: (savedSearch) =>
      dispatch(
        PartViewSavedSearchState.actionCreators.loadSavedSearch(savedSearch),
      ),
    pushPartViewDetailsPage: (trackingNumber) =>
      dispatch({
        type: "PARTVIEW_DETAILS",
        payload: { trackingNumber: trackingNumber },
      }),
    toggleShowFilters: (showFilters) =>
      dispatch(
        PartViewSearchBarState.actionCreators.toggleShowFilters(showFilters),
      ),
    setPagination: (page, pageSize) =>
      dispatch(
        PartViewSearchBarState.actionCreators.setPagination(
          null,
          page,
          pageSize,
        ),
      ),
    setSort: (sortColumn, reverse) =>
      dispatch(
        PartViewSearchBarState.actionCreators.setSort(
          null,
          sortColumn,
          reverse,
        ),
      ),
    exportEntities: () => {
      dispatch(PartViewSearchBarState.actionCreators.exportSearch());
    },
    clearExportErrors: () => {
      dispatch(PartViewSearchBarState.actionCreators.clearExportErrors());
    },
    resetSearch: () => {
      dispatch(PartViewSearchBarState.actionCreators.resetSearchAndFilters());
      dispatch(PartViewSearchBarState.actionCreators.searchEntities());
    },
    resetExport: () => {
      dispatch(PartViewSearchBarState.actionCreators.resetExport());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartViewSearch);
