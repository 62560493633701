import _ from "lodash";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import chainReducers from "vendor/signal-utils/chain-reducers";
import apiUrl from "api-url";
import { Products, ApiRequestStatus } from "../notificationmanagement.const.ts";
import { createStateObj } from "../createUIStateFromApi.util.js";
import {
  createDefinitionObject,
  mapDeliveryActions,
} from "../createPayload.util.js";

const STORE_MOUNT_POINT = "NotificationManagementWizardState";

const NOTIFICATION_MANAGEMENT_URL = apiUrl("/subscription/v2");

const getIndividualNotificationUrl = (id) => {
  return apiUrl(`subscription/v2/${id}`);
};

const postNewNotificationRule = (data) => {
  const {
    createNotificationUuid,
    notificationRuleName,
    product,
    deliveryActions,
    notificationDetailsObj,
    filterCriteria,
  } = data;

  const definition = createDefinitionObject(
    notificationDetailsObj.notificationEventType,
    notificationDetailsObj.notificationTrigger,
    notificationDetailsObj.notificationTriggerTimeObj,
    filterCriteria,
  );

  const payload = {
    id: createNotificationUuid,
    name: notificationRuleName,
    product: product,
    deliveryActions: mapDeliveryActions(deliveryActions),
    eventType: notificationDetailsObj.notificationEventType,
    definition: definition,
  };

  return (dispatch) => {
    dispatch({
      type: "SET_POST_NEW_NOTIFICATION_RULE_STATE",
      status: ApiRequestStatus.IN_PROGRESS,
    });

    return axios
      .put(`${NOTIFICATION_MANAGEMENT_URL}`, payload)
      .then((response) => {
        dispatch({
          type: "SET_POST_NEW_NOTIFICATION_RULE_STATE",
          status: ApiRequestStatus.SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_POST_NEW_NOTIFICATION_RULE_STATE",
          status: ApiRequestStatus.FAILED,
          error: err,
        });
      });
  };
};

const createUnsubscribeIdMap = (deliveryActions) => {
  const unsubscribeIdMap = {};
  for (const deliveryAction of deliveryActions) {
    unsubscribeIdMap[deliveryAction.value] = deliveryAction.unsubscribeId;
  }
  return unsubscribeIdMap;
};

const fetchIndividualNotification = (notificationRuleId) => {
  return (dispatch) => {
    dispatch({
      type: "SET_EDIT_NOTIFICATION_RULE_STATUS",
      status: "IN_PROGRESS",
    });

    return axios
      .get(getIndividualNotificationUrl(notificationRuleId))
      .then(async (response) => {
        const notificationRuleStateObject = await createStateObj(response.data);
        dispatch({
          type: "SET_EDIT_WIZARD_STATE",
          payload: notificationRuleStateObject,
        });
        dispatch({
          type: "SET_EDIT_NOTIFICATION_RULE_STATUS",
          status: "SUCCESS",
        });
        dispatch({
          type: "SET_UNSUBSCRIBE_ID_MAP",
          payload: createUnsubscribeIdMap(
            notificationRuleStateObject.deliveryActions,
          ),
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_EDIT_NOTIFICATION_RULE_STATUS",
          status: "FAILED",
          error: err,
        });
        console.log("err", err);
      });
  };
};

const initialState = () => {
  return {
    createNotificationUuid: uuidv4(),
    notificationRuleName: "",
    product: Products.SHIPMENTS,
    deliveryActions: [],
    notificationDetailsObj: {},
    isNextButtonDisabled: true,
    filterCriteria: null,
    postNewNotificationRuleStatus: null,
    postNewNotificationRuleStatusError: null,
    editNotificationRuleStatus: null,
    editNotificationRuleStatusError: null,
    unsubscribeIdMap: {},
  };
};

//action creators
const setNotificationRuleName = (notificationRuleName) => ({
  type: "SET_NOTIFICATION_RULE_NAME",
  payload: notificationRuleName,
});

const setNotificationDetailsObj = (notificationDetailsObj) => ({
  type: "SET_NOTIFICATION_DETAILS_OBJ",
  payload: notificationDetailsObj,
});

const setProduct = (product) => ({
  type: "SET_PRODUCT",
  payload: product,
});

const setDeliveryActions = (deliveryActions) => ({
  type: "SET_DELIVERY_ACTIONS",
  payload: deliveryActions,
});

const setIsNextButtonDisabled = (isNextButtonDisabled) => ({
  type: "SET_IS_NEXT_BUTTON_DISABLED",
  payload: isNextButtonDisabled,
});

const setFilterCriteria = (filterCriteria) => ({
  type: "SET_FILTER_CRITERIA",
  payload: filterCriteria,
});

const clearFilterCriteria = () => ({
  type: "CLEAR_FILTER_CRITERIA",
});

const resetWizardState = () => ({
  type: "RESET",
});

//reducers
const NotificationManagementWizardReducer = (
  state = initialState(),
  action = {},
) => {
  switch (action.type) {
    case "SET_NOTIFICATION_RULE_NAME":
      return {
        ...state,
        notificationRuleName: action.payload,
      };
    case "SET_NOTIFICATION_DETAILS_OBJ":
      return {
        ...state,
        notificationDetailsObj: action.payload,
      };

    case "SET_PRODUCT":
      return {
        ...state,
        product: action.payload,
      };
    case "SET_DELIVERY_ACTIONS":
      return {
        ...state,
        deliveryActions: action.payload,
      };
    case "SET_NOTIFICATION_EVENT_TYPE":
      return {
        ...state,
        notificationEventType: action.payload,
      };
    case "SET_NOTIFICATION_TRIGGER_TIME_OBJ":
      return {
        ...state,
        notificationTriggerTimeObj: action.payload,
      };
    case "SET_NOTIFICATION_TRIGGER":
      return {
        ...state,
        notificationTrigger: action.payload,
      };
    case "SET_IS_NEXT_BUTTON_DISABLED":
      return {
        ...state,
        isNextButtonDisabled: action.payload,
      };
    case "SET_FILTER_CRITERIA":
      return {
        ...state,
        filterCriteria: action.payload,
      };
    case "CLEAR_FILTER_CRITERIA":
      return {
        ...state,
        filterCriteria: null,
      };
    case "SET_POST_NEW_NOTIFICATION_RULE_STATE":
      return {
        ...state,
        postNewNotificationRuleStatus: action.status,
        postNewNotificationRuleStatusError: action.error || null,
      };
    case "RESET":
      return {
        ...initialState(),
      };
    case "SET_EDIT_NOTIFICATION_RULE_STATUS":
      return {
        ...state,
        editNotificationRuleStatus: action.status,
        editNotificationRuleError: action.error || null,
      };
    case "SET_EDIT_WIZARD_STATE":
      return {
        ...action.payload,
        editNotificationRuleStatus: action.status,
        editNotificationRuleError: action.error || null,
      };
    case "SET_UNSUBSCRIBE_ID_MAP":
      return {
        ...state,
        unsubscribeIdMap: action.payload,
      };
    default:
      return state;
  }
};

//selectors
const getAllUserInputData = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}`, null);

const getNotificationRuleName = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.notificationRuleName`, null);

const getProduct = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.product`, null);

const getDeliveryActions = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.deliveryActions`, null);

const getNotificationDetailsObj = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.notificationDetailsObj`, null);

const getEventType = (state) =>
  _.get(
    state,
    `${STORE_MOUNT_POINT}.notificationDetailsObj.notificationEventType`,
    null,
  );

const getIsNextButtonDisabled = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.isNextButtonDisabled`, null);

const getFilterCriteria = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.filterCriteria`, null);

const getPostNewNotificationRuleStatus = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.postNewNotificationRuleStatus`, null);

const getPostNewNotificationRuleError = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.postNewNotificationRuleError`, null);

const getEditNotificationRuleApiStatus = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.editNotificationRuleStatus`, null);

const getEditNotificationRuleApiError = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.editNotificationRuleError`, null);

const getUnsubscribeIdMap = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.unsubscribeIdMap`, null);

const NotificationManagementWizardState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    setNotificationRuleName,
    setNotificationDetailsObj,
    setProduct,
    setDeliveryActions,
    setIsNextButtonDisabled,
    clearFilterCriteria,
    resetWizardState,
    setFilterCriteria,
    postNewNotificationRule,
    fetchIndividualNotification,
  },
  selectors: {
    getNotificationDetailsObj,
    getNotificationRuleName,
    getProduct,
    getDeliveryActions,
    getIsNextButtonDisabled,
    getFilterCriteria,
    getAllUserInputData,
    getEventType,
    getPostNewNotificationRuleStatus,
    getPostNewNotificationRuleError,
    getEditNotificationRuleApiStatus,
    getEditNotificationRuleApiError,
    getUnsubscribeIdMap,
  },
  reducer: chainReducers([NotificationManagementWizardReducer]),
};

export default NotificationManagementWizardState;
