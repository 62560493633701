/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { Fragment } from "react";
import { VictoryPie } from "victory";
import { Text, FontSize } from "components/atoms/Text.atom";
import Loader from "react-loader";

import Colors from "styles/colors";

function calculateTotalFontSize(total) {
  if (total > 999999) {
    return FontSize.size18;
  } else if (total > 9999) {
    return FontSize.size24;
  } else {
    return FontSize.size32;
  }
}

export const DonutChart = ({
  data,
  isLoading,
  label,
  percentage,
  total,
  totalLabel,
  handler,
  chartLabelAlign,
  fontSize,
  styles,
  fillColor,
}) => {
  const totalsFontSize = fontSize ?? calculateTotalFontSize(total);
  const loaded = !_.isNil(isLoading) ? !isLoading : true;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        minHeight: "10em",
        minWidth: "10em",
        width: "10em",
        alignItems: "center",
        position: "relative",
        justifyContent: "center",
        ":hover": {
          cursor: handler ? "pointer" : "auto",
        },
        ...styles,
      }}
      onClick={handler}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader loaded={loaded} />
          {loaded ? (
            <Fragment>
              <Text
                size={totalsFontSize}
                color={Colors.background.DARK_BLUE}
                css={{ lineHeight: 1 }}
              >
                {total}
              </Text>
              <Text
                block
                italic
                color={Colors.background.DARK_BLUE}
                style={styles?.donutLabelStyle}
              >
                {totalLabel}
              </Text>
            </Fragment>
          ) : null}
        </div>
        <VictoryPie
          data={data}
          innerRadius={130}
          padAngle={0} /* H1-1720 Remove space between pie pieces */
          labels={() => null}
          style={{
            data: {
              fill: fillColor ? fillColor : ({ datum }) => datum.fill,
            },
          }}
        />
      </div>
      {percentage || percentage === 0 ? (
        <Text size={FontSize.size16} bold>
          {`${percentage}%`}
        </Text>
      ) : null}
      <Text
        block
        italic
        color={Colors.background.DARK_BLUE}
        align={chartLabelAlign}
      >
        {label}
      </Text>
    </div>
  );
};

DonutChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      y: PropTypes.number,
      x: PropTypes.string,
      fill: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  /** The label of the chart. Appears below the chart itself. */
  label: PropTypes.string,
  /** The count to display in the middle of the chart. */
  total: PropTypes.number,
  /** The label for the total count. Appears below the count inside of the chart. */
  totalLabel: PropTypes.string,
  handler: PropTypes.func,
  chartLabelAlign: PropTypes.string,
  styles: PropTypes.object,
  fontSize: PropTypes.number,
  fillColor: PropTypes.string,
  percentage: PropTypes.number,
};
