/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useEffect, useState, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  FormLabel,
  InputGroup,
  FormControl,
  FormGroup,
  Modal,
} from "react-bootstrap";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput,
} from "components-old/modal-elems";
import { FlexRowDiv } from "styles/container-elements";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { PasswordChecks } from "components/PasswordChecks";
import Colors from "styles/colors";

import {
  generatePassword,
  isPassLengthValid,
  isPassRegexValid,
  isPassMatchValid,
  doesPassContainEmailOrUsername,
} from "utils/password-utils";
import { Privileges } from "modules/auth/Authorization";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import UsersState from "modules/users/UsersState";

const isFormValid = (lengthValid, regexValid, matching, containsEmail) => {
  return lengthValid && regexValid && matching && !containsEmail;
};

const shouldDisableForm = (actionStatus) => {
  return (
    actionStatus &&
    ["SET_PASSWORD_USER", "RESET_PASSWORD_USER"].includes(actionStatus)
  );
};

const shouldShowFailureAlert = (actionStatus) => {
  return (
    actionStatus &&
    [
      "USER_PASSWORD_RESET_FAILED",
      "USER_PASSWORD_SET_FAILED",
      "USER_PASSWORD_SET_FAILED_INVALID",
    ].includes(actionStatus)
  );
};

const shouldShowSuccessAlert = (actionStatus) => {
  return (
    actionStatus &&
    [
      "USER_PASSWORD_SET",
      "USER_PASSWORD_RESET",
      "USER_PASSWORD_RESET_FAILED_INVALID",
    ].includes(actionStatus)
  );
};

const getAlertMessage = (actionStatus, t) => {
  switch (actionStatus) {
    case "USER_PASSWORD_SET":
      return t("users:Password set successfully.");
    case "USER_PASSWORD_RESET":
      return t(
        "users:The user has been emailed a link to reset their password.",
      );
    case "USER_PASSWORD_RESET_FAILED_INVALID":
    case "USER_PASSWORD_SET_FAILED_INVALID":
      return t(
        "users:Password must contain at least 3 of the following lower case letters (a-z), upper case letters (A-Z), numbers (0-9), and special characters (e.g. !@#$%^&*). It cannot contain the accounts email address or username.",
      );
    case "USER_PASSWORD_RESET_FAILED":
      return t("users:There was a problem sending the password reset link.");
    default:
      return t("users:There was a problem setting the password.");
  }
};

const initUser = {
  id: "",
  email: "",
  passwordToSet: "",
  confirmPassword: "",
};

const UserPasswordModal = ({
  show,
  hide,
  user,
  authorization,
  activeOrganization,
  actionStatus,
  clearActionStatus,
  resetPassword,
  setUserPassword,
}) => {
  const { t } = useTranslation("users");

  const [editUser, setUser] = useState(initUser);
  const [showSendLinkConfirmationDialog, setShowSendLinkConfirmationDialog] =
    useState(false);
  const [
    showResetPasswordConfirmationDialog,
    setShowResetPasswordConfirmationDialog,
  ] = useState(false);

  const clearForm = useCallback(() => {
    setUser((editUser) => {
      return { ...editUser, passwordToSet: "", confirmPassword: "" };
    });
  }, []);

  useEffect(() => {
    if (
      actionStatus &&
      ["USER_PASSWORD_SET", "USER_PASSWORD_RESET"].includes(actionStatus)
    ) {
      clearForm();
    }
  }, [actionStatus, clearForm]);

  useEffect(() => {
    setUser((prevState) => {
      return {
        ...prevState,
        id: user ? user.user_id : "",
        email: user ? user.email : "",
      };
    });
  }, [user]);

  const inputHandler = (value) => {
    if (actionStatus) {
      clearActionStatus();
    }

    setUser((prevState) => {
      return { ...prevState, ...value };
    });
  };

  const createGeneratedPassword = () => {
    const genPass = generatePassword();
    inputHandler({ passwordToSet: genPass, confirmPassword: genPass });
  };

  const isNewPasswordLengthValid = isPassLengthValid(editUser.passwordToSet);
  const isNewPasswordRegexValid = isPassRegexValid(editUser.passwordToSet);
  const arePasswordsMatching = isPassMatchValid(
    editUser.passwordToSet,
    editUser.confirmPassword,
  );
  const doesPasswordContainEmailOrUsername = doesPassContainEmailOrUsername(
    editUser.passwordToSet,
    editUser.email,
  );
  const canChangePassword = authorization.hasPrivileges([
    Privileges.CHANGE_USER_PASSWORDS,
  ]);
  const org = activeOrganization?.org_name ?? "";

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        clearForm();
        hide();
        clearActionStatus();
      }}
    >
      <ModalHeader title={t("users:Reset or Set Password")} />
      <ModalBody>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={editUser.email}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
        {!canChangePassword ? null : (
          <Fragment>
            <FlexRowDiv style={{ marginTop: "1em" }}>
              <div
                css={{ width: "50%", color: "#acb5be", marginRight: "0.5em" }}
              >
                <FormGroup css={{ marginBottom: "1rem" }}>
                  <FormLabel style={{ fontWeight: "normal" }}>
                    {t("users:Set password")}
                  </FormLabel>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder={t("users:Password")}
                      value={editUser.passwordToSet}
                      disabled={shouldDisableForm(actionStatus)}
                      onChange={(e) =>
                        inputHandler({ passwordToSet: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </div>
              <div css={{ width: "50%", color: "#acb5be" }}>
                <FormGroup css={{ marginBottom: "1rem" }}>
                  <FormLabel style={{ fontWeight: "normal" }}>
                    {t("users:Confirm password")}
                  </FormLabel>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder={t("users:Confirm password")}
                      value={editUser.confirmPassword}
                      disabled={shouldDisableForm(actionStatus)}
                      onChange={(e) =>
                        inputHandler({ confirmPassword: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </FlexRowDiv>
            <FlexRowDiv style={{ marginBottom: "1em", marginTop: "-.6em" }}>
              <Button
                size="sm"
                disabled={shouldDisableForm(actionStatus)}
                onClick={() => createGeneratedPassword()}
              >
                {t("users:Generate")}
              </Button>
            </FlexRowDiv>
          </Fragment>
        )}

        <PasswordChecks
          isLengthValid={isNewPasswordLengthValid}
          isPasswordsMatch={arePasswordsMatching}
          isRegexValid={isNewPasswordRegexValid}
          doesPassContainEmailOrUsername={doesPasswordContainEmailOrUsername}
        />
        <Alert
          variant={
            shouldShowFailureAlert(actionStatus)
              ? AlertVariant.Danger
              : AlertVariant.Success
          }
          show={
            shouldShowSuccessAlert(actionStatus) ||
            shouldShowFailureAlert(actionStatus)
          }
          css={{ marginTop: "1em", marginBottom: 0 }}
        >
          {getAlertMessage(actionStatus, t)}
        </Alert>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "flex-start" }}>
        <Tooltip
          placement="top"
          tooltipChildren={
            <Text style={{ padding: "0.75em", textAlign: "bottom" }}>
              {t(
                "users:Send an email to the user with a link to reset their password",
              )}
            </Text>
          }
        >
          <Button
            style={{ marginRight: ".5em" }}
            label={t("users:Send Password Reset Link")}
            disabled={shouldDisableForm(actionStatus)}
            onClick={() => {
              setShowSendLinkConfirmationDialog(true);
            }}
          >
            {t("users:Send Password Reset Link")}
          </Button>
        </Tooltip>

        <Button
          style={{ marginRight: ".5em", marginLeft: "auto" }}
          disabled={shouldDisableForm(actionStatus)}
          variant="outline-secondary"
          onClick={() => {
            clearForm();
            hide();
            clearActionStatus();
          }}
        >
          {actionStatus ? t("users:Close") : t("users:Cancel")}
        </Button>
        <Button
          disabled={
            !isFormValid(
              isNewPasswordLengthValid,
              isNewPasswordRegexValid,
              arePasswordsMatching,
              doesPasswordContainEmailOrUsername,
            ) || shouldDisableForm(actionStatus)
          }
          variant="success"
          onClick={() => {
            setShowResetPasswordConfirmationDialog(true);
          }}
        >
          {t("users:Set Password")}
        </Button>
        <DialogModal
          show={showSendLinkConfirmationDialog}
          onHide={() => setShowSendLinkConfirmationDialog(false)}
          title={t("users:Confirm Send Password Reset Link")}
          cancelButtonText={t("users:Cancel")}
          submitButtonText={t("users:Send Password Reset Link")}
          submitButtonVariant="success"
          onSubmit={() => {
            resetPassword(user);
            setShowSendLinkConfirmationDialog(false);
          }}
        >
          <Text block>
            {t(
              "users:Are you sure you want to send this user a password reset link?",
            )}
          </Text>
          <Text block bold style={{ marginTop: 10 }}>
            {editUser?.email}
          </Text>
        </DialogModal>
        <DialogModal
          show={showResetPasswordConfirmationDialog}
          onHide={() => setShowResetPasswordConfirmationDialog(false)}
          title={t("users:Confirm Password Change")}
          cancelButtonText={t("users:Cancel")}
          submitButtonText={t("users:Change Password")}
          submitButtonVariant="success"
          onSubmit={() => {
            setUserPassword(editUser.id, editUser.passwordToSet);
            setShowResetPasswordConfirmationDialog(false);
          }}
        >
          <Text block>
            {t("users:Are you sure you want to change this user's password?")}
          </Text>
          <Text block bold style={{ marginTop: 10 }}>
            {editUser?.email}
          </Text>
        </DialogModal>
      </ModalFooter>
    </Modal>
  );
};

UserPasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  user: PropTypes.object,
  authorization: PropTypes.object.isRequired,
  activeOrganization: PropTypes.object.isRequired,
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  setUserPassword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    actionStatus: state.users.actionStatus,
  };
}

const mapDispatchToProps = {
  clearActionStatus: UsersState.actionCreators.clearActionStatus,
  resetPassword: UsersState.actionCreators.resetPassword,
  setUserPassword: UsersState.actionCreators.setUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPasswordModal);
