/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { IdBox } from "components/multimodal-components/InfoBoxes";
import { CountryName } from "modules/location/components/CountryName";
import { ErrorBoundary } from "components/atoms/ErrorBoundary";

import Colors from "styles/colors";

/**
 * TODO: Merge this with the LocationAddress component, or at least
 * figure out why they are so different.
 */
export const LocationAddressComplex = ({
  name,
  address1,
  address2,
  city,
  state,
  country,
  postalCode,
  defaultTextColor = Colors.text.GRAY,
  addressColor = Colors.text.DARK_BLUE,
  style = {},
  lookupCountryNameFromCode = true,
}) => (
  <div
    css={[
      {
        display: "flex",
        flexDirection: "column",
        color: defaultTextColor,
        fontSize: "1em",
      },
      style,
    ]}
  >
    {name && (
      <div>
        <IdBox css={name.style} data-qa="text-name-location">
          {name.value}
          {name.rightContent}
        </IdBox>
      </div>
    )}
    {address1 && (
      <span style={{ color: addressColor }} data-qa="text-address-location">
        {address1}
      </span>
    )}
    {address2 && (
      <span style={{ color: addressColor }} data-qa="text-address-location">
        {address2}
      </span>
    )}
    <span>
      {city && <span data-qa="text-city-location">{city}</span>}
      {city && state && <span>,</span>}
      {state && (
        <span data-qa="text-state-location" css={{ marginLeft: "0.25em" }}>
          {state}
        </span>
      )}
      {postalCode && (
        <span
          data-qa="text-postal-code-location"
          css={{ marginLeft: "0.25em" }}
        >
          {postalCode}
        </span>
      )}
    </span>
    {country ? (
      <span data-qa="text-country-location">
        {lookupCountryNameFromCode ? (
          // Fallback to just the country if CountryName has an error.
          <ErrorBoundary fallback={country}>
            <CountryName countryCode={country} />
          </ErrorBoundary>
        ) : (
          country
        )}
      </span>
    ) : null}
  </div>
);

LocationAddressComplex.propTypes = {
  defaultTextColor: PropTypes.string,
  name: PropTypes.object,
  address1: PropTypes.string,
  address2: PropTypes.string,
  addressColor: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
  style: PropTypes.object,
  lookupCountryNameFromCode: PropTypes.bool,
};
