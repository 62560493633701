/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from "victory";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { Button } from "components/atoms/Button.atom";
import { formatTickMetric } from "shared/utils/chart.utils";
import { DamageForm } from "./DamageView.DamageForm";
import Loader from "react-loader";
import Colors from "styles/colors";

export const AllSubmissionWidgets = ({
  isFieldsLoading,
  showShipperOption,
  shipperOptions,
  fetchFields,
  solutionId,
  fetchSubmissionData,
  isLoading,
  submissionData,
  setSearchFilter,
  searchEntities,
  submitDamageForm,
  fields,
  statusConfig,
}) => {
  const { t } = useTranslation("damage-view");
  const [showForm, setShowForm] = useState(false);
  const [chartData, setChartData] = useState([
    {
      name: t("damage-view:Submitted"),
      count: 0,
      fill: Colors.donuts.BLUE,
      field: "submitted",
      status: "Submitted",
    },
    {
      name: t("damage-view:Minor"),
      count: 0,
      fill: Colors.donuts.YELLOW,
      field: "minor",
      status: "Minor",
    },
    {
      name: t("damage-view:Major"),
      count: 0,
      fill: Colors.donuts.RED,
      field: "major",
      status: "Major",
    },
    {
      name: t("damage-view:Rejected"),
      count: 0,
      fill: Colors.highlight.LIGHT_GRAY,
      field: "rejected",
      status: "Rejected",
    },
    {
      name: `${t("damage-view:Complete")} ${
        "\n (" + t("damage-view:Last 30 days") + ")"
      }`,
      count: 0,
      fill: Colors.donuts.GREEN,
      field: "completed",
      status: "Completed",
    },
  ]);

  const axisStyle = {
    axis: { stroke: Colors.border.BOTTOM_AXIS_BORDER },
    tickLabels: { fontSize: 12, fontFamily: "inherit" },
  };

  const crossAxisStyle = {
    axis: { stroke: Colors.border.BOTTOM_AXIS_BORDER },
    axisLabel: { fontSize: 20, padding: 30 },
    grid: { stroke: Colors.border.INNER_GRAY_BORDER },
    tickLabels: { fontSize: 12, padding: 5, fontFamily: "inherit" },
  };

  const getInitChart = useCallback((statusConfig) => {
    let charts = [
      {
        name: t("damage-view:Submitted"),
        count: 0,
        fill: Colors.donuts.BLUE,
        field: "submitted",
        status: "Submitted",
      },
      {
        name: t("damage-view:Minor"),
        count: 0,
        fill: Colors.donuts.YELLOW,
        field: "minor",
        status: "Minor",
      },
      {
        name: t("damage-view:Major"),
        count: 0,
        fill: Colors.donuts.RED,
        field: "major",
        status: "Major",
      },
      {
        name: t("damage-view:Rejected"),
        count: 0,
        fill: Colors.highlight.LIGHT_GRAY,
        field: "rejected",
        status: "Rejected",
      },
      {
        name: `${t("damage-view:Complete")} ${
          "\n (" + t("damage-view:Last 30 days") + ")"
        }`,
        count: 0,
        fill: Colors.donuts.GREEN,
        field: "completed",
        status: "Completed",
      },
    ];

    if (statusConfig.includes("Warranty")) {
      charts.splice(3, 0, {
        name: t("damage-view:Warranty"),
        count: 0,
        fill: Colors.donuts.GREEN,
        field: "warranty",
        status: "Warranty",
      });
    }

    return charts;
  }, []);

  useEffect(() => {
    fetchSubmissionData(solutionId);
  }, []);

  useEffect(() => {
    setChartData(getInitChart(statusConfig));
    setChartData((prevChartData) => {
      return prevChartData.map((data) => {
        const selectedData = submissionData?.filter((submission) => {
          return submission?.status?.toLowerCase() === data.field;
        });
        return {
          ...data,
          count:
            selectedData && selectedData.length > 0
              ? selectedData[0].count ?? 0
              : 0,
        };
      });
    });
  }, [submissionData, statusConfig, getInitChart]);

  return (
    <PanelGroup>
      <PanelGroup.Header
        title={t("damage-view:All Submissions")}
        rightContent={
          <Button
            onClick={() => {
              setShowForm(true);
            }}
          >
            {t("damage-view:Submit Damage")}
          </Button>
        }
      />
      <PanelGroup.Content style={{ padding: "0px" }}>
        <Loader loaded={!isLoading} />
        <VictoryChart
          domainPadding={{ x: 40 }}
          height={300}
          padding={{ top: 50, bottom: 70, left: 30, right: 50 }}
        >
          <VictoryAxis
            style={axisStyle}
            tickLabelComponent={
              <VictoryLabel
                dx={-20}
                dy={-4}
                angle={20}
                textAnchor="start"
                style={{ fontSize: 12 }}
              />
            }
          />
          <VictoryAxis
            style={crossAxisStyle}
            dependentAxis
            tickFormat={formatTickMetric}
          />
          <VictoryBar
            data={chartData}
            x={"name"}
            y={"count"}
            labels={({ datum }) => `${datum.count}`}
            style={{
              data: {
                cursor: "pointer",
                width: 50,
                fill: ({ datum }) => datum.fill,
              },
            }}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: (evt, props) => {
                    const field = props.datum.status;
                    setSearchFilter("status", [field]);
                    searchEntities();
                  },
                },
              },
            ]}
          ></VictoryBar>
        </VictoryChart>
        <DamageForm
          show={showForm}
          fetchFields={fetchFields}
          isFieldsLoading={isFieldsLoading}
          showShipperOption={showShipperOption}
          shipperOptions={shipperOptions}
          solutionId={solutionId}
          updateShow={setShowForm}
          submitDamageForm={submitDamageForm}
          fields={fields}
        />
      </PanelGroup.Content>
    </PanelGroup>
  );
};

AllSubmissionWidgets.propTypes = {
  showShipperOption: PropTypes.bool,
  isFieldsLoading: PropTypes.bool,
  solutionId: PropTypes.string,
  isLoading: PropTypes.bool,
  fetchSubmissionData: PropTypes.func,
  submissionData: PropTypes.array,
  setSearchFilter: PropTypes.func,
  searchEntities: PropTypes.func,
  submitDamageForm: PropTypes.func,
  fields: PropTypes.object,
  shipperOptions: PropTypes.array,
  fetchFields: PropTypes.func,
  statusConfig: PropTypes.array,
};
