import { connect } from "react-redux";
import { DealerPartViewSearch } from "./DealerPartView.Search.page";

import DealerPartViewSearchBarState from "../redux/DealerPartViewSearchBarState";
import DealerPartViewSavedSearchState from "../redux/DealerPartViewSavedSearchState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { OrganizationType } from "shared/constants/organization.const";

function mapStateToProps(state) {
  const { getSelectedFvOrgId } = DealerPartViewSearchBarState.selectors;
  const auth = getAuthorization(state);

  return {
    savedSearch:
      DealerPartViewSearchBarState.selectors.getSelectedSavedSearch(state),
    partViewSearchResults:
      DealerPartViewSearchBarState.selectors.getSearchResults(state),
    showFilters:
      DealerPartViewSearchBarState.selectors.getShowAdvancedSearch(state),
    isLoading: DealerPartViewSearchBarState.selectors.getIsLoading(state),
    sortColumn: DealerPartViewSearchBarState.selectors.getSortColumn(state),
    reverseSort: DealerPartViewSearchBarState.selectors.getReverseSort(state),
    page: DealerPartViewSearchBarState.selectors.getPage(state),
    pageSize: DealerPartViewSearchBarState.selectors.getPageSize(state),

    totalPages:
      DealerPartViewSearchBarState.selectors.getTotalPageCountForSearch(state),
    totalEntities:
      DealerPartViewSearchBarState.selectors.getTotalCountForSearch(state),
    totalEntitiesIsLoading:
      DealerPartViewSearchBarState.selectors.getTotalCountForSearchIsLoading(
        state,
      ),

    exportIdentifier:
      DealerPartViewSearchBarState.selectors.getExportIdentifier(state),
    exportName: DealerPartViewSearchBarState.selectors.getExportName(state),
    isExporting: DealerPartViewSearchBarState.selectors.getIsExporting(state),
    exportFailed: DealerPartViewSearchBarState.selectors.getExportFailed(state),
    selectedFvOrgId: getSelectedFvOrgId(state),
    isDealerOrg: auth.hasOrganizationTypes([OrganizationType.DEALER]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSavedSearch: (savedSearch) =>
      dispatch(
        DealerPartViewSavedSearchState.actionCreators.loadSavedSearch(
          savedSearch,
        ),
      ),
    pushPartViewDetailsPage: (trackingNumber) =>
      dispatch({
        type: "DEALER_PARTVIEW_DETAILS",
        payload: { trackingNumber: trackingNumber },
      }),
    toggleShowFilters: (showFilters) =>
      dispatch(
        DealerPartViewSearchBarState.actionCreators.toggleShowFilters(
          showFilters,
        ),
      ),
    setPagination: (page, pageSize) =>
      dispatch(
        DealerPartViewSearchBarState.actionCreators.setPagination(
          null,
          page,
          pageSize,
        ),
      ),
    setSort: (sortColumn, reverse) =>
      dispatch(
        DealerPartViewSearchBarState.actionCreators.setSort(
          null,
          sortColumn,
          reverse,
        ),
      ),
    exportEntities: () => {
      dispatch(DealerPartViewSearchBarState.actionCreators.exportSearch());
    },
    clearExportErrors: () => {
      dispatch(DealerPartViewSearchBarState.actionCreators.clearExportErrors());
    },
    resetSearch: () => {
      dispatch(
        DealerPartViewSearchBarState.actionCreators.resetSearchAndFilters(),
      );
      dispatch(DealerPartViewSearchBarState.actionCreators.searchEntities());
    },
    resetExport: () => {
      dispatch(DealerPartViewSearchBarState.actionCreators.resetExport());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealerPartViewSearch);
