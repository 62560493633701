import _ from "lodash";
import apiUrl from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { Features } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";
import { buildLocationAsyncFilterOptionsState } from "modules/location/utils/buildLocationAsyncFilterOptionsState";

// Shared logic and values between filter state configs

const pageSize = 20;

const getResponseData = (data) =>
  data?.data.filter((result) => !_.isNil(result.label)) ?? [];

const transformResult = ({ label, value }) => ({
  label: label ?? value,
  value: value,
});

// Filter option states
export const originOptionsState = buildLocationAsyncFilterOptionsState({
  topic: "partViewOriginFilter",
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const destinationOptionsState = buildLocationAsyncFilterOptionsState({
  topic: "partViewDestinationFilter",
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const originStateOptionsState = buildAsyncFilterOptionsState({
  topic: "partViewOriginStateOptionsState",
  url: apiUrl("/partview/app/list/origin"),
  pageSize,
  getResponseData: (data) => data?.data ?? [],
  transformResult,
});

export const destinationStateOptionsState = buildAsyncFilterOptionsState({
  topic: "partViewDestinationStateOptionsState",
  url: apiUrl("/partview/app/list/destination"),
  pageSize,
  getResponseData,
  transformResult,
});

export const carrierOptionsState = buildAsyncFilterOptionsState({
  topic: "partViewCarrierFilter",
  url: apiUrl("/partview/app/list/carrier"),
  pageSize,
  getResponseData,
  transformResult,
});

export const partNumberOptionsState = buildAsyncFilterOptionsState({
  topic: "partViewPartNumberFilter",
  url: apiUrl("/partview/app/list/parts"),
  pageSize,
  getResponseData,
  transformResult,
});

export const packageStatusOptionsState = buildAsyncFilterOptionsState({
  topic: "partViewPackageStatusFilter",
  url: apiUrl("/partview/app/list/lifecycle-state"),
  pageSize,
  getResponseData,
  transformResult,
});

export const activeExceptionOptionsState = buildAsyncFilterOptionsState({
  topic: "partViewActiveExceptioFilter",
  url: apiUrl("/partview/app/list/exception"),
  pageSize,
  getResponseData,
  transformResult,
});

export const orderPriorityOptionsState = buildAsyncFilterOptionsState({
  topic: "partViewOrderPriorityFilter",
  url: apiUrl("/partview/app/order-priority-list"),
  pageSize,
  getResponseData,
  transformResult,
});

//Only for Dealer View
export const orgOptionsState = buildAsyncFilterOptionsState({
  topic: "partViewOrganizationFilter",
  url: apiUrl("/iam/organizations"),
  queryParam: "everything",
  additionalParams: {
    orgType: OrganizationType.DEALER,
    paginate: true,
    feature: Features.PART_VIEW,
  },
  pageSize,
  getResponseData: (data) => data?.response ?? [],
  transformResult: (org) => ({ label: org.org_name, value: org.fv_id }),
});
