import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
/**
 * Returns a formatted location object to use with the ArrivalAndDeparturePanelGroup.
 *
 * @param {object} location Origin or destination location object
 * @param {"origin" | "destination"} type
 * @param {object} packageDetails
 * @returns
 */

export const transformLocation = (
  location: Location,
  type: string,
  packageDetails: PackageDetails,
) => {
  if (_.isNil(location)) {
    return null;
  }

  let scheduledArrivalWindow = null;
  if (type === "origin") {
    scheduledArrivalWindow = location.scheduledPickupWindow ?? [];
  } else if (type === "destination") {
    scheduledArrivalWindow = location.scheduledDeliveryWindow ?? [];
  }

  let eta = location.eta;
  if (packageDetails?.LifecycleState?.toLowerCase() === "delivered") {
    eta = null;
  }

  if (type === "origin") {
    //CAT-3496:remove actual arrival for origin
    return {
      name: location.name,
      city: location.city,
      state: location.state,
      postalCode: location.zipCode,
      country: location.country,
      address: location.address,
      scheduledPickupWindow: location.scheduledPickupWindow,
      scheduledDeliveryWindow: location.scheduledDeliveryWindow,
      earliestArrivalDateTime: scheduledArrivalWindow?.[0],
      latestArrivalDateTime: scheduledArrivalWindow?.[1],
      actualDepartureDateTime: location.actualDeparture,
      eta: eta,
    };
  } else {
    //CAT-3496:remove actual departure for destination
    return {
      name: location.name,
      city: location.city,
      state: location.state,
      postalCode: location.zipCode,
      country: location.country,
      address: location.address,
      scheduledPickupWindow: location.scheduledPickupWindow,
      scheduledDeliveryWindow: location.scheduledDeliveryWindow,
      earliestArrivalDateTime: scheduledArrivalWindow?.[0],
      latestArrivalDateTime: scheduledArrivalWindow?.[1],
      actualArrivalDateTime: location.actualArrival,
      eta: eta,
    };
  }
};

export const getCurrentEventByMode = (
  milestoneCode: string,
  mode: string,
  t: Function,
) => {
  if (milestoneCode === "DP") {
    if (
      mode.toLowerCase() === "truck" ||
      mode.toLowerCase() === "ltl" ||
      mode.toLowerCase() === "parcel"
    ) {
      return t("partview-details:On the Road");
    } else if (mode.toLowerCase() === "air") {
      return t("partview-details:In the Air");
    } else if (mode.toLowerCase() === "rail") {
      return t("partview-details:On Rail");
    } else if (mode.toLowerCase() === "ocean") {
      return t("partview-details:On the Water");
    }
  } else {
    return t("partview-details:At Location");
  }
};

const getTranslatedSource = (type: string, t: Function) => {
  if (type) {
    if (type.toLowerCase() === "milestone") {
      return t("partview-details:Milestone");
    } else {
      return t("partview-details:Shipment");
    }
  } else {
    return null;
  }
};

export const useCurrentPositionDetails = (
  actualTriplegs: any[],
  isShipmentsLoaded: boolean,
  shipmentsForTriplegs: object,
) => {
  const { t } = useTranslation("partview-details");

  const getCurrentLegModeAndLocation = useCallback(
    (
      latestMode: string,
      latestStop: any,
      latestMilestoneOrCarrierEvent: {
        city: string;
        state: string;
        country: string;
        latitude: string;
        longitude: string;
        eventTs: string;
        eventCode: string;
        eventType: string;
        code: string;
        locationName: string;
      },
      triplegType: string,
    ) => {
      let city, state, country, latitude, longitude;

      if (triplegType === "shipmentActual") {
        city = latestStop?.current_city;
        state = latestStop?.current_state;
        country = latestStop?.current_country;
        latitude = latestStop?.latitude;
        longitude = latestStop?.longitude;
      } else if (triplegType === "partViewActual") {
        city = latestMilestoneOrCarrierEvent?.city;
        state = latestMilestoneOrCarrierEvent?.state;
        country = latestMilestoneOrCarrierEvent?.country;
        latitude = latestMilestoneOrCarrierEvent?.latitude;
        longitude = latestMilestoneOrCarrierEvent?.longitude;
      }

      return {
        city: city,
        state: state,
        country: country,
        datetime: latestMilestoneOrCarrierEvent.eventTs,
        milestoneCode: getCurrentEventByMode(
          latestMilestoneOrCarrierEvent.code,
          latestMode,
          t,
        ),
        source: getTranslatedSource(latestMilestoneOrCarrierEvent.eventType, t),
        latitude: latitude,
        longitude: longitude,
        currentLocationName: latestMilestoneOrCarrierEvent.locationName,
      };
    },
    [t],
  );

  const getLatestUpdateFromEventCode = (
    actualTriplegs: any[],
    latestUpdate: {
      code: string;
      locationCode: string;
    },
  ) => {
    let latestEventLeg;

    if (latestUpdate.code === "DP") {
      latestEventLeg = actualTriplegs.filter((stop) => {
        let latestUpdateLocationCode = latestUpdate.locationCode;
        let originCode = stop.origin.code;
        return originCode === latestUpdateLocationCode;
      })?.[0];
    }

    // for event code AR/FD/APU
    // get the partview generated actual trip leg  which has the recent event update
    // or if event location code doesn't match with tripleg location origin code
    // then use the tripleg in which the event occured
    if (_.isEmpty(latestEventLeg)) {
      latestEventLeg = actualTriplegs.filter(
        (stop) => stop.updates.indexOf(latestUpdate) !== -1,
      )?.[0];
    }

    return latestEventLeg;
  };

  return useMemo(() => {
    // shipment api's are loaded then compute CurrentPositionDetails
    if (!isShipmentsLoaded) {
      return null;
    }

    //get the shipment actual leg details
    let shipmentActualTripLegs = shipmentsForTriplegs
      ? Object.values(shipmentsForTriplegs)
      : [];

    if (actualTriplegs.length > 0) {
      //get all updates of partview generated actual trip leg
      let allUpdates = actualTriplegs
        .map((leg) => {
          if (!_.isEmpty(leg?.updates)) {
            //remove Re-open (RO) event from the updates as it shouldn't change the current location status.
            return leg.updates.filter(
              (update: { code: string }) => update.code !== "RO",
            );
          }
          return [];
        })
        .flat();

      if (allUpdates.length > 0) {
        // sort all the updates as per eventDatetime
        let sortedUpdates = _.orderBy(allUpdates, "eventDatetime");

        //get the latest update object
        let latestUpdate = sortedUpdates[sortedUpdates.length - 1];

        //get the partview generated actual trip leg details which has the latest update
        let legWithLatestUpdate = getLatestUpdateFromEventCode(
          actualTriplegs,
          latestUpdate,
        );

        //This is to get the current details and mode from shipment actual trip leg
        if (
          latestUpdate.code === "DP" &&
          shipmentsForTriplegs?.hasOwnProperty(
            legWithLatestUpdate.creatorShipmentId,
          )
        ) {
          //get the shipment details from latest shipment actual  trip leg
          let latestActualTripLeg =
            !_.isEmpty(shipmentActualTripLegs) &&
            shipmentActualTripLegs.filter(
              (actualLeg) =>
                actualLeg.creator_shipment_id ===
                legWithLatestUpdate.creatorShipmentId,
            )?.[0];

          let shipmentCurrentLocationDetail =
            latestActualTripLeg?.current_location ?? "";

          let latestMode = latestActualTripLeg?.mode_name ?? "";

          return getCurrentLegModeAndLocation(
            latestMode,
            shipmentCurrentLocationDetail,
            latestUpdate,
            "shipmentActual",
          );
        } else {
          //get the current details and mode from partview generated actual leg when shipment actual leg is not available (i.e. gives 404 status)
          let latestMode = legWithLatestUpdate.mode;

          return getCurrentLegModeAndLocation(
            latestMode,
            null, // added null as triplegType 'partViewActual' will get the required details from 'latestUpdate' itself.
            latestUpdate,
            "partViewActual",
          );
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [
    actualTriplegs,
    isShipmentsLoaded,
    shipmentsForTriplegs,
    getCurrentLegModeAndLocation,
  ]);
};

interface Location {
  actualArrival: string;
  actualDeparture: string;
  address: string;
  city: string;
  code: string;
  country: string;
  eta: string | null;
  name: string;
  scheduledArrivalWindow: any[];
  scheduledDeliveryWindow: any[];
  scheduledPickupWindow: any[];
  state: string;
  zipCode: string;
}

interface PackageDetails {
  LifecycleState: string;
}
