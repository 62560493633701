import {
  getMultiSelectQueryString,
  getNQueryStringFilterValuePriority,
  getEmptyQueryString,
} from "components/search-bar/search-filter-query-strings";

import {
  locationOptionsState,
  programCodeFilterOptions,
  supplierCodeFilterOptions,
  containerTypeFilterOptions,
} from "./modules/domain-data/ContainerTrackingSearchFilterLoaders";

import {
  AsyncSelectFilterButton,
  NFilterButton,
  BatchFilterButton,
} from "components/search-bar/FilterButtons";
import {
  batchCsvContainerTypeExample,
  batchCsvTagIdExample,
  batchCsvContainerIdExample,
  batchCsvProgramCodeExample,
  batchCsvSupplierCodeExample,
} from "components/search-bar/batch-comment-csv-data";

export const FILTERS = [
  // {
  //   queryKey: "location",
  //   label: t => "Location",
  //   optionsGetter: props => props.locationFilterOptions ?? [],
  //   queryBuilder: (queryParameter, filterValue) =>
  //     getMultiSelectQueryString("location", filterValue, "value")
  // },
  {
    // ordered top down from 0 - N
    queryKey: ["status", "locations"],
    label: (t) => t("container-tracking:Status"),
    Component: NFilterButton,
    nIsAsync: {
      status: false,
      locations: true,
    },
    nOptionsState: {
      status: null,
      locations: locationOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      status: null,
      locations: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      locations: ["At Location"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      status: true,
      locations: false,
    },
    // Hides select all option
    nHideSelectAll: {
      status: true,
      locations: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      status: true,
      locations: false,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    //Object key as query key
    optionsGetter: (props) => {
      return {
        status: props.statusFilterOptions ?? [],
      };
    },
    queryBuilder: getNQueryStringFilterValuePriority,
  },
  {
    queryKey: "type:1",
    label: (t) => t("container-tracking:Container Type"),
    optionsState: containerTypeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: (_ignored_queryParameter, filterValue) =>
      getMultiSelectQueryString("type", filterValue, "value"),
  },
  {
    queryKey: "activeExceptions",
    label: (t) => t("container-tracking:Active Exception"),
    optionsGetter: (props) => props.activeExceptionsFilterOptions ?? [],
    queryBuilder: (_ignored_queryParameter, filterValue) =>
      getMultiSelectQueryString("activeExceptions", filterValue, "value"),
    hideFuzzySearch: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "lad",
    label: (t) => t("container-tracking:LAD"),
    optionsGetter: (props) => props.ladFilterOptions ?? [],
    queryBuilder: (_ignored_queryParameter, filterValue) =>
      getMultiSelectQueryString("lad", filterValue, "value"),
    hideFuzzySearch: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "programCode",
    label: (t) => t("container-tracking:Program Code"),
    optionsState: programCodeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: (_ignored_queryParameter, filterValue) =>
      getMultiSelectQueryString("programCode", filterValue, "value"),
  },
  {
    queryKey: "supplierCode",
    label: (t) => t("container-tracking:Supplier Code"),
    optionsState: supplierCodeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: (_ignored_queryParameter, filterValue) =>
      getMultiSelectQueryString("supplierCode", filterValue, "value"),
  },
  {
    queryKey: "batteryLife",
    label: (t) => t("container-tracking:Battery Life"),
    optionsGetter: (props) => props.batteryLifeOptions ?? [],
    queryBuilder: (_ignored_queryParameter, filterValue) =>
      getMultiSelectQueryString("batteryLife", filterValue, "value"),
    hideFuzzySearch: true,
    hideSelectAll: true,
  },
  {
    queryKey: "batch",
    label: (t) => t("container-tracking:Batch Search"),
    Component: BatchFilterButton,
    optionsGetter: (_ignored_props, t) => {
      return [
        {
          label: t("container-tracking:Container Type #"),
          value: "container_type",
          batchCsvExample: batchCsvContainerTypeExample,
        },
        {
          label: t("container-tracking:Tag ID #"),
          value: "tag_id",
          batchCsvExample: batchCsvTagIdExample,
        },
        {
          label: t("container-tracking:Container ID #"),
          value: "container_id",
          batchCsvExample: batchCsvContainerIdExample,
        },
        {
          label: t("container-tracking:Program Code #"),
          value: "program_code",
          batchCsvExample: batchCsvProgramCodeExample,
        },
        {
          label: t("container-tracking:Supplier Code #"),
          value: "supplier_code",
          batchCsvExample: batchCsvSupplierCodeExample,
        },
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
];

export const CONTAINER_TYPE_FILTERS = [
  {
    queryKey: "type",
    label: (t) => t("container-tracking:Container Type"),
    optionsState: containerTypeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    hideSelectEmpty: true,
    hideSelectAll: true,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "programCode",
    label: (t) => t("container-tracking:Program Code"),
    optionsState: programCodeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: getMultiSelectQueryString,
    hideSelectEmpty: true,
  },
  {
    queryKey: "supplierCode",
    label: (t) => t("container-tracking:Supplier Code"),
    optionsState: supplierCodeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: getMultiSelectQueryString,
    hideSelectEmpty: true,
  },
];
