import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import apiUrl from "../../../../api-url";

export const locationOptionsState = buildAsyncFilterOptionsState({
  topic: "ctLocationFilter",
  getUrl: () => {
    return apiUrl(
      `/containertracking/api/location?pageSize=5&pageNumber=0&filter_name=location_code`,
    );
  },
  pageSize: 5,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: `${item.location_name} (${item.location_code})`,
    value: item.location_code,
  }),
});

export const supplierCodeFilterOptions = buildAsyncFilterOptionsState({
  topic: "ctSupplierCodeFilter",
  getUrl: () => {
    return apiUrl(
      `/containertracking/api/suppliercode?pageSize=20&pageNumber=0&filter_name=supplier_code`,
    );
  },
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: item,
    value: item,
  }),
});

export const programCodeFilterOptions = buildAsyncFilterOptionsState({
  topic: "ctProgramCodeFilter",
  getUrl: () => {
    return apiUrl(
      `/containertracking/api/programcode?pageSize=20&pageNumber=0&filter_name=program_code`,
    );
  },
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: item,
    value: item,
  }),
});

export const containerTypeFilterOptions = buildAsyncFilterOptionsState({
  topic: "ctContainerTypeFilter",
  getUrl: () => {
    return apiUrl(
      `/containertracking/api/containertype?pageSize=20&pageNumber=0&filter_name=type`,
    );
  },
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: item,
    value: item,
  }),
});
