/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, FormLabel } from "react-bootstrap";
import Loader from "react-loader";
import { useUsersTranslation } from "modules/users/utils/users.utils";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput,
  SelectInput,
  FormButton,
} from "components-old/modal-elems";
import { FlexRowDiv, FlexColDiv } from "styles/container-elements";
import Colors from "styles/colors";
import { DamageViewLocationsSelect } from "./DamageViewLocationsSelect";
import { filterRoleTypes } from "./modal-utils";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import RolesState from "modules/roles/RolesState";
import UsersState from "modules/users/UsersState";

const isValidForm = ({ selectedRoleTypes }) => {
  return selectedRoleTypes && selectedRoleTypes.length > 0 ? true : false;
};

const initUser = {
  id: "",
  email: "",
  selectedRoleTypes: null,
};

const EditUserModal = ({
  show,
  hide,
  user,
  authorization,
  activeOrganization,
  roles,
  updateUser,
  fetchDamageViewLocations,
  isDamageViewLocationsLoading,
  damageLocations,
}) => {
  const { t } = useTranslation("users");

  const [editUser, setUser] = useState(initUser);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const { getTranslatedFriendlyName } = useUsersTranslation();

  const org = activeOrganization?.org_name ?? "";

  const roleTypes = useMemo(() => {
    return roles ? filterRoleTypes(roles, authorization.isFvAdmin()) : [];
  }, [authorization, roles]);

  useEffect(() => {
    const roleIds =
      roles && user
        ? roles
            .filter((role) => role.users.includes(user.user_id))
            .map((r) => {
              return { value: r.id, label: getTranslatedFriendlyName(r.name) };
            })
        : null;

    setUser({
      id: user ? user.user_id : "",
      email: user ? user.email : "",
      selectedRoleTypes: roleIds,
    });
  }, [user, roles, getTranslatedFriendlyName]);

  useEffect(() => {
    if (user) {
      fetchDamageViewLocations(user.email);
    }
  }, [user, fetchDamageViewLocations]);

  useEffect(() => {
    setSelectedLocations(
      damageLocations.map((obj) => {
        return {
          assignee: user.email ?? null,
          label: obj?.name,
          value: obj?.id,
        };
      }),
    );
  }, [damageLocations]);

  const inputHandler = (value) => {
    setUser((prevState) => {
      return { ...prevState, ...value };
    });
  };

  let formattedSelectedRoles = [];
  if (show && editUser.selectedRoleTypes && editUser.selectedRoleTypes.length) {
    editUser.selectedRoleTypes.forEach((roleType) => {
      if (typeof roleType === "string") {
        const match = roleTypes.find(({ id }) => id === roleType);
        formattedSelectedRoles.push({
          value: roleType,
          label: getTranslatedFriendlyName(match.name),
        });
      } else {
        formattedSelectedRoles.push(roleType);
      }
    });
  }

  const getIsDamageViewSelected = () => {
    return (
      editUser.selectedRoleTypes?.filter((data) => {
        return data.label === "Damageview User";
      }).length > 0
    );
  };

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        setSelectedLocations([]);
        hide();
      }}
    >
      <ModalHeader title={t("users:Edit User")} />
      <ModalBody>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={editUser.email}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <SelectInput
            label={t("users:User Roles")}
            placeholder={t("users:Select...")}
            options={roleTypes.map((r) => {
              return {
                value: r.id,
                label: getTranslatedFriendlyName(r.name),
              };
            })}
            changeHandler={inputHandler}
            prop="selectedRoleTypes"
            value={formattedSelectedRoles}
          />
        </FlexRowDiv>

        {getIsDamageViewSelected() ? (
          <FlexColDiv style={{ marginTop: "1em" }}>
            <Loader loaded={!isDamageViewLocationsLoading} />
            <div css={{ width: "50%" }}>
              <FormLabel
                style={{ fontWeight: "normal", color: Colors.text.HIT_GRAY }}
              >
                {t("user:Reporting Location")}
              </FormLabel>
              <DamageViewLocationsSelect
                user={editUser?.email?.toLowerCase()}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                disabled={isDamageViewLocationsLoading}
              />
            </div>
            <div css={{ marginTop: "1em", color: Colors.text.GRAY }}>
              <span css={{ marginRight: "5px" }}>{t("user:Note")}:</span>
              <span>
                {t(
                  "user:Greyed out locations have already been assigned to a user. You may choose to override and reassign the location to the new user but it will remove the location assignment from the original user",
                )}
              </span>
            </div>
          </FlexColDiv>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <FormButton
          style={{ marginRight: ".5em", border: "1px solid #ccc" }}
          label={t("users:Cancel")}
          clickHandler={() => hide()}
        />
        <FormButton
          disabled={!isValidForm(editUser)}
          variant="success"
          actionType="ACTION"
          style={{ border: "1px solid #ccc" }}
          label={t("users:Update")}
          clickHandler={() => {
            const role_ids = editUser.selectedRoleTypes.map((r) => r.value);
            const addedLocations = selectedLocations.map((location) => {
              return location.value;
            });
            const payload = {
              role_ids: role_ids,
              damageLocations: addedLocations,
            };
            updateUser(editUser.id, payload);
            hide();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

EditUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  user: PropTypes.object,
  authorization: PropTypes.object.isRequired,
  activeOrganization: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired,
  fetchDamageViewLocations: PropTypes.func.isRequired,
  isDamageViewLocationsLoading: PropTypes.bool,
  damageLocations: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    roles: RolesState.selectors.getRoles(state),
    isDamageViewLocationsLoading:
      UsersState.selectors.getDamageViewLocationsLoading(state),
    damageLocations: UsersState.selectors.getDamageViewLocations(state),
  };
}

const mapDispatchToProps = {
  updateUser: UsersState.actionCreators.updateUser,
  fetchDamageViewLocations: UsersState.actionCreators.fetchDamageViewLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);
