/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SavedSearchesPanel } from "components/organisms/SavedSearchesPanel.organism";
import { PartViewSavedSearch } from "./PartView.SavedSearch.organism";
import PartViewEditSavedSearchModalContainer from "../../../components/search/PartView.EditSavedSearchModal.container";

import PartViewSearchBarState from "../../../redux/PartViewSearchBarState";
import PartViewSavedSearchState from "../../../redux/PartViewSavedSearchState";

import Colors from "styles/colors";

export const PartViewSavedSearchesPanel = () => {
  const savedSearches = useSelector(
    PartViewSavedSearchState.selectors.getSavedSearches,
  );

  const getIsSavedSearchLoading = useSelector(
    PartViewSavedSearchState.selectors.getIsLoading,
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [currentSavedSearch, setCurrentSavedSearch] = useState(null);
  const [deletingSavedSearchId, setDeletingSavedSearchId] = useState(null);

  useEffect(() => {
    dispatch(PartViewSavedSearchState.actionCreators.fetchSavedSearches());
  }, [dispatch]);

  return (
    <Fragment>
      <SavedSearchesPanel
        savedSearches={savedSearches}
        isLoading={getIsSavedSearchLoading}
        onAddSavedSearch={() => {
          setShowModal(true);
        }}
        CardComponent={PartViewSavedSearch}
        getCardProps={(savedSearch) => {
          return {
            savedSearch,
            isDeleting: deletingSavedSearchId === savedSearch.id,
            onEditClick: () => {
              dispatch(
                PartViewSavedSearchState.actionCreators.loadSavedSearch(
                  savedSearch,
                  true,
                ),
              );
              setCurrentSavedSearch(savedSearch);
              setShowModal(true);
            },
          };
        }}
        contentBackgroundColor={Colors.background.WHITE}
      />
      <PartViewEditSavedSearchModalContainer
        savedSearch={currentSavedSearch}
        show={showModal}
        hide={() => {
          dispatch(
            PartViewSearchBarState.actionCreators.resetSearchAndFilters(true),
          );
          setShowModal(false);
          setCurrentSavedSearch(null);
        }}
        deleteSearch={(id) => {
          dispatch(PartViewSavedSearchState.actionCreators.deleteSearch(id));
          setDeletingSavedSearchId(id);
        }}
      />
    </Fragment>
  );
};
