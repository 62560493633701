/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput,
  FormButton,
} from "components-old/modal-elems";
import { FlexRowDiv } from "styles/container-elements";
import Colors from "styles/colors";

import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import UsersState from "modules/users/UsersState";

const initUser = {
  email: "",
  isActive: false,
};

const UserActiveModal = ({
  show,
  hide,
  user,
  activeOrganization,
  blockUser,
  unblockUser,
}) => {
  const { t } = useTranslation("users");
  const [updateUser, setUser] = useState(initUser);

  useEffect(() => {
    setUser({
      email: user?.email ?? "",
      isActive: user?.status === "Active",
    });
  }, [user]);

  const org = activeOrganization?.org_name ?? "";

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <ModalHeader
        title={
          updateUser.isActive
            ? t("users:Mark User Inactive")
            : t("users:Mark User Active")
        }
      />
      <ModalBody>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={updateUser.email}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
      </ModalBody>
      <ModalFooter>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={t("users:Cancel")}
          clickHandler={() => hide()}
        />
        <FormButton
          actionType={updateUser.isActive ? "DANGER" : "ACTION"}
          label={
            updateUser.isActive
              ? t("users:Mark Inactive")
              : t("users:Mark Active")
          }
          clickHandler={() => {
            updateUser.isActive
              ? blockUser(user, activeOrganization.organization_id)
              : unblockUser(user, activeOrganization.organization_id);
            hide();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

UserActiveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  user: PropTypes.object,
  activeOrganization: PropTypes.object.isRequired,
  blockUser: PropTypes.func.isRequired,
  unblockUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
  };
}

const mapDispatchToProps = {
  blockUser: UsersState.actionCreators.blockUser,
  unblockUser: UsersState.actionCreators.unblockUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActiveModal);
