/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import {
  LocationAddressColumn,
  LocationSourceColumn,
  LocationCodeColumn,
  LocationCountColumn,
  LocationNameColumn,
} from "modules/location/utils/table-columns";

const UnresolvedLocationTable = (props) => {
  const {
    fetchUnresolvedLocationsSummary,
    data,
    isLoading,
    searchFilters,
    setSearchFilter,
    clearSearchFilters,
    page,
    pageSize,
    totalPages,
    setPagination,
    sortColumn,
    reverseSort,
    setSort,
    rowClickHandler,
  } = props;

  const { t } = useTranslation("locations");

  // Format the tables from search bar state to match what BaseTable expects.
  let tableFilters = Object.getOwnPropertyNames(searchFilters).map((key) => {
    return { id: key, value: searchFilters[key] };
  });
  // Remove filter that isn't controlled by the table.
  tableFilters = tableFilters.filter((filter) => filter.id !== "validAddress");

  const debounceFetchSearch = _.debounce(fetchUnresolvedLocationsSummary, 500);

  return (
    <div className="mt-3">
      <BaseTable
        data={data}
        columns={[
          LocationNameColumn,
          LocationSourceColumn,
          LocationCodeColumn,
          LocationAddressColumn,
          LocationCountColumn,
        ]}
        isLoading={isLoading}
        isManualFilterable={true}
        filters={tableFilters}
        onFilterChange={(filters) => {
          // Clear the filters so we start fresh.
          clearSearchFilters();

          // For each filter set on the table, set the filter in state.
          if (filters?.length > 0) {
            filters.forEach((filter) => {
              setSearchFilter(filter.id, filter.value);
            });
          }

          // Since we cleared all filters, reapply the validAddress filter.
          // This value isn't controlled by a table filter.
          setSearchFilter("validAddress", searchFilters.validAddress);

          // After setting all filters, kick off a search.
          // Debounced so that each keypress doesn't trigger a search.
          debounceFetchSearch();
        }}
        isManualPagination={true}
        pageIndex={page}
        pageSize={pageSize}
        pageCount={totalPages}
        onPageChange={(newPage) => {
          setPagination(null, newPage, pageSize);
        }}
        onPageSizeChange={(newPageSize) => {
          setPagination(null, page, newPageSize);
        }}
        isManualSorting={true}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        onPageSort={(column, isReverse) => {
          setSort(null, column, isReverse);
        }}
        rowClickHandler={rowClickHandler}
        showPagination={true}
        fixPaginationToBottom={true}
        showFilters={true}
        noDataFoundMessage={t("locations:No Unresolved Locations found.")}
      />
    </div>
  );
};

UnresolvedLocationTable.propTypes = {
  fetchUnresolvedLocationsSummary: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setPagination: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  setSort: PropTypes.func.isRequired,
  rowClickHandler: PropTypes.func.isRequired,
};

export default UnresolvedLocationTable;
