/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import {
  ExceptionCount,
  ExceptionCountPropTypes,
} from "components/molecules/ExceptionCount.molecule";
import { Text } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import Loader from "react-loader";

import Colors from "styles/colors";

export const ExceptionCountGroup = ({
  title,
  exceptions,
  isLoading,
  vertical = false,
  clickHandler,
  style = {},
  countStyles = {},
  exceptionDirection = "row",
}) => {
  const loaded = !_.isNil(isLoading) ? !isLoading : true;

  let exceptionContainerStyles = {
    display: "flex",
    flexDirection: exceptionDirection,
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
  };

  // By default we want 4 items per row before it wraps.
  // Setting this to 21% accounts for margins/padding but is ultimately arbitrary.
  // Note: Other values for flex-basis may work because the percentage needs to be close but not exact.
  let exceptionCountStyles = {
    flexBasis: "21%",
    ...countStyles,
  };

  if (vertical) {
    exceptionContainerStyles = {
      ...exceptionContainerStyles,
      justifyContent: "flex-start",
    };
    // If vertical, we want only 2 items per "row".
    // We aren't setting the container to flex-direction: column
    //   because it would need a fixed height for it to wrap.
    exceptionCountStyles = { flexBasis: "42%" };
  }

  return (
    <div
      className="position-relative d-flex flex-column justify-content-start mt-2"
      style={style}
    >
      {title ? (
        <Text italic align="center" color={Colors.text.GRAY}>
          {title}
        </Text>
      ) : null}

      <Loader loaded={loaded}>
        <div css={exceptionContainerStyles}>
          {exceptions.map((exception, i) => (
            <Tooltip
              key={i}
              placement="top"
              tooltipChildren={
                exception?.tooltipText ? (
                  <Text>{exception?.tooltipText}</Text>
                ) : null
              }
            >
              <ExceptionCount
                name={exception?.name}
                count={exception?.count}
                percentage={exception?.percentage}
                icon={exception?.icon}
                clickHandler={clickHandler?.bind(this, exception)}
                style={exceptionCountStyles}
              />
            </Tooltip>
          ))}
        </div>
      </Loader>
    </div>
  );
};

export const ExceptionCountGroupPropTypes = {
  /** Loading state for the exceptions. */
  isLoading: PropTypes.bool,
  /** Title of the ExceptionGroup. Optional. */
  title: PropTypes.string,
  /** Array of exception data. */
  exceptions: PropTypes.arrayOf(PropTypes.exact(ExceptionCountPropTypes)),
  /**
   * Sets the exceptions to appear vertically instead of horizontally.
   *
   * Note: This appears more like a 2 column layout. It displays like it does horizontally,
   *       but it will wrap after 2 items instead of 4.
   * */
  vertical: PropTypes.bool,
  /** Handler for clicking on exception data. Returns back the clicked exception object. */
  clickHandler: PropTypes.func,
  /** A style object that will be applied to the root element of this component. */
  style: PropTypes.object,
  countStyles: PropTypes.object,
  exceptionDirection: PropTypes.string,
};

ExceptionCountGroup.propTypes = ExceptionCountGroupPropTypes;
