/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

import { Text } from "components/atoms/Text.atom";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput,
  FormButton,
} from "components-old/modal-elems";
import Colors from "styles/colors";
import { FlexRowDiv } from "styles/container-elements";

import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import UsersState from "modules/users/UsersState";

const DeleteUserModal = ({
  activeOrganization,
  deleteUser,
  hide,
  show,
  user,
}) => {
  const { t } = useTranslation("users");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const userEmail = useMemo(
    () => (user && user.email ? user.email : ""),
    [user],
  );

  const org = useMemo(
    () =>
      activeOrganization && activeOrganization.org_name
        ? activeOrganization.org_name
        : "",
    [activeOrganization],
  );

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <ModalHeader title={t("users:Delete User")} />
      <ModalBody>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={userEmail}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
      </ModalBody>
      <ModalFooter>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={t("users:Cancel")}
          clickHandler={() => hide()}
        />
        <FormButton
          actionType="DANGER"
          label={t("users:Delete")}
          clickHandler={() => {
            setShowConfirmationDialog(true);
          }}
        />
        <DialogModal
          show={showConfirmationDialog}
          onHide={() => setShowConfirmationDialog(false)}
          title={t("users:Confirm User Deletion")}
          cancelButtonText={t("users:Cancel")}
          submitButtonText={t("users:Delete User")}
          submitButtonVariant="danger"
          onSubmit={() => {
            deleteUser(user, activeOrganization.organization_id);
            setShowConfirmationDialog(false);
            hide(); // Hide the original modal
          }}
        >
          <Text block>
            {t("users:Are you sure you want to delete this user?")}
          </Text>
          <Text block bold style={{ marginTop: 10 }}>
            {userEmail}
          </Text>
        </DialogModal>
      </ModalFooter>
    </Modal>
  );
};

DeleteUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  user: PropTypes.object,
  activeOrganization: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
  };
}

const mapDispatchToProps = {
  deleteUser: UsersState.actionCreators.deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal);
