/** @jsxImportSource @emotion/react */
import { forwardRef, ElementType, ReactNode, CSSProperties } from "react";
import { FontSize } from "./enums";

export const Text = forwardRef<HTMLElement, TextProps>(
  (
    {
      size = FontSize.size14,
      bold,
      italic,
      underline,
      color,
      block,
      align = "left",
      truncate,
      style = {},
      as: Component = "span",
      children,
      ...restProps
    },
    textRef,
  ) => {
    const textStyles: CSSProperties = {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: `${size}rem`,
      color,
      textAlign: align,
    };

    if (bold && italic) {
      // Inform the dev via console and visually that this is an invalid configuration
      // TODO: Make this error appear on the line where the bold and italic props are provided
      // by default it will show it threw here but we can change that to improve DX
      throw new Error(
        "Text component does not yet support bold and italic together.\n" +
          "This is because we don't have all the font weights and styles for ProximaNova",
      );
    } else if (bold) {
      textStyles.fontWeight = "bold";
    } else if (italic) {
      textStyles.fontStyle = "italic";
    }

    if (underline) {
      textStyles.textDecoration = "underline";
    }

    if (block) {
      textStyles.display = "block";
    }

    if (truncate) {
      textStyles.overflow = "hidden";
      textStyles.textOverflow = "ellipsis";
      textStyles.whiteSpace = "nowrap";
    }

    return (
      <Component style={style} css={textStyles} {...restProps} ref={textRef}>
        {children}
      </Component>
    );
  },
);

export type SharedTextProps = {
  /**
   * Sets the text to bold.
   */
  bold?: boolean;
  /**
   * Sets the text alignment of the text to its parent element.
   * Note: "inherit" will inherit the alignment from the parent's styles.
   *
   * Acceptable values: left (default), center, right
   */
  align?: "left" | "center" | "right" | "inherit";
  /**
   * Sets the text to italic.
   */
  italic?: boolean;
  /**
   * Underlines the text.
   */
  underline?: boolean;
  /**
   * The size of text.
   *
   * Use the `FontSize` enum for this value.
   */
  size?: FontSize;
  /**
   * Sets the text color.
   */
  color?: string;
  /**
   * Sets the text to display as a block element.
   */
  block?: boolean;
  /**
   * Truncates text by adding an ellipsis to the end.
   *
   * This is dependant on the parent element having a fixed width that the text is larger than.
   */
  truncate?: boolean;
  /**
   * The underlying HTML element to use when rendering Text.
   */
  as?: ElementType;
  /**
   * An object that will be used as the element styles for the text.
   */
  style?: CSSProperties;
  /**
   * A string for defining one or more classes that will be added to the text.
   */
  className?: string;
};

type TextProps = SharedTextProps & {
  /**
   * The text content.
   */
  children: ReactNode;
};

export { FontSize };
