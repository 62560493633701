/** @jsxImportSource @emotion/react */
import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";

export default function ReportEditDetails(props) {
  const { t } = useTranslation("reports");

  const {
    show,
    onHide,
    isEditMode,
    saveReport,
    updateReport,
    isSaving,
    isEditing,
    reportData,
  } = props;
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (reportData && reportData.report_description) {
      setDescription(reportData.report_description);
    }
  }, [reportData]);

  const onSubmit = () => {
    // Always update description
    reportData.report_description = description;

    if (isEditMode === true) {
      updateReport(reportData);
    } else {
      // Location can only be changed when saving a new report
      reportData.location = selectedLocation.value;

      saveReport(reportData);
    }
  };

  const options = [
    {
      value: "my",
      label: "My Reports",
    },
    {
      value: "shared",
      label: "Shared Reports",
    },
  ];

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      backdrop="static"
      centered
      css={{ ".modal-content": { borderRadius: 0 } }}
    >
      <Modal.Header
        closeButton={true}
        style={{
          backgroundColor: "transparent",
          color: "black",
          borderBottom: 0,
          paddingBottom: 0,
        }}
      >
        <Modal.Title style={{ fontSize: "1.8em" }}>
          {t("reports:Save report details")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isEditMode === false && (
          <React.Fragment>
            <p>
              {t(
                "reports:Where would you like to save your report? Selecting 'Shared Reports' will display this report to everyone in your organization.",
              )}
            </p>
            <div>
              <Select
                name="report-save-location"
                placeholder={t("reports:Select Save Location...")}
                multi={false}
                style={{ width: "100%" }}
                options={options}
                value={selectedLocation}
                clearable={false}
                onChange={(selection) => {
                  setSelectedLocation(selection);
                }}
                isDisabled={isSaving || isEditing}
                data-qa="save-location"
              />
            </div>
          </React.Fragment>
        )}
        <p style={{ marginTop: isEditMode === false ? 20 : 0 }}>
          {t(
            "reports:Fill out a description for this report. For Shared Reports, this will be visible to everyone.",
          )}
        </p>
        <div>
          <textarea
            placeholder={t("reports:Description")}
            style={{ width: "100%", padding: "2px 8px" }}
            value={description}
            data-qa="description"
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            disabled={isSaving || isEditing}
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 0, paddingTop: 0 }}>
        <Button
          onClick={onSubmit}
          disabled={
            (isEditMode === false && !selectedLocation) || isSaving || isEditing
          }
          data-qa="save-report-button"
        >
          {isSaving === true || isEditing === true
            ? t("reports:Saving...")
            : t("reports:Save Report")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ReportEditDetails.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  isEditMode: PropTypes.bool,
  saveReport: PropTypes.func,
  updateReport: PropTypes.func,
  isSaving: PropTypes.bool,
  isEditing: PropTypes.bool,
  reportData: PropTypes.object,
};
