/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { Text, FontSize } from "components/atoms/Text.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { useColumns } from "./ImpactedVins.columns";
import Colors from "styles/colors";

const AreaTableCell = (props) => {
  const { description } = props.value;
  return (
    <div>
      <Text block size={FontSize.size14}>
        {description}
      </Text>
    </div>
  );
};

AreaTableCell.propTypes = {
  value: PropTypes.shape({
    description: PropTypes.string,
  }),
};

export const ImpactedVinsTable = ({
  submissionId,
  solutionId,
  searchEntities,
  searchResults,
  isLoading,
  pageIndex,
  pageSize,
  pageCount,
  setPagination,
  setPhotoVin,
  setShowPhotoViewer,
  fields,
  fetchFields,
}) => {
  const { t } = useTranslation("damageview-details");

  const [tableData, setTableData] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [severityOptions, setSeverityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [showAreaTypePopup, setShowAreaTypePopup] = useState(false);
  const [areaTableData, setAreaTableData] = useState(null);

  const areaTableColumns = [
    {
      Header: t("damageview-details:Area"),
      id: "area",
      accessor: (obj) => {
        return {
          description: obj?.area ?? "",
        };
      },
      disableSortBy: true,
      disableResizing: true,
      Cell: AreaTableCell,
      minWidth: 250,
    },
    {
      Header: t("damageview-details:Type"),
      id: "type",
      accessor: (obj) => {
        return {
          description: obj?.type ?? "",
        };
      },
      disableSortBy: true,
      disableResizing: true,
      Cell: AreaTableCell,
      minWidth: 250,
    },
    {
      Header: t("damageview-details:Severity"),
      id: "severity",
      accessor: (obj) => {
        return {
          description: obj?.severity ?? "",
        };
      },
      disableSortBy: true,
      disableResizing: true,
      Cell: AreaTableCell,
      minWidth: 250,
    },
  ];

  const columns = useColumns(
    setShowPhotoViewer,
    setPhotoVin,
    setShowAreaTypePopup,
    setAreaTableData,
  );

  const closeHandler = () => {
    setAreaTableData([]);
    setShowAreaTypePopup(false);
  };

  useEffect(() => {
    fetchFields(null, submissionId);
  }, [fetchFields]);

  // TODO: Handle this in redux. When fetching data for this table, we can transform the response and store that in redux rather than the exact response. It would cause less updates in the component
  useEffect(() => {
    if (fields && fields.vinFields) {
      fields.vinFields.map((field) => {
        if (field.fieldName === "area") {
          setAreaOptions(field.values);
        } else if (field.fieldName === "type") {
          setTypeOptions(field.values);
        } else if (field.fieldName === "severity") {
          setSeverityOptions(field.values);
        }
        return null;
      });
    }
  }, [fields]);

  useEffect(() => {
    const updatedData = searchResults.map((data) => {
      const updatedAreaTypeSeverity = data?.areaTypeSeverity?.map((data) => {
        return {
          area: {
            value: data.area,
            label: areaOptions.filter((option) => option.value === data.area)[0]
              ?.label,
          },
          type: {
            value: data.type,
            label: typeOptions.filter((option) => option.value === data.type)[0]
              ?.label,
          },
          severity: {
            value: data.severity,
            label: severityOptions.filter(
              (option) => option.value === data.severity,
            )[0]?.label,
          },
        };
      });
      return {
        ...data,
        areaTypeSeverity: updatedAreaTypeSeverity ?? [],
      };
    });
    setTableData(updatedData);
  }, [searchResults, areaOptions, typeOptions, severityOptions]);

  useEffect(() => {
    searchEntities();
  }, [searchEntities]);

  return (
    <div>
      <PanelGroup>
        <PanelGroup.Header
          title={t("damageview-details:Impacted VINs")}
        ></PanelGroup.Header>
        <PanelGroup.Content style={{ padding: 0 }}>
          <BaseTable
            data={tableData}
            columns={columns}
            theme={Themes.LIGHT}
            isLoading={isLoading}
            rowClickHandler={null}
            showPagination={true}
            isManualPagination={true}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageCount={pageCount}
            onPageChange={(newPageIndex) => {
              setPagination(solutionId, newPageIndex, pageSize);
            }}
            onPageSizeChange={(newPageSize) => {
              setPagination(solutionId, pageIndex, newPageSize);
            }}
          />
        </PanelGroup.Content>
      </PanelGroup>
      <Modal
        show={showAreaTypePopup}
        size="lg"
        backdrop="static"
        onHide={closeHandler}
      >
        <Modal.Header
          closeButton={true}
          css={{
            backgroundColor: Colors.background.LIGHT_GRAY,
            color: Colors.background.DARK_BLUE,
          }}
        >
          <span
            css={{
              fontSize: "1.25em",
            }}
          >
            {t("damageview-details:Area / Type / Severity")}
          </span>
        </Modal.Header>
        <Modal.Body>
          <BaseTable data={areaTableData} columns={areaTableColumns} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

ImpactedVinsTable.propTypes = {
  solutionId: PropTypes.string,
  submissionId: PropTypes.string,
  searchEntities: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  isLoading: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  setPagination: PropTypes.func.isRequired,
  setPhotoVin: PropTypes.func,
  setShowPhotoViewer: PropTypes.func,
  fetchFields: PropTypes.func,
  fields: PropTypes.object,
};
