/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import {
  useSetSubTitle,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

// Components

import { SubmissionDetailsWidget } from "./components/SubmissionDetailsWidget";
import { ImpactedVinsTable } from "./components/ImpactedVinsTable";
import { CommentFeedWidget } from "./components/CommentFeedWidget";
import { PhotoViewer } from "./components/PhotoViewerWidget";
import { isShipper } from "shared/utils/organizations.utils";
export const DamageViewDetails = ({
  submissionId,
  solutionId,
  organization,
  searchEntities,
  searchResults,
  isSearchLoading,
  pageIndex,
  pageSize,
  pageCount,
  setPagination,
  fetchPhotoData,
  isPhotoDataLoading,
  photoData,
  fetchComments,
  addComment,
  markCommentsRead,
  updateComment,
  cancelUpdateComment,
  cancelAddComment,
  comments,
  isFetchingComments,
  fetchNotification,
  fetchSubmissionDetails,
  saveSubmissionChanges,
  submissionDetailsRequestData,
  exportPDFEntities,
  exportPDFIdentifier,
  exportPDFName,
  isExportingPDF,
  resetPDFExports,
  userPreference,
  fields,
  fetchFields,
  fetchStatusOptions,
}) => {
  const { t } = useTranslation("damageview-details");
  const [showPhotoViewer, setShowPhotoViewer] = useState(false);
  const [photoVin, setPhotoVin] = useState("");
  useSetTitleOnMount(t("damageview-details:Submission Details"));
  useSetSubTitle(
    submissionDetailsRequestData?.data?.external_id ?? "",
    submissionDetailsRequestData?.data?.external_id ?? "",
  );

  useTrackWithMixpanelOnce("Viewed Page: DamageView / Submission Details");

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        padding: "0 2rem",
        gap: "1.5rem",
        marginTop: "1.5rem",
      }}
    >
      <SubmissionDetailsWidget
        solutionId={solutionId}
        submissionId={submissionId}
        submissionDetailsRequestData={submissionDetailsRequestData}
        organization={organization}
        fetchSubmissionDetails={fetchSubmissionDetails}
        saveSubmissionChanges={saveSubmissionChanges}
        exportPDFEntities={exportPDFEntities}
        exportPDFIdentifier={exportPDFIdentifier}
        exportPDFName={exportPDFName}
        isExportingPDF={isExportingPDF}
        resetPDFExports={resetPDFExports}
        userPreference={userPreference}
        fetchStatusOptions={fetchStatusOptions}
      />
      <ImpactedVinsTable
        submissionId={submissionId}
        fields={fields}
        fetchFields={fetchFields}
        solutionId={solutionId}
        setShowPhotoViewer={setShowPhotoViewer}
        setPhotoVin={setPhotoVin}
        searchEntities={searchEntities}
        searchResults={searchResults}
        isLoading={isSearchLoading}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        setPagination={setPagination}
      />
      <PhotoViewer
        fields={fields}
        show={showPhotoViewer}
        vinId={photoVin}
        setShow={setShowPhotoViewer}
        submissionId={submissionId}
        fetchPhotoData={fetchPhotoData}
        isLoading={isPhotoDataLoading}
        data={photoData}
      />
      <CommentFeedWidget
        customizedSharableOrgText={
          // When a shipper org, we should show as share with submitter else it should be share with shipper (which will be automatically added based on sharedOrgId)
          isShipper(organization) ? t("damage-view:Share with Submitter") : null
        }
        sharableId={
          /// when the current organization is the same organization which submiter the damage report sharableId should be null
          isShipper(organization)
            ? submissionDetailsRequestData?.data?.submitterOrgId &&
              submissionDetailsRequestData?.data?.submitterOrgId !==
                organization.organization_id
              ? submissionDetailsRequestData?.data?.submitterOrgId
              : null
            : submissionDetailsRequestData?.data?.submitterShipperId
        }
        fetchComments={fetchComments}
        addComment={addComment}
        markCommentsRead={markCommentsRead}
        updateComment={updateComment}
        cancelUpdateComment={cancelUpdateComment}
        cancelAddComment={cancelAddComment}
        comments={comments}
        isFetchingComments={isFetchingComments}
        fetchNotification={fetchNotification}
        submissionId={submissionId}
      />
    </div>
  );
};

DamageViewDetails.propTypes = {
  searchEntities: PropTypes.func,
  submissionId: PropTypes.string,
  solutionId: PropTypes.string,
  organization: PropTypes.object,
  searchResults: PropTypes.array,
  isSearchLoading: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  setPagination: PropTypes.func.isRequired,
  fetchPhotoData: PropTypes.func,
  isPhotoDataLoading: PropTypes.bool,
  photoData: PropTypes.object,
  // Comments
  fetchComments: PropTypes.func.isRequired,
  isFetchingComments: PropTypes.bool.isRequired,
  comments: PropTypes.shape({
    currentPage: PropTypes.number,
    data: PropTypes.array,
    totalCount: PropTypes.number,
    totalCountRead: PropTypes.number,
    totalCountUnread: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  addComment: PropTypes.func.isRequired,
  markCommentsRead: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  cancelUpdateComment: PropTypes.func.isRequired,
  cancelAddComment: PropTypes.func.isRequired,
  fetchNotification: PropTypes.func.isRequired,
  fetchSubmissionDetails: PropTypes.func.isRequired,
  saveSubmissionChanges: PropTypes.func.isRequired,
  submissionDetailsRequestData: PropTypes.shape({
    data: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
  exportPDFEntities: PropTypes.func,
  exportPDFIdentifier: PropTypes.string,
  exportPDFName: PropTypes.string,
  isExportingPDF: PropTypes.bool,
  resetPDFExports: PropTypes.func,
  userPreference: PropTypes.object,
  fields: PropTypes.object,
  fetchFields: PropTypes.func,
  fetchStatusOptions: PropTypes.func,
};
