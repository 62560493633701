import { connect } from "react-redux";
import DPUAdminTool from "./DPUAdminTool.page";
import DPUAdminToolState, {
  getActionStatus,
  getRequestResults,
} from "../redux/DPUAdminToolState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    actionStatus: getActionStatus(state),
    requestResults: getRequestResults(state),
    solutionId: getSolutionId(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  const { clearActionStatus, submitAction, submitBulkAction } =
    DPUAdminToolState.actionCreators;

  return {
    clearActionStatus: () => {
      dispatch(clearActionStatus());
    },
    submitAction: (solutionId, payload) => {
      dispatch(submitAction(solutionId, payload));
    },
    submitBulkAction: (solutionId, payload) => {
      dispatch(submitBulkAction(solutionId, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DPUAdminTool);
