/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { Button } from "components/atoms/Button.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";

import Colors from "styles/colors";

export const TripLegReferencesButton = ({ t, vinInfo }) => {
  const [showModal, setShowModal] = useState(false);
  const tripLegReferences = vinInfo.plannedTripLegReferences || [];

  const tripLegReferencesColumns = [];

  tripLegReferencesColumns.push({
    Header: t("fv-vin-details:Name"),
    Cell: (cell) => {
      return (
        <Text block size={FontSize.size12}>
          {cell.row.original.qualifier}
        </Text>
      );
    },
  });

  tripLegReferencesColumns.push({
    Header: t("fv-vin-details:Value"),
    Cell: (cell) => {
      return (
        <Text block size={FontSize.size12}>
          {cell.row.original.value}
        </Text>
      );
    },
  });

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Button
        variant="link"
        data-qa="show-trip-leg-references"
        onClick={() => setShowModal(true)}
        style={{
          color: "black",
          fontWeight: 1600,
          textDecoration: "underline",
        }}
      >
        {t("fv-vin-details:Trip Leg References")}
      </Button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header
          closeButton
          css={{
            backgroundColor: Colors.background.LIGHT_GRAY,
            color: Colors.background.DARK_BLUE,
            padding: "0.5rem 0.75rem 0.5rem 0.5rem",
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {t("fv-vin-details:Trip Leg References")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BaseTable
            data-qa="name-value-table"
            theme={Themes.LIGHT}
            data={tripLegReferences}
            columns={tripLegReferencesColumns}
            pageSize={
              tripLegReferences?.length > 20 ? tripLegReferences.length : 20
            }
            disableSortBy={true}
            defaultSortColumn={"label"}
            defaultReverseSort={false}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

TripLegReferencesButton.propTypes = {
  t: PropTypes.func,
  vinInfo: PropTypes.object,
};
