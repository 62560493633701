import { env, EnvironmentName } from "env";

/**
 * The configuration for an environment
 */
type EnvironmentConfig = {
  api: {
    url: string;
    customerUrl: string;
  };
  auth: {
    clientId: string;
    loginUrl: string;
  };
};

/**
 * A map of all available Auth0 tenants
 * - freightverify: prod and proda
 * - freightverify-t: test
 * - freightverify-s: dev, dev2, qa, and qa2
 */
const Auth0Tenants: { [key: string]: EnvironmentConfig["auth"] } = {
  freightverify: {
    clientId: "25bPZYkU3nnJhXNmQQxq5O9H8eNfkbFa",
    loginUrl: "https://login.freightverify.com",
  },
  "freightverify-t": {
    clientId: "5fNJ1aQe6mkvdVqaWwjMIqQRM8q2XUrv",
    loginUrl: "https://login-t.freightverify.com",
  },
  "freightverify-s": {
    clientId: "l1fv302czTdpNdv3abSPdNJHUIJ7QaLD",
    loginUrl: "https://login-s.freightverify.com",
  },
};

/**
 * A map of all available environments
 */
const Environments: { [key in EnvironmentName]: EnvironmentConfig } = {
  dev: {
    api: {
      url: "https://data-d.freightverify.com",
      customerUrl: "https://api.dev.freightverify.com",
    },
    auth: Auth0Tenants["freightverify-s"],
  },
  dev2: {
    api: {
      url: "https://data-d2.freightverify.com",
      customerUrl: "https://api.dev2.freightverify.com",
    },
    auth: Auth0Tenants["freightverify-s"],
  },
  qa: {
    api: {
      url: "https://data-q.freightverify.com",
      customerUrl: "https://api.qa.freightverify.com",
    },
    auth: Auth0Tenants["freightverify-s"],
  },
  qa2: {
    api: {
      url: "https://data-q2.freightverify.com",
      customerUrl: "https://api.qa2.freightverify.com",
    },
    auth: Auth0Tenants["freightverify-s"],
  },
  test: {
    api: {
      url: "https://data-t.freightverify.com",
      customerUrl: "https://test.api.freightverify.com",
    },
    auth: Auth0Tenants["freightverify-t"],
  },
  proda: {
    api: {
      url: "https://data-a.freightverify.com",
      customerUrl: "https://api-a.freightverify.com",
    },
    auth: Auth0Tenants["freightverify"],
  },
  prod: {
    api: {
      url: "https://data-b.freightverify.com",
      customerUrl: "https://api.freightverify.com",
    },
    auth: Auth0Tenants["freightverify"],
  },
};

/**
 * Centralized place for all configurable items based on environment variables.
 */
const ApplicationConfig = {
  /**
   * The active environment configuration for the UI.
   * Configure this by setting `REACT_APP_ENVIRONMENT` in your .env file to one
   * of the following values:
   * - dev
   * - dev2
   * - qa
   * - qa2
   * - test
   * - proda
   * - prod
   *
   * Note: When running the app locally, you must restart the server if your
   * .env file changes.
   */
  ENVIRONMENT: {
    name: env.REACT_APP_ENVIRONMENT,
    ...Environments[env.REACT_APP_ENVIRONMENT],
  },

  /**
   * Check if any environment name matches the active environment.
   *
   * @example
   * // Check one
   * isEnvironment("prod")
   * // ...or many at once
   * isEnvironment("dev", "dev2")
   */
  isEnvironment(...names: EnvironmentName[]) {
    return names.some((name) => env.REACT_APP_ENVIRONMENT === name);
  },

  /**
   * When enabled, API mocks will be used instead of the configured environment.
   * If there isn't a mock for a request, it will fallback to the deployed APIs.
   *
   * See src/mocks/readme.md
   */
  ENABLE_MOCK_API: env.REACT_APP_ENABLE_MOCK_API,

  /**
   * When enabled, all redux actions will be logged in the console.
   *
   * See the {@link https://github.com/LogRocket/redux-logger redux-logger}
   * project for more info.
   */
  ENABLE_REDUX_LOGGER: env.REACT_APP_ENABLE_REDUX_LOGGER,

  /**
   * Token for using Mixpanel
   */
  MIXPANEL_TOKEN: env.REACT_APP_MIXPANEL_TOKEN,

  /**
   * Flag to determine if the build is for public routes only.
   * TODO: Should the env variable be named differently?
   */
  IS_PUBLIC: env.REACT_APP_IS_PUBLIC,
};

export default ApplicationConfig;
