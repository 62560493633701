/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import { useState, useRef } from "react";
import { Modal, FormControl, InputGroup, Alert } from "react-bootstrap";
import { Button } from "components/atoms/Button.atom";
import { useTranslation } from "react-i18next";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { Text } from "components/atoms/Text.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { FlexColDiv } from "styles/container-elements";

import Colors from "styles/colors";

const csvTemplateManageLocation = {
  fileName: `template-edit-route-${Date.now()}.csv`,
  headers: [
    "Route Name",
    "Location Name",
    "Location Code",
    "LAD",
    "Address",
    "Address2",
    "City",
    "State",
    "Zip Code",
    "Country",
    "ADD",
    "DELETE",
  ],
  data: [
    [
      "Route1",
      "LocationName123",
      "LocationCode123",
      "Assembly",
      "address1",
      "address2",
      "city1",
      "state1",
      "48104",
      "US",
      "Y",
      "",
    ],
    [
      "Bucket2",
      "LocationName124",
      "LocationCode124",
      "Supplier",
      "address1",
      "address2",
      "city2",
      "state2",
      "48104",
      "US",
      "",
      "Y",
    ],
  ],
};

const csvTemplateAssignContainer = {
  fileName: `template-assign-containers-${Date.now()}.csv`,
  headers: ["TAG ID", "Container ID", "Container Type", "Route Name"],
  data: [
    ["9r7z6QSHYogn0EHrauP8", , , "RouteName123"],
    [, "ABC-0001", , "RouteName124"],
    [, , "type", "RouteName125"],
  ],
};

const initialState = {
  importFileCsvData: "",
  showResultModal: false,
};

export const BatchUploadPopup = ({
  show,
  hide,
  batchUploadAction,
  clearBatchUpload,
  batchUploadResult,
  batchUploadError,
  batchUploadLoading,
}) => {
  const { t } = useTranslation("container-tracking");

  const hideHandler = () => {
    onReset();
    hide();
  };

  const [importAssignContainerFile, setImportAssignContainerFile] =
    useState(null);
  const [importManageLocationFile, setImportManageLocationFile] =
    useState(null);
  const [
    importAssignContainerFileCsvData,
    setImportAssignContainerFileCsvData,
  ] = useState(initialState.importFileCsvData);
  const [importManageLocationFileCsvData, setImportManageLocationFileCsvData] =
    useState(initialState.importFileCsvData);

  useSetTitleOnMount(
    t("container-tracking:Container Tracking Location Management"),
  );

  const onAssignLocationFileLoad = (file) => {
    const csvData = file.target.result.trim();
    setImportAssignContainerFileCsvData(csvData);
  };

  const onManageLocationFileLoad = (file) => {
    const csvData = file.target.result.trim();
    setImportManageLocationFileCsvData(csvData);
  };

  const assignLocationInputRef = useRef(null);
  const manageLocationInputRef = useRef(null);

  const resetFileInput = () => {
    assignLocationInputRef.current.value = null;
    manageLocationInputRef.current.value = null;
  };

  const onReset = () => {
    setImportAssignContainerFile(null);
    setImportManageLocationFile(null);
    setImportAssignContainerFileCsvData(initialState.importFileCsvData);
    setImportManageLocationFileCsvData(initialState.importFileCsvData);
    resetFileInput();
    clearBatchUpload();
  };

  const onSubmit = () => {
    clearBatchUpload();
    batchUploadAction({
      locationToRoute: importManageLocationFile
        ? {
            fileName: importManageLocationFile?.name,
            data: importManageLocationFileCsvData,
          }
        : null,
      containerToRoute: importAssignContainerFile
        ? {
            fileName: importAssignContainerFile?.name,
            data: importAssignContainerFileCsvData,
          }
        : null,
    });
  };

  return (
    <Modal size="xl" backdrop="static" show={show} onHide={() => hideHandler()}>
      <Modal.Header
        closeButton={!batchUploadLoading}
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "1rem",
        }}
      >
        <Modal.Title style={{ fontWeight: "500" }}>
          {t("container-tracking:Batch Upload")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          css={{
            padding: "1em",
            flexDirection: "column",
          }}
        >
          <FlexColDiv>
            <Text size={FontSize.size16} css={{ marginBottom: "1em" }}>
              {t("container-tracking:Assign Containers")}:&nbsp;
              {t(
                "container-tracking:Download the attached CSV provide details and upload the file to successfully assign containers to a location",
              )}
            </Text>
            <InputGroup>
              <label
                className="input-group-btn"
                css={{ paddingRight: "0.375rem" }}
              >
                <Text className="btn btn-primary">
                  {t("container-tracking:Browse")}
                  <input
                    ref={assignLocationInputRef}
                    id="assignContainers"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={(e) => {
                      const reader = new FileReader();
                      reader.readAsText(e?.target?.files[0]);
                      reader.onload = onAssignLocationFileLoad;
                      setImportAssignContainerFile(e?.target?.files[0]);
                    }}
                    hidden
                  />
                </Text>
              </label>
              <FormControl
                type="type"
                value={importAssignContainerFile?.name ?? ""}
                placeholder={t(
                  "container-tracking:Upload Assign Location Data (.csv)",
                )}
                htmlFor="assignContainers"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                readOnly
              />
            </InputGroup>
            <FlexColDiv css={{ marginLeft: "5em", marginBottom: "1em" }}>
              <Text color={Colors.text.LIGHT_GRAY}>
                ({t("container-tracking:Expected columns")}:{" "}
                {t(
                  "container-tracking:TAG ID (Container ID, Container Type), Route Name",
                )}
                )
              </Text>
              <DownloadCsvLink
                data={csvTemplateAssignContainer.data}
                headers={csvTemplateAssignContainer.headers}
                label={t("container-tracking:Download template (.csv)")}
                filename={csvTemplateAssignContainer.fileName}
                css={{ fontWeight: "bold" }}
              />
            </FlexColDiv>
          </FlexColDiv>
          <FlexColDiv>
            <Text size={FontSize.size16} css={{ marginBottom: "1em" }}>
              {t("container-tracking:Edit Route")}:&nbsp;
              {t(
                "container-tracking:Download the attached CSV provide details and upload the file to successfully manage routes and locations",
              )}
            </Text>
            <InputGroup>
              <label
                className="input-group-btn"
                css={{ paddingRight: "0.375rem" }}
              >
                <Text className="btn btn-primary">
                  {t("container-tracking:Browse")}
                  <input
                    ref={manageLocationInputRef}
                    id="editRoute"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={(e) => {
                      const reader = new FileReader();
                      reader.readAsText(e?.target?.files[0]);
                      reader.onload = onManageLocationFileLoad;
                      setImportManageLocationFile(e?.target?.files[0]);
                    }}
                    hidden
                  />
                </Text>
              </label>
              <FormControl
                type="type"
                value={importManageLocationFile?.name ?? ""}
                placeholder={t(
                  "container-tracking:Upload Edit Routes Data (.csv)",
                )}
                htmlFor="editRoute"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                readOnly
              />
            </InputGroup>
            <FlexColDiv css={{ marginLeft: "5em", marginBottom: "1em" }}>
              <Text color={Colors.text.LIGHT_GRAY}>
                ({t("container-tracking:Expected columns")}:{" "}
                {t(
                  "container-tracking:Route Name, Location Name, Location Code, LAD, Address, Address2, City, State, Zip Code, Country, ADD, DELETE",
                )}
                )
              </Text>
              <DownloadCsvLink
                data={csvTemplateManageLocation.data}
                headers={csvTemplateManageLocation.headers}
                label={t("container-tracking:Download template (.csv)")}
                filename={csvTemplateManageLocation.fileName}
                css={{ fontWeight: "bold" }}
              />
            </FlexColDiv>
          </FlexColDiv>
          <div>
            {batchUploadResult ? (
              <Alert variant="success">
                <div>
                  {t(`container-tracking:File has been successfully uploaded!`)}
                </div>
              </Alert>
            ) : null}

            {batchUploadError ? (
              <Alert variant="danger">
                <div>
                  {t(
                    "container-tracking:File upload failed. please check your entries and try again",
                  )}
                </div>
                <div>
                  {t(`container-tracking:error`)}{" "}
                  {batchUploadError?.error?.message}
                </div>
              </Alert>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.background.LIGHT_GRAY,
        }}
      >
        <Button
          variant="danger"
          disabled={
            batchUploadLoading ||
            (importAssignContainerFile == null &&
              importManageLocationFile == null)
          }
          onClick={onReset}
        >
          {t("container-tracking:Reset")}
        </Button>
        <Button
          variant="success"
          disabled={
            batchUploadLoading ||
            (importAssignContainerFile == null &&
              importManageLocationFile == null)
          }
          onClick={onSubmit}
        >
          {t("container-tracking:Submit")}
          {batchUploadLoading ? (
            <Icon src={faSpinner} css={{ marginLeft: 5 }} spin={true} />
          ) : null}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

BatchUploadPopup.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  batchUploadAction: PropTypes.func,
  clearBatchUpload: PropTypes.func,
  batchUploadResult: PropTypes.object,
  batchUploadError: PropTypes.object,
  batchUploadLoading: PropTypes.bool,
};
