import apiUrl from "api-url";

export const Products = {
  SHIPMENTS: "Shipments",
};

export enum NotificationEventTypes {
  ETA_CHANGE = "ETA Update - Behind Schedule",
  EARLY_ARRIVAL = "ETA Update - Early Arrival",
  CARRIER_DELAY = "ETA Update - Carrier Delay",
}

export const NotificationTriggers = {
  AFTER_DELIVERY_WINDOW_OPEN: "After Delivery Window Open",
  AFTER_DELIVERY_WINDOW_CLOSE: "After Delivery Window Close",
};

export const ApiOptions = {
  pageSize: 20,
  pageNumber: 0,
  verbose: false,
};

export const ApiRequestStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export enum FilterTypesEnum {
  ORIGIN = "originCodes",
  DESTINATION = "destinationCodes",
  PARTS_NUMBER = "partNumbers",
  SCHEDULED_DELIVERY = "scheduledArrival",
  CARRIER = "carrierFvIds",
  MILES_OUT = "withinRangeOfDestination",
  MODE = "mode",
}

export enum ScheduleDeliveryOptionsEnum {
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
}

export enum ModeOptionsEnum {
  TRUCK = "Truck",
  OCEAN = "Ocean",
  LTL = "LTL",
  PARCEL = "Parcel",
  RAIL = "Rail",
  AIR = "Air",
  INTERMODAL = "Intermodal",
  MULTIMODAL = "Multimodal",
}

export const BehindScheduleNotificationTriggers = {
  AFTER_DELIVERY_WINDOW_OPEN: "After Delivery Window Open",
  AFTER_DELIVERY_WINDOW_CLOSE: "After Delivery Window Close",
};

export const defaultBehindScheduleNotificationTriggerObject = {
  notificationEventType: NotificationEventTypes.ETA_CHANGE,
  notificationTriggerTimeObj: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
  notificationTrigger:
    BehindScheduleNotificationTriggers.AFTER_DELIVERY_WINDOW_OPEN,
};

export const defaultEarlyArrivalNotificationTriggerObject = {
  notificationEventType: NotificationEventTypes.EARLY_ARRIVAL,
  notificationTriggerTimeObj: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
};

export const defaultCarrierExceptionNotificationTriggerObject = {
  notificationEventType: NotificationEventTypes.CARRIER_DELAY,
};

export const filterTypeOptions = [
  {
    key: "ORIGIN",
    label: "Origin",
    value: FilterTypesEnum.ORIGIN,
    allowMultiSelect: true,
  },
  {
    key: "DESTINATION",
    label: "Destination",
    value: FilterTypesEnum.DESTINATION,
    allowMultiSelect: true,
  },
  {
    key: "SCHEDULED_DELIVERY",
    label: "Scheduled Delivery",
    value: FilterTypesEnum.SCHEDULED_DELIVERY,
    allowMultiSelect: false,
  },
  {
    key: "PARTS_NUMBER",
    label: "Part Number",
    value: FilterTypesEnum.PARTS_NUMBER,
    allowMultiSelect: true,
  },
  {
    key: "CARRIER",
    label: "Carrier",
    value: FilterTypesEnum.CARRIER,
    allowMultiSelect: true,
  },
  {
    key: "MILES_OUT",
    label: "Miles Out",
    value: FilterTypesEnum.MILES_OUT,
    allowMultiSelect: false,
  },
  {
    key: "MODE",
    label: "Mode",
    value: FilterTypesEnum.MODE,
    allowMultiSelect: true,
  },
];

export interface FilterOptionUrlLookupTableType {
  [key: string]: string;
  originCodes: string;
  destinationCodes: string;
  partNumbers: string;
  carrierFvIds: string;
}

export const filterOptionUrlLookupTable: FilterOptionUrlLookupTableType = {
  [FilterTypesEnum.ORIGIN]: apiUrl("/location/locations"),
  [FilterTypesEnum.DESTINATION]: apiUrl("/location/locations"),
  [FilterTypesEnum.PARTS_NUMBER]: apiUrl("/partview/app/list/parts"),
  [FilterTypesEnum.CARRIER]: apiUrl("/shipping-ng/carriers"),
};
