/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { BehindScheduleNotificationTriggers } from "pages/administration/notification-management/notificationmanagement.const";
import { Text } from "components/atoms/Text.atom";

export const BehindScheduleOptions = (props) => {
  const { notificationDetailsObj, setNotificationDetailsObj } = props;

  const { t } = useTranslation("notification-management");

  const dropDownDayOptions = [...Array(32)].map((_, i) => `${i}`);
  const dropDownHourOptions = [...Array(24)].map((_, i) => `${i}`);
  const dropDownMinuteOptions = [...Array(4)].map((_, i) => `${i * 15}`);

  const [daysLocal, setDaysLocal] = useState(
    notificationDetailsObj.notificationTriggerTimeObj.days,
  );
  const [hoursLocal, setHoursLocal] = useState(
    notificationDetailsObj.notificationTriggerTimeObj.hours,
  );
  const [minutesLocal, setMinutesLocal] = useState(
    notificationDetailsObj.notificationTriggerTimeObj.minutes,
  );
  const [notificationTriggerLocal, setNotificationTriggerLocal] = useState(
    notificationDetailsObj.notificationTrigger,
  );

  return (
    <>
      <Text block css={{ marginTop: "0.5rem" }}>
        {t(
          "notification-management:When should this notification be triggered?",
        )}
      </Text>
      <div
        css={{
          display: "flex",
          alignItems: "left",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "1em",
          marginTop: "0.2rem",
        }}
      >
        <Select
          css={{ marginTop: "0.2rem" }}
          options={dropDownDayOptions.map((dayOption) => ({
            value: dayOption,
            label: t("notification-management:[[[dayOption]]] Days", {
              dayOption,
            }),
          }))}
          onChange={(option) => {
            setDaysLocal(parseInt(option.value));
            setNotificationDetailsObj({
              ...notificationDetailsObj,
              notificationTriggerTimeObj: {
                days: parseInt(option.value),
                hours: hoursLocal,
                minutes: minutesLocal,
              },
            });
          }}
          value={{
            value: daysLocal,
            label: t("notification-management:[[[daysLocal]]] Days", {
              daysLocal,
            }),
          }}
          data-qa="dayOption-select"
        />
        <Select
          css={{ marginTop: "0.2rem" }}
          options={dropDownHourOptions.map((hourOption) => ({
            value: hourOption,
            label: t("notification-management:[[[hourOption]]] Hours", {
              hourOption,
            }),
          }))}
          onChange={(option) => {
            setHoursLocal(parseInt(option.value));
            setNotificationDetailsObj({
              ...notificationDetailsObj,
              notificationTriggerTimeObj: {
                days: daysLocal,
                hours: parseInt(option.value),
                minutes: minutesLocal,
              },
            });
          }}
          value={{
            value: hoursLocal,
            label: t("notification-management:[[[hoursLocal]]] Hours", {
              hoursLocal,
            }),
          }}
          data-qa="hourOption-select"
        />
        <Select
          css={{ marginTop: "0.2rem" }}
          options={dropDownMinuteOptions.map((minuteOption) => ({
            value: minuteOption,
            label: t("notification-management:[[[minuteOption]]] Minutes", {
              minuteOption,
            }),
          }))}
          onChange={(option) => {
            setMinutesLocal(parseInt(option.value));
            setNotificationDetailsObj({
              ...notificationDetailsObj,
              notificationTriggerTimeObj: {
                days: daysLocal,
                hours: hoursLocal,
                minutes: parseInt(option.value),
              },
            });
          }}
          value={{
            value: minutesLocal,
            label: t("notification-management:[[[minutesLocal]]] Minutes", {
              minutesLocal,
            }),
          }}
          data-qa="minuteOption-select"
        />

        <Select
          css={{ marginTop: "0.2rem" }}
          options={Object.entries(BehindScheduleNotificationTriggers).map(
            ([key, value]) => ({
              value: value,
              label: value,
            }),
          )}
          onChange={(option) => {
            setNotificationTriggerLocal(option.value);
            setNotificationDetailsObj({
              ...notificationDetailsObj,
              notificationTrigger: option.value,
            });
          }}
          value={{
            value: notificationTriggerLocal,
            label: notificationTriggerLocal,
          }}
          data-qa="notificationTrigger-select"
        />
      </div>
    </>
  );
};

BehindScheduleOptions.propTypes = {
  notificationDetailsObj: PropTypes.object,
  setNotificationDetailsObj: PropTypes.func,
};
