import axios from "axios";
import apiUrl from "../../../api-url";
import buildFetchDuck from "../../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../../vendor/signal-utils/chain-reducers";

const STORE_MOUNT_POINT = "DPUAdminTools";

// Actions
const CLEAR_ACTION_STATUS = `${STORE_MOUNT_POINT}/CLEAR_ACTION_STATUS`;
const SUMBIT_ACTION = `${STORE_MOUNT_POINT}/SUMBIT_ACTION`;
const SUMBIT_ACTION_SUCCEEDED = `${STORE_MOUNT_POINT}/SUMBIT_ACTION_SUCCEEDED`;
const SUMBIT_ACTION_FAILED = `${STORE_MOUNT_POINT}/SUMBIT_ACTION_FAILED`;
const SUBMIT_ACTION_NOT_ALL_SUCCESS = `${STORE_MOUNT_POINT}/SUBMIT_ACTION_NOT_ALL_SUCCESS`;

// Fetch ducks
const duck = buildFetchDuck(STORE_MOUNT_POINT);

const getDPUAdminToolUrl = (solutionId) => {
  return apiUrl(`/dda/solution/${solutionId}/admin-tool`);
};

// Selector
export function getActionStatus(state) {
  return state[STORE_MOUNT_POINT].actionStatus;
}

export function getRequestResults(state) {
  return state[STORE_MOUNT_POINT].requestResults;
}

//Action Creators
export function clearActionStatus() {
  return function (dispatch) {
    dispatch({ type: CLEAR_ACTION_STATUS });
  };
}

export function submitAction(solutionId, payload) {
  return (dispatch) => {
    dispatch({ type: SUMBIT_ACTION });
    const url = getDPUAdminToolUrl(solutionId);
    return axios
      .post(url, payload, { params: { disregardCurrentStatus: true } })
      .then((response) => {
        if (!response.data.status && response.status !== 204) {
          throw new Error("Missing data");
        }
        if (response.data.status === "FAILED") {
          if (!response.data.vins_and_reasons) {
            throw new Error("Missing data");
          }
          dispatch({
            type: SUBMIT_ACTION_NOT_ALL_SUCCESS,
            payload: response.data.vins_and_reasons,
          });
        } else {
          dispatch({
            type: SUMBIT_ACTION_SUCCEEDED,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          dispatch({ type: SUMBIT_ACTION_FAILED, error });
        }, 2000);
      });
  };
}

export function submitBulkAction(solutionId, payload) {
  return (dispatch) => {
    dispatch({ type: SUMBIT_ACTION });
    const url = getDPUAdminToolUrl(solutionId);
    const config = {
      headers: {
        Accept: "text/csv;version=dda_admin_tool_mass_operation",
      },
      params: {
        disregardCurrentStatus: true,
      },
    };
    return axios
      .post(url, payload, config)
      .then((response) => {
        if (!response.data.status && response.status !== 204) {
          throw new Error("Missing data");
        }
        if (response.data.status === "FAILED") {
          if (!response.data.vins_and_reasons) {
            throw new Error("Missing data");
          }
          dispatch({
            type: SUBMIT_ACTION_NOT_ALL_SUCCESS,
            payload: response.data.vins_and_reasons,
          });
        } else {
          dispatch({
            type: SUMBIT_ACTION_SUCCEEDED,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          dispatch({ type: SUMBIT_ACTION_FAILED, error });
        }, 2000);
      });
  };
}

// Initial state
export const initialState = {
  actionStatus: null,
  requestResults: null,
};

// Reducer
function DPUAdminToolReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
        requestResults: null,
      };
    case SUMBIT_ACTION:
      return {
        ...state,
        actionStatus: "IN_PROGRESS",
      };
    case SUMBIT_ACTION_SUCCEEDED:
      return {
        ...state,
        actionStatus: "SUCCESS",
      };
    case SUMBIT_ACTION_FAILED:
      return {
        ...state,
        actionStatus: "FAILED",
      };
    case SUBMIT_ACTION_NOT_ALL_SUCCESS:
      return {
        ...state,
        actionStatus: "NOT_ALL_SUCCESS",
        requestResults: action.payload,
      };
    default:
      return state;
  }
}

const DPUAdminToolState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    getActionStatus,
    clearActionStatus,
    submitAction,
    submitBulkAction,
  },
  selectors: {
    getActionStatus,
  },
  reducer: chainReducers([DPUAdminToolReducer, duck.reducer]),
};
export default DPUAdminToolState;
