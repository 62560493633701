/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { PartViewEtaTableWidget } from "./organisms/PartViewEtaTableWidget.organism";

const ShowETADate = ({ value }) => {
  const { t } = useTranslation("partview-dashboard");
  if (value.includes(">")) {
    return <div>{`${t("partview-dashboard:ETA > 6 days")}`}</div>;
  } else {
    return <div>{value}</div>;
  }
};

ShowETADate.propTypes = {
  value: PropTypes.string,
};

export const PartViewEtaWidget = ({ isLoading, data, handleEtaClick }) => {
  const { t } = useTranslation("partview-dashboard");

  let tableData = data ? data : [];
  const columns = [
    {
      Header: t("partview-dashboard:ETA"),
      accessor: "date",
      Cell: ShowETADate,
    },
    {
      Header: t("partview-dashboard:Count"),
      width: 70,
      accessor: "count",
    },
  ];

  return (
    <React.Fragment>
      <PartViewEtaTableWidget
        title={t("partview-dashboard:Packages by ETA")}
        postTitleIcon={faQuestionCircle}
        postTitleIconTooltip={t(
          "partview-dashboard:Packages by ETA represents a forecasted delivery of all packages planned to be delivered in the next week.",
        )}
        subHeaderContent={
          <div
            css={{ padding: "0.5em 1em" }}
            data-qa="select-dwell-location"
          ></div>
        }
        columns={columns}
        loaded={!isLoading}
        chartData={tableData}
        rowClickHandler={handleEtaClick}
      />
    </React.Fragment>
  );
};

PartViewEtaWidget.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  handleEtaClick: PropTypes.func,
};
