import axios from "axios";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";

import RolesState from "modules/roles/RolesState";
import UsersSearchBarState from "./UsersSearchBarState";

const STORE_MOUNT_POINT = "users";

// URLS
const USERS_URL = apiUrl("/iam/users");
const getUserDetailsUrl = (orgId, userId) => {
  return apiUrl(`/iam/organizations/${orgId}/members/${userId}`);
};

// Actions
const getScopedActionName = (name) => `${STORE_MOUNT_POINT}/${name}`;

const SET_CURRENT_USER = getScopedActionName("SET_CURRENT_USER");
const CLEAR_ACTION_STATUS = getScopedActionName("CLEAR_ACTION_STATUS");

const ADD_USER = getScopedActionName("ADD_USER");
const ADD_USER_SUCCEEDED = getScopedActionName("ADD_USER_SUCCEEDED");
const ADD_USER_FAILED = getScopedActionName("ADD_USER_FAILED");

const UPDATE_USER = getScopedActionName("UPDATE_USER");
const UPDATE_USER_SUCCEEDED = getScopedActionName("UPDATE_USER_SUCCEEDED");
const UPDATE_USER_FAILED = getScopedActionName("UPDATE_USER_FAILED");

const DELETE_USER = getScopedActionName("DELETE_USER");
const DELETE_USER_SUCCEEDED = getScopedActionName("DELETE_USER_SUCCEEDED");
const DELETE_USER_FAILED = getScopedActionName("DELETE_USER_FAILED");

const RESET_PASSWORD_USER = getScopedActionName("RESET_PASSWORD_USER");
const RESET_PASSWORD_USER_SUCCEEDED = getScopedActionName(
  "RESET_PASSWORD_USER_SUCCEEDED",
);
const RESET_PASSWORD_USER_FAILED = getScopedActionName(
  "RESET_PASSWORD_USER_FAILED",
);

const SET_PASSWORD_USER = getScopedActionName("SET_PASSWORD_USER");
const SET_PASSWORD_USER_SUCCEEDED = getScopedActionName(
  "SET_PASSWORD_USER_SUCCEEDED",
);
const SET_PASSWORD_USER_FAILED = getScopedActionName(
  "SET_PASSWORD_USER_FAILED",
);

const IMPORT_USERS = getScopedActionName("IMPORT_USERS");
const IMPORT_USERS_SUCCEEDED = getScopedActionName("IMPORT_USERS_SUCCEEDED");
const IMPORT_USERS_FAILED = getScopedActionName("IMPORT_USERS_FAILED");

const userDetailsDuck = buildFetchDuck(STORE_MOUNT_POINT, "userDetails");
const damageViewLocationsDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "damageViewLocations",
);

// Action creators
function fetchUserDetails(orgId, userId) {
  return (dispatch) => {
    const url = getUserDetailsUrl(orgId, userId);
    dispatch(userDetailsDuck.fetch(url));
  };
}

function fetchDamageViewLocations(userEmail) {
  return (dispatch) => {
    const url = apiUrl(
      `/damageview/locations/assignee?email=${encodeURIComponent(userEmail)}`,
    );
    dispatch(damageViewLocationsDuck.fetch(url));
  };
}

function clearOrganizationMemberDetails() {
  return (dispatch) => {
    dispatch(userDetailsDuck.clear());
  };
}

function setCurrentUser(user) {
  return (dispatch) => {
    return dispatch({ type: SET_CURRENT_USER, currentUser: user });
  };
}

function clearActionStatus() {
  return {
    type: CLEAR_ACTION_STATUS,
  };
}

function addUser(payload) {
  return (dispatch) => {
    dispatch({ type: ADD_USER, payload });
    const url = USERS_URL;
    return axios
      .post(url, payload)
      .then((resp) => {
        dispatch({ type: ADD_USER_SUCCEEDED });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch((error) => {
        dispatch({ type: ADD_USER_FAILED, error });
      });
  };
}

function updateUser(userId, payload) {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER, userId, payload });
    const url = USERS_URL + `/${userId}`;
    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({ type: UPDATE_USER_SUCCEEDED, userId, payload });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch((error) => {
        dispatch({ type: UPDATE_USER_FAILED, userId, error });
      });
  };
}

function deleteUser(user, organizationId) {
  return (dispatch) => {
    const userId = user.user_id;
    const payload = {
      organization_id: organizationId,
    };

    dispatch({ type: DELETE_USER, userId, payload });
    const url = USERS_URL + `/${userId}`;
    return axios
      .delete(url, { data: payload })
      .then((resp) => {
        dispatch({ type: DELETE_USER_SUCCEEDED });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch((error) => {
        dispatch({ type: DELETE_USER_FAILED, userId, error });
      });
  };
}

function blockUser(user, organizationId) {
  return (dispatch) => {
    const userId = user.user_id;
    const payload = {
      organization_id: organizationId,
    };
    dispatch({ type: UPDATE_USER, userId, payload });
    const url = USERS_URL + `/${userId}/block-user`;
    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({ type: UPDATE_USER_SUCCEEDED, userId, payload });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
      })
      .catch((error) => {
        dispatch({ type: UPDATE_USER_FAILED, userId, error });
      });
  };
}

function unblockUser(user, organizationId) {
  const userId = user.user_id;
  return (dispatch) => {
    const payload = {
      organization_id: organizationId,
    };
    dispatch({ type: UPDATE_USER, userId, payload });
    const url = USERS_URL + `/${userId}/unblock-user`;
    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({ type: UPDATE_USER_SUCCEEDED, userId, payload });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
      })
      .catch((error) => {
        dispatch({ type: UPDATE_USER_FAILED, userId, error });
      });
  };
}

function resetPassword(user) {
  const userId = user.user_id;
  return (dispatch) => {
    const payload = {
      email: user.email,
    };
    dispatch({ type: RESET_PASSWORD_USER, userId, payload });
    const url = apiUrl("/iam") + `/password-change`;
    return axios
      .post(url, payload)
      .then((resp) => {
        dispatch({ type: RESET_PASSWORD_USER_SUCCEEDED, payload });
      })
      .catch((error) => {
        dispatch({ type: RESET_PASSWORD_USER_FAILED, error });
      });
  };
}

function setUserPassword(userId, newPassword) {
  return (dispatch) => {
    const payload = { password: newPassword };
    dispatch({ type: SET_PASSWORD_USER, payload });

    const url = `${apiUrl("/iam/users")}/${userId}/change-password`;

    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({ type: SET_PASSWORD_USER_SUCCEEDED });
      })
      .catch((error) => {
        dispatch({ type: SET_PASSWORD_USER_FAILED, error });
      });
  };
}

function importUsers(payload) {
  return (dispatch) => {
    dispatch({ type: IMPORT_USERS, payload });
    const url = USERS_URL;
    return axios
      .post(url, payload)
      .then((resp) => {
        dispatch({ type: IMPORT_USERS_SUCCEEDED });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch((error) => {
        dispatch({ type: IMPORT_USERS_FAILED, error });
      });
  };
}

// Selectors

// This selector is exported to avoid a circular dependency when importing this file.
// This file imports UsersSearchBarState which imports SeachBarStateBuilder -> AuthorizationSelectors -> UsersState.
// TODO: Can we find some way to remove the export?
export function getCurrentUser(state) {
  return state.users.currentUser;
}

function getUserDetailsRequestData(state) {
  return userDetailsDuck.selectors.getData(state);
}

function getDamageViewLocations(state) {
  return damageViewLocationsDuck.selectors.getData(state)?.data ?? [];
}

function getDamageViewLocationsLoading(state) {
  return damageViewLocationsDuck.selectors.getData(state)?.isLoading ?? false;
}

const initialState = {
  actionStatus: null,
  currentUser: null,
};

function UsersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };
    case ADD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: "USER_ADDED",
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 409
            ? "Duplicate_User"
            : null,
      };
    case IMPORT_USERS_SUCCEEDED:
      return {
        ...state,
        actionStatus: "USERS_IMPORTED",
      };
    case IMPORT_USERS_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          (action.error.response.status === 400 ||
            action.error.response.status === 403 ||
            action.error.response.status === 500)
            ? action.error.response.status === 403
              ? "Permission_Error"
              : "Import_Error"
            : null,
      };
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
      };
    case SET_PASSWORD_USER:
      return {
        ...state,
        actionStatus: "SET_PASSWORD_USER",
      };
    case RESET_PASSWORD_USER:
      return {
        ...state,
        actionStatus: "RESET_PASSWORD_USER",
      };
    case SET_PASSWORD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: "USER_PASSWORD_SET",
      };
    case RESET_PASSWORD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: "USER_PASSWORD_RESET",
      };
    case RESET_PASSWORD_USER_FAILED:
      return {
        ...state,
        actionStatus:
          action.error?.response?.status === 401
            ? "Permission_Error"
            : action.error?.response?.status === 400
            ? "USER_PASSWORD_RESET_FAILED_INVALID"
            : "USER_PASSWORD_RESET_FAILED",
      };
    case SET_PASSWORD_USER_FAILED:
      return {
        ...state,
        actionStatus:
          action.error?.response?.status === 401
            ? "Permission_Error"
            : "USER_PASSWORD_SET_FAILED_INVALID",
      };
    default:
      return state;
  }
}

const UsersState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchUserDetails,
    fetchDamageViewLocations,
    clearOrganizationMemberDetails,
    setCurrentUser,
    clearActionStatus,
    addUser,
    updateUser,
    deleteUser,
    blockUser,
    unblockUser,
    resetPassword,
    setUserPassword,
    importUsers,
  },
  selectors: {
    getCurrentUser,
    getUserDetailsRequestData,
    getDamageViewLocations,
    getDamageViewLocationsLoading,
  },
  reducer: chainReducers([
    UsersReducer,
    userDetailsDuck.reducer,
    damageViewLocationsDuck.reducer,
  ]),
};

export default UsersState;
