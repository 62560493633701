import { connect } from "react-redux";
import { DealerPartViewDashboard } from "../dealer-partview-dashboard/DealerPartView.Dashboard.page";
import PartViewDashboardState from "../redux/PartViewDashboardState";
import DealerPartViewSearchBarState from "../redux/DealerPartViewSearchBarState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { OrganizationType } from "shared/constants/organization.const";

function mapStateToProps(state) {
  const {
    getExceptionCountsRequestData,
    getWatchedTableIsLoading,
    getWatchedTableResults,
    getWatchedTablePageIndex,
    getWatchedTablePageSize,
    getWatchedTablePageCount,
    getEtaPackagesCountsRequestData,
  } = PartViewDashboardState.selectors;
  const { getSelectedFvOrgId } = DealerPartViewSearchBarState.selectors;
  const auth = getAuthorization(state);
  return {
    selectedFvOrgId: getSelectedFvOrgId(state),
    exceptionOrPackageStatusCountsRequestData:
      getExceptionCountsRequestData(state),
    watchedTableIsLoading: getWatchedTableIsLoading(state),
    watchedTableResults: getWatchedTableResults(state),
    watchedTablePageIndex: getWatchedTablePageIndex(state),
    watchedTablePageSize: getWatchedTablePageSize(state),
    watchedTablePageCount: getWatchedTablePageCount(state),
    isDealerOrg: auth.hasOrganizationTypes([OrganizationType.DEALER]),
    etaPackagesCountsRequestData: getEtaPackagesCountsRequestData(state),
    includeAPU: getAuthorization(state).validateEntitySystemConfigValue([
      { key: "AVAILABLE_FOR_PICKUP", value: "true" },
    ]),
  };
}

const {
  fetchExceptionOrPackageStatusCounts,
  fetchWatchedPackages,
  setWatchedTablePageIndex,
  fetchEtaPackagesCounts,
} = PartViewDashboardState.actionCreators;
const { searchEntities, setSearchFilter, clearSearchFilters, resetSearchBar } =
  DealerPartViewSearchBarState.actionCreators;

const actionCreators = {
  fetchExceptionOrPackageStatusCounts,
  fetchWatchedPackages,
  setWatchedTablePageIndex,
  searchEntities,
  setSearchFilter,
  clearSearchFilters,
  resetSearchBar,
  fetchEtaPackagesCounts,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(DealerPartViewDashboard);
