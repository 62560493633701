/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import debouncePromise from "debounce-promise";
import { useCallback } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { asyncSearchLocations } from "modules/location-search/LocationSearchStateExtensions";
import Colors from "styles/colors";
import LocationName from "modules/location/components/LocationName";

export const AsyncLocationSelect = ({
  value,
  onChange,
  disabled,
  customStyles,
}) => {
  const asyncLoadLocationOptions = useCallback(
    debouncePromise((query, loadedOptions) => {
      // Calculate page number by checking if the currently loaded results
      // are divisble by the pageSize
      let page = 0;
      const pageSize = 10;
      if (loadedOptions.length % pageSize === 0) {
        page = loadedOptions.length / pageSize;
      }

      return asyncSearchLocations(query, true, page, pageSize);
    }, 500),
    [],
  );

  const LocationOption = ({
    label,
    innerProps,
    selectProps,
    data,
    isDisabled,
    isFocused,
    isSelected,
  }) => {
    return (
      <div
        ref={innerProps.ref}
        {...innerProps}
        className={selectProps.className}
        css={{
          fontSize: "0.8em",
          padding: "0.25rem 0.75rem",
          backgroundColor: isDisabled
            ? Colors.background.LIGHT_GRAY
            : isSelected
            ? "#EBF3FF"
            : isFocused
            ? "#DEEBFF"
            : "#fff",
          color: isDisabled
            ? "#aaa"
            : isSelected
            ? "#333"
            : isFocused
            ? "#333"
            : "#6d6d6d",
        }}
      >
        <LocationName name={label} />
      </div>
    );
  };

  LocationOption.propTypes = {
    label: PropTypes.string,
    data: PropTypes.object,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
    isFocused: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool,
  };

  return (
    <AsyncPaginate
      name="async-select-locations"
      isMulti={false}
      cacheOptions={false}
      loadOptions={(query, loadedOptions) =>
        asyncLoadLocationOptions(query, loadedOptions)
      }
      isClearable={true}
      value={value}
      onChange={onChange}
      components={{
        Option: LocationOption,
      }}
      styles={customStyles}
    />
  );
};

AsyncLocationSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
