/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { FontSize } from "components/atoms/enums";
import { ApiRequestStatus } from "../../../notificationmanagement.const";

const SuccessMessageComponent = (props) => {
  const { notificationRuleName, notificationEventType, isEditing } = props;
  const { t } = useTranslation("notification-management");

  return (
    <>
      <Icon
        src={faCheckCircle}
        size={FontSize.size84}
        color={Colors.text.GREEN}
        data-qa="notification-management-success-icon"
      />
      <Text
        css={{ marginTop: "1rem" }}
        align="center"
        size={FontSize.size16}
        block
        data-qa="notification-management-success-text"
      ></Text>
      <Trans t={t}>
        <div
          css={{ marginTop: "1rem", textAlign: "center" }}
          data-qa="notification-management-success-message"
        >
          {isEditing
            ? "You have successfully edited your"
            : "You have successfully created an"}
          <span
            css={{
              fontWeight: "bold",
              margin: "0 0.3rem",
            }}
          >
            {notificationEventType}
          </span>
          notification from FreightVerify named
          <span css={{ fontWeight: "bold", margin: "0 0.3rem" }}>
            {notificationRuleName}.
          </span>
        </div>
      </Trans>
    </>
  );
};
SuccessMessageComponent.propTypes = {
  notificationRuleName: PropTypes.string.isRequired,
  notificationEventType: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

const ErrorMessageComponent = (props) => {
  const { isEditing } = props;
  const { t } = useTranslation("notification-management");

  const errorMessage = isEditing
    ? t(
        "notification-management:We ran into an issue updating your notification rule. Please try again.",
      )
    : t(
        "notification-management:We ran into an issue creating your notification rule. Please try again.",
      );
  return (
    <>
      <Icon
        src={faTimesCircle}
        size={FontSize.size84}
        color={Colors.text.RED}
        data-qa="notification-management-error-icon"
      />
      <Text
        block
        css={{ marginTop: "1rem", textAlign: "center" }}
        size={FontSize.size16}
        data-qa="notification-management-error-text"
      >
        {errorMessage}
      </Text>
    </>
  );
};
ErrorMessageComponent.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

export const ConfirmationStep = (props) => {
  const {
    allUserInputData,
    postNewNotificationRule,
    postNewNotificationRuleStatus,
    isEditing,
  } = props;

  const notificationRuleName = allUserInputData.notificationRuleName;
  const notificationEventType =
    allUserInputData.notificationDetailsObj.notificationEventType;

  useEffect(() => {
    postNewNotificationRule(allUserInputData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      css={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        height: "17rem",
      }}
      data-qa="notification-management-confirmation-step"
    >
      {postNewNotificationRuleStatus === ApiRequestStatus.SUCCESS ? (
        <SuccessMessageComponent
          notificationRuleName={notificationRuleName}
          notificationEventType={notificationEventType}
          isEditing={isEditing}
          data-qa="notification-management-success-message-component"
        />
      ) : postNewNotificationRuleStatus === ApiRequestStatus.FAILED ? (
        <ErrorMessageComponent
          isEditing={isEditing}
          data-qa="notification-management-error-message-component"
        />
      ) : (
        <Icon
          src={faSpinner}
          spin
          size={FontSize.size84}
          data-qa="notification-management-spinner-icon"
        />
      )}
    </div>
  );
};

ConfirmationStep.propTypes = {
  allUserInputData: PropTypes.object.isRequired,
  postNewNotificationRule: PropTypes.func.isRequired,
  postNewNotificationRuleStatus: PropTypes.string,
  isEditing: PropTypes.bool,
};
