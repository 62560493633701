import _ from "lodash";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { getDescriptionFromMilestoneCode } from "./milestone.utils";

/**
 * Returns a selector for the trip legs of the specified type.
 *
 * @param {object[]} triplegs The full array of triplegs from the detail response.
 * @param {"planned" | "actual"} type The value of the `type` field on the triplegs.
 * @returns
 */
export const getFilteredTriplegsByType = (triplegs = [], type) => {
  return triplegs.filter((leg) => leg.type?.toLowerCase() === type);
};

// function will be invoked when leg has updates
const getProgressForTripLeg = (leg) => {
  // Milestone codes
  // ===============
  // DP => Departed
  // AR => Arrived,
  // FD => Final Delivery
  // RO => Reopen
  // APU => Available for pick up

  let tripLegProgress = 0;
  const update = leg.updates[leg.updates.length - 1];

  if (update.code === "DP" || update.code === "RO") {
    if (update.locationCode === leg.origin.code) {
      tripLegProgress = 50;
    } else if (update.locationCode === leg.destination.code) {
      tripLegProgress = 100;
    }
  } else if (
    (update.code === "AR" || update.code === "FD") &&
    update.locationCode === leg.destination.code
  ) {
    tripLegProgress = 100;
  }

  return tripLegProgress;
};

// useTripStopsForTripSummary is used for the detail panel under Trip Summary to show
// the MAD icon progress
// along with the updates and locations
export const usePlannedStopsForTripSummary = (triplegs = []) => {
  return useMemo(() => {
    const plannedStops = [];
    let lastProgressIndex = 0;

    triplegs.forEach((leg, index, originalTriplegs) => {
      const nextLeg = originalTriplegs[index + 1];
      let stopProgress = 0;
      let stopUpdates = [];

      // create leg stops
      if (index === 0) {
        plannedStops.push({
          name: leg?.origin?.name,
          code: leg?.origin?.code,
          address: leg?.origin?.address,
          city: leg?.origin?.city,
          state: leg?.origin?.state,
          country: leg?.origin?.country,
          lad: leg?.origin?.lad,
          scheduled_delivery_window: null,
          scheduled_pickup_window: leg?.origin?.scheduledPickupWindow,
          mode: null,
          progress: 0,
          updates: [],
        });
      }

      // if update available, set MAD icon progress as per milestone codes DP, AR, FD
      // else set MAD icon progress to 0
      if (!_.isEmpty(leg?.updates)) {
        stopProgress = getProgressForTripLeg(leg);
        stopUpdates = leg.updates.map((update) => {
          return {
            id: update?.id,
            // Received Time
            ts: update?.datetime,
            // Event Time
            eventTs: update?.eventDatetime,
            // Update
            update: getDescriptionFromMilestoneCode(
              update?.code,
              update?.locationName,
            ),
            code: update?.code,
            reasonCode: update?.reasonCode,
            locationName: update?.locationName,
            locationCode: update?.locationCode,
            latitude: update?.latitude,
            longitude: update?.longitude,
            // additional partview params
            comments: update?.comments,
            updateDescription: update?.updateDescription,
            eventType: update?.eventType,
          };
        });
      }

      plannedStops.push({
        name: leg?.destination?.name,
        code: leg?.destination?.code,
        address: leg?.destination?.address,
        city: leg?.destination?.city,
        state: leg?.destination?.state,
        country: leg?.destination?.country,
        lad: leg?.destination?.lad,
        scheduled_delivery_window: leg?.destination?.scheduledDeliveryWindow,
        scheduled_pickup_window: nextLeg?.origin?.scheduledPickupWindow,
        mode: leg?.mode,
        progress: stopProgress,
        updates: stopUpdates,
      });

      // required in order to update previous stop progress to 100.
      const stopUpdate = !_.isEmpty(stopUpdates)
        ? stopUpdates[stopUpdates.length - 1]
        : null;

      if (stopUpdate?.code === "APU" || stopProgress > 0) {
        lastProgressIndex = plannedStops.length - 1;
      }
    });

    if (!_.isEmpty(plannedStops)) {
      const lastStopIndex = plannedStops.length - 1;
      const lastLegUpdates = plannedStops[lastStopIndex]?.updates ?? [];

      // in last leg if milestone code FD exist in updates, keep the MAD icon to 100
      if (!_.isEmpty(lastLegUpdates)) {
        let isFinalDelivery = lastLegUpdates.some(
          (update) => update.code === "FD",
        );

        if (isFinalDelivery) {
          plannedStops[lastStopIndex].progress = 100;
          lastProgressIndex = lastStopIndex;
        }
      }

      // Update previous stop progress to 100.
      for (let index = 0; index < plannedStops.length; index++) {
        if (index < lastProgressIndex) {
          plannedStops[index].progress = 100;
        } else {
          break;
        }
      }
    }

    return plannedStops;
  }, [triplegs]);
};

// CAT-4595 ticket will start sending location id and geofence details for newly created packages
// Having fallback code for existing packages in order to satisfy RoutingMap.js component requirements
// fallback code doesn't display geofence but details page will not throw any error
// in order to display geofence, RoutingMap.js requires geofence.type or geometry.type and properties.buffer values
// this fallback code to be removed once backfill task is picked up / completed for existing packages
const getLocationDetails = (location) => {
  return location?.id && location?.geofence
    ? location
    : {
        ...location,
        geofence: {
          geometry: {
            coordinates: [location?.longitude, location?.latitude],
          },
          properties: {
            center: {
              latitude: location?.latitude,
              longitude: location?.longitude,
            },
          },
        },
      };
};

const createShipmentForMap = (tripleg, isActual = true) => {
  if (!tripleg || !tripleg?.origin || !tripleg?.destination) {
    return null;
  }

  const getModeID = (modeName) => {
    const mode = modeName?.toLowerCase();
    if (mode === "truck") {
      return 1;
    } else if (mode === "rail") {
      return 2;
    } else if (mode === "ltl") {
      return 3;
    } else if (mode === "intermodal") {
      return 4;
    } else if (mode === "ocean") {
      return 5;
    } else if (mode === "multimodal") {
      return 6;
    } else if (mode === "air") {
      return 7;
    } else if (mode === "parcel") {
      return 8;
    }
  };

  const updates =
    tripleg.updates?.map((update) => {
      return { ...update, time: update.eventDatetime };
    }) ?? [];

  const currentLocationCoord = {
    latitude: tripleg.origin?.latitude,
    longitude: tripleg.origin?.longitude,
  };

  if (updates.length > 0) {
    const lastUpdate = updates[updates.length - 1];
    currentLocationCoord.latitude = lastUpdate.latitude;
    currentLocationCoord.longitude = lastUpdate.longitude;
  }

  // To closer match the status from shipments
  let status;
  // Not sure what this code is needed for. When removed the mode icon gets rendered at correct locations
  if (updates.find(({ code }) => code === "FD" || code === "AR")) {
    status = "arrived";
  } else if (updates.find(({ code }) => code === "DP")) {
    status = "active";
  }

  return {
    id: uuidv4(),
    creator_shipment_id: isActual
      ? tripleg?.creatorShipmentId
      : tripleg?.ShipmentNumber,
    active_status_ng: status,
    shipment_stops: [
      { location: getLocationDetails(tripleg.origin) },
      { location: getLocationDetails(tripleg.destination) },
    ],
    has_event_refs: false,
    mode_name: tripleg?.mode,
    mode_id: getModeID(tripleg?.mode),
    current_location: {
      ...currentLocationCoord,
      updates: _.sortBy(updates, "time"), // sorting the updates as per the time key to show the MAD icon on map for the latest coordinates
    },
    isPartViewShipment: true,
  };
};

export const transformActualTripleg = (tripleg) => {
  return createShipmentForMap(tripleg);
};

// useTransformPlannedTripleg is used to display
// MAD and LAD
// and routes between locations from the planned tripleg on MAP
export const useTransformPlannedTripleg = (triplegs = []) => {
  return useMemo(
    () =>
      triplegs.map((tripleg) => {
        return createShipmentForMap(tripleg, false);
      }),
    [triplegs],
  );
};
