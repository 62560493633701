import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import {
  buildOriginAsyncFilterOptionsState,
  buildDestinationAsyncFilterOptionsState,
} from "modules/location/utils/buildLocationAsyncFilterOptionsState";
import {
  BILL_OF_LADING_NUMBERS_URL,
  TRAILER_EQUIPMENT_NUMBERS_URL,
  STCC_CODE_URL,
  SERVICE_CODE_URL,
  PRO_NUMBER_FILTER_URL,
  ROUTE_NUMBER_FILTER_URL,
  ORIGIN_COUNTRY_FILTER_URL,
  DESTINATION_COUNTRY_FILTER_URL,
  SAW_DATA_SOURCE_FILTER_URL,
} from "modules/domain-data/ShipmentsDomainDataState";

export const originOptionsState = buildOriginAsyncFilterOptionsState({
  topic: "originShipmentFilter",
  additionalParams: {
    origin_location: true,
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const destinationOptionsState = buildDestinationAsyncFilterOptionsState({
  topic: "destinationShipmentFilter",
  additionalParams: {
    destination_location: true,
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const billOfLadingNumberOptionsState = buildAsyncFilterOptionsState({
  topic: "billOfLadingFilter",
  url: BILL_OF_LADING_NUMBERS_URL,
  pageSize: 20,
  getResponseData: (data) => data?.bill_of_lading_number ?? [],
  transformResult: (bol) => ({ label: bol, value: bol }),
});

export const trailerAndEquipmentNumberOptionsState =
  buildAsyncFilterOptionsState({
    topic: "trailerAndEquipmentNumbersFilter",
    url: TRAILER_EQUIPMENT_NUMBERS_URL,
    pageSize: 20,
    getResponseData: (data) => data?.trailer_equipment_number ?? [],
    transformResult: (number) => ({ label: number, value: number }),
  });

export const stccCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "stccCodesFilter",
  url: STCC_CODE_URL,
  pageSize: 20,
  getResponseData: (data) => data?.stcc_code ?? [],
  transformResult: (code) => ({ label: code, value: code }),
});

export const serviceCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "serviceCodesFilter",
  url: SERVICE_CODE_URL,
  pageSize: 20,
  getResponseData: (data) => data,
  transformResult: (code) => ({ label: code, value: code }),
});

export const proNumberOptionsState = buildAsyncFilterOptionsState({
  topic: "proNumberFilter",
  url: PRO_NUMBER_FILTER_URL,
  pageSize: 20,
  getResponseData: (data) => data?.pro_number ?? [],
  transformResult: (code) => ({ label: code, value: code }),
});

export const routeNumberOptionsState = buildAsyncFilterOptionsState({
  topic: "routeNumberFilter",
  url: ROUTE_NUMBER_FILTER_URL,
  pageSize: 20,
  getResponseData: (data) => data?.route_number ?? [],
  transformResult: (code) => ({ label: code, value: code }),
});

export const originCountryOptionsState = buildAsyncFilterOptionsState({
  topic: "originCountryShipmentFilter",
  url: ORIGIN_COUNTRY_FILTER_URL,
  getResponseData: (data) => data?.origin_country ?? [],
  transformResult: (country) => ({ label: country.name, value: country.code }),
});

export const destinationCountryOptionsState = buildAsyncFilterOptionsState({
  topic: "destinationCountryShipmentFilter",
  url: DESTINATION_COUNTRY_FILTER_URL,
  getResponseData: (data) => data?.destination_country ?? [],
  transformResult: (country) => ({ label: country.name, value: country.code }),
});

export const shipmentCreationSourceOptionsState = buildAsyncFilterOptionsState({
  topic: "shipmentCreationSourceFilter",
  url: SAW_DATA_SOURCE_FILTER_URL,
  getResponseData: (data) => data?.saw_data_source ?? [],
  transformResult: (source) => ({ label: source, value: source }),
});
