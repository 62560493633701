import { connect } from "react-redux";
import NotificationManagementWizardState from "pages/administration/notification-management/redux/NotificationManagementWizardState";
import { ConfirmationStep } from "./ConfirmationStep.organism";

function mapStateToProps(state) {
  const { getAllUserInputData, getPostNewNotificationRuleStatus } =
    NotificationManagementWizardState.selectors;
  return {
    allUserInputData: getAllUserInputData(state),
    postNewNotificationRuleStatus: getPostNewNotificationRuleStatus(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  const { postNewNotificationRule } =
    NotificationManagementWizardState.actionCreators;

  return {
    postNewNotificationRule: (userInputData) => {
      dispatch(postNewNotificationRule(userInputData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationStep);
