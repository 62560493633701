/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import { Search } from "components/templates/Search.template";
import SearchBarContainer from "../components/search/PartView.SearchBar.container";
import FiltersContainer from "../components/search/PartView.SearchFilters.container";
import PartViewEditSavedSearchModalContainer from "../components/search/PartView.EditSavedSearchModal.container";
import { useColumns, WATCH_COLUMN_ID } from "./PartView.Search.columns";

export const PartViewSearch = (props) => {
  const {
    savedSearch,
    loadSavedSearch,
    toggleShowFilters,
    pushPartViewDetailsPage,
    partViewSearchResults,
    isLoading,
    showFilters,
    sortColumn,
    reverseSort,
    page,
    pageSize,
    totalPages,
    setPagination,
    setSort,
    resetSearch,
    totalEntities,
    totalEntitiesIsLoading,
    exportEntities,
    isExporting,
    exportIdentifier,
    exportName,
    exportFailed,
    clearExportErrors,
    resetExport,
  } = props;
  const { t } = useTranslation("partview-search");

  useSetTitleOnMount(t("partview-search:Search Results"));

  useTrackWithMixpanelOnce("Viewed Page: PartView / Search");

  const rowClickHandler = (row, cell) => {
    // Prevent navigation if clicking in "watch" checkbox cell.
    if (cell.column.id === WATCH_COLUMN_ID) {
      return;
    }

    // Navigate to Package Details when clicking row
    pushPartViewDetailsPage(row.original.TrackingNumber);
  };

  const columns = useColumns({ isPartSeller: false });

  return (
    <Search
      isLoading={isLoading}
      searchResults={partViewSearchResults}
      totalCount={totalEntities}
      totalCountIsLoading={totalEntitiesIsLoading}
      totalCountTitle={t("partview-search:Total Packages")}
      savedSearch={savedSearch}
      loadSavedSearch={loadSavedSearch}
      SavedSearchModalContainer={PartViewEditSavedSearchModalContainer}
      resetSearch={resetSearch}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      exportProps={{
        exportEntities: exportEntities,
        exportIdentifier: exportIdentifier,
        exportName: exportName,
        isExporting: isExporting,
        exportFailed: exportFailed,
        clearExportErrors: clearExportErrors,
        resetExport: resetExport,
      }}
      tableProps={{
        data: partViewSearchResults,
        columns: columns,
        isLoading: isLoading,
        rowClickHandler: rowClickHandler,
        showPagination: true,
        fixPaginationToBottom: true,
        isManualPagination: true,
        isManualSorting: true,
        sortColumn: sortColumn,
        reverseSort: reverseSort,
        pageIndex: page,
        pageSize: pageSize,
        pageCount: totalPages,
        onPageChange: (newPage) => {
          setPagination(newPage, pageSize);
        },
        onPageSizeChange: (newPageSize) => {
          setPagination(0, newPageSize);
        },
        onPageSort: (column, isReverse) => {
          setSort(column, isReverse);
        },
      }}
    />
  );
};

PartViewSearch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadSavedSearch: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pushPartViewDetailsPage: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  exportEntities: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  totalEntities: PropTypes.number.isRequired,
  totalEntitiesIsLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  partViewSearchResults: PropTypes.array.isRequired,
  isExporting: PropTypes.bool.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func,
  resetExport: PropTypes.func,
};
