/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DomainDataState from "../../domain-data/DomainDataState";

export const CountryName = ({ countryCode, style, "data-qa": dataQa }) => {
  const { t } = useTranslation("domain");
  const countries = useSelector(DomainDataState.selectors.getCountries);
  const countryLookup = _.find(countries, { code: countryCode });
  const countryName = countryLookup ? countryLookup.name : countryCode;

  return (
    <span style={{ ...style }} data-qa={dataQa}>
      {t(countryName)}
    </span>
  );
};

CountryName.propTypes = {
  countryCode: PropTypes.string,
  style: PropTypes.object,
  "data-qa": PropTypes.string,
};
