/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import Colors from "../../../styles/colors";
import NotificationsState from "../NotificationsState";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Separator } from "components/atoms/Separator.atom";
import { Button } from "components/atoms/Button.atom";

const { postHasReadNotification } = NotificationsState.actionCreators;

export const Notification = ({
  id,
  icon,
  iconColor = Colors.notifications.PRIMARY_COLOR,
  notificationAction,
  footer,
  title,
  hasRead,
  hasErrorStatus,
  timestamp,
  actionIcon,
  actionIconColor = Colors.notifications.ACTION_BLUE,
  actionToolTip,
  isLoading = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("header-bar");

  const handleAction = (id) => {
    handleRead(id);
    dispatch(notificationAction);
  };

  const handleRead = (notification_id) => {
    dispatch(postHasReadNotification({ id: notification_id }));
  };

  const iconClass = classNames("notification-icon", "fa-1x");
  const zone = moment.tz.guess();
  const timezone = moment().tz(zone);

  const css = {
    backgroundColor: Colors.notifications.BACKGROUND,
    borderColor: Colors.notifications.BORDER,
    marginBottom: "0.5em",
    padding: "0.1em 0px",
    marginRight: 10,
    borderRightWidth: 1,
    borderLeftStyle: "solid",
    borderLeftWidth: 20,
    borderLeftColor: hasErrorStatus
      ? Colors.notifications.ERROR
      : !hasRead
      ? Colors.notifications.ACTION_BLUE
      : Colors.notifications.BORDER_LEFT,
    display: "flex",
    flexDirection: "row",
    position: "relative",
  };

  return (
    <div css={css}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 8,
          alignSelf: "baseline",
          width: 50,
        }}
      >
        <Icon
          src={icon}
          className={iconClass}
          color={iconColor}
          spin={isLoading}
          size={FontSize.size16}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignContent: "center",
        }}
      >
        <Text
          size={FontSize.size16}
          color={Colors.notifications.PRIMARY_COLOR}
          style={{
            margin: 0,
            marginTop: 8,
            lineHeight: 1,
          }}
        >
          {title}
        </Text>

        {timestamp ? (
          <Text
            size={FontSize.size12}
            color={Colors.notifications.SECONDARY_COLOR}
            style={{ marginTop: 2 }}
          >
            {moment.utc(timestamp).local().format("MMM DD, YYYY HH:mm") +
              " " +
              timezone.format("z")}
          </Text>
        ) : null}

        <Text
          size={FontSize.size14}
          color={Colors.notifications.PRIMARY_COLOR}
          style={{
            marginTop: 3,
            marginBottom: 8,
            lineHeight: 1.3,
          }}
        >
          {footer}
        </Text>
      </div>

      {!hasRead || actionIcon ? (
        <Separator
          color={Colors.notifications.SEPARATOR}
          style={{
            flex: 0.02,
            marginTop: 8,
            marginBottom: 8,
            marginRight: 2,
          }}
        />
      ) : null}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 7,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {!hasRead ? (
            <Tooltip
              placement="top"
              tooltipChildren={<Text>{t("Mark as read")}</Text>}
              style={{
                flex: 1,
                lineHeight: 1,
                marginTop: 8,
              }}
            >
              <Text
                size={FontSize.size12}
                color={Colors.text.WHITE}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "-22px",
                  transform: "translateY(-50%) rotate(-90deg)",
                }}
              >
                {t("NEW")}
              </Text>
              <Button
                variant="link"
                onClick={() => handleRead(id)}
                css={{
                  color: Colors.notifications.PRIMARY_COLOR,
                  lineHeight: 1,
                  padding: 0,
                }}
              >
                <Icon src={faCheckCircle} size={FontSize.size14} />
              </Button>
            </Tooltip>
          ) : null}

          {actionIcon ? (
            <Tooltip
              placement="top"
              tooltipChildren={actionToolTip ? actionToolTip : null}
              style={{
                flex: 1,
                lineHeight: 1,
                marginBottom: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="link"
                onClick={() => handleAction(id)}
                css={{
                  color: actionIconColor,
                  lineHeight: 1,
                  padding: 0,
                }}
              >
                <Icon src={actionIcon} size={FontSize.size14} />
              </Button>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  icon: PropTypes.any.isRequired,
  iconColor: PropTypes.string,
  notificationAction: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  footer: PropTypes.any,
  title: PropTypes.string.isRequired,
  hasRead: PropTypes.bool,
  hasErrorStatus: PropTypes.bool,
  showClose: PropTypes.bool,
  timestamp: PropTypes.string,
  actionIcon: PropTypes.object,
  actionIconColor: PropTypes.string,
  actionToolTip: PropTypes.string,
  isLoading: PropTypes.bool,
};
