/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import PropTypes from "prop-types";
import Colors from "styles/colors";
import { FontSize } from "components/atoms/enums";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { faAsterisk } from "@fortawesome/pro-solid-svg-icons";
import { FilterCriteriaSelector } from "../FilterCriteriaSelector.organism";
import {
  FilterTypesEnum,
  filterTypeOptions,
} from "pages/administration/notification-management/notificationmanagement.const";
import { useNotificationManagementTranslations } from "pages/administration/notification-management/hooks/useNotificationManagementTranslations";
import { InteractiveList } from "../../molecules/InteractiveList.molecule";

export const milesOutInputValid = (milesOut) => {
  return (!_.isNil(milesOut) && validInteger(milesOut)) || milesOut === "";
};
const isDigit = (char) => {
  const zero_ascii = 48;
  const nine_ascii = zero_ascii + 9;

  const charCode = char.charCodeAt(0);
  return charCode >= zero_ascii && charCode <= nine_ascii;
};

const validInteger = (value) => {
  const intValue = parseInt(value);
  return (
    !isNaN(intValue) &&
    intValue > 0 &&
    intValue <= 9999 &&
    intValue === parseFloat(value) &&
    Array.from(value).every(isDigit)
  );
};

const isFilterOptionsValid = (filterType, filterOptions) => {
  if (filterType === FilterTypesEnum.MILES_OUT) {
    return milesOutInputValid(filterOptions[0]);
  }
  return true;
};

export const FilterCriteriaStep = (props) => {
  const {
    filterCriteria,
    setFilterCriteria,
    setIsNextButtonDisabled,
    eventType,
  } = props;
  const { t } = useTranslation("notification-management");
  const { translateFilterType } = useNotificationManagementTranslations();
  const { handleStep } = useWizard();
  const defaultEmptyFilterCriteria = {
    filterType: null,
    filterOptions: [],
    allowMultiSelect: null,
    filterOptionsValid: true,
  };

  const [filterCriteriaLocal, setFilterCriteriaLocal] = useState(
    filterCriteria ? filterCriteria : [_.cloneDeep(defaultEmptyFilterCriteria)],
  );

  // We have this useEffect here because we want the next button to always be
  // enabeld for this step
  useEffect(() => {
    setIsNextButtonDisabled(false);
  }, [setIsNextButtonDisabled]);

  handleStep(() => {
    setFilterCriteria(filterCriteriaLocal);
  });

  useEffect(() => {
    const filterOptionsValid = (currentValue) => {
      return _.isNil(currentValue.filterOptionsValid)
        ? true
        : currentValue.filterOptionsValid;
    };
    const allFiltersOptionsValid = filterCriteriaLocal.reduce(
      (accumulator, currentValue) =>
        accumulator && filterOptionsValid(currentValue),
      true,
    );
    setIsNextButtonDisabled(!allFiltersOptionsValid);
  }, [filterCriteriaLocal, setIsNextButtonDisabled]);

  const addFilterCriteriaHandler = () =>
    setFilterCriteriaLocal((prev) => [
      ...prev,
      _.cloneDeep(defaultEmptyFilterCriteria),
    ]);

  const removeFilterCriteriaHandler = (criterionToRemoveIdx) => {
    filterCriteriaLocal.splice(criterionToRemoveIdx, 1);
    setFilterCriteriaLocal([...filterCriteriaLocal]);
  };

  const filterCriteriaSelectHandler = (idx) => {
    return (selectedFilter, selectedFilterOptions) => {
      setFilterCriteriaLocal((prev) => {
        const newArr = [...prev];
        const updatedValues = {
          filterType: newArr[idx].filterType,
          filterOptions: newArr[idx].filterOptions,
          allowMultiSelect: newArr[idx].allowMultiSelect,
        };
        if (selectedFilter) {
          updatedValues.filterType = selectedFilter.value;
          updatedValues.filterOptions = [];
          updatedValues.allowMultiSelect = selectedFilter.allowMultiSelect;
          updatedValues.filterOptionsValid = true;
        }
        if (selectedFilterOptions) {
          updatedValues.filterOptions = _.isArray(selectedFilterOptions)
            ? [...selectedFilterOptions]
            : [selectedFilterOptions];
          updatedValues.filterOptionsValid = isFilterOptionsValid(
            newArr[idx].filterType,
            selectedFilterOptions,
          );
        }
        newArr[idx] = updatedValues;
        return newArr;
      });
    };
  };

  const outdatedOptions = filterCriteriaLocal
    .map((filterCriterion) => {
      return {
        filterType: filterCriterion.filterType,
        filterOptions: filterCriterion.filterOptions.filter(
          (filterOption) => filterOption.outdated,
        ),
      };
    })
    .filter((filterCriterion) => filterCriterion.filterOptions?.length !== 0);

  return (
    <>
      <Text bold block>
        {t("notification-management:Filter Criteria")}
      </Text>
      <Text block css={{ marginTop: "0.5rem" }}>
        {t("notification-management:What criteria should trigger this event?")}
      </Text>
      <Text block css={{ marginTop: "0.2rem" }}>
        {t(
          "notification-management:Select the criteria that would trigger this event",
        )}
      </Text>
      {outdatedOptions.map((outdatedFilterOption, idx) => (
        <Text
          block
          css={{ marginTop: "0.2rem", color: Colors.text.RED }}
          key={idx}
          data-qa="outdated-filter-option"
        >
          {t(
            `notification-management:[[[filterType]]] with [[[identifier]]] [[[filterValues]]] no longer exists in our system`,
            {
              filterType: translateFilterType(outdatedFilterOption.filterType),
              filterValues: outdatedFilterOption.filterOptions
                .map((filterOption) => filterOption.value)
                .join(", "),
              identifier:
                outdatedFilterOption.filterType === FilterTypesEnum.CARRIER
                  ? "FvId"
                  : t(`notification-management:code`),
            },
          )}
        </Text>
      ))}
      <InteractiveList
        addButtonText={t("notification-management:Add filter")}
        addButtonHandler={addFilterCriteriaHandler}
        removeButtonHandler={removeFilterCriteriaHandler}
        maxItems={filterTypeOptions.length}
      >
        {filterCriteriaLocal.map((criterion, idx) => {
          const filterKeys = filterCriteriaLocal.map(
            (filterCriterion) => filterCriterion?.filterType,
          );
          const availableFilters = filterTypeOptions.filter(
            (filterType) => !filterKeys?.includes(filterType.value),
          );
          return (
            <div
              // When user add empty select use idx so we don't get duplicate keys
              key={criterion?.filterType ? criterion?.filterType : idx}
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              data-qa="filter-criteria"
            >
              <FilterCriteriaSelector
                availableFilters={availableFilters}
                filterCriterion={criterion}
                filterCriteriaSelectHandler={filterCriteriaSelectHandler(idx)}
                eventType={eventType}
              />
            </div>
          );
        })}
      </InteractiveList>
      <div>
        {filterCriteriaLocal.map((criterion) => {
          switch (criterion.filterType) {
            case FilterTypesEnum.PARTS_NUMBER:
              return (
                <div
                  css={{
                    marginLeft: "1em",
                  }}
                  key={`partsNumberNotes`}
                  data-qa="parts-number-notes"
                >
                  <Icon
                    css={{
                      verticalAlign: "super",
                      position: "relative",
                      top: "0.2rem",
                    }}
                    src={faAsterisk}
                    size={0.5}
                    color={Colors.text.RED}
                  />
                  <Text size={FontSize.size12} color={Colors.text.RED}>
                    {t(
                      "notification-management:Part number currently only supports inbound parts",
                    )}
                  </Text>
                </div>
              );
            case FilterTypesEnum.MILES_OUT:
              return (
                <div
                  css={{
                    marginLeft: "1em",
                    marginRight: "1em",
                  }}
                  key={`milesOutNotes`}
                  data-qa="miles-out-notes"
                >
                  <Icon
                    css={{
                      verticalAlign: "super",
                      position: "relative",
                      top: "0.2rem",
                    }}
                    src={faAsterisk}
                    size={0.5}
                    color={Colors.text.BLACK}
                  />
                  <Text size={FontSize.size12} color={Colors.text.BLACK}>
                    {t(
                      "notification-management:The notification is sent when the distance remaining is less than or equal to the miles you entered.",
                    )}
                  </Text>
                  <Text
                    css={{ paddingLeft: "0.3em" }}
                    size={FontSize.size12}
                    color={
                      criterion.filterOptionsValid
                        ? Colors.text.BLACK
                        : Colors.text.RED
                    }
                  >
                    {t(
                      "notification-management:Only numeric values from 1 to 9999 are allowed.",
                    )}
                  </Text>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </>
  );
};

FilterCriteriaStep.propTypes = {
  filterCriteria: PropTypes.array,
  setFilterCriteria: PropTypes.func,
  setIsNextButtonDisabled: PropTypes.func,
  eventType: PropTypes.string,
};
