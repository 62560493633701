/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Colors from "styles/colors";
import { FlexColDiv } from "styles/container-elements";

export const UnassignAssetModal = ({
  show,
  hide,
  assetId,
  unassignAsset,
  organization,
  shipment,
}) => {
  const { t } = useTranslation("shipment-details");
  return (
    <Modal show={show} style={{ ":width": "300px" }} onHide={() => hide()}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
      >
        <Modal.Title style={{ fontWeight: "300" }}>
          {t("Unassign Asset")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <FlexColDiv
          css={{
            padding: "1em",
          }}
        >
          {t("Are you sure you wish to unassign asset ID [[[assetId]]]?", {
            assetId,
          })}
        </FlexColDiv>
      </Modal.Body>

      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Button
          style={{
            backgroundColor: "white",
            color: Colors.background.DARK_BLUE,
            marginRight: "0.5em",
          }}
          onClick={() => hide()}
        >
          {t("Cancel")}
        </Button>
        <Button
          style={{
            backgroundColor: Colors.highlight.GREEN,
            color: "white",
            fontWeight: "300",
          }}
          onClick={() => {
            unassignAsset(shipment, organization, assetId);
            hide();
          }}
        >
          {t("Submit")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UnassignAssetModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  assetId: PropTypes.string,
  unassignAsset: PropTypes.func,
  organization: PropTypes.object,
  shipment: PropTypes.object,
};
