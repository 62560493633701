/** @jsxImportSource @emotion/react */
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Button } from "components/atoms/Button.atom";
import Colors from "styles/colors";

export const DeleteSavedSearchModal = ({ show, hide, handleConfirm }) => {
  const { t } = useTranslation("components");

  return (
    <Modal show={show} onHide={() => hide()}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
      >
        <Modal.Title css={{ fontWeight: "300" }}>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body css={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        {t("components:Are you sure you want to delete this saved search?")}
      </Modal.Body>
      <Modal.Footer css={{ backgroundColor: "#e2e2e2" }}>
        <Button
          variant="outline-secondary"
          css={{ marginRight: "0.5em" }}
          onClick={() => hide()}
        >
          {t("components:Cancel")}
        </Button>
        <Button variant="danger" onClick={() => handleConfirm()}>
          {t("components:Delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteSavedSearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};
