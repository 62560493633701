import _ from "lodash";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";

const STORE_MOUNT_POINT = "damageViewDashboard";

// Fetch ducks
const submissionsDuck = buildFetchDuck("submissions");

//urls
const APPLICATION_BASE_URL = apiUrl("/damageview/submission");

function fetchSubmissionCountsData() {
  return async (dispatch) => {
    const url = APPLICATION_BASE_URL + "?searchVersion=allSubmissionsCount";
    dispatch(submissionsDuck.clear());
    dispatch(submissionsDuck.fetch(url));
  };
}

// Selectors

const getSubmissionLoading = (state) =>
  state[STORE_MOUNT_POINT]?.isLoading ?? false;

const getSubmissionCountsData = (state) =>
  state[STORE_MOUNT_POINT]?.data?.data ?? [];

const DamageViewDashboardState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchSubmissionCountsData,
  },
  selectors: {
    getSubmissionCountsData,
    getSubmissionLoading,
  },
  reducer: submissionsDuck.reducer,
};
export default DamageViewDashboardState;
