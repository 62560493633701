/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";

import { getColumns } from "pages/shipments/search/Shipments.Search.columns";
import { SearchResultsTable } from "pages/shipments/search/Shipments.Search.page";
import Colors from "styles/colors";

export const ShipmentResultsModal = ({
  show,
  hide,
  title,
  chosenStatus,
  selectedFields,
  searchEntities,
  page,
  pageSize,
  totalPages,
  sortColumn,
  reverseSort,
  shipments,
  isLoading,
  setPagination,
  setSort,
  pushShipmentDetailView,
}) => {
  const { t } = useTranslation("shipment-status");
  const columns = getColumns({
    onActionFinished: () => searchEntities(null, false, true),
    t,
    isForResultModal: true,
  });

  return (
    <Modal
      show={show}
      onHide={() => hide()}
      size="xl"
      dialogClassName="shipment-results-modal"
      css={{ ".shipment-results-modal": { maxWidth: 1200 } }}
    >
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 1rem",
        }}
      >
        <Modal.Title style={{ fontWeight: "300" }}>
          {title}
          {chosenStatus ? `: ${chosenStatus}` : null}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ background: Colors.background.LIGHT_GRAY }}>
        {selectedFields}

        <SearchResultsTable
          totalPages={totalPages}
          page={page}
          pageSize={pageSize}
          sortColumn={sortColumn}
          reverseSort={reverseSort}
          shipments={shipments}
          columns={columns}
          isLoading={isLoading}
          setPagination={setPagination}
          setSort={setSort}
          pushShipmentDetailView={pushShipmentDetailView}
        />
      </Modal.Body>

      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Button
          style={{
            backgroundColor: "white",
            color: Colors.background.DARK_BLUE,
            marginRight: "0.5em",
          }}
          onClick={() => hide()}
        >
          {t("shipment-status:Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ShipmentResultsModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  title: PropTypes.string,
  chosenStatus: PropTypes.string,
  selectedFields: PropTypes.any,
  searchEntities: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  isLoading: PropTypes.bool,
  shipments: PropTypes.array,
  setPagination: PropTypes.func,
  setSort: PropTypes.func,
  pushShipmentDetailView: PropTypes.func,
};
