/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useEffect, Fragment } from "react";

import FormRow from "../../components-old/forms/FormRow";

import LocationShipper from "../location/components/LocationShipper";
import StandardInput from "../../components-old/forms/inputs/StandardInput";
import SelectInput from "../../components-old/forms/inputs/SelectInput";

const LocationEditFormDPU = ({
  data,
  pickupLocation,
  updateField,
  isReadOnly,
  countries,
  subdivisions,
  isCarrierOrg,
  fetchDdaSubdivisions,
}) => {
  useEffect(() => {
    if (!_.isNil(pickupLocation.country)) {
      fetchDdaSubdivisions(pickupLocation.country);
    }
  }, [fetchDdaSubdivisions, pickupLocation.country]);

  const { t } = useTranslation("location-edit");

  const countryOptions = countries
    ? countries.map((c) => {
        return { value: c.code, label: c.name };
      })
    : [];

  const subdivisionOptions = subdivisions
    ? subdivisions.map((s) => {
        // H1-1020: Use full subdivision name as value if code is numeric
        return { value: isNaN(s.code) ? s.code : s.name, label: s.name };
      })
    : [];

  const subdivisionLabel =
    subdivisions && subdivisions.length > 0 ? subdivisions[0].type : "";

  const ddaForm = () => (
    <div
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: "1em",
        backgroundColor: "white",
      }}
    >
      {isReadOnly ? (
        <div css={{ display: "flex", alignItems: "center" }}>
          {isCarrierOrg && (
            <div css={{ fontSize: "1.25em" }}>
              <LocationShipper shipperId={data.customer_id} />
            </div>
          )}
        </div>
      ) : null}
      <FormRow>
        <StandardInput
          label={t("location-edit:Address 1")}
          value={pickupLocation.address}
          onChange={(value) => updateField("address", value)}
          isReadOnly={isReadOnly}
        />
        <StandardInput
          label={t("location-edit:Address 2")}
          value={pickupLocation.address2}
          onChange={(value) => updateField("address2", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow>
        <StandardInput
          label={t("location-edit:City")}
          value={pickupLocation.city}
          onChange={(value) => updateField("city", value)}
          isReadOnly={isReadOnly}
        />
        <SelectInput
          label={subdivisionLabel}
          options={subdivisionOptions}
          value={pickupLocation.state}
          onChange={(value) => updateField("state", value)}
          isReadOnly={isReadOnly}
          minWidth="12em"
        />
        <StandardInput
          label={t("location-edit:Postal Code")}
          value={pickupLocation.postalCode}
          onChange={(value) => updateField("postalCode", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow divider={true}>
        <SelectInput
          label={t("location-edit:Country")}
          options={countryOptions}
          value={pickupLocation.country}
          onChange={(value) => {
            fetchDdaSubdivisions(value);
            updateField("country", value);
          }}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow>
        <StandardInput
          label={t("location-edit:Special Instructions")}
          value={pickupLocation.specialInstructions}
          onChange={(value) => updateField("specialInstructions", value)}
          isReadOnly={isReadOnly}
          asTextArea={true}
        />
      </FormRow>
    </div>
  );

  return <Fragment>{pickupLocation ? ddaForm() : null}</Fragment>;
};

LocationEditFormDPU.propTypes = {
  countries: PropTypes.any,
  data: PropTypes.any,
  isCarrierOrg: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  subdivisions: PropTypes.any,
  updateField: PropTypes.any,
  updateGeofence: PropTypes.any,
};

export default LocationEditFormDPU;
