/** @jsxImportSource @emotion/react */
import React, { useReducer, useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Modal,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
  Button,
  FormCheck,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TimePicker from "rc-time-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormButton,
} from "components-old/modal-elems";
import SelectField from "components-old/forms/fields/SelectField";
import { getCurrentDateForInput } from "../../../../utils/date-time";
import Colors from "../../../../styles/colors";
import { MediaQueries } from "../../../../components/responsive";

const formatEmail = (emails) => {
  return emails.split(";").map((singleEmail) => singleEmail.trim());
};

const formDataReducer = (state, action) => {
  switch (action.type) {
    case "NAME":
      return { ...state, name: action.payload };

    case "EMAIL":
      return { ...state, email: action.payload };

    case "BODY":
      return { ...state, body: action.payload };

    case "PATTERN":
      return { ...state, pattern: action.payload };

    case "REPEAT":
      if (
        state.pattern === "yearly_day" ||
        state.pattern === "yearly_weekday"
      ) {
        return { ...state, repeatMonth: action.payload };
      }
      return { ...state, repeat: action.payload };

    case "ON_DAY":
      return { ...state, onDay: action.payload };

    case "DATE_FROM":
      // We shouldn't be able to submit a schedule with a dateTo that is before the dateFrom.
      // - If the new "dateFrom" is after the already selected "dateTo",
      //     clear out the "dateTo" and have the user re-enter it.
      if (moment(action.payload).isAfter(state.dateTo)) {
        return { ...state, dateFrom: action.payload, dateTo: "" };
      }

      return { ...state, dateFrom: action.payload };

    case "DATE_TO":
      return { ...state, dateTo: action.payload };

    case "WEEK_NUM":
      return { ...state, weekNum: action.payload };

    case "TIME": {
      let updatedTimes = [...state.time];
      updatedTimes[action.payload.index] = action.payload.time;
      return { ...state, time: updatedTimes };
    }
    case "ADD_TIME":
      return { ...state, time: [...state.time, "00:00"] };

    case "REMOVE_TIME": {
      let updatedTimes = [...state.time];
      updatedTimes.splice(action.payload.index, 1);
      return { ...state, time: updatedTimes };
    }
    case "ADD_WEEKDAY":
      const indexOfDay = state.weekday.indexOf(action.payload.value);
      if (indexOfDay === -1) {
        const updatedWeekdays = [...state.weekday, action.payload.value].sort();
        return { ...state, weekday: updatedWeekdays };
      } else {
        return state;
      }
    case "REMOVE_WEEKDAY": {
      const indexOfDay = state.weekday.indexOf(action.payload.value);
      if (indexOfDay !== -1) {
        let updatedWeekdays = [...state.weekday];
        updatedWeekdays.splice(indexOfDay, 1);
        return { ...state, weekday: updatedWeekdays };
      } else {
        return state;
      }
    }
    case "PAUSE":
      return { ...state, paused: action.payload };

    case "LOAD_SCHEDULE":
      return {
        name: action.payload.name,
        email: action.payload.email.join("; "),
        body: action.payload.emailBody,
        pattern: action.payload.patternDetails.pattern,
        repeat: action.payload.patternDetails.repeat,
        weekday: getStateWeekdays(action.payload.patternDetails.weekday),
        weekNum: action.payload.patternDetails.weekNum,
        onDay: action.payload.patternDetails.dayOfMonth,
        time: action.payload.patternDetails.time,
        dateFrom: action.payload.patternDetails.dateFrom,
        dateTo: action.payload.patternDetails.dateTo,
        paused: !action.payload.enabled,
      };
    case "RESET":
      return action.payload;
    default:
      return state;
  }
};

const EmailForm = ({ email, body, dispatch }) => {
  const { t } = useTranslation("reports");
  return (
    <React.Fragment>
      <FormGroup css={{ marginBottom: "1rem" }}>
        <FormLabel>
          {t("reports:Send to")}
          <sup>*</sup>
        </FormLabel>
        <FormControl
          defaultValue={email}
          onChange={(e) => dispatch({ type: "EMAIL", payload: e.target.value })}
        />
        <FormText muted style={{ fontSize: "80%" }}>
          {t(
            "reports:Multiple emails can be entered by separating each with a semicolon",
          )}
        </FormText>
      </FormGroup>
      <FormGroup css={{ marginBottom: "1rem" }}>
        <FormLabel>
          {t("reports:Body")}
          <sup>*</sup>
        </FormLabel>
        <FormControl
          as="textarea"
          defaultValue={body}
          onChange={(e) => dispatch({ type: "BODY", payload: e.target.value })}
        />
        <FormText
          muted
          style={{ display: "flex", flexDirection: "column", fontSize: "80%" }}
        >
          <span css={{ fontWeight: "bold" }}>{t("reports:Notes")}:</span>
          <span css={{ marginLeft: "0.25rem" }}>
            {t("reports:Large data files may fail to send.")}
          </span>
          <span css={{ marginLeft: "0.25rem" }}>
            {t(
              "reports:Last tab of the report must contain the data table you want exported.",
            )}
          </span>
        </FormText>
      </FormGroup>
    </React.Fragment>
  );
};

const validateSendEmailForm = (data, t) => {
  if (!data.email) {
    return { error: true, errorText: t(`reports:"Send to" is required`) };
  } else if (!data.body) {
    return { error: true, errorText: t(`reports:"Body" is required`) };
  }

  return { error: false };
};

const initialSendEmailState = {
  email: "",
  body: "",
};

export const SendEmailModal = ({
  show,
  hide,
  report = {},
  sendEmail,
  sendEmailData,
}) => {
  const { t } = useTranslation("reports");

  const [data, dispatch] = useReducer(formDataReducer, initialSendEmailState);

  const [errorText, setErrorText] = useState("");
  const [showError, setShowError] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);

  useEffect(() => {
    if (show === false) {
      dispatch({ type: "RESET", payload: initialSendEmailState });
      setShowError(false);
      setHasRequested(false);
    }
  }, [show, dispatch]);

  useEffect(() => {
    if (hasRequested) {
      if (sendEmailData?.isLoadingError) {
        setErrorText(t("reports:Something went wrong"));
        setShowError(true);
        setHasRequested(false);
        return;
      }

      hide();
    }
  }, [sendEmailData, t, hasRequested, setHasRequested, hide]);

  return (
    <Modal backdrop="static" show={show} onHide={() => hide()}>
      <ModalHeader title={t("reports:Send Email")} />
      <ModalBody>
        {showError && (
          <Alert
            variant="danger"
            dismissible
            onClose={() => setShowError(false)}
          >
            {errorText}
          </Alert>
        )}
        <EmailForm email={data.email} body={data.body} dispatch={dispatch} />
      </ModalBody>
      <ModalFooter>
        <FormButton
          label={t("reports:Cancel")}
          disabled={sendEmailData?.isLoading}
          clickHandler={() => hide()}
        />
        <FormButton
          actionType="ACTION"
          label={
            sendEmailData?.isLoading
              ? t("reports:Sending...")
              : t("reports:Send")
          }
          disabled={sendEmailData?.isLoading}
          clickHandler={() => {
            const { error, errorText } = validateSendEmailForm(data, t);
            if (error) {
              setErrorText(errorText);
              setShowError(true);
              return;
            }

            sendEmail(report, formatEmail(data.email), data.body).then(() => {
              setHasRequested(true);
            });
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

SendEmailModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
};

const RepeatTimeForm = ({ data, dispatch }) => {
  const { t } = useTranslation("reports");

  let otherTimes = [...data.time];
  let firstTime;
  if (otherTimes.length > 0) {
    firstTime = otherTimes.shift();
  }

  const handleRepeatInputChange = (e) => {
    dispatch({ type: "REPEAT", payload: e.target.value });
  };

  const handleTimeChange = (index, time) => {
    dispatch({ type: "TIME", payload: { index, time } });
  };

  const handleAddTime = () => {
    dispatch({ type: "ADD_TIME" });
  };

  const handleRemoveTime = (index) => {
    dispatch({ type: "REMOVE_TIME", payload: { index } });
  };

  let repeatInput;
  if (data.pattern.startsWith("yearly")) {
    repeatInput = (
      <FormControl
        as="select"
        custom
        value={data.repeatMonth}
        onChange={handleRepeatInputChange}
      >
        <option value={0}>{t("reports:January")}</option>
        <option value={1}>{t("reports:February")}</option>
        <option value={2}>{t("reports:March")}</option>
        <option value={3}>{t("reports:April")}</option>
        <option value={4}>{t("reports:May")}</option>
        <option value={5}>{t("reports:June")}</option>
        <option value={6}>{t("reports:July")}</option>
        <option value={7}>{t("reports:August")}</option>
        <option value={8}>{t("reports:September")}</option>
        <option value={9}>{t("reports:October")}</option>
        <option value={10}>{t("reports:November")}</option>
        <option value={11}>{t("reports:December")}</option>
      </FormControl>
    );
  } else {
    repeatInput = (
      <FormControl
        style={{ width: "4.5em" }}
        type="number"
        min="1"
        value={data.repeat}
        onChange={handleRepeatInputChange}
      />
    );
  }

  return (
    <FormGroup
      className="d-flex flex-column"
      css={{ marginBottom: "1rem", width: "max-content" }}
    >
      <div
        className="d-flex align-items-center mb-1 align-self-start"
        style={{ position: "relative" }}
      >
        <span className="mb-0 me-1 ms-1 flex-shrink-0">Repeats every</span>
        {repeatInput}

        <span className="mb-0 me-1 ms-1 flex-shrink-0">
          {data.pattern === "daily" && t("reports:day(s) at")}
          {data.pattern === "weekly" && t("reports:week(s) at")}
          {data.pattern.startsWith("monthly") && t("reports:month(s) at")}
          {data.pattern.startsWith("yearly") && t("reports:at")}
        </span>

        <TimePicker
          css={{ minWidth: 170 }}
          showSecond={false}
          value={moment(firstTime, "HH:mm")}
          format={"h:mm a"}
          onChange={(value) => handleTimeChange(0, value.format("HH:mm"))}
          use12Hours
          allowEmpty={false}
        />

        <Button className="ms-1" onClick={() => handleAddTime()}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      {otherTimes.map((time, timeIndex) => {
        return (
          <div
            key={timeIndex}
            className="d-flex align-items-center mb-1 align-self-end"
            style={{ position: "relative" }}
          >
            <span className="mb-0 me-1 flex-shrink-0">and</span>

            <TimePicker
              css={{ minWidth: 170 }}
              showSecond={false}
              value={moment(time, "HH:mm")}
              format={"h:mm a"}
              onChange={(value) => {
                // Adding 1 because index 0 is for the time input above
                handleTimeChange(timeIndex + 1, value.format("HH:mm"));
              }}
              use12Hours
              allowEmpty={false}
            />

            <Button
              className="ms-1"
              variant="danger"
              onClick={() => {
                // Adding 1 because index 0 is for the time input above
                handleRemoveTime(timeIndex + 1);
              }}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </div>
        );
      })}
    </FormGroup>
  );
};

RepeatTimeForm.propTypes = {
  data: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const DaysCheckboxForm = ({ data, dispatch }) => {
  const { t } = useTranslation("reports");

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      dispatch({ type: "ADD_WEEKDAY", payload: { value } });
    } else {
      dispatch({ type: "REMOVE_WEEKDAY", payload: { value } });
    }
  };

  return (
    <FormGroup
      className="d-flex align-items-center flex-wrap"
      css={{ marginBottom: "1rem" }}
    >
      <div style={{ display: "flex" }}>
        {(data.pattern === "monthly_weekday" ||
          data.pattern === "yearly_weekday") && (
          <React.Fragment>
            <span
              style={{ flexShrink: "0", alignSelf: "center" }}
              className="me-1"
            >
              {t("reports:On the")}
            </span>
            <FormControl
              as="select"
              className="me-3"
              style={{ width: "unset", alignSelf: "center" }}
              value={data.weekNum}
              onChange={(e) =>
                dispatch({ type: "WEEK_NUM", payload: e.target.value })
              }
            >
              <option value="1">{t("reports:First")}</option>
              <option value="2">{t("reports:Second")}</option>
              <option value="3">{t("reports:Third")}</option>
              <option value="4">{t("reports:Fourth")}</option>
              <option value="5">{t("reports:Fifth")}</option>
            </FormControl>
          </React.Fragment>
        )}

        {data.pattern === "weekly" && (
          <span className="me-3">{t("reports:On")}</span>
        )}

        {/* Need IDs so that the label can be clicked and the checkbox will toggle */}
        <div
          style={{
            display: "flex",
            flex: "column",
            flexWrap: "wrap",
          }}
        >
          <FormCheck
            id="cbMon"
            style={{ minWidth: "15%" }}
            type="checkbox"
            inline
            label={t("reports:Mon")}
            value={1}
            checked={data.weekday.includes("1")}
            onChange={handleCheckboxChange}
          />
          <FormCheck
            id="cbTue"
            style={{ minWidth: "15%" }}
            type="checkbox"
            inline
            label={t("reports:Tue")}
            value={2}
            checked={data.weekday.includes("2")}
            onChange={handleCheckboxChange}
          />
          <FormCheck
            id="cbWed"
            style={{ minWidth: "15%" }}
            type="checkbox"
            inline
            label={t("reports:Wed")}
            value={3}
            checked={data.weekday.includes("3")}
            onChange={handleCheckboxChange}
          />
          <FormCheck
            id="cbThu"
            style={{ minWidth: "15%" }}
            type="checkbox"
            inline
            label={t("reports:Thu")}
            value={4}
            checked={data.weekday.includes("4")}
            onChange={handleCheckboxChange}
          />
          <FormCheck
            id="cbFri"
            style={{ minWidth: "15%" }}
            type="checkbox"
            inline
            label={t("reports:Fri")}
            value={5}
            checked={data.weekday.includes("5")}
            onChange={handleCheckboxChange}
          />
          <FormCheck
            id="cbSat"
            style={{ minWidth: "15%" }}
            type="checkbox"
            inline
            label={t("reports:Sat")}
            value={6}
            checked={data.weekday.includes("6")}
            onChange={handleCheckboxChange}
          />
          <FormCheck
            id="cbSun"
            style={{ minWidth: "15%" }}
            type="checkbox"
            inline
            label={t("reports:Sun")}
            value={0}
            checked={data.weekday.includes("0")}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
    </FormGroup>
  );
};

DaysCheckboxForm.propTypes = {
  data: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const getRequestWeekdays = (weekdays = []) => {
  return weekdays.map((weekdayNum) => {
    switch (weekdayNum) {
      case "0":
        return "Sunday";
      case "1":
        return "Monday";
      case "2":
        return "Tuesday";
      case "3":
        return "Wednesday";
      case "4":
        return "Thursday";
      case "5":
        return "Friday";
      case "6":
        return "Saturday";
      default:
        return null;
    }
  });
};

const getStateWeekdays = (weekdays = []) => {
  return weekdays.map((weekdayName) => {
    switch (weekdayName) {
      case "Sunday":
        return "0";
      case "Monday":
        return "1";
      case "Tuesday":
        return "2";
      case "Wednesday":
        return "3";
      case "Thursday":
        return "4";
      case "Friday":
        return "5";
      case "Saturday":
        return "6";
      default:
        return null;
    }
  });
};

const getPatternDetails = (data = {}) => {
  switch (data.pattern) {
    case "daily":
      return {
        pattern: "daily",
        repeat: data.repeat,
        time: data.time,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
      };

    case "weekly":
      return {
        pattern: "weekly",
        repeat: data.repeat,
        time: data.time,
        weekday: getRequestWeekdays(data.weekday),
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
      };

    case "monthly_day":
      return {
        pattern: "monthly_day",
        repeat: data.repeat,
        time: data.time,
        dayOfMonth: data.onDay,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
      };

    case "monthly_weekday":
      return {
        pattern: "monthly_weekday",
        repeat: data.repeat,
        time: data.time,
        weekNum: data.weekNum,
        weekday: getRequestWeekdays(data.weekday),
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
      };

    case "yearly_day":
      return {
        pattern: "yearly_day",
        repeatMonth: data.repeatMonth,
        time: data.time,
        day: data.onDay,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
      };

    case "yearly_weekday":
      return {
        pattern: "yearly_weekday",
        repeatMonth: data.repeatMonth,
        time: data.time,
        weekNum: data.weekNum,
        weekday: getRequestWeekdays(data.weekday),
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
      };

    default:
      return null;
  }
};

const validateScheduleForm = (data, t) => {
  if (!data.name) {
    return { error: true, errorText: t(`reports:"Schedule Name" is required`) };
  } else if (!data.email) {
    return { error: true, errorText: t(`reports:"Send to" is required`) };
  } else if (!data.body) {
    return { error: true, errorText: t(`reports:"Body" is required`) };
  }

  return { error: false };
};

const getNthWeekdayOfMonth = (baseMoment, n, weekday) => {
  // Set the weekday and check to see if it falls within the same month
  // moment sets the day of the week within that week; it could fall to the month before
  const firstWeekdayInMonth = baseMoment.clone().day(weekday);

  if (firstWeekdayInMonth.month() !== baseMoment.month()) {
    // Add a week to get the following weekday
    firstWeekdayInMonth.add(1, "week");
  }

  // Add week offset from the current week
  return firstWeekdayInMonth.clone().add(n, "weeks");
};

// This is a generator function
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
function* createSampleDateTimeGenerator(data = {}) {
  const {
    pattern,
    repeat,
    repeatMonth,
    weekday: weekdays = [],
    weekNum,
    onDay,
    time = [],
    dateFrom,
    dateTo,
  } = data;

  const times = [...time].sort();

  const startDateTime = moment(`${dateFrom}T${_.min(times)}`);
  const endDateTime = dateTo ? moment(`${dateTo}T${_.max(times)}`) : null;

  let invalidCount = 0;

  const isAfterStartDate = (dateTimeToTest) => {
    const isBeforeNow = dateTimeToTest.isBefore(moment.now());
    const isBeforeEarliestDateTime = dateTimeToTest.isBefore(startDateTime);
    return !isBeforeNow && !isBeforeEarliestDateTime;
  };

  const isBeforeEndDate = (dateTimeToTest) => {
    // If no dateTo is specified, we'll still show the user a schedule
    // that isn't bounded by an end date
    if (_.isNil(endDateTime)) {
      return true;
    }

    const isAfterLatestDateTime = dateTimeToTest.isAfter(endDateTime);
    return !isAfterLatestDateTime;
  };

  const isValidSample = (dateTimeToTest) => {
    const isValid =
      isAfterStartDate(dateTimeToTest) && isBeforeEndDate(dateTimeToTest);
    if (!isValid) {
      invalidCount += 1;
    }
    return isValid;
  };

  const baseDateTimes = times.map((time) => moment(`${dateFrom}T${time}`));

  const repeatNum = parseInt(repeat, 10);
  const onDayNum = parseInt(onDay, 10);
  const repeatMonthNum = parseInt(repeatMonth, 10);
  const weekInMonthNum = parseInt(weekNum, 10);

  // In general, this while loop will do the following for a given pattern:
  //
  // 1. Generate an array of moment objects to potentially yield
  //    Sometimes this is just the `baseDateTimes` array for simpler patterns
  //
  // 2. For each moment, test that it is valid.
  //    - If valid, yield that value.
  //    - Otherwise, continue the looping until a valid one is found.
  //
  //    The patterns "monthly_weekday" and "yearly_weekday" perform addtional
  //    checks because of how we had to get the nth weekday of the given month
  //
  // 3. Update the `baseDateTimes` array by the repeat value
  //    This value is <repeat> number of days, weeks, months or years

  // Note on repeated code: While writing, it seemed better to leave the
  // repetitive code so that the logic is clearer to future readers.

  while (true) {
    // If we can't find the next sample due to the `isValidSample` function
    // this loop will run forever and never yield.
    // This will make the UI will freeze up requiring the user to force close the tab
    // To avoid that chance, this counter will force a break after so many attempts
    if (invalidCount >= 1000) {
      break;
    }

    if (pattern === "daily") {
      for (const sampleMoment of baseDateTimes) {
        if (isValidSample(sampleMoment)) {
          yield sampleMoment.clone();
        }
      }

      baseDateTimes.forEach((timeMoment) => timeMoment.add(repeatNum, "days"));
    } else if (pattern === "weekly") {
      if (weekdays.length === 0) {
        break;
      }

      const weekdayDateTimes = weekdays
        .map((dayOfWeek) => baseDateTimes.map((m) => m.clone().day(dayOfWeek)))
        .flat();

      for (const sampleMoment of weekdayDateTimes) {
        if (isValidSample(sampleMoment)) {
          yield sampleMoment.clone();
        }
      }

      baseDateTimes.forEach((timeMoment) => timeMoment.add(repeatNum, "weeks"));
    } else if (pattern === "monthly_day") {
      const monthlyDateTimes = baseDateTimes.map((m) =>
        m.clone().date(onDayNum),
      );

      for (const sampleMoment of monthlyDateTimes) {
        if (isValidSample(sampleMoment)) {
          yield sampleMoment.clone();
        }
      }

      baseDateTimes.forEach((timeMoment) =>
        timeMoment.add(repeatNum, "months"),
      );
    } else if (pattern === "monthly_weekday") {
      if (weekdays.length === 0) {
        break;
      }

      for (const weekday of weekdays) {
        for (const baseMoment of baseDateTimes) {
          // Get day 1 of the month
          const firstDayOfMonth = baseMoment.clone().date(1);

          const nthWeekdayInMonth = getNthWeekdayOfMonth(
            firstDayOfMonth,
            weekInMonthNum - 1,
            weekday,
          );

          /* Check that nth weekday falls after the month ends
           * If it does, yield that result
           * Note: We might want default to the last weekday if this happens
           *       if we don't, we will skip a year
           */
          if (nthWeekdayInMonth.month() === firstDayOfMonth.month()) {
            if (isValidSample(nthWeekdayInMonth)) {
              yield nthWeekdayInMonth.clone();
            }
          }
        }
      }

      baseDateTimes.forEach((timeMoment) =>
        timeMoment.add(repeatNum, "months"),
      );
    } else if (pattern === "yearly_day") {
      const monthlyDateTimes = baseDateTimes.map((m) =>
        m.clone().month(repeatMonthNum).date(onDayNum),
      );

      for (const sampleMoment of monthlyDateTimes) {
        if (isValidSample(sampleMoment)) {
          yield sampleMoment.clone();
        }
      }

      baseDateTimes.forEach((timeMoment) => timeMoment.add(repeatNum, "years"));
    } else if (pattern === "yearly_weekday") {
      if (weekdays.length === 0) {
        break;
      }

      for (const weekday of weekdays) {
        for (const baseMoment of baseDateTimes) {
          // Get day 1 of the month
          const firstDayOfMonth = baseMoment.clone().month(repeatMonth).date(1);

          const nthWeekdayInMonth = getNthWeekdayOfMonth(
            firstDayOfMonth,
            weekInMonthNum - 1,
            weekday,
          );

          /* Check that nth weekday falls after the month ends
           * If it does, yield that result
           * Note: We might want default to the last weekday if this happens
           *       if we don't, we will skip a year
           */
          if (nthWeekdayInMonth.month() === firstDayOfMonth.month()) {
            if (isValidSample(nthWeekdayInMonth)) {
              yield nthWeekdayInMonth.clone();
            }
          }
        }
      }

      baseDateTimes.forEach((timeMoment) => timeMoment.add(repeatNum, "years"));
    } else {
      break;
    }
  }

  yield null;
}

const getSampleSchedule = (data) => {
  // Create a generator. A new one is created every time this function is called
  const sampleGenerator = createSampleDateTimeGenerator(data);

  // Array to hold the final display strings
  const sampleSchedule = [];

  // Limiting to 5 samples
  for (let i = 0; i < 5; i++) {
    // Get the next "yield"
    const nextTime = sampleGenerator.next();
    // Check if the generator is done; last yield or returned
    if (nextTime.done) {
      break;
    }

    sampleSchedule.push(nextTime.value);
  }

  return sampleSchedule
    .filter((item) => item !== null)
    .sort((firstDateTime, secondDateTime) => {
      if (firstDateTime.isBefore(secondDateTime)) {
        return -1;
      } else {
        return 1;
      }
    })
    .map((dateTimeMoment) => {
      return dateTimeMoment.format("ddd, MMMM D, YYYY hh:mm a");
    });
};

// Wrapping in a getter so that `getCurrentDateForInput` is called
// at the time that we need initial state
// Otherwise, it will  be set to whatever the date is at the time of running the app
const getInitialScheduleState = () => {
  return {
    name: "",
    pattern: "daily",
    repeat: "1",
    repeatMonth: "0",
    time: ["00:00"],
    weekday: [],
    onDay: "1",
    weekNum: "1",
    dateFrom: getCurrentDateForInput(),
    dateTo: "",
    paused: false,
  };
};

const RequestType = {
  Create: "Create",
  Update: "Update",
  Delete: "Delete",
};

const getErrorMessage = (t, requestType, createRes, updateRes, deleteRes) => {
  let res = null;
  switch (requestType) {
    case RequestType.Create:
      res = createRes;
      break;
    case RequestType.Update:
      res = updateRes;
      break;
    case RequestType.Delete:
      res = deleteRes;
      break;
    default:
      return;
  }

  if (!res) {
    return t("reports:Something went wrong");
  }

  let status = res?.loadingError?.response?.status;
  let message = res?.loadingError?.response?.data?.error?.message;

  if (status === 409) {
    return t("reports:Schedule already exists");
  } else if (status === 400 && message.startsWith("One or more emails")) {
    return t("reports:One or more emails is not in a valid email format.");
  }

  return t("reports:Something went wrong");
};

export const EmailScheduleModal = ({
  show,
  hide,
  report = {},
  schedule = null,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  createScheduleData,
  updateScheduleData,
  deleteScheduleData,
  fetchSchedule,
}) => {
  const { t } = useTranslation("reports");

  const [data, dispatch] = useReducer(
    formDataReducer,
    getInitialScheduleState(),
  );

  const [errorText, setErrorText] = useState("");
  const [showError, setShowError] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);
  const [requestType, setRequestType] = useState(null);

  // Reset values if we closing the modal
  useEffect(() => {
    if (show === false) {
      dispatch({ type: "RESET", payload: getInitialScheduleState() });
      setShowError(false);
      setHasRequested(false);
      setRequestType(null);
    }
  }, [show, dispatch]);

  useEffect(() => {
    if (schedule) {
      dispatch({ type: "LOAD_SCHEDULE", payload: schedule });
    }
  }, [schedule]);

  useEffect(() => {
    if (hasRequested) {
      // After we requeted data
      // Check if there are errors to display
      if (
        createScheduleData?.isLoadingError ||
        updateScheduleData?.isLoadingError ||
        deleteScheduleData?.isLoadingError
      ) {
        setErrorText(
          getErrorMessage(
            t,
            requestType,
            createScheduleData,
            updateScheduleData,
            deleteScheduleData,
          ),
        );
        setShowError(true);
        setHasRequested(false);
        setRequestType(null);
        return;
      }

      // If no errors
      if (report) {
        fetchSchedule(report);
      }
      hide();
    }
  }, [
    createScheduleData,
    updateScheduleData,
    deleteScheduleData,
    t,
    hasRequested,
    setHasRequested,
    fetchSchedule,
    report,
    hide,
  ]);

  const showDaysCheckboxes =
    data.pattern === "weekly" ||
    data.pattern === "monthly_weekday" ||
    data.pattern === "yearly_weekday";

  const isProcessingRequest =
    createScheduleData?.isLoading ||
    updateScheduleData?.isLoading ||
    deleteScheduleData?.isLoading;

  let sendOrUpdateButtonText;
  if (schedule) {
    sendOrUpdateButtonText = updateScheduleData?.isLoading
      ? t("reports:Saving...")
      : t("reports:Save Schedule");
  } else {
    sendOrUpdateButtonText = createScheduleData?.isLoading
      ? t("reports:Creating...")
      : t("reports:Create Schedule");
  }
  return (
    <Modal backdrop="static" show={show} onHide={() => hide()} size="lg">
      <ModalHeader
        title={
          schedule
            ? t("reports:Update Email Schedule")
            : t("reports:Create Email Schedule")
        }
      />
      <ModalBody>
        {showError && (
          <Alert
            variant="danger"
            dismissible
            onClick={() => setShowError(false)}
          >
            {errorText}
          </Alert>
        )}
        <FormGroup css={{ marginBottom: "1rem" }}>
          <div className="d-flex justify-content-between">
            <div>
              <FormLabel>{t("reports:Schedule Name")}</FormLabel>
              <sup>*</sup>
            </div>
            <FormCheck
              id="pause_schedule"
              label={t("reports:Pause")}
              checked={data.paused}
              onChange={(e) =>
                dispatch({ type: "PAUSE", payload: e.target.checked })
              }
            />
          </div>

          <FormControl
            value={data.name}
            onChange={(e) =>
              dispatch({ type: "NAME", payload: e.target.value })
            }
          />
        </FormGroup>
        <EmailForm email={data.email} body={data.body} dispatch={dispatch} />
        <hr />
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-grow-1">
            <FormGroup css={{ marginBottom: "1rem" }}>
              <FormLabel className="fw-bold">
                {t("reports:Repeating Pattern")}:
              </FormLabel>
              <FormControl
                as="select"
                custom
                value={data.pattern}
                onChange={(e) => {
                  dispatch({ type: "PATTERN", payload: e.target.value });
                }}
              >
                <option value="daily">{t("reports:Daily")}</option>
                <option value="weekly">{t("reports:Weekly")}</option>
                <option value="monthly_day">
                  {t("reports:Monthly (Specific Day)")}
                </option>
                <option value="monthly_weekday">
                  {t("reports:Monthly (Day of Week)")}
                </option>
                <option value="yearly_day">
                  {t("reports:Yearly (Specific Day of Month)")}
                </option>
                <option value="yearly_weekday">
                  {t("reports:Yearly (Day of Week in Month)")}
                </option>
              </FormControl>
            </FormGroup>
            <RepeatTimeForm data={data} dispatch={dispatch} />
            {showDaysCheckboxes && (
              <DaysCheckboxForm data={data} dispatch={dispatch} />
            )}
            {(data.pattern === "monthly_day" ||
              data.pattern === "yearly_day") && (
              <FormGroup
                className="d-flex align-items-center"
                css={{ marginBottom: "1rem" }}
              >
                <span>{t("reports:On day")}</span>
                <FormControl
                  style={{ width: "4em" }}
                  className="ms-1 me-1"
                  type="number"
                  min="1"
                  max="31"
                  defaultValue={data.onDay || 1}
                  onChange={(e) =>
                    dispatch({ type: "ON_DAY", payload: e.target.value })
                  }
                />
                {data.pattern === "monthly_day" && (
                  <span>{t("reports:of each month")}</span>
                )}
                {data.pattern === "yearly_day" && (
                  <span>{t("reports:of that month")}</span>
                )}
              </FormGroup>
            )}
            <FormGroup className="d-flex align-items-center flex-wrap mb-1">
              <span className="me-1">{t("reports:From")}</span>
              <FormControl
                style={{ width: "unset" }}
                type="date"
                value={data.dateFrom}
                min={getCurrentDateForInput()}
                onChange={(e) =>
                  dispatch({ type: "DATE_FROM", payload: e.target.value })
                }
              />
              <span style={{ margin: "0 0.25em 0 0.3em" }}>
                {t("reports:to")}
              </span>
              <FormControl
                style={{ width: "unset" }}
                type="date"
                value={data.dateTo}
                // The minimum value should be no earlier than the "from" date time.
                // Default to current date time.
                min={data.dateFrom ?? getCurrentDateForInput()}
                onChange={(e) =>
                  dispatch({ type: "DATE_TO", payload: e.target.value })
                }
              />
            </FormGroup>
          </div>
          <div
            className="mt-4 mt-lg-0 mb-4 mb-lg-0 me-lg-4 ms-lg-4"
            css={{
              borderTop: "1px solid rgba(0,0,0,0.1)",
              [MediaQueries.largeAndUp]: {
                borderRight: "1px solid rgba(0,0,0,0.1)",
              },
            }}
          />
          <div css={{ minWidth: 225 }}>
            <div className="mb-2 fw-bold">{t("reports:Sample Schedule")}:</div>
            {show &&
              getSampleSchedule(data).map((example, index) => (
                <div key={index}>{example}</div>
              ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {schedule && (
          <FormButton
            style={{ marginRight: "auto" }}
            actionType="DANGER"
            label={
              deleteScheduleData?.isLoading
                ? t("reports:Deleting...")
                : t("reports:Delete")
            }
            disabled={isProcessingRequest}
            clickHandler={() => {
              deleteSchedule(report, schedule).then(() => {
                setRequestType(RequestType.Delete);
                setHasRequested(true);
              });
            }}
          />
        )}
        <FormButton
          actionType="ACTION"
          label={sendOrUpdateButtonText}
          disabled={isProcessingRequest}
          clickHandler={() => {
            const { error, errorText } = validateScheduleForm(data, t);
            if (error) {
              setErrorText(errorText);
              setShowError(true);
              return;
            }

            const requestData = {
              name: data.name,
              email: formatEmail(data.email),
              emailBody: data.body,
              patternDetails: getPatternDetails(data),
              enabled: !data.paused,
            };

            let request;
            if (schedule) {
              request = updateSchedule(report, schedule, requestData);
            } else {
              request = createSchedule(report, requestData);
            }

            request.then(() => {
              setRequestType(
                schedule ? RequestType.Update : RequestType.Create,
              );
              setHasRequested(true);
            });
          }}
        />
        <FormButton
          label={t("reports:Cancel")}
          disabled={isProcessingRequest}
          clickHandler={() => hide()}
        />
      </ModalFooter>
    </Modal>
  );
};

EmailScheduleModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
};

export const SelectScheduleModal = ({
  show,
  hide,
  report = {},
  isLoading = false,
  showCreateScheduleModal,
  showUpdateScheduleModal,
  hasManageSharedReportsPrivilege,
}) => {
  const { t } = useTranslation("reports");

  const [scheduleName, setScheduleName] = useState(undefined);
  const [creatorEmail, setCreatorEmail] = useState(undefined);

  const isMyReport =
    (report?.groupName === "Saved" && report?.private === true) ||
    (report?.filterSet && report?.filterSet.private === true);

  const schedules = report?.schedules ?? [];

  useEffect(() => {
    if (show === false) {
      setScheduleName(undefined);
      setCreatorEmail(undefined);
    } else {
      if (isMyReport) {
        // If this is a "My Report" schedule
        // only the logged in user will be able to create/edit schedules.
        // Assuming all creatorEmails will be the same user.
        setCreatorEmail(schedules[0]?.creatorEmail);
      }
    }
  }, [show, isMyReport, schedules]);

  let nameOptions =
    schedules.map((sched) => ({
      label: sched.name,
      creatorEmail: sched.creatorEmail,
      value: sched.name,
    })) ?? [];

  if (hasManageSharedReportsPrivilege) {
    nameOptions = nameOptions.filter((option) => {
      return option.creatorEmail === creatorEmail;
    });
  }

  const onNameChange = (option) => {
    setScheduleName(option.value);
  };

  const creatorEmailOptions =
    _.uniqBy(
      schedules.map((sched) => ({
        label: sched.creatorEmail,
        value: sched.creatorEmail,
      })),
      "value",
    ) ?? [];

  const onCreatorEmailChange = (option) => {
    setCreatorEmail(option.value);
  };

  let isScheduleNameSelectDisabled = false;
  if (hasManageSharedReportsPrivilege) {
    isScheduleNameSelectDisabled = _.isNil(creatorEmail);
  }

  let isOpenDisabled = _.isNil(scheduleName);
  if (hasManageSharedReportsPrivilege) {
    isOpenDisabled = _.isNil(creatorEmail) || isOpenDisabled;
  }

  return (
    <Modal backdrop="static" show={show} onHide={() => hide()}>
      <ModalHeader title={t("reports:Select Schedule")} isLoading={isLoading} />
      <ModalBody>
        {hasManageSharedReportsPrivilege && !isMyReport && (
          <FormGroup css={{ marginBottom: "1rem" }}>
            <FormLabel className="fw-bold">
              {t("reports:Creator Email")}:
            </FormLabel>
            <SelectField
              stateValue={creatorEmail}
              options={creatorEmailOptions}
              onChange={onCreatorEmailChange}
              isSearchable={false}
            />
          </FormGroup>
        )}
        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormLabel className="fw-bold">
            {t("reports:Schedule Name")}:
          </FormLabel>
          <SelectField
            stateValue={scheduleName}
            options={nameOptions}
            onChange={onNameChange}
            isSearchable={false}
            isDisabled={isScheduleNameSelectDisabled}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <FormButton
          label={t("reports:Create Schedule")}
          style={{
            marginRight: "auto",
            background: Colors.background.LIGHTER_BLUE,
            color: "white",
          }}
          clickHandler={() => showCreateScheduleModal()}
        />
        <FormButton
          actionType="ACTION"
          label={t("reports:Open Schedule")}
          disabled={isOpenDisabled}
          clickHandler={() => {
            const schedule = schedules.find(
              (sched) => sched.name === scheduleName,
            );
            showUpdateScheduleModal(schedule);
          }}
        />
        <FormButton label={t("reports:Cancel")} clickHandler={() => hide()} />
      </ModalFooter>
    </Modal>
  );
};

SelectScheduleModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  report: PropTypes.object,
  isLoading: PropTypes.bool,
  showCreateScheduleModal: PropTypes.func,
  showUpdateScheduleModal: PropTypes.func,
  hasManageSharedReportsPrivilege: PropTypes.bool,
  currentUserEmail: PropTypes.string,
};
