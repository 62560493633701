/** @jsxImportSource @emotion/react */
import { Provider } from "react-redux";
import App from "./App";
import configureStore from "./redux/store";
import AuthenticationUtils from "./modules/auth/authentication";
import "./i18n";
import { initMixpanel } from "./trackers/mixpanel";
import { setupBrowserMocks } from "mocks/setupBrowserMocks";
import { BrowserStorage } from "utils/browser-storage.utils";
import { createRoot } from "react-dom/client";

//
// Init tracking
initMixpanel();

//
// send auth headers with every (axios-based) xhr request
AuthenticationUtils.installAuthInterceptor();

//
// Setup listeners for managing values in storage (session and local)
BrowserStorage.registerEventListeners();

setupBrowserMocks();

const { store } = configureStore();
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
