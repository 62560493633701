/** @jsxImportSource @emotion/react */

import { connect } from "react-redux";
import { SearchBar } from "components/search-bar/SearchBar";
import SearchBarState from "../../../redux/ContainerManagementSearchBarState";
import {
  getFeatureData,
  getCurrentOrganizationId,
} from "../../../../../modules/organizations/OrganizationsState";
import { getAuthorization } from "../../../../../modules/auth/AuthorizationSelectors";
import containerTrackingDomainData from "../../../modules/domain-data/ContainerTrackingDomainData";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
  } = SearchBarState.selectors;

  return {
    domainDataArgs: [getFeatureData(state), getCurrentOrganizationId(state)],
    auth: getAuthorization(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    searchText: getSearchText(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
  };
};

const {
  setSearchCategoryForKey,
  setSearchText,
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
} = SearchBarState.actionCreators;

const { fetchDomainData } = containerTrackingDomainData.actionCreators;

const actionCreators = {
  setSearchCategory: setSearchCategoryForKey,
  setSearchText: (text, ignoreValueChange) => (dispatch) => {
    dispatch(setSearchText(text, ignoreValueChange, true));
  },
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
  fetchDomainData,
};

export default connect(mapStateToProps, actionCreators)(SearchBar);
