import { connect } from "react-redux";

import { LocationManagementView } from "./LocationManagementView";
import { getAuthorization } from "../auth/AuthorizationSelectors";
import { getActiveOrganization } from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
  };
}

export default connect(mapStateToProps)(LocationManagementView);
