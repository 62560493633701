/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import {
  useEffect,
  useRef,
  useState,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Modal, Form } from "react-bootstrap";
import { Alert } from "components/atoms/Alert.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";
import SelectField from "components-old/forms/fields/SelectField";
import { AlertVariant } from "components/atoms/enums";
import { isValidEmail } from "utils/validation-utils";
import Loader from "react-loader";
import { isValidPhoneNumber } from "components/molecules/PhoneInput.molecule";
import {
  faPlusCircle,
  faMinusCircle,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import { FieldComponents } from "./DamageView.Form.FieldsRender";
import { ImageUploaderContainer } from "./DamageView.ImageUploader.container";
import { useDamageViewFormLabelTranslation } from "pages/damageview/utils/translation.utils";

const CustomLabel = styled.span((props) => ({
  "&:after": {
    content: props.isRequired ? '" *"' : '""',
    color: Colors.text.RED,
  },
}));

// returns whether the field is visible or not
const getIsVisible = (submissionData, field) => {
  let isVisible = false;

  if (field?.visibleWhen?.page.length > 0) {
    isVisible = field.visibleWhen.page.includes("submission");
  }

  if (field?.visibleWhen?.group === "submissionFields") {
    isVisible = field.visibleWhen.values.includes(
      submissionData[field.visibleWhen.fieldName],
    );
  }

  if (!isVisible && _.has(submissionData, field.fieldName)) {
    submissionData[field.fieldName] = undefined;
  }
  return isVisible;
};

// Check whether the field is required or not when submissionData is changed
const checkForRequired = (submissionData, field) => {
  if (field?.required) {
    return true;
  } else if (field?.requiredWhen?.group === "submissionFields") {
    if (
      field?.requiredWhen?.values?.includes(
        submissionData[field?.requiredWhen?.fieldName],
      )
    ) {
      return true;
    }
  } else {
    return false;
  }
};

// Internally maintaining the required fields to check the field type and make vaidations based on the value type
const updateRequired = (submissionData, field, fieldsArray) => {
  if (checkForRequired(submissionData, field)) {
    fieldsArray.push({ fieldName: field.fieldName, type: field.valueType });
  }
};

const getRequiredFields = (submissionData, checkFields) => {
  const requiredFields = [];
  checkFields?.forEach((field) => {
    if (field.visibleWhen) {
      if (field.visibleWhen.group === "submissionFields") {
        if (
          field.visibleWhen.values.includes(
            submissionData[field.visibleWhen.fieldName],
          )
        ) {
          updateRequired(submissionData, field, requiredFields);
        }
      } else if (
        field.visibleWhen.page &&
        field.visibleWhen.page.includes("submission")
      ) {
        updateRequired(submissionData, field, requiredFields);
      }
    } else {
      updateRequired(submissionData, field, requiredFields);
    }
  });
  return requiredFields;
};

export const DamageForm = ({
  shipperOptions,
  fetchFields,
  isFieldsLoading,
  showShipperOption,
  solutionId,
  show,
  updateShow,
  submitDamageForm,
  fields,
}) => {
  const { t } = useTranslation("damage-view");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leftColumnFormFields, setLeftColumnFormFields] = useState(false);
  const [rightColumnFormFields, setRightColumnFormFields] = useState(false);
  const [currentLoadStatus, setCurrentLoadStatus] = useState(null);
  const [successSubmissionId, setSuccessSubmissionId] = useState([]);
  // Form step indicates the current step in the form
  const [formStep, setFormStep] = useState(1);
  // Total step indicates the total steps in the form. Since we have min 2 steps we have default value to 2.
  const [totalStep, setTotalStep] = useState(!showShipperOption ? 2 : 3);
  const [shipper, setShipper] = useState(null);
  const [submissionData, setSubmissionData] = useState({});
  const [vinFieldsData, setVinFieldsData] = useState({});
  const [tagCategories, setTagCategories] = useState([]);
  const [savedData, setSavedData] = useState({});
  const [vinData, setVinData] = useState([]);
  const [isShipperDisabled, setIsShipperDisabled] = useState(false);

  const { getTranslatedFormLabel } = useDamageViewFormLabelTranslation();

  let vins = vinData.map((data) => {
    return data.vin.value;
  });

  const savedVin =
    !_.isEmpty(savedData) && savedData.vin.value ? [savedData.vin.value] : [];

  const selectedVins = !vinFieldsData.vin
    ? [...vins, ...savedVin]
    : [...vins, ...savedVin, vinFieldsData.vin.value];

  const vinField = useMemo(() => {
    return {
      fieldName: "vin",
      fieldLabel: "VIN",
      valueType: "dropdown",
      isAsync: true,
      endpoint: `/damageview/submission/filters?filterType=vin&solution_id=${
        solutionId ? solutionId : shipper
      }`,
      required: true,
    };
  }, [solutionId, shipper]);

  const internalVinField = useMemo(() => {
    return [vinField];
  }, [vinField]);

  const internalVinFields = !fields.vinFields
    ? internalVinField
    : [...internalVinField, ...fields.vinFields];

  const vinFields = internalVinFields;

  const form = useRef(null);

  const requiredSubmissionDataFields = getRequiredFields(
    submissionData,
    fields?.submissionFields,
  );
  const requiredCoreDataFields = getRequiredFields(
    submissionData,
    fields?.coreFields,
  );

  const requiredVinDataFields = getRequiredFields(
    submissionData,
    fields?.vinFields,
  );

  const requiredSubmissionFields = useMemo(() => {
    return [...requiredCoreDataFields, ...requiredSubmissionDataFields];
  }, [requiredCoreDataFields, requiredSubmissionDataFields]);

  const requiredVinFields = useMemo(() => {
    return [...requiredVinDataFields, { fieldName: "vin", type: "dropdown" }];
  }, [requiredVinDataFields]);

  const getDefaultVinFieldsData = useCallback(() => {
    const internalVinFields = {};
    const fieldsList = !fields?.vinFields
      ? [vinField]
      : [vinField, ...fields.vinFields];
    const tagList = fields ? fields.imageFields : [];
    fieldsList?.forEach((field) => {
      if (!field.acceptMultiple) {
        internalVinFields[field.fieldName] = "";
      } else {
        internalVinFields[field.fieldName] = [];
        const subFieldData = {};
        field.subFields.forEach((subField) => {
          subFieldData[subField.fieldName] = "";
        });
        internalVinFields[field.fieldName].push(subFieldData);
      }

      if (tagList && tagList.length > 0) {
        const internalPhotoCategories = [];
        internalVinFields["uploadPhotos"] = {};
        tagList.forEach((tag) => {
          internalVinFields["uploadPhotos"][tag.fieldName] = {
            comments: "",
            files: [],
          };
          internalPhotoCategories.push({
            name: tag.fieldLabel,
            isRequired: false,
            category: tag.fieldName,
          });
        });
        setTagCategories(internalPhotoCategories);
      }
    });
    return internalVinFields;
  }, [fields, vinField]);

  const checkForSubmissionDataValidity = useCallback(() => {
    return !_.isEmpty(fields)
      ? requiredSubmissionFields
          .map((field) => {
            if (field.type === "email") {
              let checkForSecondary = true;
              if (field.fieldName === "secondarySubmitterEmail") {
                checkForSecondary =
                  submissionData["submitterEmail"] &&
                  isValidEmail(submissionData["submitterEmail"]) &&
                  submissionData["submitterEmail"] !==
                    submissionData[field.fieldName];
              }
              return (
                checkForSecondary &&
                isValidEmail(submissionData[field.fieldName])
              );
            } else if (field.type === "phone") {
              let checkForSecondary = true;
              if (field.fieldName === "secondarySubmitterPhoneNumber") {
                checkForSecondary =
                  submissionData["submitterPhoneNumber"] &&
                  isValidPhoneNumber(submissionData["submitterPhoneNumber"]) &&
                  submissionData["submitterPhoneNumber"] !==
                    submissionData[field.fieldName];
              }
              return (
                checkForSecondary &&
                submissionData[field.fieldName] &&
                isValidPhoneNumber(submissionData[field.fieldName])
              );
            } else if (field.type === "date") {
              return _.isDate(submissionData[field.fieldName]);
            } else {
              return (
                !_.isEmpty(submissionData[field.fieldName]) &&
                !_.isNull(submissionData[field.fieldName])
              );
            }
          })
          .filter((data) => !data).length === 0
      : false;
  }, [requiredSubmissionFields, submissionData, fields]);

  const checkForImagesValidity = useCallback(
    (vinFieldData) => {
      if (vinFieldData) {
        const imageData = vinFieldData.uploadPhotos;

        const requiredPhotos = tagCategories.filter((category) => {
          return category.isRequired;
        });

        const invalidData = requiredPhotos.filter((data) => {
          return imageData && imageData[data.category].files?.length === 0;
        });
        return invalidData.length === 0;
      } else {
        return false;
      }
    },
    [tagCategories],
  );

  const checkForVinFieldsValidity = useCallback(
    (vinFieldData) => {
      return !_.isEmpty(fields)
        ? requiredVinFields
            .map((field) => {
              if (field.type === "email") {
                return isValidEmail(vinFieldData[field.fieldName]);
              } else if (field.type === "phone") {
                return (
                  vinFieldData[field.fieldName] &&
                  isValidPhoneNumber(vinFieldData[field.fieldName])
                );
              } else if (field.type === "date") {
                return _.isDate(vinFieldData[field.fieldName]);
              } else {
                return (
                  !_.isEmpty(vinFieldData[field.fieldName]) &&
                  !_.isNull(vinFieldData[field.fieldName])
                );
              }
            })
            .filter((data) => !data).length === 0 &&
            checkForImagesValidity(vinFieldData)
        : false;
    },
    [requiredVinFields, fields, checkForImagesValidity],
  );

  const updateVinFieldsData = (field, value) => {
    if (formStep === totalStep) {
      setVinFieldsData({ ...vinFieldsData, [field]: value });
    } else {
      const vinDetails = [...vinData];
      const index = formStep - 2;
      vinDetails[index] = { ...vinDetails[index], [field]: value };
      setVinFieldsData({ ...vinFieldsData, [field]: value });
      setVinData(vinDetails);
    }
  };

  const updateSubmitFieldsData = (field, value) => {
    setSubmissionData({ ...submissionData, [field]: value });
  };

  const updateMultipleFieldData = (
    rootFieldName,
    data,
    index,
    field,
    value,
  ) => {
    // updating subField value which will be a array of value (multiple values) in groups like Area/Type/Severity
    //   vinFields: {
    //     areaTypeSeverity: [
    //       {area: "", type: "", severity: ""},
    //       {area: "", type: "", severity: ""},
    //     ]
    //  }
    const multipleData = [...vinFieldsData[rootFieldName]];
    const updatedData = { ...data[index], [field]: value };
    multipleData.splice(index, 1, updatedData);
    updateVinFieldsData(rootFieldName, multipleData);
  };

  const updateVinData = (internalData, pageNumber, inputValue) => {
    let populateData = internalData.filter((data) => {
      return !selectedVins.includes(data.value);
    });
    return vinFieldsData.vin && pageNumber === 0 && !inputValue
      ? [vinFieldsData.vin, ...populateData]
      : populateData;
  };
  // updating the default vinFieldsData to have empty or null values.
  // We are doing this as we will need to render the Area/Type/Severity based in the total number of group of values available in vinFields
  useEffect(() => {
    setVinFieldsData(getDefaultVinFieldsData());
  }, [fields, getDefaultVinFieldsData, show]);

  const enableNextStep = useMemo(() => {
    if (showShipperOption && formStep === 1 && !_.isEmpty(shipper)) {
      return true;
    } else if (
      (!showShipperOption ? formStep === 1 : formStep === 2) &&
      checkForSubmissionDataValidity()
    ) {
      return true;
    } else if (!showShipperOption ? formStep > 1 : formStep > 2) {
      const checkData =
        formStep === totalStep
          ? vinFieldsData
          : vinData[showShipperOption ? formStep - 3 : formStep - 2];
      return checkForVinFieldsValidity(checkData);
    }
    return false;
  }, [
    showShipperOption,
    formStep,
    totalStep,
    shipper,
    vinData,
    vinFieldsData,
    checkForSubmissionDataValidity,
    checkForVinFieldsValidity,
  ]);

  const enableSubmit = useMemo(() => {
    const internalData = [...vinData, vinFieldsData];
    return (
      internalData
        .map((data) => checkForVinFieldsValidity(data))
        .filter((data) => !data).length === 0
    );
  }, [vinData, vinFieldsData, checkForVinFieldsValidity]);

  useEffect(() => {
    const updatedTags = tagCategories?.map((data) => {
      data.isRequired =
        fields && !_.isEmpty(fields)
          ? checkForRequired(
              submissionData,
              fields.imageFields.filter(
                (tag) => tag.fieldName === data.category,
              )[0],
            )
          : false;
      return data;
    });
    if (!_.isEqual(updatedTags, tagCategories)) {
      setTagCategories(updatedTags);
    }
  }, [submissionData, fields, tagCategories]);

  useEffect(() => {
    if (!_.isEmpty(fields)) {
      const updatedFields = [...fields.submissionFields, ...fields.coreFields];

      const maxOrder = _.maxBy(updatedFields, function (data) {
        return data.order;
      })?.order;
      let updatedSubmissionFields = _.orderBy(updatedFields, "order", "asc");
      updatedSubmissionFields = maxOrder
        ? updatedSubmissionFields.filter((filterData) => {
            return filterData.order <= maxOrder;
          })
        : updatedSubmissionFields;

      const valueSplitter = Math.ceil(updatedSubmissionFields.length / 2);
      setLeftColumnFormFields(
        [...updatedSubmissionFields].splice(0, valueSplitter),
      );
      setRightColumnFormFields(
        [...updatedSubmissionFields].splice(valueSplitter),
      );
    }
  }, [fields]);

  const hideHandler = () => {
    updateShow(false);
    setIsSubmitting(false);
    setCurrentLoadStatus(null);
    setSuccessSubmissionId([]);
    setSubmissionData({});
    setVinFieldsData({});
    setTagCategories([]);
    setSavedData({});
    setVinData([]);
    setShipper(null);
    setFormStep(1);
    setIsShipperDisabled(false);
  };

  return (
    <Modal size="lg" show={show} backdrop="static" onHide={hideHandler}>
      <Modal.Header
        closeVariant="white"
        closeButton={true}
        css={{
          color: Colors.text.WHITE,
          backgroundColor: Colors.text.BLACK,
          borderBottom: `1px solid ${Colors.border.BOTTOM_AXIS_BORDER}`,
          padding: ".75rem",
        }}
      >
        {isSubmitting ? null : currentLoadStatus === "success" ? (
          <Text size={FontSize.size20}>
            {t("damage-view:Confirmation Message")}
          </Text>
        ) : (
          <Text size={FontSize.size20}>
            {t(
              "damage-view:Create Damage Report (Step [[[formStep]]]/[[[totalStep]]])",
              { formStep, totalStep },
            )}
          </Text>
        )}
      </Modal.Header>
      <Modal.Body
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          padding: "1em",
        }}
      >
        <Loader loaded={!isFieldsLoading} />
        {isSubmitting ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "5rem",
            }}
          >
            <Icon src={faSpinner} spin size={FontSize.size32} />
          </div>
        ) : currentLoadStatus === "success" ? (
          <div css={{ padding: "1rem" }}>
            <Text size={FontSize.size16} block>
              {t("damage-view:Thank you for your submission") + "!"}
            </Text>
            <Text size={FontSize.size16} block>
              {t("damage-view:Your submission IDs are [[[submissionId]]]", {
                submissionId: successSubmissionId.join(", "),
              })}
            </Text>
          </div>
        ) : (
          <Fragment>
            <Alert
              show={currentLoadStatus === "failed"}
              variant={AlertVariant.Warning}
            >
              {t("damage-view:Something went wrong. Please submit again.")}
            </Alert>
            <div
              css={{
                padding: "5px",
                borderBottom: `2px solid ${Colors.border.INNER_GRAY_BORDER}`,
              }}
            >
              <Text size={FontSize.size16}>
                {formStep === 1
                  ? !showShipperOption
                    ? t("damage-view:Step 1 - Damage Details")
                    : t("damage-view:Step 1 - Select Shipper Details")
                  : showShipperOption
                  ? t("damage-view:Step 2 - Damage Details")
                  : t("damage-view:Step [[[formStep]]] - Add VIN Information", {
                      formStep,
                    })}
              </Text>
            </div>
            {(showShipperOption ? formStep <= 2 : formStep === 1) ? (
              <Form ref={form}>
                {showShipperOption && formStep === 1 ? (
                  <div
                    css={{
                      display: "flex",
                      gap: "2em",
                    }}
                  >
                    <CustomLabel isRequired={true}>
                      {t("damage-view:Shipper")}:
                    </CustomLabel>
                    <SelectField
                      css={{
                        maxWidth: "fit-content",
                      }}
                      id="shipper"
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={isShipperDisabled}
                      stateValue={shipper}
                      onChange={(data) => {
                        fetchFields(data.value);
                        setShipper(data.value);
                      }}
                      options={shipperOptions.map((data) => {
                        return {
                          label: data.shipper,
                          value: data.solution_id,
                        };
                      })}
                    />
                  </div>
                ) : (!showShipperOption ? formStep === 1 : formStep === 2) ? (
                  <div
                    css={{
                      width: "100%",
                      display: "flex",
                      gap: "1em",
                      flexDirection: "row",
                      [MediaQueries.mediumAndDown]: {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <div
                      css={{
                        width: "50%",
                        [MediaQueries.mediumAndDown]: {
                          width: "100%",
                        },
                      }}
                    >
                      {leftColumnFormFields &&
                        leftColumnFormFields?.map((field) => {
                          const shouldRender = field?.visibleWhen?.group
                            ? getIsVisible(submissionData, field)
                            : true;
                          return shouldRender ? (
                            <div
                              key={field.order}
                              css={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "top",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              <CustomLabel
                                isRequired={checkForRequired(
                                  submissionData,
                                  field,
                                )}
                              >
                                {getTranslatedFormLabel(field?.fieldLabel)}:
                              </CustomLabel>
                              <FieldComponents
                                step={formStep}
                                field={field}
                                fieldData={submissionData}
                                setFieldData={updateSubmitFieldsData}
                                shipper={shipper ?? null}
                              />
                            </div>
                          ) : null;
                        })}
                    </div>
                    <div
                      css={{
                        width: "50%",
                        [MediaQueries.mediumAndDown]: {
                          width: "100%",
                        },
                      }}
                    >
                      {rightColumnFormFields &&
                        rightColumnFormFields?.map((field) => {
                          const shouldRender = field?.visibleWhen?.group
                            ? getIsVisible(submissionData, field)
                            : true;
                          return shouldRender ? (
                            <div
                              key={field.fieldLabel}
                              css={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "top",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              <CustomLabel
                                isRequired={checkForRequired(
                                  submissionData,
                                  field,
                                )}
                              >
                                {getTranslatedFormLabel(field?.fieldLabel)}:
                              </CustomLabel>
                              <FieldComponents
                                step={formStep}
                                field={field}
                                fieldData={submissionData}
                                setFieldData={updateSubmitFieldsData}
                              />
                            </div>
                          ) : null;
                        })}
                    </div>
                  </div>
                ) : null}
              </Form>
            ) : (
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {vinFields?.map((field) => {
                  const subFields = field?.subFields;

                  return (
                    <div
                      css={{
                        width: "100%",
                        display: "flex",
                        marginBottom: ".5em",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        [MediaQueries.mediumAndUp]: {
                          flexDirection:
                            field.valueType === "textarea" ||
                            field.acceptMultiple
                              ? "column"
                              : "row",
                        },
                      }}
                      key={field.fieldName}
                    >
                      {!field.acceptMultiple ? (
                        <CustomLabel
                          isRequired={checkForRequired(submissionData, field)}
                        >
                          {getTranslatedFormLabel(field?.fieldLabel)}:
                        </CustomLabel>
                      ) : null}
                      {!field.acceptMultiple ? (
                        <FieldComponents
                          setDefaultValue={false}
                          step={formStep}
                          field={field}
                          fieldData={
                            formStep === totalStep
                              ? vinFieldsData
                              : vinData[
                                  showShipperOption
                                    ? formStep - 3
                                    : formStep - 2
                                ]
                          }
                          setFieldData={updateVinFieldsData}
                          updateAsyncData={
                            field.fieldName === "vin" ? updateVinData : null
                          }
                        />
                      ) : (
                        vinFieldsData[field.fieldName]?.map(
                          (data, valueIndex) => {
                            const vinFieldData = vinFieldsData[field.fieldName];
                            const vinFieldName = [field.fieldName];
                            return (
                              <div
                                css={{
                                  display: "grid",
                                  // 4 columns: 3 for the inputs, 1 for the add/remove button
                                  gridTemplateColumns: "3fr auto",
                                  [MediaQueries.mediumAndDown]: {
                                    gridTemplateColumns: "1fr 1fr",
                                  },
                                  gap: "1em",
                                }}
                              >
                                <div
                                  css={{
                                    display: "grid",
                                    // 4 columns: 3 for the inputs
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    [MediaQueries.mediumAndDown]: {
                                      gridTemplateColumns: "repeat(1, 1fr)",
                                    },
                                    gap: "1em",
                                    marginBottom: "10px",
                                  }}
                                  key={field.fieldName}
                                >
                                  {subFields.map((dataField, index) => {
                                    return (
                                      <div
                                        css={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                        key={`${index}-${dataField.fieldName}`}
                                      >
                                        <CustomLabel
                                          isRequired={checkForRequired(
                                            submissionData,
                                            field,
                                          )}
                                        >
                                          {getTranslatedFormLabel(
                                            dataField.fieldLabel,
                                          )}
                                          :
                                        </CustomLabel>
                                        <FieldComponents
                                          isSubfield={true}
                                          setDefaultValue={false}
                                          key={index}
                                          step={formStep}
                                          field={dataField}
                                          fieldData={data}
                                          setFieldData={(field, value) => {
                                            updateMultipleFieldData(
                                              vinFieldName,
                                              vinFieldData,
                                              valueIndex,
                                              field,
                                              value,
                                            );
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div
                                  css={{
                                    height: "3em",
                                    alignItems: "center",
                                    alignSelf: "end",
                                  }}
                                >
                                  {valueIndex + 1 ===
                                  vinFieldsData[field.fieldName].length ? (
                                    <Icon
                                      color={Colors.highlight.GREEN}
                                      size={FontSize.size20}
                                      src={faPlusCircle}
                                      onClick={() => {
                                        const addData = {};
                                        subFields.forEach((data) => {
                                          addData[data.fieldName] = "";
                                        });
                                        setVinFieldsData({
                                          ...vinFieldsData,
                                          [field.fieldName]: [
                                            ...vinFieldsData[field.fieldName],
                                            addData,
                                          ],
                                        });
                                      }}
                                    />
                                  ) : (
                                    <Icon
                                      color={Colors.highlight.RED}
                                      size={FontSize.size20}
                                      src={faMinusCircle}
                                      onClick={() => {
                                        let cloneData = [
                                          ...vinFieldsData[field.fieldName],
                                        ];
                                        cloneData.splice(valueIndex, 1);
                                        setVinFieldsData({
                                          ...vinFieldsData,
                                          [field.fieldName]: cloneData,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          },
                        )
                      )}
                    </div>
                  );
                })}
                <div>
                  <Text block size={FontSize.size20}>
                    {t("damage-view:Upload Photos")}
                  </Text>
                  <Text block size={FontSize.size16}>
                    {t(
                      "damage-view:Please upload the following photos. Multiple photos can be uploaded under each photo category",
                    )}
                  </Text>
                  <ol>
                    {tagCategories.map((data) => {
                      return (
                        <ImageUploaderContainer
                          key={`${formStep}-${data.category}`}
                          css={{ marginBlockEnd: "1em" }}
                          data={data}
                          category={data.category}
                          formData={vinFieldsData}
                          imagesData={vinFieldsData.uploadPhotos}
                          setFormData={(photoData) => {
                            updateVinFieldsData("uploadPhotos", photoData);
                          }}
                        />
                      );
                    })}
                  </ol>
                </div>
              </div>
            )}
            <Alert
              css={{ margin: 0 }}
              show={
                (showShipperOption ? formStep <= 2 : formStep === 1)
                  ? !enableNextStep
                  : !enableSubmit
              }
              variant={AlertVariant.Info}
            >
              {(showShipperOption ? formStep === 2 : formStep === 1)
                ? t("damage-view:Please fill out all required fields")
                : t(
                    "damage-view:Please fill out all required fields and photos",
                  )}
            </Alert>
          </Fragment>
        )}
      </Modal.Body>
      {isSubmitting || currentLoadStatus === "success" ? (
        isSubmitting ? null : (
          <Modal.Footer>
            <div
              css={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                css={{}}
                variant="success"
                onClick={() => {
                  hideHandler();
                }}
              >
                {t("damage-view:Close")}
              </Button>
            </div>
          </Modal.Footer>
        )
      ) : (
        <Modal.Footer>
          <div
            css={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>
              {/* We can allow the users to add only a maximum of 14 VIN's data so the total form steps can never be a greater than 15 */}
              {formStep === totalStep && formStep <= 15 ? (
                <Button
                  disabled={!enableSubmit}
                  onClick={() => {
                    setVinData([...vinData, { ...vinFieldsData }]);
                    setSavedData({});
                    setVinFieldsData(getDefaultVinFieldsData());
                    setTotalStep(totalStep + 1);
                    setFormStep(formStep + 1);
                  }}
                >
                  {t("damage-view:Add Another VIN")}
                </Button>
              ) : null}
            </div>
            <div>
              {formStep !== 1 ? (
                <Button
                  variant="light"
                  onClick={() => {
                    const updatedStep = formStep - 1;
                    setFormStep(updatedStep);
                    if (formStep === totalStep) {
                      setSavedData(vinFieldsData);
                    }
                    // check whether form is opened in shipper or carrier/partner orgs. Since the carrier or partner orgs will have a extra step, we check like this
                    if (
                      (showShipperOption
                        ? updatedStep >= 3
                        : updatedStep >= 2) &&
                      vinData.length > 0
                    ) {
                      setVinFieldsData(
                        vinData[
                          showShipperOption ? updatedStep - 3 : updatedStep - 2
                        ],
                      );
                    }
                  }}
                >
                  {t("damage-view:Previous Step")}
                </Button>
              ) : null}
              {formStep !== totalStep ? (
                <Button
                  css={{
                    marginLeft: "10px",
                  }}
                  disabled={!enableNextStep || isFieldsLoading}
                  onClick={() => {
                    const updatedStep = formStep + 1;
                    if (!isShipperDisabled) {
                      setIsShipperDisabled(
                        showShipperOption ? updatedStep > 1 : isShipperDisabled,
                      );
                    }

                    // check whether form is opened in shipper or carrier/partner orgs. Since the carrier or partner orgs will have a extra step, we check like this
                    if (
                      showShipperOption ? updatedStep >= 3 : updatedStep >= 2
                    ) {
                      if (updatedStep === totalStep) {
                        const updatedFieldsData = _.isEmpty(savedData)
                          ? { ...vinFieldsData }
                          : { ...savedData };
                        setVinFieldsData(updatedFieldsData);
                      } else if (vinFields.length > 0) {
                        setVinFieldsData(
                          vinData[
                            showShipperOption
                              ? updatedStep - 3
                              : updatedStep - 2
                          ],
                        );
                      }
                    }
                    setFormStep(updatedStep);
                    if (updatedStep === totalStep) {
                      setSavedData({});
                    }
                  }}
                  variant="success"
                >
                  {t("damage-view:Next Step")}
                </Button>
              ) : null}
              {formStep === totalStep ? (
                <Button
                  css={{
                    marginLeft: "10px",
                  }}
                  disabled={!enableSubmit}
                  variant="success"
                  onClick={() => {
                    setIsSubmitting(true);
                    const submitData = {
                      ...submissionData,
                      vinData: [...vinData, { ...vinFieldsData }],
                    };
                    submitDamageForm(submitData, fields, shipper)
                      .then((subIDs) => {
                        setIsSubmitting(false);
                        setCurrentLoadStatus("success");
                        setSuccessSubmissionId(subIDs);
                      })
                      .catch((e) => {
                        setIsSubmitting(false);
                        setCurrentLoadStatus("failed");
                      });
                  }}
                >
                  {t("damage-view:Submit")}
                </Button>
              ) : null}
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

DamageForm.propTypes = {
  show: PropTypes.bool,
  updateShow: PropTypes.func,
  reportingLocationOptions: PropTypes.array,
  areaOptions: PropTypes.array,
  shipperLocations: PropTypes.array,
  fields: PropTypes.object,
  showShipperOption: PropTypes.bool,
  solutionId: PropTypes.string,
  submitDamageForm: PropTypes.func,
  shipperOptions: PropTypes.array,
  fetchFields: PropTypes.func,
  isFieldsLoading: PropTypes.bool,
};
