/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSetTitle } from "components/hooks/useSetTitle";
import { Dashboard } from "components/templates/Dashboard.template";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import SearchBarContainer from "../components/search/PartView.SearchBar.container";
import FiltersContainer from "../components/search/PartView.SearchFilters.container";
import WatchedPackages from "./components/organisms/PartView.WatchedPackages.organism";
import { PartViewSavedSearchesPanel } from "./components/organisms/PartView.SavedSearchesPanel.organism";
import { usePartViewExceptionLabel } from "../components/hooks/usePartViewExceptionLabel";
import {
  getExceptionsDataForWidget,
  getIconData,
} from "../utils/exceptions.utils";
import { PackageStatusOption } from "../utils/filter.utils";

export const PartViewDashboard = ({
  searchEntities,
  setSearchFilter,
  clearSearchFilters,
  resetSearchBar,
  exceptionsListRequestData = {},
  exceptionOrPackageStatusCountsRequestData = {},
  fetchExceptionList,
  fetchExceptionOrPackageStatusCounts,
  fetchWatchedPackages,
  watchedTableIsLoading,
  watchedTableResults,
  watchedTablePageIndex,
  watchedTablePageSize,
  watchedTablePageCount,
  setWatchedTablePageIndex,
  includeAPU,
}) => {
  const { t } = useTranslation("partview-dashboard");
  const { getTranslatedExceptionNameByCode } = usePartViewExceptionLabel();
  const [showFilters, setShowFilters] = useState(false);

  useSetTitle(t("partview-dashboard:PartView"));

  useEffect(() => {
    resetSearchBar();
    clearSearchFilters();
  }, [resetSearchBar, clearSearchFilters]);

  useEffect(() => {
    fetchExceptionList();
    fetchExceptionOrPackageStatusCounts(null, false, includeAPU);
  }, [includeAPU, fetchExceptionList, fetchExceptionOrPackageStatusCounts]);

  const selectedActiveFilterOptions = useMemo(() => {
    let result = [
      PackageStatusOption.CREATED_OR_PACKAGED,
      PackageStatusOption.IN_ROUTE,
      PackageStatusOption.DELAYED,
    ];

    if (includeAPU) {
      result.push(PackageStatusOption.AVAILABLE_FOR_PICKUP);
    }
    return result;
  }, [includeAPU]);

  const onActiveGraphClick = useCallback(() => {
    clearSearchFilters();
    setSearchFilter("lifecycleState", selectedActiveFilterOptions);
    searchEntities();
  }, [
    clearSearchFilters,
    setSearchFilter,
    searchEntities,
    selectedActiveFilterOptions,
  ]);

  const onExceptionCountClick = useCallback(
    (exception) => {
      clearSearchFilters();

      if (exception.reasonCode === "Delivered") {
        setSearchFilter("lifecycleState", [PackageStatusOption.DELIVERED]);
      } else {
        setSearchFilter("lifecycleState", selectedActiveFilterOptions);
        setSearchFilter("exception", [
          { label: exception.name, value: exception.reasonCode },
        ]);
      }

      searchEntities();
    },
    [
      clearSearchFilters,
      setSearchFilter,
      searchEntities,
      selectedActiveFilterOptions,
    ],
  );
  //Part view for part user
  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(show) => setShowFilters(show)}
    >
      <Dashboard.Tabs>
        <Dashboard.TabList>
          <Dashboard.Tab>{t("partview-dashboard:Summary View")}</Dashboard.Tab>
          <Dashboard.Tab>
            {t("partview-dashboard:My PartView Homepage")}
          </Dashboard.Tab>
        </Dashboard.TabList>
        <Dashboard.TabPanel>
          <ExceptionsPanel
            title={t("partview-dashboard:Package Statuses")}
            totalCount={
              exceptionOrPackageStatusCountsRequestData.data?.data?.active ?? 0
            }
            totalCountIsLoading={
              exceptionOrPackageStatusCountsRequestData.isLoading
            }
            totalCountLabel={t("partview-dashboard:Active")}
            exceptionGroups={[
              {
                title: t("partview-dashboard:Exceptions"),
                includeInDonutChart: true,
                style: { flexGrow: 2 },
                exceptions: getExceptionsDataForWidget(
                  exceptionsListRequestData.data?.data,
                  exceptionOrPackageStatusCountsRequestData.data?.data,
                ).map((exception) => ({
                  ...exception,
                  name: getTranslatedExceptionNameByCode(exception.reasonCode),
                })),
                isLoading:
                  exceptionsListRequestData.isLoading ||
                  exceptionOrPackageStatusCountsRequestData.isLoading,
              },
              {
                style: { flexGrow: 1 },
                exceptions: [
                  {
                    name: t("partview-dashboard:Delivered"),
                    count:
                      exceptionOrPackageStatusCountsRequestData.data?.data
                        ?.delivered ?? 0,
                    icon: {
                      ...getIconData("Delivered"),
                    },
                    reasonCode: "Delivered",
                  },
                ],
                isLoading: exceptionOrPackageStatusCountsRequestData.isLoading,
              },
            ]}
            handleClickGraph={onActiveGraphClick}
            handleClickException={onExceptionCountClick}
          />
        </Dashboard.TabPanel>
        <Dashboard.TabPanel
          styles={{ display: "flex", flexDirection: "column", gap: "1em" }}
        >
          <PartViewSavedSearchesPanel />

          <WatchedPackages
            fetchWatchedPackages={fetchWatchedPackages}
            isLoading={watchedTableIsLoading}
            watchedPackages={watchedTableResults}
            pageIndex={watchedTablePageIndex}
            pageSize={watchedTablePageSize}
            pageCount={watchedTablePageCount}
            setPageIndex={setWatchedTablePageIndex}
          />
        </Dashboard.TabPanel>
      </Dashboard.Tabs>
    </Dashboard>
  );
};

PartViewDashboard.propTypes = {
  searchEntities: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  fetchExceptionList: PropTypes.func,
  exceptionsListRequestData: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string, // name
          value: PropTypes.string, // reasonCode
        }),
      ),
    }),
    isLoading: PropTypes.bool,
  }),
  fetchExceptionOrPackageStatusCounts: PropTypes.func,
  exceptionOrPackageStatusCountsRequestData: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.number,
      delivered: PropTypes.number,
      exceptions: PropTypes.objectOf(PropTypes.number),
    }),
    isLoading: PropTypes.bool,
  }),
  fetchWatchedPackages: PropTypes.func.isRequired,
  watchedTableIsLoading: PropTypes.bool,
  watchedTableResults: PropTypes.array,
  watchedTablePageIndex: PropTypes.number,
  watchedTablePageSize: PropTypes.number,
  watchedTablePageCount: PropTypes.number,
  setWatchedTablePageIndex: PropTypes.func.isRequired,
  includeAPU: PropTypes.bool,
};
