import _ from "lodash";
import moment from "moment";
import {
  BehindScheduleNotificationTriggers,
  FilterTypesEnum,
  NotificationEventTypes,
} from "./notificationmanagement.const";
import { isValidEmail } from "utils/validation-utils";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export const mapDeliveryActions = (deliveryActions) => {
  const validDeliveryActions = removeInvalidDeliveryActions(deliveryActions);
  return validDeliveryActions.map((deliveryAction) => {
    return {
      method: convertDeliveryActionType(deliveryAction.type),
      target: deliveryAction.value.replace(/\s/g, ""),
      unsubscribeId: deliveryAction.unsubscribeId,
    };
  });
};

const removeInvalidDeliveryActions = (deliveryActions) => {
  return deliveryActions.filter((deliveryAction) => {
    if (deliveryAction.type === "Email") {
      return isValidEmail(deliveryAction.value);
    } else if (deliveryAction.type === "SMS") {
      return isPossiblePhoneNumber(deliveryAction.value);
    }
    return false;
  });
};

const convertDeliveryActionType = (type) => {
  switch (type) {
    case "Email":
      return "email";
    case "SMS":
      return "sms";
    default:
      throw new Error("Invalid delivery action type");
  }
};

export const createDefinitionObject = (
  eventType,
  eventTypeDetail,
  timeObj,
  filters,
) => {
  if (eventType === NotificationEventTypes.ETA_CHANGE) {
    return createBehindScheduleV1Definition(eventTypeDetail, timeObj, filters);
  } else if (eventType === NotificationEventTypes.EARLY_ARRIVAL) {
    return createEarlyArrivalV1Definition(timeObj, filters);
  } else if (eventType === NotificationEventTypes.CARRIER_DELAY) {
    return createCarrierDelayV1Definition(filters);
  } else {
    throw new Error("Invalid event type");
  }
};

const createEarlyArrivalV1Definition = (timeObj, filters) => {
  return {
    earlyArrivalV1: {
      earlyBy: createIso8601DurationString(
        timeObj.days,
        timeObj.hours,
        timeObj.minutes,
      ),
      filters: createFilterObject(filters),
    },
  };
};

const createBehindScheduleV1Definition = (
  eventTypeDetail,
  timeObj,
  filters,
) => {
  return {
    behindScheduleV1: {
      scheduledArrivalWindowBoundary: setEventTypeDetail(eventTypeDetail),
      lateBy: createIso8601DurationString(
        timeObj.days,
        timeObj.hours,
        timeObj.minutes,
      ),
      filters: createFilterObject(filters),
    },
  };
};

const createCarrierDelayV1Definition = (filters) => {
  return {
    carrierDelayV1: {
      filters: createFilterObject(filters),
    },
  };
};

const createFilterObject = (filters) => {
  return filters.reduce((filterObject, filter) => {
    validateFilter(filter);
    const options = filter.filterOptions;
    if (options.length !== 0) {
      if (filter.filterType === FilterTypesEnum.MILES_OUT) {
        filterObject[filter.filterType] =
          createWithInRangeOfDestinationFilter(filter);
      } else {
        filterObject[filter.filterType] = filter.allowMultiSelect
          ? options.map((option) => option.value)
          : options[0]?.value;
      }
    }
    return filterObject;
  }, {});
};

const createWithInRangeOfDestinationFilter = (filter) => {
  return {
    value: Number(filter.filterOptions[0]),
    unit: "miles",
  };
};

const validateFilter = (filter) => {
  const validFilterTypes = new Set(Object.values(FilterTypesEnum));
  if (!validFilterTypes.has(filter.filterType) && filter.filterType !== null) {
    throw new Error("Invalid filter type");
  } else if (
    ("allowMultiSelect" in filter === false ||
      typeof filter.allowMultiSelect !== "boolean") &&
    filter.allowMultiSelect !== null
  ) {
    throw new Error("Invalid filter multiselect");
  } else if ("filterOptions" in filter === false) {
    throw new Error("Filter must include filterOptions");
  }
};

const setEventTypeDetail = (eventTypeDetail) => {
  let boundary = undefined;
  if (
    eventTypeDetail ===
    BehindScheduleNotificationTriggers.AFTER_DELIVERY_WINDOW_OPEN
  ) {
    boundary = "OPEN";
  } else if (
    eventTypeDetail ===
    BehindScheduleNotificationTriggers.AFTER_DELIVERY_WINDOW_CLOSE
  ) {
    boundary = "CLOSE";
  } else {
    throw new Error("Invalid event type detail");
  }
  return boundary;
};

const createIso8601DurationString = (day, hour, minute) => {
  if (_.isNil(day) || _.isNil(hour) || _.isNil(minute)) {
    throw new Error("Day hour and minute can not be null");
  }
  if (day + hour + minute <= 0) {
    throw new Error("0 Day 0 hour and 0 minute is not a duration");
  }
  return moment
    .duration({ days: day, hours: hour, minutes: minute })
    .toISOString();
};
