/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Wizard, useWizard } from "react-use-wizard";
import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import PropTypes from "prop-types";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import Colors from "styles/colors";
import { Modal } from "react-bootstrap";
import { faCommentSms, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import {
  faPlus,
  faTrash,
  faPenToSquare,
  faUserGroup,
} from "@fortawesome/pro-solid-svg-icons";
import { faSpinner, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

import { Dashboard } from "components/templates/Dashboard.template";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { Button } from "components/atoms/Button.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";

import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import { ApiRequestStatus } from "./notificationmanagement.const";

import {
  GeneralInformationStepOrganismContainer,
  EventInformationStepOrganismContainer,
  FilterCriteriaStepOrganismContainer,
  ConfirmationStepOrganismContainer,
} from "./components/organisms/wizard-steps";

import {
  RetryButton,
  NextButton,
  CancelButton,
  DoneButton,
  BackButton,
  CreateButton,
  UpdateButton,
} from "./components/atoms/WizardFooterButtons";

const WizardWrapper = ({ children, editNotificationRuleApiStatus }) => {
  const { t } = useTranslation("notification-management");
  return (
    <div
      css={{
        padding: "2.5%",
        minHeight: "20rem",
        width: "100%",
      }}
    >
      {editNotificationRuleApiStatus === "IN_PROGRESS" ? (
        <div
          css={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            height: "17rem",
          }}
          data-qa="loading-spinner"
        >
          <Icon src={faSpinner} spin size={FontSize.size84} />
        </div>
      ) : null}

      {editNotificationRuleApiStatus === "FAILED" ? (
        <div
          css={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            height: "17rem",
          }}
          data-qa="error-message"
        >
          <Icon
            src={faTimesCircle}
            size={FontSize.size84}
            color={Colors.text.RED}
          />
          <Text
            block
            css={{ marginTop: "1rem", textAlign: "center" }}
            size={FontSize.size16}
          >
            {t(
              "notification-management:We ran into an issue retrieving your notification rule. Please try again.",
            )}
          </Text>
        </div>
      ) : null}
      {editNotificationRuleApiStatus === "SUCCESS" ||
      editNotificationRuleApiStatus === null
        ? children
        : null}
    </div>
  );
};

WizardWrapper.propTypes = {
  children: PropTypes.node,
  editNotificationRuleApiStatus: PropTypes.string,
};

const WizardDialogHeader = (props) => {
  const { onHide, isEditing } = props;
  const { activeStep, stepCount } = useWizard();

  const { t } = useTranslation("notification-management");

  const notifTitle = isEditing
    ? t("notification-management:Update Notification")
    : t("notification-management:Create New Notification");

  let headerTitle;
  if (activeStep === stepCount - 1) {
    headerTitle = notifTitle;
  } else {
    headerTitle = t(
      "notification-management:[[[notifTitle]]] (Step [[[currentStep]]]/[[[stepsBeforeConfirmation]]])",
      {
        notifTitle,
        currentStep: activeStep + 1,
        stepsBeforeConfirmation: stepCount - 1,
      },
    );
  }

  return (
    <>
      <Modal.Header
        closeButton
        onHide={onHide}
        css={{
          backgroundColor: Colors.dialogs.HEADER_BACKGROUND,
          color: Colors.dialogs.HEADER_TEXT_COLOR,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
      >
        <Modal.Title>{headerTitle}</Modal.Title>
      </Modal.Header>
    </>
  );
};

WizardDialogHeader.propTypes = {
  onHide: PropTypes.func,
  isEditing: PropTypes.bool,
};

const WizardFooter = (props) => {
  const {
    onDone,
    onClose,
    isNextButtonDisabled = false,
    resetWizardState = null,
    postNewNotificationRuleStatus = null,
    allUserInputData = null,
    postNewNotificationRule = null,
    fetchNotificationRuleList = null,
    pageIdx = null,
    pageSize = null,
    isEditing,
  } = props;
  const {
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
  } = useWizard();

  const close = () => {
    resetWizardState();
    onClose(false);
  };

  const done = () => {
    fetchNotificationRuleList(pageIdx, pageSize);
    resetWizardState();
    onDone(false);
  };

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: Colors.dialogs.FOOTER_BACKGROUND,
        padding: "2.5%",
      }}
    >
      <div>
        {isFirstStep ? (
          <CancelButton onClick={close}></CancelButton>
        ) : isLastStep &&
          postNewNotificationRuleStatus === ApiRequestStatus.SUCCESS ? null : (
          <BackButton
            disabeld={[
              ApiRequestStatus.IN_PROGRESS,
              ApiRequestStatus.SUCCESS,
            ].includes(postNewNotificationRuleStatus)}
            onClick={previousStep}
          ></BackButton>
        )}
      </div>
      <div>
        {isLastStep ? (
          postNewNotificationRuleStatus === ApiRequestStatus.FAILED ? (
            <RetryButton
              onClick={() => {
                postNewNotificationRule(allUserInputData);
              }}
            ></RetryButton>
          ) : (
            <DoneButton
              disabled={
                postNewNotificationRuleStatus === ApiRequestStatus.IN_PROGRESS
              }
              onClick={done}
            ></DoneButton>
          )
        ) : activeStep === stepCount - 2 ? (
          isEditing ? (
            <UpdateButton disabled={isNextButtonDisabled} onClick={nextStep} />
          ) : (
            <CreateButton disabled={isNextButtonDisabled} onClick={nextStep} />
          )
        ) : (
          <NextButton
            disabled={isNextButtonDisabled}
            onClick={nextStep}
          ></NextButton>
        )}
      </div>
    </div>
  );
};

WizardFooter.propTypes = {
  onDone: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isNextButtonDisabled: PropTypes.bool,
  resetWizardState: PropTypes.func,
  postNewNotificationRuleStatus: PropTypes.string,
  allUserInputData: PropTypes.object,
  postNewNotificationRule: PropTypes.func,
  fetchNotificationRuleList: PropTypes.func,
  pageIdx: PropTypes.number,
  pageSize: PropTypes.number,
  isEditing: PropTypes.bool,
};

const StatusCell = (cellProps) => {
  const { t } = useTranslation("notification-management");
  const status = cellProps.value;
  switch (status) {
    case "enabled":
      return (
        <Text bold color={Colors.text.GREEN}>
          {t("notification-management:Enabled")}
        </Text>
      );
    case "noSubscribers":
      return (
        <Text bold color={Colors.text.YELLOW}>
          {t("notification-management:No Recipients")}
        </Text>
      );
    default:
      break;
  }
  const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);
  return (
    <Text>
      {t("notification-management:[[[capitalizedStatus]]]", {
        capitalizedStatus,
      })}
    </Text>
  );
};

const ActionCell = (cellProps) => {
  const {
    id,
    name,
    setDeleteConfirmationShow,
    setDeleteNotificationId,
    setDeleteNotificationName,
    setWizardShow,
    setIsEditing,
    fetchIndividualNotification,
  } = cellProps;
  const { t } = useTranslation("notification-management");

  const handleDelete = () => {
    setDeleteNotificationId(id);
    setDeleteNotificationName(name);
    setDeleteConfirmationShow(true);
  };
  return (
    <div css={{ display: "flex", justifyContent: "space-evenly", gap: "1em" }}>
      <Tooltip
        placement="top"
        trigger={["hover", "focus"]}
        tooltipChildren={<Text>{t("notification-management:Edit")}</Text>}
      >
        <Button
          variant={"outline-dark"}
          onClick={() => {
            setWizardShow(true);
            setIsEditing(true);
            fetchIndividualNotification(id);
          }}
        >
          <Icon src={faPenToSquare} />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        trigger={["hover", "focus"]}
        tooltipChildren={<Text>{t("notification-management:Delete")}</Text>}
      >
        <Button variant="outline-danger" onClick={handleDelete}>
          <Icon src={faTrash} />
        </Button>
      </Tooltip>
    </div>
  );
};

const RecipientsCell = (cellProps) => {
  const emailDeliveries = cellProps.value.filter(
    (recipient) => recipient.method === "email",
  );
  const smsDeliveries = cellProps.value.filter(
    (recipient) => recipient.method === "sms",
  );
  if (emailDeliveries.length + smsDeliveries.length === 0) {
    return <></>;
  }
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div css={{ paddingRight: "0.4em" }}>
        <Icon src={faUserGroup} color={Colors.text.GREEN}></Icon>
      </div>
      <div
        css={{
          display: "flex",
          borderRadius: "0.375rem",
          border: "solid 1px",
          borderColor: "#2d2d2d80",
          padding: "6px 5px 6px 0",
        }}
      >
        {emailDeliveries.length > 0 ? (
          <RecipientsCellDetail
            deliveries={emailDeliveries}
            icon={faEnvelope}
            iconColor={Colors.text.BLUE}
          />
        ) : null}
        {smsDeliveries.length > 0 ? (
          <RecipientsCellDetail
            deliveries={smsDeliveries}
            icon={faCommentSms}
            iconColor={Colors.text.GREEN}
          />
        ) : null}
      </div>
    </div>
  );
};

const RecipientsCellDetail = (props) => {
  const { deliveries, icon, iconColor } = props;
  return (
    <Tooltip
      css={{ display: "flex" }}
      placement="top"
      tooltipChildren={deliveries.map((delivery, idx) => (
        <Text block key={idx}>
          {delivery.method === "sms"
            ? formatPhoneNumberIntl(delivery.target)
            : delivery?.target}
        </Text>
      ))}
    >
      <Icon
        css={{ padding: "0 0.3em" }}
        size={FontSize.size20}
        color={iconColor}
        src={icon}
        data-qa="notification-management-notification-icon"
      ></Icon>
      <Text>{deliveries.length}</Text>
    </Tooltip>
  );
};

RecipientsCellDetail.propTypes = {
  deliveries: PropTypes.array,
  icon: PropTypes.object,
  iconColor: PropTypes.string,
};

const DeleteNotificationConfirmationModal = (props) => {
  const {
    deleteNotification,
    id,
    name,
    setDeleteNotificationId,
    setDeleteNotificationName,
    deleteConfirmationShow,
    setDeleteConfirmationShow,
  } = props;

  const { t } = useTranslation(["notification-management"]);

  return (
    <DialogModal
      show={deleteConfirmationShow}
      showCloseButton={false}
      onHide={() => setDeleteConfirmationShow(false)}
      title={t("notification-management:Delete Notification")}
      cancelButtonText={t("notification-management:Cancel")}
      submitButtonText={t("notification-management:Delete")}
      onSubmit={() => {
        deleteNotification(id);
        setDeleteNotificationId(null);
        setDeleteNotificationName(null);
        setDeleteConfirmationShow(false);
      }}
      data-qa="notification-management-delete-confirmation-modal"
    >
      <Text>
        {t(
          "notification-management: Are you sure you want to delete [[[name]]]?",
          {
            name: name,
          },
        )}
      </Text>
    </DialogModal>
  );
};

DeleteNotificationConfirmationModal.propTypes = {
  deleteNotification: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  setDeleteNotificationId: PropTypes.func.isRequired,
  setDeleteNotificationName: PropTypes.func.isRequired,
  deleteConfirmationShow: PropTypes.bool.isRequired,
  setDeleteConfirmationShow: PropTypes.func.isRequired,
};

const NotificationManagement = (props) => {
  const {
    notificationRuleList,
    notificationRuleListLoading,
    fetchNotificationRuleList,
    pageIdx,
    pageSize,
    pageCount,
    setPagination,
    isNextButtonDisabled,
    deleteNotification,
    resetWizardState,
    allUserInputData,
    postNewNotificationRule,
    postNewNotificationRuleStatus,
    fetchIndividualNotification,
    editNotificationRuleApiStatus,
  } = props;

  useEffect(() => {
    fetchNotificationRuleList(pageIdx, pageSize);
  }, [fetchNotificationRuleList, pageIdx, pageSize]);

  const { t } = useTranslation(["notification-management"]);

  useTrackWithMixpanelOnce(
    "Viewed Page: Notification Management / My Notification Rules",
  );

  useSetTitleOnMount(t("notification-management:Notification Management"));

  useSetDescriptionOnMount(
    t("notification-management:Manage notification rules"),
  );

  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
  const [deleteNotificationId, setDeleteNotificationId] = useState(null);
  const [deleteNotificationName, setDeleteNotificationName] = useState(null);

  const [wizardShow, setWizardShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const columns = [
    {
      Header: () => <>{t("notification-management:Rule Name")}</>,
      id: "rule",
      width: 200,
      accessor: "name",
      Cell: (cellProps) => <Text>{cellProps.value}</Text>,
    },
    {
      Header: () => <>{t("notification-management:Product Name")}</>,
      id: "product",
      width: 100,
      accessor: "product",
      Cell: (cellProps) => <Text>{cellProps.value}</Text>,
    },
    {
      Header: () => <>{t("notification-management:Recipients")}</>,
      disableSortBy: true,
      id: "recipients",
      width: 100,
      accessor: "notificationDeliveryActions",
      Cell: RecipientsCell,
    },
    {
      Header: () => <>{t("notification-management:Status")}</>,
      id: "status",
      width: 70,
      accessor: "state",
      Cell: StatusCell,
    },
    {
      Header: () => <>{t("notification-management:Date Modified")}</>,
      id: "dateModified",
      width: 100,
      accessor: "modified",
      Cell: (cellProps) => <DateTimeCell dateTime={cellProps.value} localize />,
    },
    {
      Header: (headerProps) =>
        headerProps ? <>{t("notification-management:Actions")}</> : null,
      id: "action",
      disableSortBy: true,
      width: 160,
      accessor: (row) => {
        return row.id;
      },
      Cell: (cellProps) => (
        <ActionCell
          id={cellProps.value}
          name={cellProps.row.original.name}
          deleteNotification={deleteNotification}
          setDeleteConfirmationShow={setDeleteConfirmationShow}
          deleteConfirmationShow={deleteConfirmationShow}
          deleteNotificationId={deleteNotificationId}
          deleteNotificationName={deleteNotificationName}
          setDeleteNotificationId={setDeleteNotificationId}
          setDeleteNotificationName={setDeleteNotificationName}
          setWizardShow={setWizardShow}
          setIsEditing={setIsEditing}
          fetchIndividualNotification={fetchIndividualNotification}
          data-qa="notification-management-action-cell"
        />
      ),
    },
  ];

  return (
    <>
      <DeleteNotificationConfirmationModal
        deleteNotification={deleteNotification}
        deleteConfirmationShow={deleteConfirmationShow}
        setDeleteConfirmationShow={setDeleteConfirmationShow}
        id={deleteNotificationId}
        name={deleteNotificationName}
        setDeleteNotificationId={setDeleteNotificationId}
        setDeleteNotificationName={setDeleteNotificationName}
      />
      <Dashboard>
        <Dashboard.Tabs>
          <Dashboard.TabList>
            <Dashboard.Tab>
              {t("notification-management:My Notification Rules")}
            </Dashboard.Tab>
          </Dashboard.TabList>
          <Dashboard.TabPanel>
            <div
              css={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "1em",
              }}
            >
              <Button
                variant="success"
                onClick={() => {
                  setWizardShow(true);
                  setIsEditing(false);
                }}
                data-qa="notification-management-create-notification-button"
              >
                <Icon src={faPlus} css={{ marginRight: "0.5em" }} />
                {t("notification-management:Create Notification")}
              </Button>
            </div>
            <Modal backdrop={"static"} show={wizardShow}>
              <Wizard
                startIndex={0}
                header={
                  <WizardDialogHeader
                    onHide={() => {
                      if (
                        postNewNotificationRuleStatus ===
                        ApiRequestStatus.SUCCESS
                      ) {
                        fetchNotificationRuleList(pageIdx, pageSize);
                      }
                      resetWizardState();
                      setWizardShow(false);
                    }}
                    isEditing={isEditing}
                  />
                }
                footer={
                  <WizardFooter
                    onDone={setWizardShow}
                    onClose={setWizardShow}
                    isNextButtonDisabled={isNextButtonDisabled}
                    resetWizardState={resetWizardState}
                    postNewNotificationRuleStatus={
                      postNewNotificationRuleStatus
                    }
                    allUserInputData={allUserInputData}
                    postNewNotificationRule={postNewNotificationRule}
                    fetchNotificationRuleList={fetchNotificationRuleList}
                    pageIdx={pageIdx}
                    pageSize={pageSize}
                    isEditing={isEditing}
                  />
                }
                wrapper={
                  <WizardWrapper
                    editNotificationRuleApiStatus={
                      editNotificationRuleApiStatus
                    }
                  />
                }
              >
                <EventInformationStepOrganismContainer isEditing={isEditing} />
                <FilterCriteriaStepOrganismContainer />
                <GeneralInformationStepOrganismContainer
                  isEditing={isEditing}
                />
                <ConfirmationStepOrganismContainer isEditing={isEditing} />
              </Wizard>
            </Modal>
            <BaseTable
              columns={columns}
              defaultSortColumn="dateModified"
              data={notificationRuleList}
              isLoading={notificationRuleListLoading}
              showPagination={true}
              fixPaginationToBottom={true}
              defaultReverseSort={true}
              showFilters={false}
              showPageSizeDropdown={true}
              pageIndex={pageIdx}
              pageSize={pageSize}
              pageCount={pageCount}
              isManualPagination={true}
              onPageChange={(newPageIdx) => {
                setPagination(newPageIdx, pageSize);
              }}
              onPageSizeChange={(newPageSize) => {
                setPagination(0, newPageSize);
              }}
              data-qa="notification-management-table"
            />
          </Dashboard.TabPanel>
        </Dashboard.Tabs>
      </Dashboard>
    </>
  );
};

NotificationManagement.propTypes = {
  notificationRuleList: PropTypes.array,
  fetchNotificationRuleList: PropTypes.func,
  notificationRuleListLoading: PropTypes.bool,
  pageIdx: PropTypes.number,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  setPagination: PropTypes.func,
  isNextButtonDisabled: PropTypes.bool,
  deleteNotification: PropTypes.func,
  resetWizardState: PropTypes.func,
  allUserInputData: PropTypes.object,
  postNewNotificationRule: PropTypes.func,
  postNewNotificationRuleStatus: PropTypes.string,
  fetchIndividualNotification: PropTypes.func,
  editNotificationRuleApiStatus: PropTypes.string,
};

export default NotificationManagement;
