import { connect } from "react-redux";

import { FilterSection } from "components/search-bar/FilterSection";
import {
  selectStatusFilterOptions,
  selectLadFilterOptions,
  selectActiveExceptionsFilterOptions,
  selectBatteryLifeFilterOptions,
} from "pages/containertracking/modules/domain-data/ContainerTrackingSearchFilterSelectors";
import ContainerTrackingSearchBarState from "pages/containertracking/redux/ContainerTrackingSearchBarState";
import { FILTERS } from "../ContainerTrackingSearchFilterDefs";

const mapStateToProps = (state) => ({
  searchFilters:
    ContainerTrackingSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    ContainerTrackingSearchBarState.selectors.getHasSearchCriteriaChanged(
      state,
    ),
  statusFilterOptions: selectStatusFilterOptions(state),
  ladFilterOptions: selectLadFilterOptions(state),
  activeExceptionsFilterOptions: selectActiveExceptionsFilterOptions(state),
  batteryLifeOptions: selectBatteryLifeFilterOptions(state),
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = ContainerTrackingSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
