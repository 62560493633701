/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Text } from "components/atoms/Text.atom";
import { useTranslation } from "react-i18next";

export const EarlyArrivalOptions = (props) => {
  const { notificationDetailsObj, setNotificationDetailsObj } = props;

  const { t } = useTranslation("notification-management");
  const [daysLocal, setDaysLocal] = useState(
    notificationDetailsObj.notificationTriggerTimeObj.days,
  );
  const [hoursLocal, setHoursLocal] = useState(
    notificationDetailsObj.notificationTriggerTimeObj.hours,
  );
  const [minutesLocal, setMinutesLocal] = useState(
    notificationDetailsObj.notificationTriggerTimeObj.minutes,
  );

  const dropDownDayOptions = [...Array(32)].map((_, i) => `${i}`);
  const dropDownHourOptions = [...Array(24)].map((_, i) => `${i}`);
  const dropDownMinuteOptions = [...Array(4)].map((_, i) => `${i * 15}`);

  const timeUnits = {
    days: t("notification-management:[[[count]]] day", { count: daysLocal }),
    hours: t("notification-management:[[[count]]] hour", { count: hoursLocal }),
    minutes: t("notification-management:[[[count]]] minute", {
      count: minutesLocal,
    }),
  };

  const getTimeEarlyText = () => {
    let timeEarlyOptions = [];
    if (daysLocal > 0) {
      timeEarlyOptions.push(timeUnits.days);
    }
    if (hoursLocal > 0) {
      timeEarlyOptions.push(timeUnits.hours);
    }
    if (minutesLocal > 0) {
      timeEarlyOptions.push(timeUnits.minutes);
    }

    switch (timeEarlyOptions.length) {
      case 1:
        return timeEarlyOptions[0];
      case 2:
        return timeEarlyOptions.join(" and ");
      case 3:
        return `${timeEarlyOptions[0]}, ${timeEarlyOptions[1]}, and ${timeEarlyOptions[2]}`;
      default:
        return timeUnits.minutes; // Handle additional cases if needed
    }
  };

  return (
    <>
      <Text block css={{ marginTop: "0.5rem" }}>
        {t(
          "notification-management:When should this notification be triggered?",
        )}
      </Text>
      <div
        css={{
          display: "flex",
          alignItems: "left",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "1em",
          marginTop: "0.2rem",
        }}
      >
        <Select
          css={{ marginTop: "0.2rem" }}
          options={dropDownDayOptions.map((dayOption) => ({
            value: dayOption,
            label: t("notification-management:[[[dayOption]]] Days", {
              dayOption,
            }),
          }))}
          onChange={(option) => {
            setDaysLocal(parseInt(option.value));
            setNotificationDetailsObj({
              ...notificationDetailsObj,
              notificationTriggerTimeObj: {
                days: parseInt(option.value),
                hours: hoursLocal,
                minutes: minutesLocal,
              },
            });
          }}
          value={{
            value: daysLocal,
            label: t("notification-management:[[[daysLocal]]] Days", {
              daysLocal,
            }),
          }}
          data-qa="dayOption-select"
        />
        <Select
          css={{ marginTop: "0.2rem" }}
          options={dropDownHourOptions.map((hourOption) => ({
            value: hourOption,
            label: t("notification-management:[[[hourOption]]] Hours", {
              hourOption,
            }),
          }))}
          onChange={(option) => {
            setHoursLocal(parseInt(option.value));
            setNotificationDetailsObj({
              ...notificationDetailsObj,
              notificationTriggerTimeObj: {
                days: daysLocal,
                hours: parseInt(option.value),
                minutes: minutesLocal,
              },
            });
          }}
          value={{
            value: hoursLocal,
            label: t("notification-management:[[[hoursLocal]]] Hours", {
              hoursLocal,
            }),
          }}
          data-qa="hourOption-select"
        />
        <Select
          css={{ marginTop: "0.2rem" }}
          options={dropDownMinuteOptions.map((minuteOption) => ({
            value: minuteOption,
            label: t("notification-management:[[[minuteOption]]] Minutes", {
              minuteOption,
            }),
          }))}
          onChange={(option) => {
            setMinutesLocal(parseInt(option.value));
            setNotificationDetailsObj({
              ...notificationDetailsObj,
              notificationTriggerTimeObj: {
                days: daysLocal,
                hours: hoursLocal,
                minutes: parseInt(option.value),
              },
            });
          }}
          value={{
            value: minutesLocal,
            label: t("notification-management:[[[minutesLocal]]] Minutes", {
              minutesLocal,
            }),
          }}
          data-qa="minuteOption-select"
        />
        <Text block css={{ marginTop: "0.1rem" }}>
          {t(
            "notification-management: At least [[[timeEarly]]] before delivery window open",
            { timeEarly: getTimeEarlyText() },
          )}
        </Text>
      </div>
    </>
  );
};
EarlyArrivalOptions.propTypes = {
  notificationDetailsObj: PropTypes.object,
  setNotificationDetailsObj: PropTypes.func,
};
