/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { LinkPanels } from "components/templates/LinkPanels.template";
import { LinkPanel } from "components/molecules/LinkPanel.molecule";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";

/** Displays all available tools in a grid. */
export const InternalTools = ({ redirectToRoute }) => {
  const { t } = useTranslation("internal-tools");

  useSetTitleOnMount(t("internal-tools:Internal Tools"));

  return (
    <LinkPanels>
      <LinkPanel
        title={t("internal-tools:VIN ETA Validator")}
        description={t(
          "internal-tools:Tool to provide visibility into the historical data that goes into a specific VIN ETA calculation.",
        )}
        onClick={() => redirectToRoute("VIN_ETA_VALIDATOR")}
      />
      <LinkPanel
        title={t("internal-tools:Shipment ETA Validator")}
        description={t(
          "internal-tools:Tool to troubleshoot and view details of Shipment ETA calculation.",
        )}
        onClick={() => redirectToRoute("SHIPMENT_ETA_VALIDATOR")}
      />
      <LinkPanel
        title={t("internal-tools:Freeze ETA")}
        description={t(
          "internal-tools:Tool to freeze and unfreeze VIN ETA calculations.",
        )}
        onClick={() => redirectToRoute("FREEZE_ETA")}
      />
      <LinkPanel
        title={t("internal-tools:DPU Admin Tools")}
        description={t(
          "internal-tools:Tool to provide admins the ability to perform multiple functions with VIN's on DPU platform.",
        )}
        onClick={() => redirectToRoute("DPU_ADMIN_TOOL")}
      />
      <LinkPanel
        title={t("internal-tools:Debug ETA Tool")}
        description={t(
          "internal-tools:Tool to provide a current test ETA call from a set of query string parameters.",
        )}
        onClick={() => redirectToRoute("DEBUG_ETA_TOOL")}
      />
      <LinkPanel
        title={t("internal-tools:Set PartView ETA")}
        description={t("internal-tools:Tool to Set PartView ETA calculations")}
        onClick={() => redirectToRoute("FREEZE_PARTVIEW_ETA_TOOL")}
      />
      <LinkPanel
        title={t("internal-tools:Set Manual ETA Range Tool")}
        description={t(
          "internal-tools:Tool to provide admins the ability to update Manual ETA Ranges.",
        )}
        onClick={() => redirectToRoute("SET_MANUAL_ETA_RANGE_TOOL")}
      />
    </LinkPanels>
  );
};

InternalTools.propTypes = {
  redirectToRoute: PropTypes.func.isRequired,
};
