/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";
// import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Components
import { MediaQueries } from "components/responsive";
import { SavedSearchPanel } from "components/organisms/SavedSearchPanel.organism";
import { ExceptionCountGroup } from "components/molecules/ExceptionCountGroup.molecule";

// Utils
import { getIconData } from "pages/finishedvehicle/utils/exceptions.utils";

// State
import ContainerTrackingSearchBarState from "../../../redux/ContainerTrackingSearchBarState";
import ContainerTrackingSavedSearchState from "../../../redux/ContainerTrackingSavedSearchState";
import ContainerTrackingSavedSearchCardsState from "pages/containertracking/redux/ContainerTrackingSavedSearchCardsState";
import { getExceptionChartData } from "components/utils/exceptions.utils";
import { DonutChart } from "components/molecules/DonutChart.molecule";

const { searchEntities } = ContainerTrackingSearchBarState.actionCreators;
const { loadSavedSearch } = ContainerTrackingSavedSearchState.actionCreators;

export const ContainerTrackingSavedSearch = ({
  savedSearch,
  onEditClick,
  isDeleting,
}) => {
  const { t } = useTranslation("container-tracking");
  const unaccountedText = t("container-tracking:Unaccounted");
  const excessiveDwellText = t("container-tracking:Excessive Dwell");
  const noUpdateThirtyDaysText = t("container-tracking:No Update 30+ Days");
  const offRouteText = t("container-tracking:Off Route");
  const batteryLevelText = t("container-tracking:Battery Level");

  const unaccountedFvId = "UNACCOUNTED";
  const excessiveDwellFvId = "EXCESSIVE_DWELL";
  const noUpdateThirtyDaysfvId = "NO_UPDATE_30+_DAYS";
  const OffRouteFvId = "OFF_ROUTE";
  const batteryLevelFvId = "BATTERY_LEVEL";

  const assemblyText = t("container-tracking:Onsite at Assembly");
  const supplierText = t("container-tracking:Onsite at Supplier");
  const odcText = t("container-tracking:Onsite at ODC");
  const undefinedText = t("container-tracking:Onsite at Undefined Location");
  const inTransitText = t("container-tracking:In Transit");
  // const noUpdatesText = t("container-tracking:No Update 30+ Days");
  const dispatch = useDispatch();

  const savedSearchData = useSelector(
    ContainerTrackingSavedSearchCardsState.selectors.getSavedSearchCardData(
      savedSearch?.id,
    ),
  );
  const isLoading = savedSearchData?.isLoading ?? true;
  const data = savedSearchData?.data;

  useEffect(() => {
    dispatch(
      ContainerTrackingSavedSearchCardsState.actionCreators.fetchSavedSearchCardData(
        savedSearch,
      ),
    );
  }, [dispatch, savedSearch]);

  const loadFullSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(searchEntities());
  };

  const handleExceptionClick = (exception) => {
    dispatch(
      loadSavedSearch({
        search: { ...savedSearch.search, activeExceptions: [exception.name] },
      }),
    );
    dispatch(searchEntities());
  };

  const handleOnSiteClick = (onsiteData) => {
    dispatch(
      loadSavedSearch({
        search: { ...savedSearch.search, ...onsiteData.filterData },
      }),
    );
    dispatch(searchEntities());
  };

  const getExceptionCount = (exceptionFvId) => {
    const exceptiondata = data?.exceptions?.filter(
      (exception) => exception.fvId === exceptionFvId,
    );
    return exceptiondata && exceptiondata[0]?.count
      ? exceptiondata[0].count
      : 0;
  };

  const getLatestExceptionCount = (exceptionFvId) => {
    const exceptiondata = data?.exceptions?.filter(
      (exception) => exception.fvId === exceptionFvId,
    );
    return exceptiondata && exceptiondata[0]?.latest_count
      ? exceptiondata[0].latest_count
      : 0;
  };

  const getExceptionGroups = ({ lastestExceptionOnly }) => {
    const batteryLevelCount = lastestExceptionOnly
      ? getLatestExceptionCount(batteryLevelFvId)
      : getExceptionCount(batteryLevelFvId);

    return [
      {
        id: 1,
        name: unaccountedText,
        count: lastestExceptionOnly
          ? getLatestExceptionCount(unaccountedFvId)
          : getExceptionCount(unaccountedFvId),
      },
      {
        id: 2,
        name: excessiveDwellText,
        count: lastestExceptionOnly
          ? getLatestExceptionCount(excessiveDwellFvId)
          : getExceptionCount(excessiveDwellFvId),
        icon: {
          style: { height: "30px", width: "30px" },
          ...getIconData("Excessive Dwell"),
        },
      },
      {
        id: 3,
        name: noUpdateThirtyDaysText,
        count: lastestExceptionOnly
          ? getLatestExceptionCount(noUpdateThirtyDaysfvId)
          : getExceptionCount(noUpdateThirtyDaysfvId),
        icon: {
          color: Colors.highlight.ORANGE,
        },
      },
      {
        id: 4,
        name: offRouteText,
        count: lastestExceptionOnly
          ? getLatestExceptionCount(OffRouteFvId)
          : getExceptionCount(OffRouteFvId),
        icon: {
          color: Colors.highlight.YELLOW,
        },
      },
      {
        id: 5,
        name: batteryLevelText,
        count: batteryLevelCount,
        icon: {
          ...getIconData("Battery Level"),
        },
        percentage:
          isLoading || !data?.count
            ? 0
            : ((batteryLevelCount / data?.count) * 100).toFixed(1),
      },
    ];
  };

  const getOnsiteCounts = () => {
    return [
      {
        id: 1,
        name: assemblyText,
        count: data?.onsiteAtAssembly ?? 0,
        filterData: { lad: ["Assembly"] },
      },
      {
        id: 2,
        name: supplierText,
        count: data?.onsiteAtSupplier ?? 0,
        filterData: { lad: ["Supplier"] },
      },
      {
        id: 3,
        name: odcText,
        count: data?.onsiteAtODC ?? 0,
        filterData: { lad: ["Distribution"] },
      },
      {
        id: 4,
        name: undefinedText,
        count: data?.onsiteAtUndefined ?? 0,
        filterData: { lad: ["Undefined"] },
      },
      {
        id: 5,
        name: inTransitText,
        count: data?.inTransit ?? 0,
        filterData: { status: ["In Transit"] },
      },
    ];
  };

  const totalExceptionGroups = getExceptionGroups({
    lastestExceptionOnly: false,
  });
  const totalLatestExceptionGroups = getExceptionGroups({
    lastestExceptionOnly: true,
  });

  const totalOnsiteCounts = getOnsiteCounts();

  const totalLatestExceptionsCount = useMemo(
    () => totalLatestExceptionGroups.reduce((acc, curr) => acc + curr.count, 0),
    [totalLatestExceptionGroups],
  );

  const chartData = getExceptionChartData(
    totalLatestExceptionGroups,
    data?.count,
    totalLatestExceptionsCount,
  );

  return (
    <SavedSearchPanel
      savedSearch={savedSearch}
      onSearchClick={loadFullSavedSearch}
      onEditClick={onEditClick}
      isLoading={isLoading}
      isDeleting={isDeleting}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "column-reverse",
            justifyContent: "between",
          },
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "space-around",
          },
        }}
      >
        <ExceptionCountGroup
          exceptions={totalOnsiteCounts}
          vertical
          countStyles={{
            width: "100%",
          }}
          clickHandler={handleOnSiteClick}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            [MediaQueries.mediumAndUp]: {
              flexDirection: "row",
              justifyContent: "center",
            },
            [MediaQueries.extraLarge]: {
              flexDirection: "column",
              justifyContent: "start",
            },
          }}
        >
          <DonutChart
            data={chartData}
            handler={loadFullSavedSearch}
            label={t("container-tracking:Total Containers")}
            total={data?.count}
          />
          <ExceptionCountGroup
            exceptions={totalExceptionGroups}
            exceptionDirection={"column"}
            clickHandler={handleExceptionClick}
            countStyles={{
              width: "100%",
              padding: "0.5em",
            }}
          />
        </div>
      </div>
    </SavedSearchPanel>
  );
};

ContainerTrackingSavedSearch.propTypes = {
  // These come from the getCardProps in ContainerTrackingSavedSearchesPanel
  savedSearch: PropTypes.object,
  onEditClick: PropTypes.func,
  isDeleting: PropTypes.bool,
};
