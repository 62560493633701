import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";
import { getFilteredTriplegsByType } from "../utils/tripleg.utils";
import { transformActualTripleg } from "../../partview/utils/tripleg.utils";

const STORE_MOUNT_POINT = "partViewEntityDetails";

const packageDetailUrl = () => apiUrl("/partview/app/search");
const baseUrl = (trackingNumber) =>
  apiUrl(`/partview/app/package-container/${trackingNumber}`);
const getShipmentUrl = (carrierScac, shipmentId) => {
  return apiUrl(`/shipping-ng/carriers/${carrierScac}/shipments/${shipmentId}`);
};
const packageReferenceUrl = (trackingNumber) =>
  apiUrl(`/partview/app/package-container/${trackingNumber}/reference`);

const packageDetailsDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "packageDetails",
  null,
);
const packageWatchDuck = buildFetchDuck(STORE_MOUNT_POINT, "packageWatch");
const partsDuck = buildFetchDuck(STORE_MOUNT_POINT, "parts");
const packageReferencesDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "packageReferences",
  null,
);

// Actions
const getScopedActionName = (actionName) =>
  `${STORE_MOUNT_POINT}/${actionName}`;

const FETCH_ALL_TRIPLEG_DETAILS = getScopedActionName(
  "FETCH_ALL_TRIPLEG_DETAILS",
);
const FETCH_TRIPLEG_DETAILS = getScopedActionName("FETCH_TRIPLEG_DETAILS");
const RECEIVE_TRIPLEG_DETAILS = getScopedActionName("RECEIVE_TRIPLEG_DETAILS");
const RECIEVE_PARTVIEW_GENERATED_ACTUAL_TRIPLEG_DETAILS = getScopedActionName(
  "RECIEVE_PARTVIEW_GENERATED_ACTUAL_TRIPLEG_DETAILS",
);
const RECEIVED_ALL_TRIPLEG_DETAILS = getScopedActionName(
  "RECEIVED_ALL_TRIPLEG_DETAILS",
);
const CLEAR_ALL_TRIPLEG_DETAILS = getScopedActionName(
  "CLEAR_ALL_TRIPLEG_DETAILS",
);
const FETCH_COMMENTS = getScopedActionName("FETCH_COMMENTS");
const FETCH_COMMENTS_FAILED = getScopedActionName("FETCH_COMMENTS_FAILED");
const RECEIVE_COMMENTS = getScopedActionName("RECEIVE_COMMENTS");
const SUBMIT_NEW_COMMENT = getScopedActionName("SUBMIT_NEW_COMMENT");
const RECEIVE_NEW_COMMENT = getScopedActionName("RECEIVE_NEW_COMMENT");
const SUBMIT_NEW_COMMENT_FAILED = getScopedActionName(
  "SUBMIT_NEW_COMMENT_FAILED",
);
const CANCEL_NEW_COMMENT = getScopedActionName("CANCEL_NEW_COMMENT");
const SET_IS_UPDATING_COMMENT = getScopedActionName("SET_IS_UPDATING_COMMENT");
const SET_IS_UPDATING_COMMENT_FAILED = getScopedActionName(
  "SET_IS_UPDATING_COMMENT_FAILED",
);
const CANCEL_UPDATE_COMMENT = getScopedActionName("CANCEL_UPDATE_COMMENT");

// Action Creators
function fetchPackageDetails(trackingNumber) {
  const url = packageDetailUrl();
  return (dispatch) => {
    dispatch(
      packageDetailsDuck.fetch(
        url,
        {
          params: { trackingNumber, pageNumber: 0, pageSize: 1 },
          headers: {
            "x-time-zone": moment.tz.guess(),
            Accept: "application/json;version=DETAILS",
          },
        },
        (data) => {
          const detail = data?.data?.[0] ?? null;

          if (
            detail?.ActiveExceptionList &&
            !Array.isArray(detail.ActiveExceptionList)
          ) {
            detail.ActiveExceptionList = [];
          }

          return detail;
        },
      ),
    );
  };
}

function setWatchPackage(trackingNumber, watch = true, onRequestFinished) {
  let url = baseUrl(trackingNumber) + "/watch";
  return async (dispatch) => {
    const config = {
      method: watch ? "POST" : "DELETE",
      data: { watch },
    };

    try {
      await dispatch(packageWatchDuck.fetch(url, config));
    } catch {
      console.error("Failed to update watch on package.");
    }

    if (onRequestFinished) {
      onRequestFinished();
    }
  };
}

function fetchComments(trackingNumber, pageNumber, pageSize) {
  let url = `${baseUrl(trackingNumber)}/comment`;
  if (pageNumber && pageSize) {
    url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  return (dispatch) => {
    let clearData = false;
    if (pageNumber === 0) {
      clearData = true;
      dispatch({
        type: FETCH_COMMENTS,
      });
    }

    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_COMMENTS,
          payload: { comments: response.data, clearData },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: FETCH_COMMENTS_FAILED,
        });
      });
  };
}

function addComment(trackingNumber, data) {
  let url = `${baseUrl(trackingNumber)}/comment`;

  return (dispatch) => {
    const fakeCommentId = uuidv4();
    dispatch({
      type: SUBMIT_NEW_COMMENT,
      payload: { data: { ...data, id: fakeCommentId, isAdding: true } },
    });

    const requestData = { text: data.text, shared_with: data.shared_with };

    return axios
      .post(url, requestData)
      .then((response) => {
        dispatch({
          type: RECEIVE_NEW_COMMENT,
          // Force the new comment to be marked as read
          payload: { data: { ...response.data, read: true }, fakeCommentId },
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_NEW_COMMENT_FAILED,
          payload: { fakeCommentId },
        });
      });
  };
}

function markCommentsRead(trackingNumber, datetime) {
  let url = `${baseUrl(trackingNumber)}/comment/read`;

  // Pass in the current date and time as the last read date
  const data = {
    date_until: moment.utc(datetime).format("YYYY-MM-DDTHH:mm:ss.SSS"),
  };

  return (_ignored_dispatch) => {
    return axios
      .post(url, data)
      .then((_ignored_response) => {
        // Do nothing
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

function updateComment(trackingNumber, commentId, updatedData) {
  let url = `${baseUrl(trackingNumber)}/comment/${commentId}`;

  return (dispatch) => {
    dispatch({
      type: SET_IS_UPDATING_COMMENT,
      payload: { isUpdating: true, commentId, updatedData },
    });

    const requestData = {
      text: updatedData.text,
      shared_with: updatedData.shared_with,
    };

    return axios
      .patch(url, requestData)
      .then((response) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT,
          payload: { isUpdating: false, commentId, updatedData: response.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT_FAILED,
          payload: { commentId },
        });
      });
  };
}

function cancelUpdateComment(trackingNumber) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_UPDATE_COMMENT,
      payload: { commentId: trackingNumber },
    });
  };
}

function cancelAddComment(fakeCommentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_NEW_COMMENT,
      payload: { fakeCommentId },
    });
  };
}

function fetchParts(trackingNumber) {
  let url = baseUrl(trackingNumber) + "/part";

  return (dispatch) => {
    dispatch(partsDuck.fetch(url));
  };
}

function fetchReferences(trackingNumber) {
  let url = packageReferenceUrl(trackingNumber);

  return (dispatch) => {
    dispatch(packageReferencesDuck.fetch(url));
  };
}

function fetchShipmentsForTriplegs(triplegs) {
  const cancelSource = axios.CancelToken.source();
  return (dispatch) => {
    if (_.isEmpty(triplegs)) {
      dispatch({
        type: CLEAR_ALL_TRIPLEG_DETAILS,
      });
      return;
    }

    dispatch({
      type: FETCH_ALL_TRIPLEG_DETAILS,
    });

    const requests = triplegs
      .filter((leg) => leg.type.toLowerCase() === "actual")
      .map((leg, i) => {
        const shipmentId = leg?.creatorShipmentId;
        const carrierScac = leg?.carrierInformation?.carrierScac;

        if (shipmentId) {
          dispatch({
            type: FETCH_TRIPLEG_DETAILS,
            payload: { id: shipmentId },
          });

          const url = getShipmentUrl(carrierScac, shipmentId);

          return axios
            .get(url, {
              params: { format: "DETAIL" },
              cancelToken: cancelSource.token,
            })
            .then((response) => {
              dispatch({
                type: RECEIVE_TRIPLEG_DETAILS,
                payload: {
                  id: shipmentId,
                  data: { ...response.data, shipmentLegSequence: i + 1 },
                },
              });
            })
            .catch((error) => {
              if (error.response?.status === 404) {
                dispatch({
                  type: RECIEVE_PARTVIEW_GENERATED_ACTUAL_TRIPLEG_DETAILS,
                  payload: {
                    id: shipmentId,
                    data: {
                      ...transformActualTripleg(leg),
                      shipmentLegSequence: i + 1,
                    },
                  },
                  error,
                });
              }
              console.error(error);
            });
        }

        // .map() always needs to return
        return null;
      });

    // Wait for all requests to resolve
    axios
      .all(requests)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch({ type: RECEIVED_ALL_TRIPLEG_DETAILS });
      });

    return cancelSource;
  };
}

const clearAllTriplegDetails = () => (dispatch) =>
  dispatch({
    type: CLEAR_ALL_TRIPLEG_DETAILS,
  });

// Selectors
const getPackageDetails = (state) =>
  packageDetailsDuck.selectors.getData(state);

const getIsSetWatchPackageLoading = (state) =>
  packageWatchDuck.selectors.getData(state)?.isLoading;

const getOrders = (state) => {
  const packageDetails = getPackageDetails(state);
  const tripLeg = packageDetails.data?.Triplegs ?? [];
  const orders = _.chain(tripLeg)
    // Get a single array of all orders
    .map((leg) => leg.orders ?? [])
    .flatten()
    // Filter out duplicates by `OrderNumber` but keep most recent
    .orderBy(["OrderTimestamp", "OrderNumber"], ["desc"])
    .uniqBy("OrderNumber")
    .value();
  return orders;
};

const getReferences = (state) =>
  packageReferencesDuck.selectors.getData(state)?.data?.data;
const getParts = (state) => partsDuck.selectors.getData(state)?.data?.data;
const getMeta = (state) => partsDuck.selectors.getData(state)?.data?.meta;

const getReferencesIsLoading = (state) =>
  packageReferencesDuck.selectors.getData(state)?.isLoading ?? false;

const getPartsIsLoading = (state) =>
  partsDuck.selectors.getData(state)?.isLoading ?? false;

const getShipmentsForTriplegs = (state) =>
  state[STORE_MOUNT_POINT].shipments ?? {};

const getIsShipmentsLoaded = (state) =>
  state[STORE_MOUNT_POINT].isShipmentsLoaded ?? false;

const getCoordinates = (state) => {
  const shipments = getShipmentsForTriplegs(state);
  const packageDetails = getPackageDetails(state);
  const plannedLegs = getFilteredTriplegsByType(
    packageDetails.data?.Triplegs,
    "planned",
  );
  const activeExceptions = packageDetails.data?.ActiveExceptionList ?? [];

  // Coordinates from shipments (telematics, milestones, etc).
  const coordsFromShipments = Object.values(shipments)
    .map((shipment) => {
      return shipment.current_location.updates ?? [];
    })
    .flat();

  // Coordinates from PartView milestones.
  const coordsFromUpdates = plannedLegs
    .map((leg) => leg.updates ?? [])
    .flat()
    .map((update) => ({
      time: update.eventDatetime,
      db_time: update.datetime,
      latitude: update.latitude,
      longitude: update.longitude,
      city: update.city,
      state: update.state,
    }));

  // Coordinates from exceptions.
  const coordsFromActiveExceptions = activeExceptions.map((exception) => ({
    time: exception.eventDatetime,
    db_time: exception.datetime,
    latitude: exception.latitude,
    longitude: exception.longitude,
    city: exception.city,
    state: exception.state,
  }));

  return coordsFromShipments
    .concat(coordsFromUpdates)
    .concat(coordsFromActiveExceptions);
};

const getIsFetchingComments = (state) =>
  state[STORE_MOUNT_POINT].isFetchingComments ?? false;

const getComments = (state) => state[STORE_MOUNT_POINT].comments ?? {};

const getIsLoading = (state) => {
  return getPackageDetails(state)?.isLoading || !getIsShipmentsLoaded(state);
};

// Reducer
const initialState = {
  isFetchingComments: false,
  comments: {
    totalPages: 0,
    totalCount: 0,
    totalCountUnread: 0,
    data: [],
  },
  shipments: null,
  isShipmentsLoaded: false,
};

const partViewEntityDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENTS:
      return {
        ...state,
        isFetchingComments: true,
        comments: initialState.comments,
      };

    case FETCH_COMMENTS_FAILED:
      return {
        ...state,
        isFetchingComments: false,
      };

    case RECEIVE_COMMENTS:
      return {
        ...state,
        isFetchingComments: false,
        comments: {
          ...action.payload.comments,
          // The infinite-scrolling package requires the data to be consolidated,
          // not just the current page. So do a union of the new data with the old data.
          // Ignore the above if this is the first page of data.
          // Note: We use unionWith to avoid duplicates in case new comments were added
          // since the last page was loaded.
          data: action.payload.clearData
            ? action.payload.comments.data
            : _.unionWith(
                state.comments.data,
                action.payload.comments.data,
                (d1, d2) => d1.id === d2.id,
              ),
        },
      };

    case SUBMIT_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: [action.payload.data].concat(state.comments.data),
        },
      };

    case RECEIVE_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          // Manually increase the total count of comments
          totalCount: state.comments.totalCount + 1,
          // If this is the first comment, update the number of pages to 1
          totalPages:
            state.comments.totalPages === 0
              ? state.comments.totalPages + 1
              : state.comments.totalPages,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId ? action.payload.data : c,
          ),
        },
      };

    case SUBMIT_NEW_COMMENT_FAILED:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId
              ? { ...c, isAdding: false, isAddingFailed: true }
              : c,
          ),
        },
      };

    case CANCEL_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.filter(
            (c) => c.id !== action.payload.fakeCommentId,
          ),
        },
      };

    case SET_IS_UPDATING_COMMENT: {
      let commentList = state.comments.data ? [...state.comments.data] : [];
      if (action.payload.updatedData) {
        const updatedData = action.payload.updatedData;
        commentList = commentList.map((c) =>
          c.id === action.payload.commentId
            ? {
                ...c,
                ...updatedData,
                // Keep track of the original data in case we need to cancel the update.
                // Gets cleared on success.
                original: action.payload.isUpdating ? c : null,
              }
            : c,
        );
      }

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: action.payload.isUpdating,
              isUpdatingFailed: false,
            }
          : c,
      );

      return {
        ...state,
        comments: {
          ...state.comments,
          data: commentList,
        },
      };
    }

    case SET_IS_UPDATING_COMMENT_FAILED: {
      let commentList = state.comments.data ? [...state.comments.data] : [];

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: false,
              isUpdatingFailed: true,
            }
          : c,
      );

      return {
        ...state,
        comments: { ...state.comments, data: commentList },
      };
    }

    case CANCEL_UPDATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.commentId
              ? { ...c.original, isUpdating: false, isUpdatingFailed: false }
              : c,
          ),
        },
      };

    case FETCH_ALL_TRIPLEG_DETAILS:
      return {
        ...state,
        shipments: {},
        isShipmentsLoaded: false,
      };
    case FETCH_TRIPLEG_DETAILS:
      return state;
    case RECEIVE_TRIPLEG_DETAILS:
      return {
        ...state,
        shipments: {
          ...state.shipments,
          [action.payload.id]: action.payload.data,
        },
      };
    case RECIEVE_PARTVIEW_GENERATED_ACTUAL_TRIPLEG_DETAILS:
      return {
        ...state,
        shipments: {
          ...state.shipments,
          [action.payload.data.id]: action.payload.data,
        },
      };
    case RECEIVED_ALL_TRIPLEG_DETAILS:
      return {
        ...state,
        isShipmentsLoaded: true,
      };
    case CLEAR_ALL_TRIPLEG_DETAILS:
      return {
        ...state,
        shipments: null,
        isShipmentsLoaded: false,
      };
    default:
      return state;
  }
};

const PartViewEntityDetailsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchPackageDetails,
    setWatchPackage,
    fetchParts,
    fetchReferences,
    fetchShipmentsForTriplegs,
    clearAllTriplegDetails,
    fetchComments,
    addComment,
    markCommentsRead,
    updateComment,
    cancelUpdateComment,
    cancelAddComment,
  },
  selectors: {
    getPackageDetails,
    getIsSetWatchPackageLoading,
    getOrders,
    getParts,
    getReferences,
    getMeta,
    getPartsIsLoading,
    getReferencesIsLoading,
    getIsShipmentsLoaded,
    getShipmentsForTriplegs,
    getCoordinates,
    getIsFetchingComments,
    getComments,
    getIsLoading,
  },
  reducer: chainReducers([
    packageDetailsDuck.reducer,
    packageWatchDuck.reducer,
    partsDuck.reducer,
    packageReferencesDuck.reducer,
    partViewEntityDetailsReducer,
  ]),
};

export default PartViewEntityDetailsState;
