/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useState, useCallback, useEffect } from "react";
import { Modal, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logo from "assets/logos/fv_mark.svg";
import googleIcon from "../assets/icons/google.png";
import arubaIcon from "../assets/icons/aruba.png";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

import DetailsTable from "components-old/DetailsTable";
import ReceivedTimeCell from "components/organisms/bootstrap-table/Cell/ReceivedTimeCell";
import ReportedTimeCell from "components/organisms/bootstrap-table/Cell/ReportedTimeCell";
import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { Icon, IconType } from "components/atoms/Icon.atom";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "components-old/modal-elems";

import { localizedDateFormatter } from "../utils/date-time";
import { translateCurrentPositionType } from "shared/utils/entity.utils";
import Colors from "styles/colors";
import {
  getReferenceByName,
  getReferenceValueByName,
} from "../utils/response-utils";

import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";

const VerifyCell = ({
  groupedPositionUpdateIds = [],
  solutionId,
  entityId,
  isLoading,
  error,
  updateEntityPositionUpdate,
  clearUpdateEntityPositionUpdateError,
  references = [],
}) => {
  const { t } = useTranslation("components");

  const [showModal, setShowModal] = useState(false);
  const hideModal = useCallback(() => {
    clearUpdateEntityPositionUpdateError();
    setShowModal(false);
  }, [clearUpdateEntityPositionUpdateError, setShowModal]);

  const [isAssetLocationExpected, setIsAssetLocationExpected] = useState(null);
  const [distanceOff, setDistanceOff] = useState(null);

  useEffect(() => {
    if (!isLoading && _.isNil(error)) {
      hideModal();
    }
  }, [isLoading, error, hideModal]);

  // Return UI based on state.
  const verifiedState = getReferenceByName(references, "verified_state")?.value;

  if (verifiedState === "accepted") {
    return (
      <td style={{ display: "flex", alignItems: "center" }}>
        <Icon
          type={IconType.LocalImage}
          src={logo}
          style={{ height: "1.75em" }}
        />
        <Text>{t("components:Verified")}</Text>
      </td>
    );
  } else if (verifiedState === "rejected") {
    return (
      <td>
        <Text>{t("components:Rejected")}</Text>
      </td>
    );
  }

  const isSaveDisabled =
    isLoading ||
    _.isNil(isAssetLocationExpected) ||
    (!isAssetLocationExpected && _.isNil(distanceOff));

  const onSaveClick = () => {
    // For each position update ID in the group,
    // - update with the verification state and supporting data.
    groupedPositionUpdateIds.forEach((id) => {
      updateEntityPositionUpdate(solutionId, entityId, id, {
        isAssetLocationExpected,
        distanceOff,
      });
    });
  };

  return (
    <td
      onClick={(e) => {
        // Stops click events happening in this cell from bubbling.
        e.stopPropagation();
      }}
    >
      <Button variant="success" onClick={(e) => setShowModal(true)}>
        <Text>{t("components:Verify")}</Text>
      </Button>
      <Modal show={showModal} onHide={hideModal}>
        <ModalHeader
          title={t("components:Coordinate Verification")}
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY,
          }}
        />
        <ModalBody style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          {!_.isNil(error) ? (
            <Alert variant="danger">
              <Alert.Heading>
                {t(
                  "components:An error occured attempting to verify this coordinate.",
                )}
              </Alert.Heading>
            </Alert>
          ) : null}

          <Form.Group
            as="fieldset"
            onChange={(e) => {
              setIsAssetLocationExpected(e.target.value === "yes");
              setDistanceOff(null);
            }}
            css={{ marginBottom: "1rem" }}
          >
            <Form.Label>
              <Text>
                {t("components:Is the asset where you expected it to be?")}
              </Text>
            </Form.Label>
            <Form.Check
              type="radio"
              id="asset-yes"
              name="asset-location-expected"
              value="yes"
              label={t("components:Yes")}
            />
            <Form.Check
              type="radio"
              id="asset-no"
              name="asset-location-expected"
              value="no"
              label={t("components:No")}
            />
          </Form.Group>
          {!_.isNil(isAssetLocationExpected) && !isAssetLocationExpected ? (
            <Form.Group
              as="fieldset"
              onChange={(e) => setDistanceOff(e.target.value)}
              css={{ marginBottom: "1rem" }}
            >
              <Form.Label className="display-block">
                <Text>
                  {t(
                    "components:How off was the asset from the displayed position?",
                  )}
                </Text>
              </Form.Label>
              <Form.Check
                type="radio"
                id="15-20-off"
                name="distance-off"
                value="15-20 feet off"
                label={t("components:15-20 feet off")}
              />
              <Form.Check
                type="radio"
                id="20-25-off"
                name="distance-off"
                value="20-25 feet off"
                label={t("components:20-25 feet off")}
              />
              <Form.Check
                type="radio"
                id="25-plus-off"
                name="distance-off"
                value="25+ feet off"
                label={t("components:25+ feet off")}
              />
            </Form.Group>
          ) : null}
        </ModalBody>
        <ModalFooter style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <Button
            style={{
              background: "white",
              borderColor: "#ccc",
              color: Colors.background.DARK_BLUE,
            }}
            onClick={hideModal}
          >
            {t("components:Cancel")}
          </Button>
          <Button
            variant="success"
            disabled={isSaveDisabled}
            onClick={onSaveClick}
          >
            {t("components:Save")}
          </Button>
        </ModalFooter>
      </Modal>
    </td>
  );
};

VerifyCell.propTypes = {
  groupedPositionUpdateIds: PropTypes.arrayOf(PropTypes.number),
  solutionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  updateEntityPositionUpdate: PropTypes.func.isRequired,
  clearUpdateEntityPositionUpdateError: PropTypes.func.isRequired,
  references: PropTypes.array.isRequired,
};

/* DEV-1163 Group the matching lat/long and display as single entries as a start-end time*/
/* DEV-1717 Only group the matching lat/long if they are consecutive by time */
function groupCoords(arr) {
  let result = [];
  let prev = {};

  arr.forEach((o, i) => {
    let coord;

    if (
      prev &&
      prev.latitude &&
      prev.longitude &&
      prev.latitude === o.latitude &&
      prev.longitude === o.longitude
    ) {
      result[result.length - 1].groupedIds.push(o.id);
      result[result.length - 1].start = o.time;
    } else {
      coord = Object.assign({}, o, {
        groupedIds: [o.id],
        start: o.time,
        end: o.time,
      });

      result.push(coord);
      prev = o;
    }
  });

  return result;
}

const getCurrentVehicleLocation = (t, coord) => {
  const positionType = translateCurrentPositionType(t, coord.positionType);

  const refs = coord.references;
  const locationName = getReferenceValueByName(refs, "locationName");
  const locationId = getReferenceValueByName(refs, "locationId");
  const geofenceName = getReferenceValueByName(refs, "geofenceName");

  if (positionType || locationName || locationId || geofenceName) {
    return [
      `${positionType ? positionType : ""}`,
      `${
        positionType && (locationName || locationId || geofenceName) ? " -" : ""
      }`,
      `${locationName ? " " + locationName : ""}`,
      `${locationId ? " (" + locationId + ")" : ""}`,
      `${geofenceName ? " " + geofenceName : ""}`,
    ].join("");
  } else {
    return "";
  }
};

export const CoordinatesTable = ({
  addCoordinate,
  selectedMapCoordinate,
  showIconColumn,
  showEventTimeColumn,
  showReceivedTimeColumn,
  showCityColumn,
  showStateColumn,
  showCityStateColumn,
  showCurrentVehicleLocationColumn,
  showFloorColumn,
  showVerifyColumn,
  verifyColumnCellProps,
  clearCoordinatesByType,
  coords,
  "data-qa": dataQa,
}) => {
  // Sort the time in descending order, so most
  // recent update is at top of list
  const sortedCoords = _.orderBy(coords, ["time"], ["desc"]);

  // H1-190: Always expand the first day of coordinates.

  const [expandedRows, setExpandedRows] = useState(
    sortedCoords.length ? [0] : [],
  );

  useEffect(() => {
    clearCoordinatesByType([MapCoordinateType.SELECTED_COORDINATE]);
  }, [clearCoordinatesByType]);

  const { t } = useTranslation("components");

  const dataQaStr = dataQa ? `${dataQa}-` : "";

  const onRowClick = (row, ind) => {
    const { latitude, longitude, time } = row;
    // Check if `elevationUnits` === "floor", if so, we want to add the floor to the call to selectCoordinate
    const elevationUnits = getReferenceByName(
      row.references,
      "elevationUnits",
    )?.value;

    let floor = null;
    if (elevationUnits?.toLowerCase() === "floor") {
      floor = getReferenceByName(row.references, "elevation")?.value;
    }

    // Get the radius for the coord. This displays a circle around the marker.
    let radius = parseFloat(
      getReferenceByName(row.references, "confidence_radius_meters")?.value,
      10,
    );
    if (isNaN(radius)) {
      radius = 0;
    }

    addCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
      latitude,
      longitude,
      time,
      ind,
      row,
      floor,
      radius,
    );
  };

  /* DEV-1163 Group the Coordinates table by date with expand/collapse */
  let rows = [];
  let coordsByDate = _.groupBy(sortedCoords, (c) =>
    localizedDateFormatter(c.time),
  );

  // Adjust the td column span depending on how many columns we're showing
  let colspan = 2; // We always show Latitude and Longitude columns
  [
    showIconColumn,
    showEventTimeColumn,
    showReceivedTimeColumn,
    showCityColumn,
    showStateColumn,
    showCityStateColumn,
    showCurrentVehicleLocationColumn,
    showFloorColumn,
    showVerifyColumn,
  ].forEach((item) => (colspan += item ? 1 : 0));

  Object.keys(coordsByDate).map((d, i) => {
    let coords = coordsByDate[d];
    rows.push(
      <tr
        key={i}
        onClick={() => {
          const newExRows = expandedRows.slice();
          const idx = newExRows.indexOf(i);
          idx > -1 ? newExRows.splice(idx, 1) : newExRows.push(i);
          setExpandedRows(newExRows);
        }}
        css={{
          ":hover": {
            cursor: "pointer",
            backgroundColor: `${Colors.highlight.LIGHT_GRAY} !important`,
          },
        }}
        data-qa={`rows-coordinates-table-${dataQaStr}parent`}
      >
        <td colSpan={colspan}>
          {expandedRows.includes(i) ? (
            <FaAngleDown style={{ marginRight: ".5em" }} />
          ) : (
            <FaAngleRight style={{ marginRight: ".5em" }} />
          )}
          <span>{d}</span>
        </td>
      </tr>,
    );

    let coordRows;

    if (expandedRows.includes(i)) {
      let grouped = groupCoords(coords);
      coordRows = grouped.map((coord, j) => {
        const ind = `${i}_${j}`;
        const isSelectedCoordinate = selectedMapCoordinate?.ind === ind;

        let telematicsPlatform = getReferenceByName(
          coord.references,
          "telematicsplatform",
        )?.value;
        let elevation = getReferenceByName(
          coord.references,
          "elevation",
        )?.value;

        const { bgColor, textColor, highlightBgColor, highlightTextColor } =
          isSelectedCoordinate
            ? {
                bgColor: `${Colors.background.DARK_TEAL} !important`,
                textColor: "#fff",
                highlightBgColor: `${Colors.highlight.DARK_TEAL} !important`,
                highlightTextColor: "#fff",
              }
            : {
                bgColor: Colors.background.LIGHT_GRAY,
                textColor: "inherit",
                highlightBgColor: `${Colors.highlight.LIGHT_GRAY} !important`,
                highlightTextColor: "inherit",
              };

        const currentVehicleLocation = showCurrentVehicleLocationColumn
          ? getCurrentVehicleLocation(t, coord)
          : null;

        return (
          <tr
            key={ind}
            onClick={() => onRowClick(coord, ind)}
            css={{
              ":hover": {
                cursor: "pointer",
                backgroundColor: highlightBgColor,
                color: highlightTextColor,
              },
              backgroundColor: bgColor,
              color: textColor,
            }}
            data-qa={`rows-coordinates-table-${dataQaStr}child`}
          >
            {showIconColumn ? (
              <td css={{ width: 36, textAlign: "right" }}>
                {telematicsPlatform === "google" ? (
                  <img src={googleIcon} width={16} height={16} alt="Google" />
                ) : null}
                {telematicsPlatform === "aruba" ? (
                  <img src={arubaIcon} width={16} height={16} alt="Aruba" />
                ) : null}
              </td>
            ) : null}
            {showEventTimeColumn ? <ReportedTimeCell coord={coord} /> : null}
            {showReceivedTimeColumn ? <ReceivedTimeCell coord={coord} /> : null}
            <td>{coord.latitude}</td>
            <td>{coord.longitude}</td>
            {showCurrentVehicleLocationColumn ? (
              <td>{currentVehicleLocation}</td>
            ) : null}
            {showCityColumn ? <td>{coord.city}</td> : null}
            {showStateColumn ? <td>{coord.state}</td> : null}
            {showCityStateColumn ? (
              <td>
                {coord.city}, {coord.state}
              </td>
            ) : null}
            {showFloorColumn ? <td>{elevation}</td> : null}
            {showVerifyColumn ? (
              <VerifyCell
                groupedPositionUpdateIds={coord.groupedIds}
                references={coord.references}
                {...verifyColumnCellProps}
              />
            ) : null}
          </tr>
        );
      });

      rows.push(coordRows);
    }
    return 0;
  });

  let headers = [];

  if (showIconColumn) {
    headers.push("");
  }
  if (showEventTimeColumn) {
    headers.push(t("components:Event Time"));
  }
  if (showReceivedTimeColumn) {
    headers.push(t("components:Received Date/Time"));
  }
  headers.push(t("components:Latitude"));
  headers.push(t("components:Longitude"));
  if (showCurrentVehicleLocationColumn) {
    headers.push(t("components:Current Location"));
  }
  if (showCityColumn) {
    headers.push(t("components:City"));
  }
  if (showStateColumn) {
    headers.push(t("components:State"));
  }
  if (showCityStateColumn) {
    headers.push(t("components:City, State"));
  }

  if (showFloorColumn) {
    headers.push(t("components:Floor"));
  }
  if (showVerifyColumn) {
    headers.push(t("components:Verify?"));
  }

  return <DetailsTable headers={headers} rows={rows} />;
};

CoordinatesTable.propTypes = {
  coords: PropTypes.array,
  selectedMapCoordinate: PropTypes.object,
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  showIconColumn: PropTypes.bool,
  showEventTimeColumn: PropTypes.bool,
  showReceivedTimeColumn: PropTypes.bool,
  showCityColumn: PropTypes.bool,
  showStateColumn: PropTypes.bool,
  showCityStateColumn: PropTypes.bool,
  showCurrentVehicleLocationColumn: PropTypes.bool,
  showFloorColumn: PropTypes.bool,
  showVerifyColumn: PropTypes.bool,
  verifyColumnCellProps: PropTypes.shape({
    updateEntityPositionUpdate: PropTypes.func,
    clearUpdateEntityPositionUpdateError: PropTypes.func,
    solutionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool,
    error: PropTypes.object,
  }),
  "data-qa": PropTypes.string,
};

CoordinatesTable.defaultProps = {
  coords: [],
  showIconColumn: false,
  showEventTimeColumn: true,
  showReceivedTimeColumn: true,
  showCityColumn: true,
  showStateColumn: true,
  showCityStateColumn: false,
  showCurrentVehicleLocationColumn: false,
  showFloorColumn: false,
  showVerifyColumn: false,
  verifyColumnCellProps: {
    updateEntityPositionUpdate: _.noop,
    clearUpdateEntityPositionUpdateError: _.noop,
    solutionId: null,
    entityId: null,
    isLoading: false,
    error: null,
  },
};
