import axios from "axios";
import _ from "lodash";
import moment from "moment";

import apiUrl from "api-url";
import {
  SEARCH_CATEGORIES,
  FILTERS,
} from "../components/search/DealerPartView.searchOptions";

import { PackageStatusOption } from "../utils/filter.utils";

const STORE_MOUNT_POINT = "dealerPartViewSavedSearchCards";

const getScopedActionName = (actionName) =>
  `${STORE_MOUNT_POINT}/${actionName}`;
const FETCH_SAVED_SEARCH_CARD_DATA = getScopedActionName(
  "FETCH_SAVED_SEARCH_CARD_DATA",
);
const RECEIVE_SAVED_SEARCH_CARD_DATA = getScopedActionName(
  "RECIEVE_SAVED_SEARCH_CARD_DATA",
);

const formatResponse = (response) => {
  return {
    active: response.data?.data?.active ?? 0,
    delivered: response.data?.data?.delivered ?? 0,
    total_delivered: response.data?.data?.total_delivered ?? 0,
    available_for_pickup: response.data?.data?.available_for_pickup ?? 0,
    backorder: response.data?.data?.backorder ?? 0,
    created: response.data?.data?.created ?? 0,
    delayed: response.data?.data?.delayed ?? 0,
    in_route: response.data?.data?.in_route ?? 0,
  };
};

// Using search categories and filters, get the query strings from the queryBuilder
const getSavedSearchQueryString = (savedSearch) => {
  // Clone the search object (to not affect the passed in one)
  const searchObj = _.cloneDeep(savedSearch.search);

  let category = null;
  let categoryValue = null;
  for (let categoryDef of SEARCH_CATEGORIES) {
    if (
      searchObj[categoryDef.queryKey] !== null &&
      typeof searchObj[categoryDef.queryKey] === "string"
    ) {
      category = categoryDef;
      categoryValue = searchObj[category.queryKey];
    }
  }

  let filterValues = searchObj;
  if (!_.isNil(category)) {
    filterValues = _.omit(filterValues, category.queryKey);
  }

  let qs = "";

  if (category && categoryValue) {
    qs += category.queryBuilder(category.queryKey, categoryValue);
  }
  const filterValueKeys = Object.keys(filterValues);
  for (let filterDef of FILTERS) {
    // Handle an array of QSPs for NFilterButton.
    if (
      Array.isArray(filterDef.queryKey) &&
      filterValueKeys.some((r) => filterDef.queryKey.includes(r))
    ) {
      qs += filterDef.queryBuilder(filterDef.queryKey, filterValues);
    }

    // Handle usual filter value.
    if (!_.isNil(filterValues[filterDef.queryKey])) {
      qs += filterDef.queryBuilder(
        filterDef.queryKey,
        filterValues[filterDef.queryKey],
      );
    }
  }

  return qs;
};

// Object that will hold cancel callbacks for the requests made in the fetch
const tokens = {};
// Allows us to know if the error is from a cancelation in the catch of the promise
const cancelMessage = "CANCELED";

const fetchSavedSearchCardData = (savedSearch, includeAPU) => {
  // If we saved tokens from a previous request, cancel those requests
  if (tokens[savedSearch.id]) {
    tokens[savedSearch.id].cancelRequest(cancelMessage);
  }

  // Reset tokens for this saved search
  tokens[savedSearch.id] = {};

  //Default lifecycleStates for shipper and dealer for organizations that doesn't required Available For Pickup(APU).
  const lifecycleOptionsParamList = [
    PackageStatusOption.CREATED_OR_PACKAGED.value,
    PackageStatusOption.IN_ROUTE.value,
    PackageStatusOption.DELAYED.value,
    PackageStatusOption.DELIVERED.value,
  ];

  //Include Available For Pickup(APU) to lifecycleState for organizations that requires.
  if (includeAPU) {
    lifecycleOptionsParamList.push(
      PackageStatusOption.AVAILABLE_FOR_PICKUP.value,
    );
  }

  return (dispatch) => {
    dispatch({ type: FETCH_SAVED_SEARCH_CARD_DATA, id: savedSearch.id });

    // Clone the saved search object (to not affect the passed in one)
    // and override the lifeCycleState parameter
    let clonedSavedSearch = _.cloneDeep(savedSearch);

    const isBatch = !_.isNil(clonedSavedSearch.search.batch);

    // Build the parameters and add batch if applicable
    const params = new URLSearchParams(getSavedSearchQueryString(savedSearch));
    params.set("status", "active");
    if (
      !savedSearch.search.hasOwnProperty("lifecycleState") ||
      savedSearch.search?.lifecycleState?.length === 0
    ) {
      //To format lifecycleState qsp value into "Created/Packaged,In Route,Delayed,Available for Pickup"
      params.set("lifecycleState", lifecycleOptionsParamList.join(","));
    }

    if (isBatch) {
      params.append("batchType", clonedSavedSearch.search.batch.batch_type);
    }

    // Batch searches use POST with data in the body and query string parameters
    // Regular searches use GET with query string parameters
    const request = axios({
      method: isBatch ? "POST" : "GET",
      url: apiUrl(`/partview/app/search?${params.toString()}`),
      data: isBatch
        ? {
            batchList: clonedSavedSearch.search.batch.batch_list
              .split(",")
              .slice(0, -1),
          }
        : undefined,
      headers: {
        Accept: "application/json;version=PARTSELLER_COUNT",
        "x-time-zone": moment.tz.guess(),
      },
      // Create a cancel token for this request and save it in the tokens object
      cancelToken: new axios.CancelToken((cancel) => {
        tokens[savedSearch.id].cancelRequest = cancel;
      }),
    });

    return request
      .then((response) => {
        dispatch({
          type: RECEIVE_SAVED_SEARCH_CARD_DATA,
          id: savedSearch.id,
          data: formatResponse(response),
        });
      })
      .catch((error) => {
        if (error.message !== cancelMessage) {
          console.log(error);
        }
      });
  };
};

const getSavedSearchCardData = (id) => (state) =>
  state[STORE_MOUNT_POINT][id] ?? { data: null, isLoading: false };

const reducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SAVED_SEARCH_CARD_DATA:
      return {
        ...state,
        [action.id]: {
          data: null,
          isLoading: true,
        },
      };
    case RECEIVE_SAVED_SEARCH_CARD_DATA:
      return {
        ...state,
        [action.id]: {
          data: action.data,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

const DealerPartViewSavedSearchCardsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: { fetchSavedSearchCardData },
  selectors: { getSavedSearchCardData },
  reducer: reducer,
};

export default DealerPartViewSavedSearchCardsState;
