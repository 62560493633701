/** @jsxImportSource @emotion/react */

//External Packages
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";

import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";

//Components
import { Text, FontSize } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon } from "components/atoms/Icon.atom";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import { DateTime } from "components/atoms/DateTime.atom";
import { MadChicletCSS as Chiclet } from "components/chiclets";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { AlertMeToggle } from "shared/components/molecules/AlertMeToggle.molecule";

import { getArrivalWindowText } from "modules/shipment-detail/ArrivalWindow";
import {
  extractLastReportedAt,
  getLoadedStatusDisplayValue,
  getBillOfLadingNumbers,
} from "modules/shipment-detail/ShipmentUtils";
import { CountryName } from "modules/location/components/CountryName";
import EditShipmentState from "modules/shipment-detail/EditShipmentState";
import ShipmentSubscriptionState from "modules/shipment-detail/ShipmentSubscriptionState";
import {
  getIconData,
  getShipmentExceptionEnumFromName,
  useEtaTimeCategory,
} from "pages/shipments/utils/exception.utils";
import { ShipmentExceptionType } from "shared/constants/shipments.const";
import Colors from "styles/colors";

import {
  useShipmentTranslation,
  useShipmentExceptionTranslation,
} from "shared/hooks/useShipmentTranslation";
import { useEtaTranslations } from "shared/hooks/useEtaTranslations";
import { Mode, ShipmentException } from "shared/constants/shipments.const";

const ActionsCellRenderer = ({ row, value }) => {
  const dispatch = useDispatch();
  const { isUserSubscribed, watched, onActionClick, onActionFinished } = value;

  return (
    <div
      key={row.original.id}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
      }}
    >
      <WatchToggle
        checked={watched ?? false}
        onChange={(newWatchValue) => {
          if (onActionClick) {
            onActionClick(row.original);
          }
          dispatch(
            EditShipmentState.actionCreators.setWatchShipment(
              row.original,
              newWatchValue,
              () => {
                if (onActionFinished) {
                  onActionFinished(row.original);
                }
              },
            ),
          );
        }}
        iconSize={FontSize.size24}
        color={Colors.nav.NAVY}
        checkedColor={Colors.highlight.YELLOW}
      />
      <AlertMeToggle
        checked={isUserSubscribed ?? false}
        onModalOpen={() => {
          if (onActionClick) {
            onActionClick(row.original);
          }
        }}
        onModalHide={() => {
          if (onActionFinished) {
            onActionFinished(row.original);
          }
        }}
        subscribeeDetails={row.original}
        displayedId={row.original.creator_shipment_id}
        subscriptionState={ShipmentSubscriptionState}
      />
    </div>
  );
};

ActionsCellRenderer.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
      creator_shipment_id: PropTypes.string,
    }),
  }),
  value: PropTypes.shape({
    watched: PropTypes.bool,
    isUserSubscribed: PropTypes.bool,
    onActionClick: PropTypes.func,
    onActionFinished: PropTypes.func,
  }),
};

const TimeAndDateSplit = ({ dateTime }) => {
  const [date, time] = dateTime.split(/ (.+)/);
  return (
    <div>
      <span>{date}</span>
      <br />
      <b>{time}</b>
    </div>
  );
};

TimeAndDateSplit.propTypes = {
  dateTime: PropTypes.string.isRequired,
};

const StatusCellRenderer = (props) => {
  const {
    activeStatus,
    currentStatus,
    exception,
    currentLocation,
    actualDelivery,
    mode,
    isMultileg,
    isBackOrder,
    statusName,
    loadedStatus,
    t,
  } = props.value;

  const { getTranslatedStatus, getTranslatedStatusNameByName } =
    useShipmentTranslation();
  const { getTranslatedShipmentException } = useShipmentExceptionTranslation();

  const reportedAt = extractLastReportedAt(
    activeStatus,
    currentStatus,
    exception,
    currentLocation,
    actualDelivery,
    mode,
    t,
  );

  const isRailMode = mode === "Rail";
  const loadedStatusDisplay = getLoadedStatusDisplayValue(loadedStatus, t);
  let reportedDetailedInfo = null;

  // H1-1851: If we have information reported, we should show more data that
  // would be different depending on the shipment mode
  if (reportedAt.formattedLastTime) {
    // Rail shipment should present information in one way
    if (isRailMode) {
      reportedDetailedInfo = (
        <React.Fragment>
          <TimeAndDateSplit dateTime={reportedAt.formattedLastTime} />
          <div>{reportedAt.formattedLastCity}</div>
          {statusName ? (
            <div>{getTranslatedStatusNameByName(statusName)}</div>
          ) : null}
        </React.Fragment>
      );
    } else if (!isMultileg) {
      // Non-multimodal/crossborder should present information in another way
      reportedDetailedInfo = (
        <React.Fragment>
          <div>{reportedAt.formattedLastTime}</div>
          <div>{reportedAt.formattedLastCity}</div>
        </React.Fragment>
      );
    }
  }

  const exceptionType = getShipmentExceptionEnumFromName(exception);
  const exceptionIconData = getIconData(exceptionType);

  //SH-7926 - Add backorder to Details and Shipment Search Results
  const backOrderExceptionData = isBackOrder
    ? getIconData(ShipmentExceptionType.BACKORDER)
    : {};

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ fontWeight: "bold", marginLeft: "0.25em" }}>
        {getTranslatedStatus(activeStatus)} {getTranslatedStatus(currentStatus)}
        {isRailMode && loadedStatus && (
          <div css={{ fontWeight: "normal" }}>{loadedStatusDisplay}</div>
        )}
      </div>
      <div
        css={{
          color: exceptionIconData.color,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "0.5em",
        }}
      >
        <div css={{ fontSize: "large" }}>
          {exception && <Icon {...exceptionIconData} />}
        </div>
        <div css={{ marginLeft: "0.25em" }}>
          {exception ? getTranslatedShipmentException(exception) : null}
        </div>
      </div>

      {isBackOrder ? (
        <div
          css={{
            color: backOrderExceptionData.color,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div css={{ fontSize: "large" }}>
            <Icon {...backOrderExceptionData} />
          </div>
          <div css={{ marginLeft: "0.25em" }}>
            {getTranslatedShipmentException(ShipmentException.BACKORDER)}
          </div>
        </div>
      ) : null}

      {reportedDetailedInfo && (
        <div>
          <div
            css={{
              marginLeft: "0.25em",
              marginTop: "0.5em",
              fontWeight: "bold",
            }}
          >
            {reportedAt.label}
          </div>
          <div css={{ marginLeft: "0.5em" }}>{reportedDetailedInfo}</div>
        </div>
      )}
    </div>
  );
};

StatusCellRenderer.propTypes = {
  value: PropTypes.object.isRequired,
};

const CarrierCellRenderer = (props) => {
  const {
    mode,
    currentModeName,
    exception,
    carrier,
    currentRoad,
    serviceCode,
    trailerAndEquipmentNumbers = [],
    t,
  } = props.value;
  const { getTranslatedMode } = useShipmentTranslation();

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ marginLeft: "0.25em", marginBottom: "0.5em" }}>{carrier}</div>
      {currentRoad ? (
        <div css={{ marginLeft: "0.25em", marginBottom: "0.5em" }}>
          <b>{t("shipment-search:Current Road")}: </b>
          {currentRoad}
        </div>
      ) : null}
      {serviceCode ? (
        <div css={{ marginLeft: "0.25em", marginBottom: "0.5em" }}>
          {serviceCode}
        </div>
      ) : null}
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div css={{ marginLeft: "0.25em" }}>
          <Chiclet
            shipmentMode={mode}
            stopMode={currentModeName}
            activeException={exception}
            width={25}
            height={25}
          />
        </div>
        <div css={{ marginLeft: "0.5em", marginRight: "0.5em", width: "100%" }}>
          {getTranslatedMode(mode)}
        </div>
      </div>
      {trailerAndEquipmentNumbers.length > 0 ? (
        <div css={{ marginTop: "0.5em" }}>
          <Text bold>{t("shipment-search:Trailer/Equipment #")}</Text>
          <ShowMoreList
            title={t("shipment-search:Trailer/Equipment #")}
            list={trailerAndEquipmentNumbers}
            visibleItemCount={2}
          />
        </div>
      ) : null}
    </div>
  );
};

CarrierCellRenderer.propTypes = {
  value: PropTypes.object.isRequired,
};

const NullDatetimeRow = ({ label }) => {
  const { t } = useTranslation("shipment-search");

  return (
    <div css={{ color: "#999", display: "flex", flexDirection: "column" }}>
      <div
        css={{
          fontWeight: "bold",
          fontSize: "small",
          textDecoration: "underline",
        }}
      >
        {label}
      </div>
      {t("shipment-search:N/A")}
    </div>
  );
};

NullDatetimeRow.propTypes = {
  label: PropTypes.string,
};

const DatetimeRow = ({
  label,
  windowTextLine1,
  windowTextLine2,
  "data-qa": dataQa,
}) => {
  if (windowTextLine1) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          fontStyle: "normal",
          fontWeight: "bold",
        }}
        data-qa={dataQa}
      >
        <div
          css={{
            fontWeight: "bold",
            fontSize: "small",
            textDecoration: "underline",
          }}
        >
          {label}
        </div>
        {windowTextLine1}
        {windowTextLine2 ? (
          <div css={{ fontWeight: "normal" }}> {windowTextLine2}</div>
        ) : null}
      </div>
    );
  }

  return null;
};

DatetimeRow.propTypes = {
  label: PropTypes.string,
  windowTextLine1: PropTypes.string,
  windowTextLine2: PropTypes.string,
  "data-qa": PropTypes.string,
};

const RowForWindow = ({
  label,
  earliest,
  latest,
  isDateOnly,
  "data-qa": dataQa,
}) => {
  if (!(earliest || latest)) {
    return <NullDatetimeRow label={label} />;
  }

  const { windowTextLine1, windowTextLine2 } = getArrivalWindowText({
    earliest_arrival_datetime: earliest,
    latest_arrival_datetime: latest,
    isDateOnly: isDateOnly,
  });

  return (
    <DatetimeRow
      label={label}
      windowTextLine1={windowTextLine1}
      windowTextLine2={windowTextLine2}
      data-qa={dataQa}
    />
  );
};

RowForWindow.propTypes = {
  label: PropTypes.string,
  earliest: PropTypes.string,
  latest: PropTypes.string,
  isDateOnly: PropTypes.bool,
  "data-qa": PropTypes.string,
};

/* DEV-1545 LTL shipments displays dates only for Pickup & Delivery, no times.
    update: display times for Actual
*/
export const DateTimeWindowCellRenderer = (props) => {
  const {
    earliestExpected,
    latestExpected,
    arrival,
    departure,
    eta,
    frozenEta,
    mode,
    etaTimeCategory,
  } = props.value;

  const { t } = useTranslation("shipment-search");

  const { getTranslatedETA } = useShipmentTranslation();
  const { isEtaName } = useEtaTranslations();
  const etaTimeCategoryVals = useEtaTimeCategory(etaTimeCategory);

  const finalEta = !_.isNil(frozenEta) ? frozenEta : eta;
  const hasEtaName = isEtaName(finalEta);
  const displayScheduledDateOnly = mode === Mode.LTL;
  const displayEtaDateOnly = mode === Mode.PARCEL || mode === Mode.LTL;

  return (
    <div css={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
      <RowForWindow
        label={t("shipment-search:Scheduled")}
        earliest={earliestExpected}
        latest={latestExpected}
        isDateOnly={displayScheduledDateOnly}
        data-qa="scheduled-window"
      />
      <div css={{ height: "0.5em" }} />
      <RowForWindow
        label={t("shipment-search:Actual Arrival")}
        earliest={arrival}
        latest={null}
        isDateOnly={false}
        data-qa="actual-arrival"
      />
      <div css={{ height: "0.5em" }} />
      <RowForWindow
        label={t("shipment-search:Actual Departure")}
        earliest={departure}
        latest={null}
        isDateOnly={false}
        data-qa="actual-departure"
      />
      {!_.isNil(frozenEta) || (!arrival && eta) ? (
        <div css={{ display: "flex", flexDirection: "row" }}>
          <Text bold>{t("shipment-search:ETA")}:</Text>
          <Text css={{ marginLeft: 3 }}>
            {hasEtaName ? (
              getTranslatedETA(finalEta)
            ) : (
              <DateTime
                dateTime={finalEta}
                plain
                localize
                showTime={!displayEtaDateOnly}
              />
            )}
          </Text>
        </div>
      ) : null}
      {etaTimeCategoryVals ? (
        // Don't apply color because of color contrast issues.
        <Text>{etaTimeCategoryVals.longLabel}</Text>
      ) : null}
    </div>
  );
};

DateTimeWindowCellRenderer.propTypes = {
  value: PropTypes.object.isRequired,
};

const InterchangeRow = ({ references }) => {
  const { t } = useTranslation("shipment-search");

  if (references) {
    // Filter down to ETAI references in the future
    const interchangeRefs = references
      .filter(
        (r) =>
          _.includes(["ETAI"], r.qualifier) &&
          moment(_.split(r.value, "|")[3], "YYYY-MM-DD-HH:mm:SSS") > moment(),
      )
      .sort((r1, r2) => {
        const etai1 = _.split(r1.value, "|");
        const eta1 = moment(etai1[3], "YYYY-MM-DD-HH:mm:SSS");
        const etai2 = _.split(r2.value, "|");
        const eta2 = moment(etai2[3], "YYYY-MM-DD-HH:mm:SSS");

        return eta1.valueOf() - eta2.valueOf();
      });

    if (interchangeRefs.length > 0) {
      const etai = _.split(interchangeRefs[0].value, "|");
      let loc = `${etai[0]}, ${etai[1]}`;
      let eta = etai[3];
      let tz = etai[4];

      // DEV-1601: times were off by an hour.  moment() by default parses
      // the date in the local timezone.  We need to parse it in the timezone
      // provided in the ETAI, which can be abbreviated CT, etc.  Convert first..
      // Moment doesn't seem to understand CT and others.  EST might not even be
      // parsed.  Do what is safe and convert.
      let parsingTZ = tz;
      if (tz === "ET" || tz === "EST") {
        parsingTZ = "America/Detroit";
      } else if (tz === "CT" || tz === "CST") {
        parsingTZ = "America/Chicago";
      } else if (tz === "MT" || tz === "MST") {
        parsingTZ = "America/Denver";
      } else if (tz === "PT" || tz === "PST") {
        parsingTZ = "America/Los_Angeles";
      }
      const momentObj = moment
        .tz(eta, "YYYY-MM-DD-HH:mm:SSS", parsingTZ)
        .local();
      let interchangeTime = momentObj.format("HH:mm");
      // Since we convert to local time, now guess the local timezone and format
      // it as MST, EST, CST, etc. depending on the user's local timezone.  This
      // readout should then match what is in the Stops tab of the Shipment Detail
      // page, since that is also a datetime formatted to display in the user's
      // timezone (which is shown).  Show here for validation purposes.
      let localTZ = moment.tz(moment.tz.guess()).format("z");
      let nextInterchangeDisplayTime = interchangeTime + " " + localTZ;
      let nextInterchangeDisplayDate = momentObj.format("l");

      return (
        <div css={{ marginTop: "0.5em" }}>
          <div
            css={{
              fontSize: "small",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            {t("shipment-search:Next Interchange")}
          </div>
          <div>
            <span css={{ marginTop: "0.5em", fontWeight: "bold" }}>
              {nextInterchangeDisplayTime}
            </span>
            <br />
            <span>{nextInterchangeDisplayDate}</span>
          </div>
          <div css={{ marginTop: "0.5em", fontSize: "small" }}>{loc}</div>
        </div>
      );
    }
  }

  return null;
};

InterchangeRow.propTypes = {
  references: PropTypes.array,
};

const LocationCellRenderer = (props) => {
  const { name, city, state, postalCode, country, code, references } =
    props.value;

  return (
    <div css={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
      <div css={{ fontWeight: "bold" }}>{code}</div>
      <div css={{ marginBottom: "0.5em" }}>{name}</div>
      <div css={{ fontSize: "small" }}>
        {city}
        {city && (state || postalCode) ? ", " : ""}
        {state}
        {state && postalCode ? ", " : ""}
        {postalCode}
      </div>
      {country && <CountryName countryCode={country} />}
      <InterchangeRow references={references} />
    </div>
  );
};

LocationCellRenderer.propTypes = {
  value: PropTypes.object.isRequired,
};

const IdCellRenderer = (props) => {
  const {
    shipmentId,
    shipmentStopCount,
    routeId,
    billOfLadingNumbers,
    tripPlanNumber,
    railTrainId,
    commentsCount,
    t,
  } = props.value;
  return (
    <div>
      <div
        css={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {commentsCount && commentsCount > 0 ? (
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>
                {t("shipment-search:This shipment contains comments")}
              </Text>
            }
          >
            <Icon
              src={faCommentAlt}
              color={Colors.comments.unreadCommentIcon}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
        ) : null}
        <Text bold>{shipmentId}</Text>
      </div>
      <div>
        {shipmentStopCount > 2 ? t("shipment-search:Multi-Stop") : null}
      </div>
      {routeId ? (
        <div css={{ marginTop: "0.5em" }}>
          <Text bold block>
            {t("shipment-search:Route ID")}
          </Text>
          <Text block>{routeId}</Text>
        </div>
      ) : null}
      {tripPlanNumber ? (
        <div
          css={{
            marginTop: "0.5em",
          }}
        >
          <Text bold block>
            {t("shipment-search:Trip Plan ID")}
          </Text>
          <Text block>{tripPlanNumber}</Text>
        </div>
      ) : null}
      {billOfLadingNumbers?.length > 0 ? (
        <div css={{ marginTop: "0.5em" }}>
          <Text bold>{t("shipment-search:Bill of Lading")}</Text>
          <ShowMoreList
            title={t("shipment-search:Bill of Lading")}
            list={billOfLadingNumbers}
            visibleItemCount={2}
          />
        </div>
      ) : null}
      {railTrainId && (
        <div>
          <div
            css={{
              marginTop: "0.5em",
              fontWeight: "bold",
            }}
          >
            {t("shipment-search:Train ID")}
          </div>
          {railTrainId}
        </div>
      )}
    </div>
  );
};

IdCellRenderer.propTypes = {
  value: PropTypes.object.isRequired,
};

export const getColumns = ({
  t,
  // Click callback for watch table. Called when any action is clicked.
  onActionClick,
  // Callback for after an action finishes, usually to refresh data.
  onActionFinished,
  // Flag for search results in modal, e.g dashboard dwell widget modals.
  isForResultModal = false,
}) => {
  return [
    {
      id: "ACTIONS_COLUMN",
      Header: t("shipment-search:Actions"),
      accessor: (d) => {
        return {
          isUserSubscribed: d.user_has_alert_me_subscription,
          watched: d.watched,
          onActionClick,
          onActionFinished,
        };
      },
      Cell: ActionsCellRenderer,
      width: 68,
      centerAligned: true,
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: t("shipment-search:Shipment ID"),
      id: "creator_shipment_id",
      accessor: (d) => {
        return {
          shipmentId: d.creator_shipment_id,
          shipmentStopCount: d.shipment_stop_count,
          routeId: d.route_number,
          billOfLadingNumbers: getBillOfLadingNumbers(
            d.shipment_reference_details,
          ),
          tripPlanNumber: d.trip_plan_number,
          railTrainId: d.rail_train_id,
          commentsCount: d.comments_count,
          t: t,
        };
      },
      Cell: IdCellRenderer,
      minWidth: 150,
      disableSortBy: isForResultModal,
    },
    {
      Header: t("shipment-search:Status"),
      id: "current_status_ng",
      accessor: (shipment) => {
        return {
          activeStatus: shipment.active_status_ng,
          currentStatus: shipment.current_status_ng,
          exception: shipment.active_exceptions_ng,
          remainingDistance: shipment.remaining_distance,
          currentLocation: shipment.current_location,
          actualDelivery: shipment.destination_actual_arrival,
          mode: shipment.mode_name,
          isMultileg: shipment?.shipment_details?.is_multileg,
          isBackOrder: shipment?.shipment_details?.is_back_order,
          statusName: shipment.status_name,
          loadedStatus: shipment.loaded_status,
          t,
        };
      },
      Cell: StatusCellRenderer,
      disableSortBy: isForResultModal,
    },
    {
      Header: t("shipment-search:Carrier"),
      id: "carrier_name",
      accessor: (d) => {
        return {
          mode: d.mode_name,
          currentModeName: d.mode_name,
          exception: d.active_exceptions_ng,
          carrier: `${d.carrier_scac} - ${d.carrier_name}`,
          currentRoad: d.current_road_name,
          serviceCode: _.find(d.shipment_reference_details, {
            qualifier: "service_code",
          })?.value,
          // H1-3859: Only show unique values on the UI.
          //   To see all, the user can look at the References tab.
          trailerAndEquipmentNumbers: _.uniq(d.trailer_equipment_number ?? []),
          t: t,
        };
      },
      Cell: CarrierCellRenderer,
      disableSortBy: isForResultModal,
    },
    {
      Header: t("shipment-search:Origin"),
      id: "origin_name",
      accessor: (d) => {
        return {
          name: d.origin_name,
          city: d.origin_city,
          state: d.origin_state,
          postalCode: d.origin_postal_code,
          country: d.origin_country,
          code: d.origin_location_code,
          references: d.shipment_references,
        };
      },
      Cell: LocationCellRenderer,
      disableSortBy: isForResultModal,
    },
    {
      Header: t("shipment-search:Destination"),
      id: "destination_name",
      accessor: (d) => {
        return {
          name: d.destination_name,
          city: d.destination_city,
          state: d.destination_state,
          postalCode: d.destination_postal_code,
          country: d.destination_country,
          code: d.destination_location_code,
        };
      },
      Cell: LocationCellRenderer,
      disableSortBy: isForResultModal,
    },
    // DEV-256: for the expected arrival times (at origin and destination), the
    // field ids are still origin_earliest_arrival and destination_earliest_arrival.
    // However, we previously used "d.origin_earliest_arrival" and
    // "d.destination_earliest_arrival" as the data sources for these fields.
    // The ask here is to use latest time instead.
    {
      Header: t("shipment-search:Pickup"),
      id: "origin_earliest_arrival",
      accessor: (d) => {
        return {
          earliestExpected: d.origin_earliest_arrival,
          latestExpected: d.origin_latest_arrival,
          arrival: d.origin_actual_arrival,
          departure: d.origin_actual_departure,
          mode: d.mode_name,
        };
      },
      Cell: DateTimeWindowCellRenderer,
      disableSortBy: isForResultModal,
    },
    {
      Header: t("shipment-search:Delivery"),
      id: "destination_earliest_arrival",
      accessor: (d) => {
        return {
          earliestExpected: d.destination_earliest_arrival,
          latestExpected: d.destination_latest_arrival,
          arrival: d.destination_actual_arrival,
          departure: d.destination_actual_departure,
          eta: d.destination_calculated_eta,
          frozenEta: d.destination_frozen_eta,
          mode: d.mode_name,
          etaTimeCategory: d.eta_time_category,
        };
      },
      Cell: DateTimeWindowCellRenderer,
      // This column is sortable for the results modal.
      disableSortBy: false,
    },
  ];
};
