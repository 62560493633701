import { connect } from "react-redux";
import PartViewEntityDetailsState from "../redux/PartViewEntityDetailsState";
import PartViewSubscriptionState from "../redux/PartViewSubscriptionState";
import ShipmentsStatusState from "pages/shipments/redux/ShipmentsState";
import MapState from "modules/map/MapState";
import NotificationsState from "modules/notifications/NotificationsState";
import DomainDataState from "modules/domain-data/DomainDataState";
import AppConfigurationState from "shared/redux/AppConfigurationState";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { PartViewDetails } from "./PartView.Details.page";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import PartViewDeliverPackageState from "../redux/PartViewDeliverPackageState";
import ErrorState from "modules/errors/ErrorState";

function mapStateToProps(state) {
  return {
    trackingNumber: state.location.payload.trackingNumber,
    isLoading:
      // Show spinner if details or the page config is loading
      PartViewEntityDetailsState.selectors.getIsLoading(state) ||
      AppConfigurationState.selectors.getIsPageConfigLoading(state),
    packageDetails:
      PartViewEntityDetailsState.selectors.getPackageDetails(state),
    isSetWatchPackageLoading:
      PartViewEntityDetailsState.selectors.getIsSetWatchPackageLoading(state),
    orders: PartViewEntityDetailsState.selectors.getOrders(state),
    parts: PartViewEntityDetailsState.selectors.getParts(state),
    references: PartViewEntityDetailsState.selectors.getReferences(state),
    meta: PartViewEntityDetailsState.selectors.getMeta(state),
    partsIsLoading:
      PartViewEntityDetailsState.selectors.getPartsIsLoading(state),

    isShipmentsLoaded:
      PartViewEntityDetailsState.selectors.getIsShipmentsLoaded(state),
    shipmentsForTriplegs:
      PartViewEntityDetailsState.selectors.getShipmentsForTriplegs(state),
    coordinates: PartViewEntityDetailsState.selectors.getCoordinates(state),
    // Comments
    comments: PartViewEntityDetailsState.selectors.getComments(state),
    isFetchingComments:
      PartViewEntityDetailsState.selectors.getIsFetchingComments(state),
    // Map
    selectedMapCoordinate: MapState.selectors.getMapCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
    )(state),
    currentMapCoordinate: MapState.selectors.getMapCoordinate(
      MapCoordinateType.CURRENT_LOCATION_PACKAGE,
    )(state),
    // Subscription
    subscription: PartViewSubscriptionState.selectors.getSubscription(state),
    isSubscriptionLoading:
      PartViewSubscriptionState.selectors.getIsSubscriptionLoading(state),
    subscriptionRequestError:
      PartViewSubscriptionState.selectors.getSubscriptionRequestError(state),
    isSubscriptionUpdating:
      PartViewSubscriptionState.selectors.getIsSubscriptionUpdating(state),
    subscriptionUpdateSuccess:
      PartViewSubscriptionState.selectors.getSubscriptionUpdateSuccess(state),
    subscriptionUpdateError:
      PartViewSubscriptionState.selectors.getSubscriptionUpdateError(state),
    activeOrganization: getActiveOrganization(state),
    shipmentModes: DomainDataState.selectors.getShipmentModes(state),
    // Shipment comments
    shipmentComments: ShipmentsStatusState.selectors.getComments(state),
    isFetchingShipmentComments:
      ShipmentsStatusState.selectors.getIsFetchingComments(state),
    isBatchShipmentCommentInProgress:
      ShipmentsStatusState.selectors.getIsBatchCommentInProgress(state),
    isBatchShipmentCommentSuccessful:
      ShipmentsStatusState.selectors.getIsBatchCommentSuccessful(state),
    isBatchShipmentCommentFailed:
      ShipmentsStatusState.selectors.getIsBatchCommentFailed(state),
    // Deliver Package
    packageDeliveryEvent:
      PartViewDeliverPackageState.selectors.getPackageDeliveryEvent(state),
    isPackageDeliveryEventLoading:
      PartViewDeliverPackageState.selectors.getIsPackageDeliveryEventLoading(
        state,
      ),
    packageDeliveryEventRequestError:
      PartViewDeliverPackageState.selectors.getPackageDeliveryEventRequestError(
        state,
      ),
    packageDeliveryEventRequestSuccess:
      PartViewDeliverPackageState.selectors.getPackageDeliveryEventRequestSuccess(
        state,
      ),
    auth: getAuthorization(state),
    pageConfig: AppConfigurationState.selectors.getPageConfigForRoute(state),
  };
}

const {
  fetchPackageDetails,
  setWatchPackage,
  fetchParts,
  fetchReferences,
  fetchShipmentsForTriplegs,
  clearAllTriplegDetails,
  // Comments
  fetchComments,
  addComment,
  markCommentsRead,
  updateComment,
  cancelUpdateComment,
  cancelAddComment,
} = PartViewEntityDetailsState.actionCreators;
const { fetchNotification } = NotificationsState.actionCreators;
const { fetchSubscription, updateSubscription, subscribe, unsubscribe } =
  PartViewSubscriptionState.actionCreators;
const { addCoordinate, clearCoordinatesByType, clearAllCoordinates } =
  MapState.actionCreators;
const {
  fetchComments: fetchShipmentComments,
  addComment: addShipmentComment,
  cancelAddComment: cancelAddShipmentComment,
  updateComment: updateShipmentComment,
  cancelUpdateComment: cancelUpdateShipmentComment,
  markCommentsRead: markShipmentCommentsRead,
  addBatchComments: addBatchShipmentComments,
  clearBatchComments: clearBatchShipmentComments,
} = ShipmentsStatusState.actionCreators;
const { setPackageDeliveryEvent, resetSetPackageDeliveryEvent } =
  PartViewDeliverPackageState.actionCreators;
const { redirectTo404 } = ErrorState.actionCreators;

const actionCreators = {
  fetchPackageDetails,
  setWatchPackage,
  fetchParts,
  fetchReferences,
  fetchShipmentsForTriplegs,
  clearAllTriplegDetails,
  // Comments
  fetchComments,
  addComment,
  markCommentsRead,
  updateComment,
  cancelUpdateComment,
  cancelAddComment,
  fetchNotification,
  // Map
  addCoordinate,
  clearCoordinatesByType,
  clearAllCoordinates,
  // Subscription
  fetchSubscription,
  updateSubscription,
  subscribe,
  unsubscribe,
  // Shipment Comments
  fetchShipmentComments,
  addShipmentComment,
  cancelAddShipmentComment,
  updateShipmentComment,
  cancelUpdateShipmentComment,
  markShipmentCommentsRead,
  addBatchShipmentComments,
  clearBatchShipmentComments,
  // Package Delivery
  setPackageDeliveryEvent,
  resetSetPackageDeliveryEvent,
  // Error redirects
  redirectTo404,
};

export default connect(mapStateToProps, actionCreators)(PartViewDetails);
