import { createSelector } from "reselect";
import { createOptionsMapper } from "../../../../utils/selectors-utils";
import ContainerTrackingDomainData from "./ContainerTrackingDomainData";

export const selectStatusFilterOptions = createSelector(
  [ContainerTrackingDomainData.selectors.getStatus],
  createOptionsMapper({
    labelKey: (item) => item.filter_desc,
    valueKey: "filter_value",
  }),
);

export const selectLadFilterOptions = createSelector(
  [ContainerTrackingDomainData.selectors.getLad],
  createOptionsMapper({
    labelKey: (item) => item.filter_desc,
    valueKey: "filter_value",
  }),
);

export const selectActiveExceptionsFilterOptions = createSelector(
  [ContainerTrackingDomainData.selectors.getActiveExceptions],
  createOptionsMapper({
    labelKey: (item) => item.filter_desc,
    valueKey: "filter_value",
  }),
);

export const selectBatteryLifeFilterOptions = createSelector(
  [ContainerTrackingDomainData.selectors.getBatteryLife],
  createOptionsMapper({
    labelKey: (item) => item.filter_value,
    valueKey: "filter_value",
  }),
);
