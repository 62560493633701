/** @jsxImportSource @emotion/react */
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontSize } from "components/atoms/Text.atom";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import DamageViewWatchedSubmissionsWidgetState from "pages/damageview/redux/DamageViewWatchedSubmissionState";
import Colors from "styles/colors";
import {
  SubmissionIDCell,
  VinsCell,
  StatusCell,
  AssigneeCell,
  SubmitterCell,
  SubmissionDateCell,
  InspectionDateCell,
  ReportingLocationCell,
  LastMilestoneCell,
  getLastMilestones,
  getVins,
} from "pages/damageview/search/DamageView.Search.columns";

export const useWatchedSubmissionColumns = (
  solutionId,
  exportSubmissonData,
  userPreferences,
  toggleHandler = null,
) => {
  const { t } = useTranslation("damage-view");

  return [
    {
      Header: t("damage-view:Watch"),
      id: "watch",
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const dispatch = useDispatch();
        const submissionId = cellInfo.row.original.id;
        // API returns boolean in a string
        const watched = cellInfo.value === "true";
        return (
          <WatchToggle
            key={submissionId}
            checked={watched ?? false}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(newWatchValue) => {
              dispatch(
                DamageViewWatchedSubmissionsWidgetState.actionCreators.watchActions(
                  solutionId,
                  submissionId,
                  newWatchValue,
                ),
              );
              if (toggleHandler) {
                toggleHandler(submissionId);
              }
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("damage-view:Submission ID"),
      id: "submissionId",
      accessor: (obj) => {
        return {
          submissionId: obj?.id ?? "",
          external_id: obj?.external_id ?? "",
          exportPDFEntities: exportSubmissonData,
          userPreference: userPreferences,
        };
      },
      disableSortBy: true,
      disableResizing: true,
      Cell: SubmissionIDCell,
    },
    {
      Header: t("damage-view:VINs"),
      id: "vins",
      accessor: (obj) => {
        return {
          vinIds: obj?.vins ? getVins(obj.vins) : [],
        };
      },
      minWidth: 220,
      disableSortBy: true,
      Cell: VinsCell,
    },
    {
      Header: t("damage-view:Last Milestone"),
      id: "lastmilestone",
      accessor: (obj) => {
        return {
          vinIds: obj?.vins ? getVins(obj.vins) : [],
          milestones: obj?.vins ? getLastMilestones(obj.vins, t) : [],
        };
      },
      disableSortBy: true,
      width: 220,
      Cell: LastMilestoneCell,
    },
    {
      Header: t("damage-view:Status"),
      id: "status",
      accessor: (obj) => {
        return {
          status: obj?.status ?? "",
        };
      },
      disableSortBy: true,
      width: 100,
      Cell: StatusCell,
    },
    {
      Header: t("damage-view:Reporting Location"),
      id: "reportingLocation",
      accessor: (obj) => {
        return {
          isAtLocation: obj?.location?.toLowerCase() === "at location",
          reportingLocation: obj?.reportingLocation,
        };
      },
      disableSortBy: true,
      minWidth: 290,
      Cell: ReportingLocationCell,
    },
    {
      Header: t("damage-view:Assignee"),
      id: "assignee",
      accessor: (obj) => {
        return {
          assignee: obj?.assignee_email ?? "",
        };
      },
      disableSortBy: true,
      width: 300,
      Cell: AssigneeCell,
    },
  ];
};

export const useMySubmissionColumns = (
  solutionId,
  exportSubmissonData,
  userPreferences,
  toggleHandler,
) => {
  const { t } = useTranslation("damage-view");

  const defaultColumns = useWatchedSubmissionColumns(
    solutionId,
    exportSubmissonData,
    userPreferences,
    toggleHandler,
  );

  const results = [
    ...defaultColumns,
    {
      Header: t("damageview:Submitters"),
      id: "submitter",
      accessor: (obj) => {
        return {
          submitter: obj?.submitter_email ?? "",
          phone: obj?.submitter_phone ?? "",
          secondSubmitter: obj?.secondarySubmitterEmail ?? "",
          secondPhone: obj?.secondarySubmitterPhoneNumber ?? "",
        };
      },
      disableSortBy: true,
      width: 300,
      Cell: SubmitterCell,
    },
    {
      Header: t("damageview:Submission Date"),
      id: "submission_date",
      accessor: (obj) => {
        return {
          date: obj?.submission_date ?? "",
          status: obj?.status ?? "",
        };
      },
      Cell: SubmissionDateCell,
    },
    {
      Header: t("damageview-search:Inspection Date"),
      id: "inspectionDate",
      accessor: (obj) => {
        return {
          date: obj?.inspection_date ?? "",
        };
      },
      disableSortBy: true,
      Cell: InspectionDateCell,
    },
  ];

  return results;
};
