/** @jsxImportSource @emotion/react */

import { isCarrier } from "shared/utils/organizations.utils";
import {
  getBasicQueryString,
  getBasicQueryStringFilterValuePriority,
} from "components/search-bar/search-filter-query-strings";
import {
  getEmptyQueryString,
  getShipmentDateRangeQueryString,
  getMultiSelectQueryString,
  getLocationQueryString,
  getBasicWithStaticOptionsQueryString,
} from "components/search-bar/search-filter-query-strings";
import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import {
  DateRangeFilterButton,
  BatchFilterButton,
  AsyncSelectFilterButton,
  MultiSelectFilterButton,
} from "components/search-bar/FilterButtons";
import {
  batchCsvVinExample,
  batchCsvShipmentIDExample,
  batchCsvTrailerEquipmentNumberExample,
  batchCsvRouteIdExample,
  batchCsvPartNumberExample,
} from "components/search-bar/batch-comment-csv-data";
import { useLadsTranslation } from "modules/lads/utils/lads.utils";
// H1-5849 and ISS-8267
// Temporarily commenting out these imports used by commented out filters until some API issues are resolved.
import {
  originOptionsState,
  destinationOptionsState,
  billOfLadingNumberOptionsState,
  trailerAndEquipmentNumberOptionsState,
  stccCodeOptionsState,
  //serviceCodeOptionsState,
  proNumberOptionsState,
  routeNumberOptionsState,
  originCountryOptionsState,
  destinationCountryOptionsState,
  shipmentCreationSourceOptionsState,
} from "pages/shipments/components/search/Shipments.SearchFilterLoaders";
import {
  useShipmentTranslation,
  useShipmentExceptionTranslation,
} from "shared/hooks/useShipmentTranslation";
import { useRegionTranslation } from "shared/hooks/useRegionTranslation";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "shipment_id",
    label: (t) => t("shipment-search:Shipment ID"),
    placeholder: (t) => t("shipment-search:Search Shipment ID"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("creator_shipment_id", filterValue),
    property: "shipmentIDs",
  },
  {
    queryKey: "location",
    label: (t) => t("shipment-search:Location"),
    placeholder: (t) => t("shipment-search:Search Location"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("shipper_location_name", filterValue),
    loaderProperty: "searchLocations",
    property: "locations",
    loadingProperty: "locationsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "part_number",
    label: (t) => t("shipment-search:Part #"),
    placeholder: (t) => t("shipment-search:Search Part #"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("part_values", filterValue),
    property: null,
  },
  {
    queryKey: "trailer_number",
    label: (t) => t("shipment-search:Trailer/Equipment #"),
    placeholder: (t) => t("shipment-search:Search Trailer/Equipment #"),
    queryBuilder: (queryKey, filterValue) => {
      return getBasicQueryString(queryKey + ":contains", filterValue);
    },
    loaderProperty: "fetchTrailerAndEquipmentNumberOptions",
    property: "trailerAndEquipmentNumberOptions",
    loadingProperty: "isTrailerAndEquipmentNumberOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "route_id",
    label: (t) => t("shipment-search:Route ID"),
    placeholder: (t) => t("shipment-search:Search Route ID"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("route_number:contains", filterValue),
    property: "routeIDs",
  },
  {
    queryKey: "vin",
    label: (t) => t("shipment-search:VIN"),
    placeholder: (t) => t("shipment-search:Search VIN #"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("vin_values", filterValue),
    loaderProperty: "searchVins",
    property: "vins",
    loadingProperty: "vinsLoading",
  },
  // H1-5849 and ISS-8267
  // Temporarily commenting out this search category until some API issues are resolved.
  // Note: If re-enabling, don't forget to update the "everything" placeholder text.
  // {
  //   queryKey: "order_number",
  //   label: t => t("shipment-search:Order #"),
  //   placeholder: t => t("shipment-search:Search Order #"),
  //   queryBuilder: (queryParameter, filterValue) =>
  //     getBasicQueryString("sli_number", filterValue),
  //   property: "orderNumbers"
  // },
  {
    queryKey: "trip_plan_number",
    label: (t) => t("shipment-search:Trip Plan ID"),
    placeholder: (t) => t("shipment-search:Search Trip Plan ID"),
    queryBuilder: getBasicQueryString,
    property: "tripPlanNumbers",
  },
  {
    queryKey: "asset_id",
    label: (t) => t("shipment-search:Asset ID"),
    placeholder: (t) => t("shipment-search:Search Asset ID"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("asset_id_values", filterValue),
    property: "assetIDs",
  },
  {
    queryKey: "current_road",
    label: (t) => t("shipment-search:Current Road"),
    placeholder: (t) => t("shipment-search:Search Current Road"),
    // H1-1783: Use filterValue (Current Road ID) if available (user clicked
    // the typeahead option)
    queryBuilder: getBasicQueryStringFilterValuePriority,
    property: "carriers",
    itemLabelProperty: "name",
    itemValueProperty: "id",
  },
  {
    queryKey: "train_id",
    label: (t) => t("shipment-search:Train ID"),
    placeholder: (t) => t("shipment-search:Search Train ID"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("train_id", filterValue),
    property: "trainIDs",
  },
  // H1-5849 and ISS-8267
  // Temporarily commenting out this search category until some API issues are resolved.
  // Note: If re-enabling, don't forget to update the "everything" placeholder text.
  // {
  //   queryKey: "service_code",
  //   label: t => t("shipment-search:Service Code"),
  //   placeholder: t => t("shipment-search:Search Service Code"),
  //   queryBuilder: (queryParameter, filterValue) =>
  //     getBasicQueryString("ref:service_code:1", filterValue),
  //   property: "serviceCode",
  //   itemLabelProperty: "label",
  //   itemValueProperty: "value"
  // },
  {
    queryKey: "stcc_code",
    label: (t) => t("shipment-search:STCC Code"),
    placeholder: (t) => t("shipment-search:Search STCC Code"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchStccCodeOptions",
    property: "stccCodeOptions",
    loadingProperty: "isStccCodeOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "order_number",
    label: (t) => t("shipment-search:Order #"),
    placeholder: (t) => t("shipment-search:Search Order #"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("order_values", filterValue),
    property: "orderNumbers",
  },
  // H1-5849 and ISS-8267
  // Temporarily commenting out these search categories until some API issues are resolved.
  // Note: If re-enabling, don't forget to update the "everything" placeholder text.
  // {
  //   queryKey: "house_reference_number",
  //   label: t => t("shipment-search:House Reference #"),
  //   placeholder: t => t("shipment-search:Search House Reference #"),
  //   queryBuilder: getBasicQueryString,
  //   loaderProperty: "fetchHouseReferenceNumberOptions",
  //   property: "houseReferenceNumberOptions",
  //   loadingProperty: "isHouseReferenceNumberOptionsLoading",
  //   itemLabelProperty: "label",
  //   itemValueProperty: "value"
  // },
  // {
  //   queryKey: "bill_of_lading_number",
  //   label: t => t("shipment-search:Bill of Lading"),
  //   placeholder: t => t("shipment-search:Search Bill of Lading"),
  //   queryBuilder: getBasicQueryString,
  //   loaderProperty: "fetchBillOfLadingOptions",
  //   property: "billOfLadingOptions",
  //   loadingProperty: "isBillOfLadingOptionsLoading",
  //   itemLabelProperty: "label",
  //   itemValueProperty: "value"
  // },
  // {
  //   queryKey: "rack_type",
  //   label: t => t("shipment-search:Rack Type"),
  //   placeholder: t => t("shipment-search:Search Rack Type"),
  //   queryBuilder: getBasicQueryString
  // }
];

export const FILTERS = [
  {
    queryKey: "origin",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("shipment-search:Origin"),
    optionsState: originOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("origin_id", filterValue, { valueKey: "id" }),
  },
  {
    queryKey: "destination",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("shipment-search:Destination"),
    optionsState: destinationOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("destination_id", filterValue, { valueKey: "id" }),
  },
  {
    queryKey: "carrier",
    label: (t) => t("shipment-search:Carrier"),
    optionsGetter: (props) => props.carrierFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hidden: (props) => isCarrier(props.activeOrganization),
  },
  {
    queryKey: "pickup",
    label: (t) => t("shipment-search:Pickup Date"),
    Component: DateRangeFilterButton,
    queryBuilder: getShipmentDateRangeQueryString,
    dateTypeOptions: (t) => [
      {
        label: t("fv-vin-search:Scheduled"),
        value: "Scheduled",
      },
      { label: t("fv-vin-search:Actual"), value: "Actual" },
    ],
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "delivery",
    label: (t) => t("shipment-search:Delivery Date"),
    Component: DateRangeFilterButton,
    queryBuilder: getShipmentDateRangeQueryString,
    dateTypeOptions: (t) => [
      {
        label: t("fv-vin-search:Scheduled"),
        value: "Scheduled",
      },
      { label: t("fv-vin-search:Actual"), value: "Actual" },
      { label: t("fv-vin-search:ETA"), value: "ETA" },
    ],
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "mode",
    label: (t) => t("shipment-search:Mode"),
    optionsGetter: (props) => props.shipmentModeFilterOptions,
    Component: (props) => {
      const { getTranslatedMode } = useShipmentTranslation();

      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedMode(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicWithStaticOptionsQueryString("shipment_mode", filterValue),
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "location_type",
    label: (t) => t("shipment-search:Location Type"),
    optionsGetter: (props) => props.locationTypeFilterOptions,
    Component: (props) => {
      const { getTranslatedLadLobLabel } = useLadsTranslation();

      const translatedOptions = props.options.map((lad) => ({
        value: lad.default_id,
        label: getTranslatedLadLobLabel(lad),
      }));

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hidden: (props) => isCarrier(props.activeOrganization),
  },
  {
    queryKey: "status",
    label: (t) => t("shipment-search:Shipment Status"),
    optionsGetter: (props) => props.statusFilterOptions,
    Component: (props) => {
      const { getTranslatedStatus } = useShipmentTranslation();

      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedStatus(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicWithStaticOptionsQueryString("current_status_id", filterValue),
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  // DEV-1070 Shipment event filters will group 4 status ids into 2.
  {
    queryKey: "shipment_event",
    label: (t) => t("shipment-search:Last Shipment Event"),
    optionsGetter: (props) => props.shipmentEventFilterOptions,
    Component: (props) => {
      const { getTranslatedShipmentEvent } = useShipmentTranslation();
      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedShipmentEvent(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicWithStaticOptionsQueryString("current_status_id", filterValue),
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "active_exception",
    label: (t) => t("shipment-search:Active Exception"),
    optionsGetter: (props) => props.exceptionFilterOptions,
    Component: (props) => {
      const { getTranslatedShipmentException } =
        useShipmentExceptionTranslation();

      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedShipmentException(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicWithStaticOptionsQueryString("exception_id", filterValue),
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "shipment_type",
    label: (t) => t("shipment-search:Shipment Type"),
    optionsGetter: (props) => props.shipmentTypeFilterOptions,
    Component: (props) => {
      const { getTranslatedShipmentType } = useShipmentTranslation();
      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedShipmentType(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "line_of_business",
    label: (t) => t("shipment-search:Line Of Business"),
    optionsGetter: (props) => props.lineOfBusinessFilterOptions,
    Component: (props) => {
      const { getTranslatedShipmentLob } = useShipmentTranslation();
      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedShipmentLob(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "shipper",
    label: (t) => t("shipment-search:Shipper"),
    optionsGetter: (props) => props.shipperFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hidden: (props) => !isCarrier(props.activeOrganization),
  },
  {
    queryKey: "pro_number",
    label: (t) => t("shipment-search:Pro Number"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: proNumberOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "trailer_number_filter",
    label: (t) => t("shipment-search:Trailer/Equipment #"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: trailerAndEquipmentNumberOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("trailer_number", filterValue),
  },
  {
    queryKey: "route_id_filter",
    label: (t) => t("shipment-search:Route ID"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: routeNumberOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("route_number", filterValue),
  },
  // H1-5849 and ISS-8267
  // Temporarily commenting out this filter until some API issues are resolved.
  // {
  //   queryKey: "trip_plan_number",
  //   label: t => t("shipment-search:Trip Plan ID"),
  //   optionsGetter: props => props.tripPlanNumberFilterOptions,
  //   queryBuilder: getBasicWithStaticOptionsQueryString
  // },
  {
    queryKey: "loaded_status",
    label: (t) => t("shipment-search:Loaded Status"),
    optionsGetter: (props) => props.loadedStatusFilterOptions,
    Component: (props) => {
      const { getTranslatedLoadedStatus } = useShipmentTranslation();
      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedLoadedStatus(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "batch",
    label: (t) => t("shipment-search:Batch Search"),
    Component: BatchFilterButton,
    optionsGetter: (_ignored_props, t) => {
      return [
        {
          label: t("shipment-search:Shipment ID"),
          value: "shipment_id",
          description: t(
            "shipment-search:Enter Shipment IDs (Line Separated and exact shipment ID is required)",
          ),
          batchCsvExample: batchCsvShipmentIDExample,
        },
        {
          label: t("shipment-search:Trailer/Equipment #"),
          value: "trailer_number",
          batchCsvExample: batchCsvTrailerEquipmentNumberExample,
        },
        {
          label: t("shipment-search:Route ID"),
          value: "route_number",
          batchCsvExample: batchCsvRouteIdExample,
        },
        // H1-5849 and ISS-8267
        // Temporarily commenting out this batch search until some API issues are resolved.
        //{ label: t("shipment-search:Order #"), value: "order_number" },
        {
          label: t("shipment-search:VIN"),
          value: "vin",
          batchCsvExample: batchCsvVinExample,
        },
        {
          label: t("shipment-search:Part #"),
          value: "part_values",
          batchCsvExample: batchCsvPartNumberExample,
        },
        // H1-5849 and ISS-8267
        // Temporarily commenting out these batch searches until some API issues are resolved.
        // { label: t("shipment-search:Trip Plan ID"), value: "trip_plan_number" },
        // {
        //   label: t("shipment-search:Bill of Lading"),
        //   value: "bill_of_lading_number"
        // }
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
  // H1-5849 and ISS-8267
  // Temporarily commenting out this filter until some API issues are resolved.
  // {
  //   queryKey: "service_code",
  //   label: t => t("shipment-search:Service Code"),
  //   Component: AsyncSelectFilterButton,
  //   isMulti: true,
  //   optionsState: serviceCodeOptionsState,
  //   queryBuilder: (queryParameter, filterValue) =>
  //     getMultiSelectQueryString("ref:service_code:0", filterValue)
  // },
  {
    queryKey: "origin_country",
    label: (t) => t("shipment-search:Origin Country"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: originCountryOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("origin_country", filterValue),
  },
  {
    queryKey: "destination_country",
    label: (t) => t("shipment-search:Destination Country"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: destinationCountryOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("destination_country", filterValue),
  },
  {
    queryKey: "origin_region",
    label: (t) => t("shipment-search:Origin Region"),
    optionsGetter: (props) => props.regionFilterOptions,
    Component: (props) => {
      const { getTranslatedRegion } = useRegionTranslation();
      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedRegion(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "destination_region",
    label: (t) => t("shipment-search:Destination Region"),
    optionsGetter: (props) => props.regionFilterOptions,
    Component: (props) => {
      const { getTranslatedRegion } = useRegionTranslation();
      const translatedOptions = props.options.map((option) => {
        return {
          ...option,
          label: getTranslatedRegion(option.label),
        };
      });

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "stcc_code_filter",
    label: (t) => t("shipment-search:STCC Code"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: stccCodeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString("stcc_code", filterValue),
  },
  {
    queryKey: "bill_of_lading_number",
    label: (t) => t("shipment-search:Bill of Lading"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: billOfLadingNumberOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "saw_data_source",
    label: (t) => t("shipment-search:Shipment Creation Source"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: shipmentCreationSourceOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
];
