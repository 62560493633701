/** @jsxImportSource @emotion/react */
import { ChangeEvent, useEffect, useState } from "react";
import { FormControl, FormGroup, Modal } from "react-bootstrap";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { FlexRowDiv } from "styles/container-elements";
import Colors from "styles/colors";

interface AlertMessagesProps {
  responseStatusCode: string;
  showAlert: boolean;
  hideAlert: () => void;
}

interface BolTextProps {
  shipmentReferences: {
    qualifier: string;
    value: string;
  }[];
}

interface BillOfLadingModalProps {
  show: boolean;
  hide: () => void;
  updateShipment: (
    shipment: any,
    payload: BolTextProps,
    fetchShipmentDetails: () => void,
  ) => void;
  shipment: any;
  organizations: any[];
  clearEditShipmentStatus: () => void;
  fetchShipmentDetails: () => void;
  isSubmitLoading: boolean;
  errorMessages: string;
  responseStatusCode: string;
}

const AlertMessages = ({
  responseStatusCode,
  showAlert,
  hideAlert,
}: AlertMessagesProps) => {
  const { t } = useTranslation("shipment-details");

  return (
    <>
      {responseStatusCode ? (
        <Alert
          show={(responseStatusCode && showAlert) as boolean}
          onClose={() => hideAlert()}
          variant={
            responseStatusCode === "success"
              ? AlertVariant.Success
              : AlertVariant.Danger
          }
          dismissible
        >
          <Text block size={FontSize.size16}>
            {responseStatusCode === "error" &&
              t(
                "shipment-details:An error occurred updating the shipment with bill of lading value(s).",
              )}
            {responseStatusCode === "success" &&
              t(
                "shipment-details:Shipment has been updated with bill of lading value(s).",
              )}
          </Text>
        </Alert>
      ) : null}
    </>
  );
};

export const BillOfLadingModal = ({
  show,
  hide,
  updateShipment,
  shipment,
  fetchShipmentDetails,
  clearEditShipmentStatus,
  isSubmitLoading,
  errorMessages,
  responseStatusCode,
}: BillOfLadingModalProps) => {
  const { t } = useTranslation("shipment-details");
  const [bolText, setBolText] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  // Reset modal inputs when show changes
  useEffect(() => {
    setBolText("");
  }, [show]);

  useEffect(() => {
    if (responseStatusCode) {
      clearEditShipmentStatus();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (responseStatusCode) {
      setBolText("");
    }
    //eslint-disable-next-line
  }, [responseStatusCode]);

  const submitForm = () => {
    const formattedBolText = bolText.split(/\r\n|\n/);
    const payload: BolTextProps = {
      shipmentReferences: [],
    };

    formattedBolText.map((text: string) => {
      return payload.shipmentReferences.push({
        qualifier: "bol",
        value: text,
      });
    });

    updateShipment(shipment, payload, fetchShipmentDetails);
  };

  useEffect(() => {
    if (errorMessages || responseStatusCode) {
      setShowAlert(true);
    }
  }, [errorMessages, responseStatusCode]);

  const closeModal = () => {
    hide();
    setShowAlert(false);
  };

  return (
    <Modal backdrop="static" show={show} onHide={closeModal}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
      >
        <Modal.Title style={{ fontWeight: 300 }}>
          {t("shipment-details:Add Bill of Lading/Manifest Numbers")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <Text>
            {t("shipment-details:Bill of Lading/Manifest Numbers")} (
            {t("shipment-details:Line Separated")}):
          </Text>
          <FormControl
            as="textarea"
            value={bolText}
            rows={5}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setBolText(event?.target?.value ?? "")
            }
            placeholder={
              t(
                "shipment-details:Enter Bill of Lading/Manifest Numbers here. If adding more than one, enter each value line separated",
              ) ?? undefined
            }
          />
        </FormGroup>

        <AlertMessages
          responseStatusCode={responseStatusCode}
          showAlert={showAlert}
          hideAlert={() => setShowAlert(false)}
        />
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <FlexRowDiv>
          <Button
            variant="light"
            css={{ marginRight: "0.5em" }}
            onClick={closeModal}
          >
            {t("shipment-details:Cancel")}
          </Button>
          <Button
            variant="success"
            onClick={submitForm}
            disabled={isSubmitLoading || !bolText.length}
          >
            {isSubmitLoading ? (
              <Icon src={faSpinner} spin />
            ) : (
              t("shipment-details:Submit")
            )}
          </Button>
        </FlexRowDiv>
      </Modal.Footer>
    </Modal>
  );
};
