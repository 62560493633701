/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import { renderToString } from "react-dom/server";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import {
  LadShape,
  getLadName,
  useLadsTranslation,
} from "modules/lads/utils/lads.utils";
import Colors from "styles/colors";

export const UNCLASSIFIED_CHICLET_COLORS = {
  primary: "grey",
  gradient: ["black", "grey"],
};

export const NAME_FOR_LAD_ID = {
  46: "Dealer",
  117: "Border Crossing",
  126: "Border Crossing",
  136: "Border Crossing",
  120: "Carrier Terminal",
  111: "Consolidation",
  123: "Consolidation",
  132: "Consolidation",
  112: "Cross-dock",
  133: "Cross-dock",
  110: "Customer Plant",
  122: "Customer Plant",
  130: "Dealer",
  140: "Dealer",
  116: "Distribution",
  125: "Distribution",
  135: "Distribution",
  113: "Logistics Center",
  118: "Ocean Port",
  127: "Ocean Port",
  137: "Ocean Port",
  119: "Processor",
  138: "Processor",
  121: "Rail Station",
  129: "Rail Station",
  139: "Rail Station",
  109: "Supplier",
  131: "Supplier",
  115: "Warehouse",
  134: "Warehouse",
  114: "Yard",
  124: "Yard",
};

export const CHICLET_COLORS = {
  Assembly: { primary: "#85171a", gradient: ["#85171a", "red"] },
  "Border Crossing": { primary: "#8B4512", gradient: ["#8B4512", "sienna"] },
  "Carrier Terminal": { primary: "#C0C0C0", gradient: ["gray", "silver"] },
  "Carrier Facility": { primary: "#999999", gradient: ["#999999", "#999999"] },
  Consolidation: {
    primary: "#c29a44",
    gradient: ["darkgoldenrod", "goldenrod"],
  },
  "Cross-dock": { primary: "#C0C0C0", gradient: ["gray", "silver"] },
  "Customer Plant": { primary: "#A63332", gradient: ["#A63332", "red"] },
  Dealer: { primary: "#5F3D8C", gradient: ["#5F3D8C", "black"] },
  " Dealer": { primary: "#5F3D8C", gradient: ["#5F3D8C", "black"] },
  Distribution: {
    primary: "#c29a44",
    gradient: ["darkgoldenrod", "goldenrod"],
  },
  "Final Destination": { primary: "#5F3D8C", gradient: ["#5F3D8C", "black"] },
  "Logistics Center": {
    primary: "#c29a44",
    gradient: ["darkgoldenrod", "goldenrod"],
  },
  "Mine Site": {
    primary: "#631D0D",
    gradient: ["#631D0D", "sienna"],
  },
  "Ocean Port": {
    primary: "#66CDAA",
    gradient: ["lightseagreen", "mediumaquamarine"],
  },
  "Parking Location": { primary: "#0000ff", gradient: ["#0000ff", "#0000ff"] },
  "Port of Entry": { primary: "#46947E", gradient: ["#46947E", "#46947E"] },
  Processor: { primary: "#758d3a", gradient: ["#758d3a", "forestgreen"] },
  "Rail Station": { primary: "#C0C0C0", gradient: ["gray", "silver"] },
  Restaurant: { primary: "#5F3D8C", gradient: ["#5F3D8C", "black"] },
  Supplier: { primary: "#2B538A", gradient: ["#2B538A", "deepskyblue"] },
  " Supplier": { primary: "#2B538A", gradient: ["#2B538A", "deepskyblue"] },
  Warehouse: { primary: "#c29a44", gradient: ["darkgoldenrod", "goldenrod"] },
  Yard: { primary: "#B65E28", gradient: ["#B65E28", "crimson"] },
  " Yard": { primary: "#B65E28", gradient: ["#B65E28", "crimson"] },
  Bailment: { primary: "#1a548e", gradient: ["#1a548e", "mediumaquamarine"] },
  Upfitter: { primary: "#758C3A", gradient: ["#758C3A", "forestgreen"] },
  Hospital: { primary: "#1071E5", gradient: ["#1071E5", "#1071E5"] },
  Unclassified: UNCLASSIFIED_CHICLET_COLORS,
  "Value-Add Service": { primary: "#7A8C46", gradient: ["#7A8C46", "#7A8C46"] },
};
// since we have mode coming e.g. "Truck" or "truck" therefore converting it to "Truck" format which is accepted in other part of the code.
const convertModes = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const letterForShipmentMode = (shipmentMode) => {
  if (convertModes(shipmentMode) === "Rail") {
    return "R";
  }
  if (convertModes(shipmentMode) === "LTL") {
    return "L";
  }
  if (convertModes(shipmentMode) === "Intermodal") {
    return "I";
  }
  if (convertModes(shipmentMode) === "Ocean") {
    return "O";
  }
  if (convertModes(shipmentMode) === "Air") {
    return "A";
  }
  if (convertModes(shipmentMode) === "Multimodal") {
    return "M";
  }
  if (convertModes(shipmentMode) === "Parcel") {
    return "P";
  }
  return "T";
};

const letterForStopMode = (stopMode) => {
  if (convertModes(stopMode) === "Rail") {
    return "R";
  }
  if (convertModes(stopMode) === "Intermodal") {
    return "I";
  }
  if (convertModes(stopMode) === "Ocean") {
    return "O";
  }
  if (convertModes(stopMode) === "Air") {
    return "A";
  }
  if (convertModes(stopMode) === "Multimodal") {
    return "M";
  }
  if (convertModes(stopMode) === "Parcel") {
    return "P";
  }
  return "T";
};

export const colorsForLad = (lad) => {
  if (lad) {
    // For canonical and default names, check if snake_case or if camelCase. Default to null.
    const name = lad.name;
    const canonicalName = lad.canonical_name ?? lad.canonicalName ?? null;
    const defaultName = lad.default_name ?? lad.defaultName ?? null;
    const idName = NAME_FOR_LAD_ID[lad.id];

    // Go through each name type and try to find a relevant color, default to unclassified
    return (
      _.get(CHICLET_COLORS, name, null) ??
      _.get(CHICLET_COLORS, canonicalName, null) ??
      _.get(CHICLET_COLORS, defaultName, null) ??
      _.get(CHICLET_COLORS, idName, null) ??
      UNCLASSIFIED_CHICLET_COLORS
    );
  }

  return UNCLASSIFIED_CHICLET_COLORS;
};

export const colorForLad = (lad) => colorsForLad(lad).primary;

export const gradientForLad = (lad) => {
  const [gradFrom, gradTo] = colorsForLad(lad).gradient;
  return `linear-gradient(${gradFrom}, ${gradTo})`;
};

// TODO:
//   - use open sans font by default and make settable

// uses <figure> and <figcaption> instead of <div> to avoid css
// contamination from react-table
export const ChicletCSS = ({
  codeLetter,
  backgroundColor,
  height = 60,
  width = 60,
  textColor = "white",
  gradientBackground = {},
  borderStyle = "none",
  active = false,
  ladLabel,
  lobLabel,
  numberLabel,
  square,
  style,
}) => {
  const fontSize = `${_.floor(height / 24, 1)}em`;

  return (
    <figure
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: square ? "0px" : "3px",
        borderStyle: active ? "solid" : null,
        borderColor: active ? "rgba(255,255,255,.7)" : null,
        position: "relative",
        gradientBackground: gradientBackground,
        border: borderStyle,
        cursor: "default",
        ...style,
      }}
    >
      {lobLabel ? (
        <figcaption
          css={{
            top: 3,
            right: 3,
            fontSize: 5,
            fontWeight: "400",
            position: "absolute",
          }}
        >
          {lobLabel}
        </figcaption>
      ) : null}
      {ladLabel ? (
        <figcaption
          css={{
            bottom: 3,
            fontSize: 5,
            fontWeight: "400",
            position: "absolute",
          }}
        >
          {ladLabel}
        </figcaption>
      ) : null}
      {numberLabel ? (
        <figcaption
          css={{
            bottom: 1,
            right: 2,
            textAlign: "right",
            fontSize: 8,
            fontWeight: "400",
            position: "absolute",
          }}
        >
          {numberLabel}
        </figcaption>
      ) : null}
      <figcaption
        css={{
          fontSize: fontSize,
          fontWeight: "400",
          textTransform: "uppercase",
        }}
      >
        {codeLetter}
      </figcaption>
    </figure>
  );
};

ChicletCSS.propTypes = {
  codeLetter: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  weight: PropTypes.number,
  textColor: PropTypes.string,
  gradientBackground: PropTypes.object,
  borderStyle: PropTypes.string,
  active: PropTypes.bool,
  ladLabel: PropTypes.string,
  lobLabel: PropTypes.string,
  numberLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  square: PropTypes.bool,
  style: PropTypes.object,
};

export const LadChicletCSS = ({
  lad,
  gradient,
  showLadLabel,
  showLobLabel,
  ...props
}) => {
  const { getTranslatedLobName, getTranslatedLadName } = useLadsTranslation();
  const backgroundColor = colorForLad(lad);
  const gradientBackground = gradient
    ? { backgroundImage: gradientForLad(lad) }
    : {};

  const codeLetter = lad?.code?.toUpperCase();

  const ladLabel = showLadLabel
    ? _.truncate(getTranslatedLadName(getLadName(lad)), {
        length: 20,
        omission: "",
      })
    : null;

  const lobLabel = showLobLabel
    ? _.truncate(getTranslatedLobName(lad.lob_name), {
        length: 20,
        omission: "",
      })
    : null;

  return (
    <ChicletCSS
      codeLetter={codeLetter}
      backgroundColor={backgroundColor}
      gradientBackground={gradientBackground}
      ladLabel={ladLabel}
      lobLabel={lobLabel}
      {...props}
    />
  );
};

LadChicletCSS.propTypes = {
  lad: PropTypes.shape(LadShape),
  height: PropTypes.number,
  width: PropTypes.number,
  textColor: PropTypes.string,
  gradient: PropTypes.bool,
  showLadLabel: PropTypes.bool,
  showLobLabel: PropTypes.bool,
};

export const NumberedLadChicletCSS = ({
  lad,
  numberLabel,
  gradient,
  ...props
}) => {
  const backgroundColor = colorForLad(lad);
  const gradientBackground = gradient
    ? { backgroundImage: gradientForLad(lad) }
    : {};

  const codeLetter = lad?.code?.toUpperCase();

  return (
    <ChicletCSS
      codeLetter={codeLetter}
      backgroundColor={backgroundColor}
      gradientBackground={gradientBackground}
      numberLabel={numberLabel}
      {...props}
    />
  );
};

NumberedLadChicletCSS.propTypes = {
  lad: PropTypes.shape(LadShape),
  numberLabel: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  textColor: PropTypes.string,
  gradient: PropTypes.bool,
};

export const MadChicletCSS = ({
  shipmentMode,
  stopMode,
  activeException,
  hideBorder = false,
  ...props
}) => {
  let codeLetter = letterForShipmentMode(shipmentMode);
  const stopCodeLetter = letterForStopMode(stopMode);
  if (codeLetter !== stopCodeLetter) {
    codeLetter = stopCodeLetter;
  }

  const borderSize = props.height / 9;
  const borderColor = exceptionStringToColor(activeException);
  const border = hideBorder ? null : `${borderSize}px solid ${borderColor}`;

  return (
    <ChicletCSS
      codeLetter={codeLetter}
      backgroundColor={Colors.background.DARK_BLUE}
      borderStyle={border}
      {...props}
    />
  );
};

MadChicletCSS.propTypes = {
  shipmentMode: PropTypes.string,
  stopMode: PropTypes.string,
  activeException: PropTypes.string,
  hideBorder: PropTypes.bool,
  height: PropTypes.number,
};

export const CustomSearchChicletCSS = ({
  backgroundColor,
  height = 60,
  width = 60,
  textColor = "white",
}) => {
  const { t } = useTranslation("components");
  const fontSize = `${_.floor(height / 7, 7)}px`;
  return (
    <figure
      css={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: "3px",
      }}
    >
      <FaSearch
        height={height / 2.5}
        width={width / 2.5}
        style={{ position: "absolute" }}
      />
      <figcaption
        css={{
          fontSize: fontSize,
          fontWeight: "400",
          textAlign: "center",
          position: "absolute",
          bottom: 2,
        }}
      >
        {t("Search")}
      </figcaption>
    </figure>
  );
};

CustomSearchChicletCSS.propTypes = {
  backgroundColor: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  textColor: PropTypes.string,
};

export const PartNumberChicletCSS = ({
  backgroundColor,
  height = 60,
  width = 60,
  textColor = "white",
  lad = null,
}) => {
  const fontSize = lad
    ? _.floor(height / 4, 7)
    : `${_.floor(height / 2.75, 12)}px`;

  return (
    <figure
      css={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: lad ? "left" : "center",
        justifyContent: lad ? "left" : "space-around",
        width: width,
        height: height,
        backgroundColor: lad ? "none" : backgroundColor,
        background: lad
          ? `linear-gradient(-45deg, ${colorForLad(
              lad,
            )} 50%, ${backgroundColor} 50%)`
          : "none",
        color: textColor,
        borderRadius: "3px",
      }}
    >
      <figcaption
        css={{
          fontSize: fontSize,
          fontWeight: "400",
          textAlign: lad ? "left" : "center",
          position: lad ? "absolute" : "relative",
          paddingLeft: lad ? "0.5em" : null,
        }}
      >
        Part
        <br />#
      </figcaption>

      {lad && (
        <figcaption
          css={{
            fontSize: fontSize * 2,
            fontWeight: "400",
            textAlign: "center",
            position: "absolute",
            right: 0,
            bottom: 0,
            paddingRight: "0.25em",
          }}
        >
          {lad.code.toUpperCase()}
        </figcaption>
      )}
    </figure>
  );
};

PartNumberChicletCSS.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  textColor: PropTypes.string,
  lad: PropTypes.object,
};

export const BoxChicletSVG = ({
  codeLetter,
  backgroundColor,
  decorator = null,
  decoratorColor = "white",
  decoratorFill = "black",
  borderColor = "transparent",
  textColor = "white",
  height = 64,
  width = 64,
  textSubscript = null,
  boxTextSize,
  boxTextX,
  boxTextY,
  boxRadius = 2,
  boxSubscriptTextSize = 12,
  boxSubscriptTextY = 10,
}) => {
  const computeCircleRadius = () => {
    if (decorator === null) {
      return 0;
    }

    const extraLength = _.max([0, decorator.toString().length - 2]);

    return 16 + extraLength * 4;
  };
  const circleRadius = computeCircleRadius();

  const boxOffsetY = circleRadius + 1;
  const boxHeight = height - circleRadius;
  const boxWidth = width - circleRadius;

  const textSize = boxTextSize ?? boxHeight / 2;
  const textX = boxTextX ?? boxWidth / 2;
  const textY = boxTextY ?? boxHeight / 2 + textSize / 2 + boxOffsetY;
  const circleTextSize = boxHeight / 3;

  let circleElem = decorator ? (
    <g>
      <circle
        style={{ fill: decoratorFill }}
        stroke={decoratorColor}
        cx={boxWidth}
        cy={boxOffsetY}
        r={circleRadius}
      />
      <text
        x={boxWidth}
        alignmentBaseline="middle"
        y={boxOffsetY}
        fontSize={`${circleTextSize}pt`}
        fontFamily="sans-serif"
        fontWeight={600}
        textAnchor="middle"
        fill={decoratorColor}
      >
        {decorator}
      </text>
    </g>
  ) : null;

  return renderToString(
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <rect
        stroke={borderColor}
        strokeWidth={textSize / 2.5}
        fill={backgroundColor}
        x={1}
        y={boxOffsetY}
        rx={boxRadius}
        ry={boxRadius}
        width={boxWidth - 2}
        height={boxHeight - 2}
      />
      <text
        x={textX}
        y={textY}
        fontSize={`${textSize}pt`}
        fontFamily="sans-serif"
        fontWeight={300}
        textAnchor="middle"
        fill={textColor}
      >
        {codeLetter}
        {textSubscript ? (
          <tspan fontSize={`${boxSubscriptTextSize}pt`} dy={boxSubscriptTextY}>
            {textSubscript}
          </tspan>
        ) : null}
      </text>
      <polygon
        points="6,16 14,16 10,20"
        style={{ fill: backgroundColor, stroke: "transparent" }}
      />
      {circleElem}
    </svg>,
  );
};

export const PinChicletSVG = ({
  codeLetter,
  backgroundColor,
  borderColor = "black",
  textColor = "white",
  height = 64,
  width = 64,
  textSubscript = null,
}) => {
  return renderToString(
    <svg
      width={width}
      height={height}
      viewBox="-16 -18 72 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="rgba(0,0,0,.2)"
        stroke="none"
        transform="translate(13,48) scale(1,0.5) rotate(40) translate(7,-24)"
        d="M0,47 Q0,28 10,15 A15,15 0,1,0 -10,15 Q0,28 0,47"
      />
      <path
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth="1"
        transform="translate(15,15)"
        d="M0,47 Q0,28 10,15 A15,15 0,1,0 -10,15 Q0,28 0,47"
      />
      <text
        x="14"
        y="25"
        fontSize="14pt"
        fontFamily="sans-serif"
        fontWeight="300"
        textAnchor="middle"
        fill={textColor}
      >
        {codeLetter}
        {textSubscript ? (
          <tspan fontSize="12pt" dy="10">
            {textSubscript}
          </tspan>
        ) : null}
      </text>
    </svg>,
  );
};

export const AlertChicletSVG = ({
  codeLetter = "!",
  backgroundColor,
  textColor = "white",
  height = 64,
  width = 64,
}) => {
  return renderToString(
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill={backgroundColor} cx="16" cy="16" r="16" />
      <text
        x="15"
        y="21"
        fontSize="14pt"
        fontFamily="sans-serif"
        fontWeight="600"
        textAnchor="middle"
        fill={textColor}
      >
        {codeLetter}
      </text>
    </svg>,
  );
};

export const LadChicletSVG = ({
  lad,
  chicletStyle = PinChicletSVG,
  ...props
}) => {
  let codeLetter = "U";
  if (lad && lad.code) {
    codeLetter = lad.code.toUpperCase();
  }
  const backgroundColor = colorForLad(lad);
  const borderRadius = 4;

  return chicletStyle({
    codeLetter,
    backgroundColor,
    decoratorColor: backgroundColor,
    decoratorFill: "white",
    borderColor: "transparent",
    borderRadius,
    ...props,
  });
};

const exceptionStringToColor = (str) => {
  if (!str) {
    return Colors.highlight.GREEN;
  } else if (
    str === "Behind Schedule" ||
    str === "Idle Trailer" ||
    str === "Car/Train Idle" ||
    str === "Under Review" ||
    str === "Early Arrival" ||
    str === "Excessive Dwell"
  ) {
    return Colors.highlight.YELLOW;
  }

  return Colors.highlight.RED;
};

export const MadChicletSVG = ({
  shipmentMode,
  stopMode,
  activeException,
  shipmentCount = 1,
  chicletStyle = BoxChicletSVG,
  ...props
}) => {
  let codeLetter = letterForShipmentMode(shipmentMode);
  const stopCodeLetter = letterForStopMode(stopMode);
  if (codeLetter !== stopCodeLetter) {
    codeLetter = stopCodeLetter;
  }

  const decorator = shipmentCount > 1 ? shipmentCount : null;

  const borderColor = exceptionStringToColor(activeException);
  const backgroundColor = Colors.background.DARK_BLUE;
  const decoratorFill = borderColor;

  return chicletStyle({
    codeLetter,
    backgroundColor,
    borderColor,
    decorator,
    decoratorFill,
    ...props,
  });
};

export const BreadCrumbSVG = ({
  backgroundColor = "white",
  borderColor = "cornflowerblue",
  height = 16,
  width = 16,
}) => {
  const circleRadius = width / 4;

  return renderToString(
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <circle
        style={{ fill: backgroundColor }}
        stroke={borderColor}
        strokeWidth={4}
        cx={width / 2}
        cy={height / 2}
        r={circleRadius}
      />
    </svg>,
  );
};

export const CircleChicletSVG = ({
  text,
  backgroundColor = "black",
  borderColor = "transparent",
  textColor = "white",
  radius = 50,
  shadow = false,
}) => {
  const cx = radius * 2;
  const cy = radius * 2;
  const width = radius * 4;
  const height = radius * 4;

  return renderToString(
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g>
        {shadow ? (
          <circle
            fill={backgroundColor}
            cx={cx}
            cy={cy}
            strokeWidth={0}
            fillOpacity="0.4"
            r={radius * 1.5}
          />
        ) : null}
        <circle
          fill={backgroundColor}
          cx={cx}
          cy={cy}
          stroke={borderColor}
          strokeWidth={1}
          r={radius}
        />
        <text
          x="50%"
          y="50%"
          alignmentBaseline="middle"
          fontSize={"30pt"}
          fontFamily="sans-serif"
          textAnchor="middle"
          fill={textColor}
        >
          {text}
        </text>
      </g>
    </svg>,
  );
};
