/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { Icon } from "components/atoms/Icon.atom";

import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import PartViewEntityDetailsState from "../../../redux/PartViewEntityDetailsState";
import { usePartViewExceptionLabel } from "../../../components/hooks/usePartViewExceptionLabel";
import { getIconData } from "pages/partview/utils/exceptions.utils";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import Colors from "styles/colors";

const WATCH_COLUMN_ID = "watch";

const useWatchedTableColumns = ({ isPartSeller, fetchWatchedPackages }) => {
  const { t } = useTranslation("partview-dashboard");
  const { getFullTranslatedNameForException } = usePartViewExceptionLabel();

  const [unwatchedList, setUnwatchedList] = useState([]);
  let unwatchTimers = useRef({});

  const toggleWatch = (trackingNumber) => {
    let newUnwatchedPackages = [...unwatchedList];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers.current[trackingNumber]) {
      clearTimeout(unwatchTimers.current[trackingNumber]);
    }

    if (newUnwatchedPackages.includes(trackingNumber)) {
      // Checkbox has already been unchecked -> re-watch it
      newUnwatchedPackages = newUnwatchedPackages.filter(
        (unwatchedTrackingNumber) => unwatchedTrackingNumber !== trackingNumber,
      );
    } else {
      // Checkbox is checked -> unwatch it
      newUnwatchedPackages.push(trackingNumber);
    }

    setUnwatchedList(newUnwatchedPackages);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers.current[trackingNumber] = setTimeout(() => {
      fetchWatchedPackages();
    }, 2000);
  };

  const columns = [
    {
      Header: t("partview-dashboard:Watch"),
      id: WATCH_COLUMN_ID,
      accessor: "Watched",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const dispatch = useDispatch();
        const id = cellInfo.row.original.id;
        const trackingNumber = cellInfo.row.original.TrackingNumber;
        const watched = cellInfo.value === true;
        return (
          <WatchToggle
            key={id}
            checked={watched ?? false}
            onChange={(newWatchValue) => {
              dispatch(
                PartViewEntityDetailsState.actionCreators.setWatchPackage(
                  trackingNumber,
                  newWatchValue,
                ),
              );
              toggleWatch(trackingNumber);
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("partview-dashboard:Package Number"),
      accessor: "TrackingNumber",
      Cell: (cellInfo) => {
        return (
          <div>
            {cellInfo.row.original?.commentsCount > 0 ? (
              <Tooltip
                placement="top"
                tooltipChildren={
                  <Text>
                    {t("partview-dashboard:This package contains comments")}
                  </Text>
                }
              >
                <Icon
                  src={faCommentAlt}
                  color={Colors.comments.unreadCommentIcon}
                  style={{ marginRight: 8 }}
                />
              </Tooltip>
            ) : null}
            <Text>{cellInfo.value}</Text>
          </div>
        );
      },
    },
    {
      Header: t("partview-dashboard:Last Milestone"),
      accessor: "LastMilestone",
      Cell: (cellInfo) => {
        return cellInfo.value?.location?.name;
      },
    },
    {
      Header: t("partview-dashboard:ETA"),
      accessor: "DestinationEta", // used eta value from "DestinationEta"
      Cell: (cellInfo) => {
        let lifecycleState = cellInfo.row.original.LifecycleState;
        const heldExceptionDateTime =
          cellInfo.row.original?.ActiveExceptionList.filter(
            (exception) => exception.name.toLowerCase() === "held",
          )[0]?.resolvedDateTime ?? null;
        let eta = cellInfo.value ?? null;

        if (!eta) {
          return null;
        }

        if (lifecycleState?.toLowerCase() === "delivered") {
          return t("partview-search:Delivered");
        } else {
          if (heldExceptionDateTime) {
            return (
              <Text>
                {t("partview-search:TBD until")}{" "}
                <DateTime
                  plain
                  localize
                  dateTime={heldExceptionDateTime}
                  fallback={t("partview-search:N/A")}
                >
                  <DateTime.Time style={{ display: "none" }} />
                  <DateTime.Timezone style={{ display: "none" }} />
                </DateTime>
              </Text>
            );
          } else if (
            eta.toLowerCase() === "hold" ||
            eta.toLowerCase() === "tbd"
          ) {
            return <Text>{t("partview-search:TBD")}</Text>;
          } else {
            return (
              <DateTime
                plain
                localize
                dateTime={eta}
                fallback={t("partview-search:N/A")}
              >
                <DateTime.Time style={{ display: "none" }} />
                <DateTime.Timezone style={{ display: "none" }} />
              </DateTime>
            );
          }
        }
      },
    },
  ];

  if (!isPartSeller) {
    columns.push({
      Header: t("partview-dashboard:Active Exceptions"),
      accessor: "ActiveExceptionList",
      Cell: (cellInfo) => {
        let exceptions = cellInfo.value ?? [];

        if (!Array.isArray(exceptions)) {
          return null;
        }

        return (
          <div css={{ display: "flex", flexDirection: "column" }}>
            {exceptions.map((exception, i) => {
              const name = getFullTranslatedNameForException(exception);
              const icon = getIconData(exception.reasonCode);
              return (
                <div
                  key={i}
                  css={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {icon ? (
                    <Icon
                      type={icon.type}
                      src={icon.src}
                      color={icon.color}
                      altText={icon.altText}
                      style={{
                        width: 15,
                        height: 15,
                        ...icon.style,
                      }}
                    />
                  ) : null}
                  <Text>{name}</Text>
                </div>
              );
            })}
          </div>
        );
      },
    });
  }
  return { columns, unwatchedList };
};

export const WatchedPackages = ({
  watchedPackages,
  isLoading,
  pageIndex,
  pageSize,
  pageCount,
  fetchWatchedPackages,
  pushPartViewDetailsPage,
  setPageIndex,
  isPartSeller = false,
  selectedFvOrgId = null,
  isDealerOrg = false,
}) => {
  const { t } = useTranslation("partview-dashboard");

  const { columns, unwatchedList } = useWatchedTableColumns({
    isPartSeller,
    fetchWatchedPackages,
  });

  useEffect(() => {
    fetchWatchedPackages();
  }, [fetchWatchedPackages, pageIndex]);

  return (
    <PanelGroup collapsible style={{ marginBottom: "10px" }}>
      <PanelGroup.Header title={t("partview-dashboard:My Watched Packages")} />
      <PanelGroup.Content style={{ padding: 0 }}>
        <BaseTable
          theme={Themes.LIGHT}
          data={watchedPackages}
          columns={columns}
          isLoading={isLoading}
          rowClickHandler={(row, cell) => {
            // Prevent navigation if clicking in "unwatch" checkbox cell
            if (cell.column.id === WATCH_COLUMN_ID) {
              return;
            }

            // Navigate to Package Details when clicking row
            pushPartViewDetailsPage(
              row.original.TrackingNumber,
              selectedFvOrgId,
              isDealerOrg,
            );
          }}
          showPagination={true}
          showPageSizeDropdown={false}
          isManualPagination={true}
          pageCount={pageCount}
          pageSize={pageSize}
          pageIndex={pageIndex}
          onPageChange={(newPage) => {
            setPageIndex(newPage);
          }}
          rowDisabledHandler={(row) => {
            return unwatchedList.includes(row.original.TrackingNumber);
          }}
        />
      </PanelGroup.Content>
    </PanelGroup>
  );
};

WatchedPackages.propTypes = {
  fetchWatchedPackages: PropTypes.func.isRequired,
  watchedPackages: PropTypes.array,
  isLoading: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  setPageIndex: PropTypes.func.isRequired,
  pushPartViewDetailsPage: PropTypes.func.isRequired,
  isPartSeller: PropTypes.bool,
  selectedFvOrgId: PropTypes.string,
  isDealerOrg: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushPartViewDetailsPage: (
      trackingNumber,
      selectedFvOrgId = null,
      isDealerOrg = false,
    ) => {
      if (isDealerOrg || selectedFvOrgId) {
        dispatch({
          type: "DEALER_PARTVIEW_DETAILS",
          payload: { trackingNumber: trackingNumber },
        });
      } else {
        dispatch({
          type: "PARTVIEW_DETAILS",
          payload: { trackingNumber: trackingNumber },
        });
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(WatchedPackages);
