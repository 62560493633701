/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import TableMetaTotalRecords from "components/organisms/base-table/MetaData/TableMetaTotalRecords";
import ValidityFilter from "./components/ValidityFilter";
import UnresolvedLocationTable from "./components/UnresolvedLocationTable";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";

export const LocationResolutionListPage = ({
  authorization,
  activeOrganization,
  fetchUnresolvedLocationsSummary,
  unresolvedLocations,
  totalUnresolvedLocations,
  isLoading,
  page,
  pageSize,
  totalPages,
  setPagination,
  sortColumn,
  reverseSort,
  setSort,
  searchFilters,
  setSearchFilter,
  clearSearchFilters,
  setShipmentWithUnresolvedLocation,
  pushLocationMatchingView,
  exportIdentifier,
  exportName,
  isExporting,
  exportFailed,
  exportLocations,
  resetExport,
  // The app's route location.
  location,
}) => {
  const { t } = useTranslation("locations");

  useEffect(() => {
    let prevLocationType = location.prev.type;
    // Prevent refetching search when we just resolved a location.
    let resetPagination = !(
      prevLocationType === "LOCATION_MATCHING" ||
      prevLocationType === "CREATE_LOCATION"
    );

    fetchUnresolvedLocationsSummary(null, resetPagination);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parentStyle = {
    backgroundColor: "#fff",
    border: "1px solid #dee2e6",
    borderTop: 0,
    padding: "1rem",
  };

  return (
    <div style={parentStyle}>
      <ExportAlert exportFailed={exportFailed} className="mt-2" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TableMetaTotalRecords
          total={totalUnresolvedLocations ?? 0}
          label={t("locations:Locations")}
        />
        <div className="d-flex">
          <DownloadDataButton
            onClick={() => {
              exportLocations();
            }}
            isExporting={isExporting}
            isDisabled={unresolvedLocations.length === 0}
            data-qa="locations-export"
          />
          <ValidityFilter
            setFilter={(key, value) => {
              setSearchFilter(key, value);
              fetchUnresolvedLocationsSummary();
            }}
          />
        </div>
      </div>
      <UnresolvedLocationTable
        fetchUnresolvedLocationsSummary={fetchUnresolvedLocationsSummary}
        data={unresolvedLocations}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        setPagination={setPagination}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        setSort={setSort}
        searchFilters={searchFilters}
        setSearchFilter={setSearchFilter}
        clearSearchFilters={clearSearchFilters}
        rowClickHandler={(rowInfo) => {
          const locID = rowInfo.original.location_id;
          setShipmentWithUnresolvedLocation(null);
          pushLocationMatchingView(locID);
        }}
      />
      <ExportModal
        exportIdentifier={exportIdentifier}
        exportName={exportName}
        resetExport={resetExport}
      />
    </div>
  );
};

LocationResolutionListPage.propTypes = {
  activeOrganization: PropTypes.any,
  authorization: PropTypes.object,
  fetchUnresolvedLocationsSummary: PropTypes.func.isRequired,
  unresolvedLocations: PropTypes.array,
  isLoading: PropTypes.bool,
  totalUnresolvedLocations: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setPagination: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  setSort: PropTypes.func.isRequired,
  searchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  pushLocationMatchingView: PropTypes.func.isRequired,
  setShipmentWithUnresolvedLocation: PropTypes.func.isRequired,
  location: PropTypes.object,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  isExporting: PropTypes.bool,
  exportFailed: PropTypes.bool,
  exportLocations: PropTypes.func.isRequired,
  resetExport: PropTypes.object,
};
