import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import {
  damageAreaUrl,
  assigneesUrl,
  partnersUrl,
  currentPositionCodesUrl,
  productTypesUrl,
} from "../components/search/DamageViewDomainData";
import ProfileState from "modules/profile/ProfileState";

export const damageViewAssigneesOptionsState = buildAsyncFilterOptionsState({
  topic: "damageViewAssigneeFilter",
  getUrl: (solutionId, state) => {
    return assigneesUrl(solutionId, state);
  },
  pageSize: 20,
  getResponseData: (data) => {
    return data?.data ?? [];
  },
  transformResult: (data) => {
    return {
      label: data,
      value: data,
    };
  },
});

export const damageViewPartnersOptionsState = buildAsyncFilterOptionsState({
  topic: "damageViewPartnerFilter",
  getUrl: (solutionId, state) => {
    return partnersUrl(solutionId, state);
  },
  pageSize: 20,
  getResponseData: (data) => {
    return data?.data ?? [];
  },
  transformResult: (data) => {
    return {
      label: data?.org_name,
      value: data?.fv_id,
    };
  },
});

export const currentPositionsCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "damageViewCurrentPositionCodeFilter",
  getUrl: currentPositionCodesUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: `${result?.value} (${result?.code})`,
    value: result?.id,
  }),
});

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "damageViewProductTypeFilter",
  getUrl: productTypesUrl,
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const damageAreaOptionsState = buildAsyncFilterOptionsState({
  topic: "damageViewDamageAreaFilter",
  getUrl: damageAreaUrl,
  pageSize: 20,
  getAdditionalParams: (state) => {
    const userPreference = ProfileState.selectors.getUserPreferences(state);
    return {
      locale: userPreference?.locale ?? "en",
    };
  },
  getResponseData: (data) => data?.data ?? [],
  transformResult: (option) => ({ label: option.value, value: option.label }),
});
