/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { useState, useEffect, useMemo } from "react";
import Loader from "react-loader";
import { useTranslation } from "react-i18next";
import { Modal, Form } from "react-bootstrap";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import { useCarrierDelayedReasonCode } from "pages/shipments/utils/exception.utils";
import { Text, FontSize } from "components/atoms/Text.atom";
import SelectField from "components-old/forms/fields/SelectField";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import Colors from "styles/colors";

export const ReportCarrierDelayModal = ({
  show,
  hide,
  isOrgTypeShipper,
  fetchDelayOptions,
  reportDelayOptionsList,
  reportDelayOptionsIsLoading,
  reportDelayOptionsIsLoadingError,
  reportDelay,
  isSubmitLoading,
  responseStatusCode,
  errorResponse,
}) => {
  const { t } = useTranslation("shipment-details");
  const { getOptionLabelFromCode } = useCarrierDelayedReasonCode();

  // State for form fields.
  const [reasonCode, setReasonCode] = useState(null);
  const [newEta, setNewEta] = useState();
  const [comments, setComments] = useState("");
  const [shipmentIDs, setShipmentIDs] = useState({
    importText: "",
    importShipmentIDs: "",
  });
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    fetchDelayOptions();
  }, [fetchDelayOptions]);

  useEffect(() => {
    if (responseStatusCode) {
      setShowAlert(true);
    }
  }, [responseStatusCode]);

  useEffect(() => {
    //SH-7752 - When the yellow/ green banner message is displayed,
    //all modal inputs are cleared and the submit button is disabled
    if (responseStatusCode === 201 || responseStatusCode === 408) {
      resetStopData();
    }
  }, [responseStatusCode]);

  function resetStopData() {
    setReasonCode(null);
    setNewEta();
    setComments("");
    setShipmentIDs({
      importText: "",
      importShipmentIDs: "",
    });
  }

  const delayOptionsList = useMemo(() => {
    return reportDelayOptionsList?.map((code) => {
      return { label: getOptionLabelFromCode(code.code), value: code.code };
    });
  }, [getOptionLabelFromCode, reportDelayOptionsList]);

  // Event handlers.
  const onReasonChange = ({ value }) => {
    setReasonCode(value);
  };

  const onNewEtaChange = (dateTime) => {
    setNewEta(dateTime);
  };

  const onCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const onModalClose = () => {
    hide();
    setShowAlert(false);
    //SH-7752 - When the user returns to Actions | Report Destination Arrival Estimate,
    //the modal should be blank.
    resetStopData();
  };

  const canSubmit =
    !reportDelayOptionsIsLoading &&
    !isSubmitLoading &&
    !_.isNil(reasonCode) &&
    !_.isNil(newEta);

  const onSubmitClick = () => {
    setShowAlert(false);
    const { importShipmentIDs } = shipmentIDs;
    if (canSubmit) {
      if (isOrgTypeShipper) {
        reportDelay(reasonCode, newEta, comments, importShipmentIDs);
      } else {
        reportDelay(reasonCode, newEta, comments);
      }
    }
  };

  const textChangeHandler = (e) => {
    const batchText = e && e.target && e.target.value ? e.target.value : "";
    const lines = batchText.split(/\r\n|\n/);
    const batchLines = lines.map((x) => x.trimStart()).join("\r\n");

    setShipmentIDs({ importText: batchLines, importShipmentIDs: lines });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onModalClose();
      }}
    >
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
      >
        <Modal.Title>
          {t("shipment-details:Report Destination Arrival Estimate")}
          <span
            css={{
              position: "relative",
              ".loadedContent": { display: "inline" },
            }}
          >
            <Loader
              style={{ display: "inline-block" }}
              loaded={!reportDelayOptionsIsLoading}
              left="1em"
              scale={0.5}
            />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body css={{ background: Colors.background.LIGHT_GRAY }}>
        <Alert
          show={reportDelayOptionsIsLoadingError}
          variant={AlertVariant.Danger}
          dismissible
        >
          <Text block size={FontSize.size16}>
            {t(
              "shipment-details:Something went wrong. Please try again later.",
            )}
          </Text>
        </Alert>
        <Form.Group css={{ marginBottom: "1rem" }}>
          <Form.Label htmlFor="reason-for-delay">
            <Text>{t("shipment-details:Reason")}</Text>
          </Form.Label>
          <SelectField
            id="reason-for-delay"
            placeholder={t("shipment-details:Select the reason for delay")}
            isLoading={reportDelayOptionsIsLoading}
            options={delayOptionsList}
            stateValue={reasonCode}
            onChange={onReasonChange}
          />
        </Form.Group>
        <Form.Group css={{ marginBottom: "1rem" }}>
          <Form.Label htmlFor="new-eta-datetime">
            <Text>{t("shipment-details:New ETA for Delivery")}</Text>
          </Form.Label>
          <DateTimePicker
            id="new-eta-datetime"
            data-qa="eta-datetime"
            value={newEta}
            onChange={onNewEtaChange}
            min={moment().toDate()}
            // SH-8735: Format requested by customer
            format="MMM DD, YYYY HH:mm"
            timeFormat="HH:mm"
          />
        </Form.Group>
        <Form.Group css={{ marginBottom: "1rem" }}>
          <Form.Label htmlFor="delay-comments">
            <Text block>{t("shipment-details:Comments (Optional)")}</Text>
          </Form.Label>
          <Form.Control
            as="textarea"
            id="delay-comments"
            aria-describedby="comments-help-text"
            value={comments}
            onChange={onCommentsChange}
          />
        </Form.Group>
        {isOrgTypeShipper ? (
          <Form.Group css={{ marginBottom: "1rem" }}>
            <label css={{ marginBottom: "0.5rem" }}>
              {`${t("shipment-details:Enter Shipment IDs")} (${t(
                "shipment-details:Line Separated",
              )})`}
            </label>
            <textarea
              className="form-control"
              rows={5}
              value={shipmentIDs.importText ?? ""}
              onChange={textChangeHandler}
              data-qa="textarea-batch-modal"
            ></textarea>
          </Form.Group>
        ) : null}

        <Form.Group css={{ marginBottom: "1rem" }}>
          {isOrgTypeShipper ? (
            <Form.Text id="comments-help-text">
              <Text block color={Colors.text.GRAY} size={FontSize.size12}>
                {t(
                  "shipment-details:The Enter Shipment IDs field is optional. Leave blank if updating only the selected shipment. This only applies to Truck and Rail shipments. Shipments of other modes will not be updated with a delay. If updating multiple shipments, the same reason, ETA, and comments will be applied.",
                )}
              </Text>
            </Form.Text>
          ) : (
            <Form.Text id="comments-help-text">
              <Text block color={Colors.text.GRAY} size={FontSize.size12}>
                {t("shipment-details:Enter comments about the delay here.")}
              </Text>
              <Text block color={Colors.text.GRAY} size={FontSize.size12}>
                {t(
                  "shipment-details:Please note these will be shared with the shipper.",
                )}
              </Text>
            </Form.Text>
          )}
        </Form.Group>

        <Alert
          className="mt-1 mb-0"
          show={showAlert}
          onClose={() => setShowAlert(false)}
          variant={
            responseStatusCode === 201
              ? AlertVariant.Success
              : responseStatusCode === 408
              ? AlertVariant.Warning
              : AlertVariant.Danger
          }
          dismissible
        >
          {responseStatusCode === 201 ? (
            <Text block size={FontSize.size14}>
              {t("shipment-details:Successfully reported delay(s).")}
            </Text>
          ) : responseStatusCode === 408 ? (
            <Text block size={FontSize.size14}>
              {t(
                "shipment-details:Batch ETA update is processing. Please review the shipments after a few minutes.",
              )}
            </Text>
          ) : (
            <Text block size={FontSize.size14}>
              {/* Get error message(s) from `errorResponse`. If none, fallback to generic message */}
              {errorResponse?.errors ??
                t("shipment-details:Something went wrong.")}
            </Text>
          )}
        </Alert>
      </Modal.Body>
      <Modal.Footer style={{ background: Colors.background.GRAY }}>
        <Button
          variant="outline-secondary"
          disabled={isSubmitLoading}
          css={{
            marginRight: "0.5em",
          }}
          onClick={() => {
            onModalClose();
          }}
          data-qa="button-close-report-delay-modal"
        >
          {t("shipment-details:Close")}
        </Button>
        <Button
          variant="success"
          onClick={onSubmitClick}
          disabled={!canSubmit}
          data-qa="button-submit-report-delay"
        >
          {isSubmitLoading ? (
            <Icon src={faSpinner} spin />
          ) : (
            t("shipment-details:Submit")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ReportCarrierDelayModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  isOrgTypeShipper: PropTypes.bool,
  fetchDelayOptions: PropTypes.func,
  reportDelayOptionsList: PropTypes.array,
  reportDelayOptionsIsLoading: PropTypes.bool,
  reportDelayOptionsIsLoadingError: PropTypes.bool,
  reportDelay: PropTypes.func.isRequired,
  isSubmitLoading: PropTypes.bool,
  responseStatusCode: PropTypes.number,
  errorResponse: PropTypes.object,
};

export const ClearCarrierDelayModal = ({
  show,
  hide,
  clearDelay,
  isSubmitLoading,
  errorMessage,
}) => {
  const { t } = useTranslation("shipment-details");

  const onSubmitClick = () => {
    clearDelay(hide);
  };

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
      >
        <Modal.Title>{t("shipment-details:Clear Delay")}</Modal.Title>
      </Modal.Header>
      <Modal.Body css={{ background: Colors.background.LIGHT_GRAY }}>
        <Text>
          {t(
            "shipment-details:Are you sure you want to clear the Carrier Delayed exception from this shipment?",
          )}
        </Text>
        <Alert
          className="mt-1 mb-0"
          show={!_.isEmpty(errorMessage)}
          variant={AlertVariant.Danger}
          dismissible
        >
          <Text block size={FontSize.size16}>
            {t("shipment-details:Something went wrong")}
          </Text>
          <Text block size={FontSize.size14}>
            {errorMessage}
          </Text>
        </Alert>
      </Modal.Body>
      <Modal.Footer css={{ background: Colors.background.GRAY }}>
        <Button
          variant="outline-secondary"
          css={{
            marginRight: "0.5em",
          }}
          onClick={hide}
          data-qa="button-close-report-delay-modal"
        >
          {t("shipment-details:Close")}
        </Button>
        <Button
          variant="success"
          onClick={onSubmitClick}
          data-qa="button-submit-report-delay"
          disabled={isSubmitLoading}
        >
          {isSubmitLoading ? (
            <Icon src={faSpinner} spin />
          ) : (
            t("shipment-details:Submit")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ClearCarrierDelayModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  clearDelay: PropTypes.func.isRequired,
  isSubmitLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
};
